import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../style/theme';

export const StyledFormDiv = styled.div`
    display: flex;
    gap:1rem;
    ${theme.mediaQueries.mobileMax}{
        display: flex;
        flex-direction: column;
    }
`;

export const DropdownWrapper = styled.div`
    ${theme.mediaQueries.mobileMax}{
        display: flex;
        flex-direction: column;
    }
`;

type StyledTextAreaProps = {
    hasErrors?: boolean;
};
export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
    font-size: ${theme.fontSize.medium};
    font-family: ${fontFamilyAtosRegular};
    border-radius: 5px;
    padding: 1rem;
    resize: none;
    border: ${p => p.hasErrors ? `1px solid ${theme.colors.text.pink}` : '1px solid #707070'};
`;

export const StyledPolicyWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`;

export const FormLink = styled.a`
    margin-left: 0.36rem;
    color: red;
`;
export const PolicyDiv = styled.div`
`;

export const FormSelect = styled.select`
    padding: 0.71rem;
`;

export const FieldWrapper = styled.div`
    display: flex;
    justify-content: end;
    width: 37.7rem;
`;

export const FormButtonWrapper = styled.div`
    ${theme.mediaQueries.mobileMax}{
        display: flex;
        justify-content: center;
    }
`;

export const FormWrapper = styled.div`
    ${theme.mediaQueries.mobileMax}{
        max-width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0px;
    }
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 4rem;
`;

export const StyledFieldErrorMessage = styled.small`
    color: ${theme.colors.text.pink};
    font-family: ${fontFamilyAtosRegular};
    line-height: 0;
    margin-bottom: 1rem;
    font-size: 1.3rem;
    `;
    
