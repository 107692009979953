import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../../../../style/theme';

export const StyledFirstLevelMenuItemLink = styled(Link)<{ withIcon: boolean }>`
    text-transform: uppercase;
    font-size: 1.29rem;
    color: ${theme.palette.darkGray};
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-decoration: none;
    cursor: pointer;
    padding-block: 0.8rem;
    display: flex;
    gap: 0.65rem;

    &:hover {
        color: ${theme.palette.red};

        svg {
            path {
                fill: ${theme.palette.red};
            }
        }
    }
`;

