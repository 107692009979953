import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { Show, mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 33.75 30',
    // width: 33.75,
};

export const ShoppingBasketIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <Show when={props.altText}>
                <title>{props.altText}</title>
            </Show>
            <path
                d="M13.125,22.965a1.406,1.406,0,1,1-2.812,0V18.278a1.406,1.406,0,0,1,2.812,0Zm3.75-6.094a1.4,1.4,0,0,1,1.406,1.406v4.687a1.406,1.406,0,1,1-2.812,0V18.278A1.4,1.4,0,0,1,16.875,16.871Zm6.562,6.094a1.406,1.406,0,1,1-2.812,0V18.278a1.406,1.406,0,0,1,2.812,0ZM14.244.155a1.409,1.409,0,0,1,.6,1.9l-4.781,9.2H23.689l-4.781-9.2a1.407,1.407,0,1,1,2.5-1.3l5.455,10.492h5.484a1.406,1.406,0,1,1,0,2.812H31.172L27.9,27.155A3.794,3.794,0,0,1,24.258,30H9.439a3.736,3.736,0,0,1-3.587-2.842l-3.274-13.1H1.406a1.406,1.406,0,1,1,0-2.812H6.891L12.346.754a1.408,1.408,0,0,1,1.9-.6ZM8.578,26.475a.928.928,0,0,0,.861.709H24.258a.941.941,0,0,0,.914-.709l3.1-12.416H5.477Z"
                transform="translate(0 0.004)"
            />
        </SvgIcon>
    );
};
