import { ErrorCatcher } from '../../../tools/error-catcher';
import { For, Show, useContext } from 'solid-js';
import { pdfStore } from '../pdf-store';
import { useSearchParams } from '@solidjs/router';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { PdfRenderer } from './pdf-renderer/pdf-renderer';
import { EventA4Body, EventFittingTemplate } from './components/event/event-templates';
import { ArticleA4Body, ArticleFittingTemplate } from './components/article/article-templates';
import { ProductA4Body, ProductFittingTemplate } from './components/product/product-templates';
import { fetchPosts } from '../pdf-fetch-posts';
import { PageA4Body, PageFittingTemplate } from './components/page/page-templates';
import { components as componentsProduct } from './components/product/product-components';
import { components as componentsArticle } from './components/article/article-components';
import { components as componentsEvent } from './components/event/event-components';
import { components as componentsPage } from './components/page/page-components';

/**
 * This component loops through all the posts in the pdfStore and sends them to the renderer,
 * which inserts them into an element which fits on an A4 page.
 */

export const PdfFactory = () => {
    const { graphQlClient } = useContext(AppContext);
    const [ searchParams ] = useSearchParams();
    
    const data = searchParams.data;
    if (data) {
        fetchPosts({graphQlClient: graphQlClient, data});
    }

    const postTypeComponents = (postType: string) => {
        switch (postType) {
            case 'blog-and-news':
                return {
                    template: ArticleA4Body,
                    fittingTemplate: ArticleFittingTemplate,
                    components: componentsArticle,
                };
            case 'product':
                return {
                    template: ProductA4Body,
                    fittingTemplate: ProductFittingTemplate,
                    components: componentsProduct,
                };
            case 'page':
                return {
                    template: PageA4Body,
                    fittingTemplate: PageFittingTemplate,
                    components: componentsPage,
                };
            case 'event':
                return {
                    template: EventA4Body,
                    fittingTemplate: EventFittingTemplate,
                    components: componentsEvent,
                };
        }
    };


    return (
        <Show when={pdfStore?.postsData?.length}>
            <ErrorCatcher componentName='PdfFactory'>
                <div id="pdf-factory">
                    <For each={pdfStore.postsData}>{(post, index) => {
                        return (
                            <PdfRenderer
                                post={post}
                                template={postTypeComponents(post.type)?.template}
                                fittingTemplate={postTypeComponents(post.type)?.fittingTemplate}
                                components={postTypeComponents(post.type)?.components}
                                isLastPost={index() === pdfStore.postsData.length - 1}
                            />
                        );
                    }}</For>
                </div>
            </ErrorCatcher>
        </Show>
    );
};
