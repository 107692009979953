import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../../style/theme';

const {spacing, palette} = theme;

export const StyledSpecificationsMobile = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledSpecificationsMobileTable = styled.div`
    display: grid;
    grid-template-columns: repeat(3,1fr);
    text-transform: none;
    gap: ${spacing.xs} ${spacing.s};
    border-bottom: 1px solid ${palette.darkGray};
    padding-bottom: ${spacing.s};
    margin-bottom: ${spacing.s};

    :first-child {
        grid-column: 1 / -1;
    }

    :last-child {
        grid-column: 1 / -1;
    }
`;

export const StyledSpecificationsMobileTableCare = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    text-transform: none;
    gap: ${spacing.s} ${spacing.s};
    border-bottom: 1px solid ${palette.darkGray};
    padding-bottom: ${spacing.s};
    margin-bottom: ${spacing.s};

    :nth-child(4) {
        grid-column: 1 / -2;
    }
    
    ${theme.mediaQueries.tabletMaxOriginal} {
        :nth-child(4) {
            grid-column: 1 / -3;
        }
        :first-child {
            grid-column: 1 / -1;
        }
    }

    ${theme.mediaQueries.tabletMax} {
        grid-template-columns: repeat(2, auto);
        :nth-child(4) {
            grid-column: unset;
        }
        :first-child {
            grid-column: 1 / -1;
        }
    }
`;

export const StyledSpecificationsMobileColumn = styled.div``;

export const StyledSpecificationsMobileHeader = styled.div`
    font-size: 1.29rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    white-space: nowrap;
    text-transform: uppercase;
    margin-bottom: 0.3rem;
`;

export const StyledSpecificationsMobileCell = styled.div`
    font-size: 1.29rem;
    font-family: ${fontFamilyAtosRegular};
`;