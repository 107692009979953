import { useContext, createSignal, Show } from 'solid-js';
import { StyledColumn, FlexContainer, StyledImg } from './column.style';
import { ColumnProps } from './column.d';
import { getBackgroundImageSrcData, ImageSrcData } from '../../components/image-component/image-component';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { PageContext } from '../../components/page/page-context-provider';
import { focusPosition } from '../../tools/get-image-focus';
import { ErrorCatcher } from '../../tools/error-catcher';
import { LockedContent } from '../../ui-components/layouts/locked-content';

export const Column = (props: ColumnProps) => {
    const { imagesServiceUrl, supportedImageFormats, userState } = useContext(AppContext);
    const { withSidebarMenu } = useContext(PageContext);
    const [backgroundImageSrcData, setBackgroundImageSrcData] = createSignal<ImageSrcData>();

    if (props.image) {
        const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
            imagesServiceUrl,
            supportedImageFormats,
            src: props.image.url,
            jwt: props.image.jwt,
            width: 960,
            height: 'AUTO',
        });

        setBackgroundImageSrcData(backgroundImage);
    }

    const isLockedContent = () => {
        return props.lockedContent;
    };

    return (
        <ErrorCatcher componentName='Column'>
            <StyledColumn
                class='atos-column'
                classList={{
                    'with-image': !!props.image,
                    'with-bg-color': !!props.backgroundValue,
                    'combine-with-right': !props.withBorder && props.combineWithRightColumn,
                    'extend-bg-color-left': !props.withBorder && props.extendBgColor && props.extendBgColorSide !== 'right',
                    'extend-bg-color-right': !props.withBorder && props.extendBgColor && props.extendBgColorSide === 'right',
                    'no-extend': !props.extendBgColor,
                    'round-corners': props.roundCorners,
                    'with-divider': props.redDividerEnabled,
                }}
                verticalAlignment={props.verticalAlignment}
                horizontalAlignment={props.horizontalAlignment}
                backgroundValue={props.backgroundValue || 'transparent'}
                backgroundImageSrcData={backgroundImageSrcData()}
                extendBgColor={!props.withBorder && props.extendBgColor ? true : false}
                withSidebarMenu={withSidebarMenu}
                extendBgColorSide={props.extendBgColorSide}
                roundCorners={props.roundCorners}
                combineWithRightColumn={!props.withBorder && props.combineWithRightColumn}
                redDividerEnabled={props.redDividerEnabled}
                focusPosition={focusPosition(props?.image?.focus)}
                overlayType={props.overlayType}
                lockedContent={props.lockedContent}
                withBorder={!props.extendBgColor && !props.combineWithRightColumn && props.withBorder}
            >
                <FlexContainer
                    class="atos-column-flex"
                    horizontalAlignment={props.horizontalAlignment}
                    verticalAlignment={props.verticalAlignment}
                    bgImageColumn={backgroundImageSrcData()?.original !== '' ? props.backgroundValue : false}
                    customCss={props.customCss}
                    {...(backgroundImageSrcData()?.original && { role: 'img' })}
                    {...(props.image && props.image?.altText !== undefined && { 'aria-label': props.image?.altText })}
                >
                    {/* TODO: fix this hacky way of inserting image in the future */}
                    <Show when={backgroundImageSrcData()?.original}>
                        <StyledImg src={backgroundImageSrcData()?.original} alt={props.image?.altText || props.image?.title || ''} title={props.image?.title || ''} />
                    </Show>
                    {props.children}
                </FlexContainer>

                <Show when={isLockedContent() && !userState.user}>
                    <LockedContent hasHero={false} isColumn={true} text={props.lockedContentText}/>
                </Show>
            </StyledColumn>
        </ErrorCatcher>
    );
};

Column.parseProps = (atts: any) => {
    return {
        horizontalAlignment: atts.horizontalAlignment,
        verticalAlignment: atts.verticalAlignment,
        backgroundValue: atts.backgroundColor ? atts.backgroundColor.name : undefined,
        roundCorners: atts.roundCorners,
        image: atts.image,
        extendBgColor: !!atts.backgroundColor && atts.extendBgColor,
        extendBgColorSide: !!atts.backgroundColor && atts.extendBgColorSide,
        combineWithRightColumn: atts.combineWithRight,
        redDividerEnabled: atts.redDividerEnabled,
        overlayType: atts.overlayType,
        lockedContent: atts.lockedContent,
        lockedContentText: atts.lockedContentText,
        withBorder: atts.withBorder,
    };
};
