import { styled } from 'solid-styled-components';
import theme from '../../../../../style/theme';

export const StyledProductsPdfHeadingWrapper = styled.div`
    margin-top: 2.5rem;
    display: flex;
    gap: 3rem;
`;

export const StyledProductPdfCardWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 100%;
    border: 1px solid ${theme.palette.lightGray};
    border-radius: ${theme.borderRadius.medium};
    background-color: ${theme.palette.white};
    color: unset;
    text-decoration: unset;
    padding: 1.5rem;
    padding-right: 0.6rem;
    margin-bottom: 1.5rem;
`;

export const StyledProductPdfCardInner = styled.div`
    display: flex;
    height: 100%;
    gap: 1.1rem;
`;

export const StyledProductPdfThumbnailWrapper = styled.div`
    flex-basis: 23%;
    height: 8rem;
    overflow: hidden;
    flex-shrink: 0;
    align-self: center;
    display: flex;
    align-items: center;
`;

export const StyledProductsPdfList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

export const StyledQrCodeForProduct = styled.div<{ hasProductIcon: boolean }>`
    align-self: flex-end;
    margin-left: auto;
    height: 7rem;
    padding-right: 0.73rem;
    padding-top: 1.5rem;
    ${p => p.hasProductIcon ? 'margin-top: 2.9rem' : ''};
    display: flex;
    justify-content: center;
    & > svg {
        height: 100%;
        width: auto;
    }
`;

export const StyledProductPdfTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 0.5rem;
`;

export const StyledMoreInfoProductsPdfStore = styled.div`
    display: flex;
    gap: 0.5rem;
    align-self: flex-end;
    margin-top: auto;
    padding: 0.73rem;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0.3rem;
    align-items: center;
`;

export const StyledArrowRighContainer = styled.div`
    margin-top: 0.2rem;
`;

export const StyledProductsPdfHeadingWrapperAreaOfCare = styled.div`
    flex-shrink: 0;
`;

export const StyledProductPdfTextInnerContainer = styled.div`
    padding-right: 1rem;
    padding-bottom: 0.5rem;
`;