import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledAddToMyContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledDeleteFromMyContentButton = styled.button`
    background: transparent;
    margin: 2rem;
    border: none;
    cursor: pointer;

    & > svg:hover, path {
        path {
            fill: ${theme.palette.pink};
        }
    }
`;