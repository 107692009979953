import { Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { Button } from '../../ui-components/button/button';
import { Modal } from '../../ui-components/modal/modal';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import { EnvelopeIcon } from '../icons-library/envelope';
import { ShareIcon } from '../icons-library/share';
import { FacebookIconPlain } from '../icons-library/social-media/facebook-plain';
import { LinkedinIconPlain } from '../icons-library/social-media/linkedin-plain';
import { TwitterIconPlain } from '../icons-library/social-media/twitter-plain';
import { WhatsappIcon } from '../icons-library/social-media/whatsapp';
import {
    StyledPopupHeading,
    StyledShareIcons,
    StyledShareIconsRow,
    StyledShareIcon,
    StyledPopupText,
    StyledShareUrl,
    StyledCopyButtonRow,
} from './share-popup.style';

export const SharePopup = (props: SharePopupProps) => {
    const { localize, viewport } = useContext(AppContext);
    const shareUrl = window.location.host + window.location.pathname;

    const urlToClipboard = () => {
        const mockElement = document.createElement('textarea');
        document.body.appendChild(mockElement);
        mockElement.value = shareUrl;
        mockElement.select();
        document.execCommand('copy');
        document.body.removeChild(mockElement);
    };

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    return (
        <Modal visible={props.visible} onClose={() => props.closeModal()} size="small" darkenBackground={true}>
            <StyledVerticalSpace size={2} />
            <ShareIcon size="large" />
            <StyledPopupHeading>{localize('share-content', 'Share content')}</StyledPopupHeading>
            <StyledShareIcons>
                <StyledShareIconsRow>
                    <StyledShareIcon>
                        <a href={`mailto:?body=${shareUrl}`}>
                            <EnvelopeIcon size="large" />
                        </a>
                    </StyledShareIcon>

                    <StyledShareIcon>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                            <FacebookIconPlain size="large" />
                        </a>
                    </StyledShareIcon>

                    <StyledShareIcon>
                        <a href={`https://twitter.com/share?url=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                            <TwitterIconPlain size="large" />
                        </a>
                    </StyledShareIcon>
                </StyledShareIconsRow>

                <StyledShareIconsRow>
                    <StyledShareIcon>
                        <a href={`http://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                            <LinkedinIconPlain size="large" />
                        </a>
                    </StyledShareIcon>

                    <StyledShareIcon>
                        <Show
                            when={isMobile()}
                            fallback={
                                <a href={`https://web.whatsapp.com://send?text=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                                    <WhatsappIcon size="large" />
                                </a>
                            }
                        >
                            <a href={`whatsapp://send?text=${shareUrl}`} target="_blank" data-action="share/whatsapp/share">
                                <WhatsappIcon size="large" />
                            </a>
                        </Show>
                    </StyledShareIcon>
                </StyledShareIconsRow>
            </StyledShareIcons>
            <StyledVerticalSpace size={2} />
            <StyledPopupText>{localize('or-copy-link', 'Or copy link')}</StyledPopupText>
            <StyledShareUrl onClick={() => urlToClipboard()}>{shareUrl}</StyledShareUrl>
            <StyledCopyButtonRow>
                <Button label="Copy" variant="tertiary" arrow={true} onClick={() => urlToClipboard()} />
            </StyledCopyButtonRow>
        </Modal>
    );
};

type SharePopupProps = {
    visible: boolean;
    closeModal: () => void;
};
