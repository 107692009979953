import { ErrorCatcher } from '../../tools/error-catcher';
import { VideoPostCard } from '../videos/video-post-card/video-post-card';
import { VideoBlock, VideoEmbedComponentProps } from './video-embed-types';

export const VideoEmbed = (props: VideoEmbedComponentProps) => {
    return (
        <ErrorCatcher componentName="VideoEmbed">
            <VideoPostCard title={props.title} videoUrl={props.url} isHealthcareProfessional={false} isVisibleForPublic={true} ableToPlay={true} permalink={''} />
        </ErrorCatcher>
    );
};

VideoEmbed.parseProps = (atts: VideoBlock) => {
    return {
        url: atts.url,
        title: atts.title,
        shortDescription: atts.shortDescription,
    };
};
