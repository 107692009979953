import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 39.595 30',
    width: 3.21,
    height: 2.86,
};

export const EnvelopeIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props} >
            <path
                d="M0,69a4.974,4.974,0,0,1,4.949-5h29.7a4.977,4.977,0,0,1,4.949,5V89a4.979,4.979,0,0,1-4.949,5H4.949A4.977,4.977,0,0,1,0,89Zm3.712,0v1.727l13.34,11.063a4.281,4.281,0,0,0,5.491,0l13.34-11.062v-1.8a1.3,1.3,0,0,0-1.237-1.25H4.949a1.293,1.293,0,0,0-1.237,1.25Zm0,6.578V89a1.245,1.245,0,0,0,1.237,1.25h29.7A1.247,1.247,0,0,0,35.883,89V75.578L24.9,84.688a8.076,8.076,0,0,1-10.278,0Z"
                transform="translate(0 -64)"
            />
        </SvgIcon>
    );
};
