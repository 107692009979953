import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 50 50',
    // width: 29.992
};

export const FreeHandsIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                id="Path_21790"
                data-name="Path 21790"
                d="M25,0A25,25,0,1,0,50,25,25.028,25.028,0,0,0,25,0Zm0,48.85A23.85,23.85,0,1,1,48.85,25,23.878,23.878,0,0,1,25,48.85Z"
            /> 
            <path id="Path_21791" data-name="Path 21791" d="M24.74,16.12a2.566,2.566,0,1,0-2.87-2.22,2.573,2.573,0,0,0,2.87,2.22Z"/>
            <path
                id="Path_21792"
                data-name="Path 21792"
                d="M36.42,26.96a.6.6,0,0,0-.55-.27l-1.07.08a.43.43,0,0,1-.38-.17,8.087,8.087,0,0,0-1.32-1.43,2.182,2.182,0,0,0-1.86-.43l-2.62-1.96-2.83-3.76a1.94,1.94,0,0,0-1.72-1.41,1.961,1.961,0,0,0-1.82.94l-3.31,1.24a.949.949,0,0,0-.43.34l-2.96,4.34a2.211,2.211,0,0,0-1.1.47,7.717,7.717,0,0,0-1.32,1.43.43.43,0,0,1-.38.17l-1.07-.08a.6.6,0,0,0-.55.27L7.89,31.59a.957.957,0,0,0,.33,1.37l5.1,2.88a.725.725,0,0,0,.97-.26l3.22-5.54a.528.528,0,0,0-.14-.68l-.42-.31a.343.343,0,0,1-.11-.44,6.643,6.643,0,0,0,.75-2.27,3.152,3.152,0,0,0-.37-1.08l2.65-3.89,2.03-.76-.66,8.55c-.01.13,0,.26,0,.38l-1.59,4.62-4.27,4.93a1.205,1.205,0,0,0,1.82,1.58l4.43-5.11a1.228,1.228,0,0,0,.23-.4l1.35-3.91h.01l4.91,9.25a1.2,1.2,0,0,0,1.07.64,1.254,1.254,0,0,0,.56-.14,1.2,1.2,0,0,0,.5-1.63L25.1,29.65a1.38,1.38,0,0,0,.03-.2l.59-7.57,1.56,2.07a.769.769,0,0,0,.18.18l2.66,1.98a3.631,3.631,0,0,0-.1.45,6.713,6.713,0,0,0,.75,2.27.371.371,0,0,1-.11.44l-.42.31a.528.528,0,0,0-.14.68l3.22,5.54a.707.707,0,0,0,.97.26l5.1-2.88a.959.959,0,0,0,.33-1.37l-3.24-4.86Zm-20.3,1.25a.389.389,0,0,1-.54.13l-1.65-1a.383.383,0,0,1-.13-.54,6.678,6.678,0,0,1,.87-1.15,1.177,1.177,0,0,1,.42-.24.908.908,0,0,0,1.62.56,1.861,1.861,0,0,1,.13.78,5.41,5.41,0,0,1-.72,1.47Zm17.49-.64-1.65,1a.389.389,0,0,1-.54-.13,5.552,5.552,0,0,1-.72-1.47,2,2,0,0,1,.02-.36l.06.05a.9.9,0,0,0,.54.18.928.928,0,0,0,.73-.36.908.908,0,0,0,.1-.91,1.34,1.34,0,0,1,.72.31,6.677,6.677,0,0,1,.87,1.15A.381.381,0,0,1,33.61,27.57Z"
            />
            <path
                id="Path_21793"
                data-name="Path 21793"
                d="M30.29,17.17a.43.43,0,0,0,.28.11.442.442,0,0,0,.33-.15,5.215,5.215,0,0,0,0-6.68.432.432,0,0,0-.65.57,4.246,4.246,0,0,1,1,2.78,4.193,4.193,0,0,1-1,2.78.43.43,0,0,0,.04.61Z"
            />
            <path
                id="Path_21794"
                data-name="Path 21794"
                d="M28.06,15.38a.43.43,0,0,0,.28.11.442.442,0,0,0,.33-.15,2.453,2.453,0,0,0,0-3.12.432.432,0,0,0-.65.57,1.478,1.478,0,0,1,.35.99,1.531,1.531,0,0,1-.35.99A.43.43,0,0,0,28.06,15.38Z"
            />
            <path
                id="Path_21795"
                data-name="Path 21795"
                d="M32.59,18.61a.43.43,0,0,0,.28.11.407.407,0,0,0,.32-.15,7.283,7.283,0,0,0,1.73-4.78,7.231,7.231,0,0,0-1.73-4.78.432.432,0,0,0-.65.57,6.384,6.384,0,0,1,1.52,4.22,6.4,6.4,0,0,1-1.52,4.22.43.43,0,0,0,.04.61Z"
            />
            <path
                id="Path_21796"
                data-name="Path 21796"
                d="M36.87,13.78a8.6,8.6,0,0,1-2.03,5.66.435.435,0,0,0,.32.72.407.407,0,0,0,.32-.15,9.441,9.441,0,0,0,2.25-6.22,9.422,9.422,0,0,0-2.25-6.22.432.432,0,0,0-.65.57,8.6,8.6,0,0,1,2.03,5.66Z"
            />
        </SvgIcon>
    );
};
