import { Show, createSignal, mergeProps, onCleanup, onMount, useContext } from 'solid-js';
import { AngleUpIcon } from '../../components/icons-library/angle-up';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../heading/heading';
import { StyledRelativeContainer } from '../utility-style-components/positioning';
import { ExpandableProps } from './expandable-container.d';
import { StyledExpandableContainer, StyledExpandableWrapper, StyledExpandableHeadingRowButton, StyledExpandableHeadingRowDiv } from './expandable-container.styles';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { Dynamic } from 'solid-js/web';
import { StyledHorizontalSpace } from '../utility-style-components/spacing';
import clsx from 'clsx';

const defaultProps = {
    isDropdown: false,
};

const elementOptions = {
    StyledExpandableHeadingRowDiv,
    StyledExpandableHeadingRowButton
};

export const ExpandableContainer = (componentProps: ExpandableProps) => {
    const props = mergeProps(defaultProps, componentProps);
    const { viewport } = useContext(AppContext);
    const [open, setOpen] = createSignal(false);
    const openMenu = () => setOpen(true);

    const id = Math.random().toString();

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    let expandableRef: HTMLDivElement | undefined;
    const handleOutsideClick = () => {
        const handleClickOutside = (event: Event) => {            
            if (!expandableRef?.contains(event.target as Node) && open()) {
                setOpen(false);
            }
        };

        window.addEventListener('click', handleClickOutside);

        onCleanup(() => {
            window.removeEventListener('click', handleClickOutside);
        });
    };

    onMount(() => {
        if (props.initialOpen) {
            openMenu();
        } else if (isMobile() || props.enableOutsideClickToClose) {
            handleOutsideClick();
        }

        const container = document?.getElementById(id);
        if (container) {
            container.addEventListener('keydown', handleClickBehaviour);
        }
        
        onCleanup(() => {
            const container = document?.getElementById(id);
            if (container) {
                container.removeEventListener('keydown', handleClickBehaviour);
            }
        });
    });

    const handleClick = () => {
        if (props.disableClick) {
            return;
        }

        open() ? setOpen(false) : openMenu();
    };

    const handleClickBehaviour = (e: any) => {
        if (e.type === 'click') {
            if (!open()) {
                setOpen(true);
            }
        } else if (e.type === 'keydown' && e.keyCode === 32) { // 32 is the keycode for spacebar
            e.preventDefault(); // Prevent the default spacebar action (scrolling)
            open() ? setOpen(false) : setOpen(true);;
        }
    };

    return (
        <ErrorCatcher componentName="Expandable container">
            <StyledExpandableWrapper 
                isDropdown={props.isDropdown}
                ref={expandableRef}
                shrinkOnTablet={props.shrinkOnTablet}
            >
                <StyledExpandableContainer isOpen={open()} isDropdown={props.isDropdown} withBorder={props.withBorder}>
                    <Dynamic 
                        component={elementOptions[props.disableClick ? 'StyledExpandableHeadingRowDiv' : 'StyledExpandableHeadingRowButton']}
                        ref={props.ref}
                        id={id}
                        onClick={() => handleClick()}
                        backgroundColor={props.backgroundColor}
                        customHeadingRowCss={props.customHeadingRowCss}
                        isOpen={open()}
                        withBorder={props.withBorder}
                        roundCorners={props.roundCorners}
                        disableClick={props.disableClick}
                        withPadding={props.withPadding}
                    >
                        <Heading {...props.headingProps} />
                        <Show when={!props.disableClick}>
                            <StyledHorizontalSpace size={1.5} />

                            <StyledRelativeContainer>
                                <span
                                    class={clsx('block transform transition-transform duration-300 origin-center', {
                                        'rotate-180': !open(),
                                    })}
                                >
                                    <AngleUpIcon fill={props.chevronColor} width={props.chevronSize || 1.43} height={props.chevronSize || 1.43} />
                                </span>
                            </StyledRelativeContainer>
                        </Show>
                    </Dynamic>
                    <div
                        class={clsx('first-row', {
                            'is-open': open(),
                        })}
                    >
                        <div class="first-row-child">
                            {props.children}
                        </div>
                    </div>
                </StyledExpandableContainer>
            </StyledExpandableWrapper>
        </ErrorCatcher>
    );
};
