import { styled } from 'solid-styled-components';
import isVisregTest from '../../../../../tools/is-visual-regression-test';
import { pdfStore } from '../../../pdf-store';
import { pageLeftMargin } from '../../components/shared-components.style';
import { JSX } from 'solid-js';

export const StyledLeftRuler = styled.div<{ height?: number }>`
    position: relative;
    &:after {
        content: '${p => p.height}';
        position: absolute; 
        width: 30px;
        height: ${p => p.height}px;
        border-bottom: 1px solid #bbb;
        top: 0;
        left: ${pageLeftMargin * -1}px;
        z-index: 1000;
        color: white;
        background: #555;
        padding: 3px;
    }
`;

export const debugWrapper = (element: JSX.Element | any, omit?: boolean, manualHeight?: number) => {
    if (!pdfStore.debugging && !isVisregTest) return element;
    
    if (omit) return element;
        
    const height = manualHeight || pdfStore.debugMeasuredElementHeight;

    return (
        <>
            <StyledLeftRuler height={height} class='pdf-debug-wrapper'/>
            {element}
        </>
    );
};
