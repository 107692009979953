import { styled } from 'solid-styled-components';

export const StyledFlexRow = styled.div<FlexRowProps>`
    display: flex;
    flex-direction: row;
    align-items: ${p => p.alignItems ? `${p.alignItems};` : 'center'};
    ${ p => p.gap ? `gap: ${p.gap}` : ''};

    ${p => p.flexWrap ? `flex-wrap: ${p.flexWrap};` : ''}
    ${p => p.justifyContent ? `justify-content: ${p.justifyContent};` : ''}
`;

export const StyledFlexColumn = styled.div<FlexContainerProps>`
    display: flex;
    flex-direction: column;
    ${p => p.justifyContent ? `justify-content: ${p.justifyContent};` : ''}
    ${p => p.alignItems ? `align-items: ${p.alignItems};` : ''}
`;

type FlexContainerProps = {
    justifyContent?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    alignItems?: 'stretch' | 'center' | 'start' | 'end';
    gap?: string;
};

interface FlexRowProps extends FlexContainerProps {
    flexWrap?: 'wrap';
};
