import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

const customCss = `
    width: 2.14rem;
    height: 2.14rem;
`;

export const WhatsappIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props} customCss={customCss}>
            <path d="M25.507,36.359A14.871,14.871,0,0,0,2.109,54.3L0,62l7.882-2.069a14.821,14.821,0,0,0,7.1,1.808h.007A14.772,14.772,0,0,0,25.507,36.359ZM14.993,59.234A12.334,12.334,0,0,1,8.7,57.513l-.449-.268L3.576,58.471l1.246-4.56-.295-.469a12.38,12.38,0,1,1,22.962-6.569A12.494,12.494,0,0,1,14.993,59.234ZM21.77,49.98c-.368-.187-2.2-1.085-2.538-1.205s-.589-.187-.837.188-.958,1.205-1.179,1.46-.435.281-.8.094a10.111,10.111,0,0,1-5.056-4.42c-.382-.656.382-.609,1.092-2.029a.688.688,0,0,0-.033-.65c-.094-.187-.837-2.016-1.145-2.759-.3-.723-.609-.623-.837-.636s-.462-.013-.71-.013a1.376,1.376,0,0,0-.991.462,4.174,4.174,0,0,0-1.3,3.1,7.277,7.277,0,0,0,1.513,3.844,16.6,16.6,0,0,0,6.348,5.612c2.357,1.018,3.281,1.1,4.46.931a3.8,3.8,0,0,0,2.5-1.768,3.107,3.107,0,0,0,.214-1.768C22.386,50.254,22.138,50.161,21.77,49.98Z" transform="translate(0 -32)" fill="#726060"/>
        </SvgIcon>
    );
};
