import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 32 30',
    // width: 29.992
};

export const PlusIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M33.308,50.308a2.308,2.308,0,0,0-4.615,0V60.692H18.308a2.308,2.308,0,0,0,0,4.615H28.692V75.692a2.308,2.308,0,1,0,4.615,0V65.308H43.692a2.308,2.308,0,0,0,0-4.615H33.308Z"
                transform="translate(-16 -48)"
            />
        </SvgIcon>
    );
};