import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 50 50',
    // width: 29.992
};

export const ProtectIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                id="Path_21805"
                data-name="Path 21805"
                d="M25,50A25,25,0,1,1,50,25,25.022,25.022,0,0,1,25,50ZM25,1.15A23.85,23.85,0,1,0,48.85,25,23.878,23.878,0,0,0,25,1.15Z"
            />
            <path
                id="Path_21806"
                data-name="Path 21806"
                d="M20.27,31.25a.42.42,0,0,1-.24-.07,16.552,16.552,0,0,1-4.8-5.13c-1.62-2.99-1.8-8.31-1.81-8.53a.448.448,0,0,1,.15-.34.425.425,0,0,1,.35-.11l1.6.22a4.033,4.033,0,0,0,3.25-.96l1.21-1.09a.443.443,0,0,1,.59,0l1.21,1.09a4.033,4.033,0,0,0,3.25.96l1.6-.22a.406.406,0,0,1,.35.11.448.448,0,0,1,.15.34c0,.22-.18,5.54-1.81,8.53a16.552,16.552,0,0,1-4.8,5.13.42.42,0,0,1-.24.07ZM14.33,18.01c.09,1.41.43,5.33,1.67,7.62a15.517,15.517,0,0,0,4.27,4.64,15.517,15.517,0,0,0,4.27-4.64c1.24-2.29,1.59-6.21,1.67-7.62l-1.07.15h0a4.942,4.942,0,0,1-3.96-1.18l-.91-.82-.91.82a4.953,4.953,0,0,1-3.96,1.18l-1.07-.15Zm5.94,11.15a.42.42,0,0,1-.24-.07,12.1,12.1,0,0,1-3.53-3.77c-1.19-2.2-1.32-6.09-1.33-6.25a.448.448,0,0,1,.15-.34.461.461,0,0,1,.35-.11l1.16.16a2.841,2.841,0,0,0,2.27-.67l.88-.79a.443.443,0,0,1,.59,0l.88.79a2.8,2.8,0,0,0,2.27.67l1.16-.16a.406.406,0,0,1,.35.11.448.448,0,0,1,.15.34c0,.16-.13,4.05-1.33,6.25a12.244,12.244,0,0,1-3.53,3.77.42.42,0,0,1-.24.07Zm-4.19-9.6a15.282,15.282,0,0,0,1.19,5.34,11.188,11.188,0,0,0,3,3.28,11.188,11.188,0,0,0,3-3.28,15.06,15.06,0,0,0,1.19-5.34l-.63.09a3.725,3.725,0,0,1-2.98-.89l-.58-.53-.58.53a3.714,3.714,0,0,1-2.98.89l-.63-.09Z"
            />
            <g id="Group_3565" data-name="Group 3565">
                <path
                    id="Path_21807"
                    data-name="Path 21807"
                    d="M33.68,35.13l-4.22-3.55a2.024,2.024,0,0,1-.69-1.37l-.18-9.76.93,2.25.36,3.82a.944.944,0,0,0,1.09.75.929.929,0,0,0,.75-1.08l-.37-3.91-2.21-5.42a2.033,2.033,0,0,0-4.02.1l1.45-.2h.11a.744.744,0,0,1,.51.2.794.794,0,0,1,.26.6c0,.23-.18,5.62-1.84,8.68a16.755,16.755,0,0,1-4.91,5.24.776.776,0,0,1-.86,0s-.03-.02-.07-.05a4.065,4.065,0,0,0-.23,1.25l.08,6.04a1.189,1.189,0,0,0,1.16,1.33c.73,0,1.07-.64,1.07-1.35l-.04-5.85a2.156,2.156,0,0,1,.52-1.41l4.1-3.62.08,2.36a3.488,3.488,0,0,0,1.16,2.83l4.48,3.9a1.164,1.164,0,1,0,1.52-1.75Z"
                />
                <circle id="Ellipse_121" data-name="Ellipse 121" cx="2.56" cy="2.56" r="2.56" transform="translate(24.68 9.6)" />
            </g>
        </SvgIcon>
    );
};
