import { createContext, createSignal } from 'solid-js';
import { MenuContent } from '../sidebar/desktop/sidebar-menu-desktop';

type PageContextProviderProps = {
    isHcpPage: boolean;
    hasHero: boolean;
    withSidebarMenu: boolean;
    sidebarMenu: null | MenuContent;
    children?: any;
};

type ContextType = {
    isHcpPage: any;
    hasHero: any;
    withSidebarMenu: any;
    sidebarMenu: any;
}
export const PageContext = createContext<ContextType>({
    isHcpPage: () => false,
    hasHero: () => false,
    withSidebarMenu: () => false,
    sidebarMenu: () => null,
});

export function PageContextProvider(props: PageContextProviderProps) {
    const [ isHcpPage ] = createSignal(props.isHcpPage);
    const [ hasHero ] = createSignal(props.hasHero);
    const [ withSidebarMenu ] = createSignal(props.withSidebarMenu);
    const [ sidebarMenu ] = createSignal(props.sidebarMenu);

    return (
        <PageContext.Provider value={{ isHcpPage, hasHero, withSidebarMenu, sidebarMenu }}>
            {props.children}
        </PageContext.Provider>
    );
}

