import { Show, useContext } from 'solid-js';
import { AppContext } from '../../../../../app-context-provider/app-context-provider';
import { Heading } from '../../../../../ui-components/heading/heading';
import { BlogNewsData } from '../../../../blog-news/blog-news';
import { StyledRelativeContainer } from '../../../../../ui-components/utility-style-components/positioning';
import { CalendarIcon } from '../../../../icons-library/calendar';
import { StyledPdfAuthor, StyledPdfArticleDate, StyledPdfArticleDateText, StyledPdfArticleHeader, StyledPdfArticleMetaInfo } from './article-components.style';
import { ComponentPiece } from '../../pdf-renderer/pdf-renderer';
import { PdfIconTab } from '../post-icon-tab/icon-tab';
import { extractBlocks } from '../extract-blocks';
import { formatDateDigits } from '../../../../../tools/date-format';
import { SpacerPdf } from '../blocks/spacer/spacer-pdf';


const header: ComponentPiece = {
    elements: (data: BlogNewsData) => {
        const { localize, siteInfo } = useContext(AppContext);
        
        return (
            <>
                <PdfIconTab type={'article'} label={data.categories.length ? data.categories[0].name : localize('blog', 'Blog')} />
                <StyledPdfArticleHeader>
                    <SpacerPdf height={4} />
                    <Heading tag={'h1'} variant='xlargeBoldPDF'>{data.post_title}</Heading>
                    <StyledPdfArticleMetaInfo>
                        <StyledPdfArticleDate>
                            <StyledRelativeContainer top={-5}>
                                <CalendarIcon height={2.21} width={1.79} fill='black' />
                            </StyledRelativeContainer>
                            <StyledPdfArticleDateText>{
                                data?.date
                                    ? formatDateDigits(data?.date, siteInfo.key)
                                    : ''
                            }</StyledPdfArticleDateText>
                        </StyledPdfArticleDate>
                        <Show when={data.listCardContent.displayHostName === 'yes'}>
                            <StyledPdfAuthor>
                                {localize('author', 'Author')}: {data.listCardContent.hostName}
                            </StyledPdfAuthor>
                        </Show>
                    </StyledPdfArticleMetaInfo>
                </StyledPdfArticleHeader>
            </>
        );
    }
};

const body: ComponentPiece = {
    canBeBrokenUp: true,
    elements: (data: BlogNewsData) => {
        const context = useContext(AppContext);

        return extractBlocks(context, {
            title: data.post_title,
            ...data,
        });
    }
};

export const components = [
    header,
    body
];