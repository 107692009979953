import { createEffect, createContext } from 'solid-js';
import { UserStoreType } from '../../app-context-provider/app-context-provider';
import { createStore } from 'solid-js/store';
import { SiteInfo } from '../../types/app-state';

export type OrderLine = {
    postTitle: string;
    id: number;
    externalId: string;
    slug: string;
    type: string;
    skuRefNumber: string;
    skuDescription: string;
    skuName: string;
    skuQuantity: number;
    amount: number;
    imageUrl?: string;
    imageRatio?: number;
};

type Order = {
    orderDetails: OrderLine[];
    site?: string;
    email?: string;
}

export type Basket = {
    order: Order;
    loading?: boolean;
};

const defaultOrder: Basket = {
    order: {
        orderDetails: [],
    },
    loading: true,
};

type BasketContextType = {
    basket: Basket;
    setBasket: (key: string, value: any) => void;
    incrementQuantity: (orderLine: OrderLine) => void;
    incrementQuantityByAmount: (orderLine: OrderLine, amountToAdd: number) => void;
    decrementQuantity: (orderLine: OrderLine) => void;
    addNewOrderLine: (orderLine: OrderLine) => void;
    removeOrderLine: (orderLine: OrderLine) => void;
    completeOrder: () => void;
    setSite: (site: string) => void;
    setEmail: (email?: string) => void;
    resetBasket: () => void;
}

export const BasketContext = createContext<BasketContextType>({
    basket: defaultOrder,
    setBasket: () => {},
    incrementQuantity: () => {},
    incrementQuantityByAmount: () => {},
    decrementQuantity: () => {},
    addNewOrderLine: () => {},
    removeOrderLine: () => {},
    completeOrder: () => {},
    setSite: () => {},
    setEmail: () => {},
    resetBasket: () => {},
});


type BasketContextProviderProps = {
    children: any;
    siteInfo: SiteInfo;
    userState: UserStoreType;
}

export const BasketContextProvider = (props: BasketContextProviderProps) => {    
    const [ basket, setBasket ] = createStore<Basket>({...defaultOrder});

    const isValidBasket = (basket: Basket) => {
        if (!basket) return false;
        if (basket.order.site !== props.siteInfo.siteSlug) return false;

        const basketUserEmail = basket.order.email;

        if (basketUserEmail && (props.userState.user?.email !== basketUserEmail)) {
            return false;
        }

        return true;
    };

    const initializeBasket = () => {
        const storedBasket = localStorage.getItem('basket');

        if (!storedBasket) return;

        try {
            const initialState = JSON.parse(storedBasket);
            if (!isValidBasket(initialState)) {                
                resetBasket();
                return;
            }

            setBasket(initialState as Basket);
        } catch (error) {
            console.error('Error parsing basket from local storage', error);
        }
    };

    createEffect(() => {
        try {
            if (props.siteInfo.siteType === 'atos-care') {
                initializeBasket();
                
                setEmail(props.userState.user?.email);
                setSite(props.siteInfo.siteSlug || '');
                setBasket('loading', false);
            }
        } catch (error) {
            console.error('Error loading basket from local storage', error);
            setBasket('loading', false);
        }
    });

    const incrementQuantity = (orderLine: OrderLine) => {
        setBasket(
            'order',
            'orderDetails',
            (line) => {
                return line.skuRefNumber === orderLine.skuRefNumber;
            },
            (line) => {
                return ({
                    amount: line.amount + 1,
                });
            }
        );
    
        updateBasket();
    };
    
    const incrementQuantityByAmount = (orderLine: OrderLine, amountToAdd: number) => {
        setBasket(
            'order',
            'orderDetails',
            (line) => line.skuRefNumber === orderLine.skuRefNumber,
            (line) => ({
                amount: line.amount + amountToAdd,
            })
        );
    
        updateBasket();
    };
    
    const decrementQuantity = (orderLine: OrderLine) => {
        const newQuantity = orderLine.amount !== 1 ? orderLine.amount - 1 : 1;
    
        setBasket(
            'order',
            'orderDetails',
            (line) => line.skuRefNumber === orderLine.skuRefNumber,
            () => ({
                amount: newQuantity,
            })
        );
    
        updateBasket();
    };
    
    const addNewOrderLine = (orderLine: OrderLine) => {
        if (basket.order.orderDetails.find((line) => line.skuRefNumber === orderLine.skuRefNumber)) {
            incrementQuantityByAmount(orderLine, orderLine.amount);
            return;
        }
    
        setBasket(
            'order', 
            'orderDetails', 
            (orderDetails) => {
                return [ ...orderDetails, orderLine ];
            });
    
        updateBasket();
    };
    
    const removeOrderLine = (orderLine: OrderLine) => {
        setBasket(
            'order',
            'orderDetails',
            basket.order.orderDetails.filter((line) => line.skuRefNumber !== orderLine.skuRefNumber)
        );
    
        updateBasket();
    };
    
    const updateBasket = () => {
        localStorage.setItem('basket', JSON.stringify(basket));
    };
    
    const completeOrder = () => {
        localStorage.removeItem('basket');
        setBasket('order', 'orderDetails', []);
    };
    
    const setSite = (site: string) => {
        setBasket('order', 'site', site);
    };
    
    const setEmail = (email?: string) => {
        setBasket('order', 'email', email);
    };
    
    const resetBasket = () => {
        setBasket(defaultOrder);
    };

    return (
        <BasketContext.Provider value={
            { 
                basket,
                setBasket,
                incrementQuantity,
                incrementQuantityByAmount,
                decrementQuantity,
                addNewOrderLine,
                removeOrderLine,
                completeOrder,
                setSite,
                setEmail,
                resetBasket,
            }
        }>
            {props.children}
        </BasketContext.Provider>
    );
};