import { Resource } from 'solid-js';
import { createStore } from 'solid-js/store';
import { BlogNewsData } from '../blog-news/blog-news';
import { ProductData } from '../product/product-types';
import { ContentItem } from '../search/searchbar-standalone';
import { PageData, PostData } from '../share-and-save/share-and-save';
import { EventData } from '../event/event-types';
// import { isServer } from 'solid-js/web';

type Output = OutputObject[];

type EntitySlugMap = {
    [key in EntitySlug]: string;
};
export type EntitySlug = 'product' | 'blog-and-news' | 'page' | 'event' | 'video-item';

export type EnhancedContentItem = ContentItem & {
    key: string;
};


export type ValidDataTypes = ProductData & BlogNewsData & PageData & EventData & PostData

export type OutputObject = {
    key: string;
    type: EntitySlug;
    data: ValidDataTypes;
};

export type Collection = {
    title: string;
    orderedPosts: EnhancedContentItem[];
    downloaded: boolean;
};

type PDFBuilderState = {
    status: 
        | ''
        | 'naming'
        | 'printing'
        | 'error'
        | 'generating'
        | 'retrying-generating'
        | 'checking-availability'
        | 'server-busy';
    renderComplete: boolean;
    title: string;
    products: string[];
    articles: string[];
    workingCollectionPosts: EnhancedContentItem[];
    collections: Collection[];
    postsData: Output;
    rawResource?: Resource<any>;
    entitySlugMap: EntitySlugMap;
    entityNameMap: EntitySlugMap;
    undo: EnhancedContentItem[];
    output: {
        blob: string;
        id: string;
    };
    debugging: boolean;
    debugMeasuredElementHeight: number;
};

export const supportedTypes: EntitySlug[] = ['product', 'blog-and-news', 'page', 'event'];

export const [ pdfStore, setPdfStore ] = createStore<PDFBuilderState>({
    status: '',
    renderComplete: false,
    title: '',
    products: [],
    articles: [],
    workingCollectionPosts: [],
    collections: [],
    postsData: [],
    entitySlugMap: {
        product: '',
        'blog-and-news': '',
        page: '',
        event: '',
        'video-item': '',
    },
    entityNameMap: {
        product: '',
        'blog-and-news': '',
        page: '',
        event: '',
        'video-item': '',
    },
    undo: [],
    output: {
        blob: '',
        id: '',
    },
    debugging: false,
    debugMeasuredElementHeight: 0,
});

// if (isServer) {
//     setInterval(() => {
//         console.log('PDF collections :', pdfStore.collections.length);
//         console.log('PDF posts:', pdfStore.postsData.length);
//         console.log('PDF products:', pdfStore.products.length);
//     }, 600000);
// }


declare global {
    interface Window {
        pdfRenderComplete?: boolean;
    }
}

