/* eslint-disable indent */
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../style/theme';
import { StyledQuoteProps } from './quote.d';

const { colors } = theme;

export const StyledQuoteWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    align-self: flex-start;
    gap: 0 1.5rem;

    ${theme.mediaQueries.mobileMax} {
        flex-direction: column;
    }
`;

export const StyledOpeningQuoteSvgContainer = styled.div``;

export const StyledQuoteContainer = styled.div`
`;

export const StyledClosingQuoteSvgContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: end;
`;

const sharedQuoteStyles = `
    font-weight: 400;
    font-family: ${fontFamilyAtosRegular};
    margin: 0;
    margin-bottom: 1rem;
`;

export const StyledQuote = styled.p<StyledQuoteProps>`
    ${sharedQuoteStyles}
    font-size: 2.71rem;
    letter-spacing: 0;
    line-height: 3.93rem;
    ${theme.mediaQueries.mobileMax} {
        margin-bottom: 0;
    }
    color: ${(p) => (p.color ? p.color : colors.text.quote)};
`;

export const StyledQuotePdf = styled.p<StyledQuoteProps>`
    ${sharedQuoteStyles}
    font-size: 1.36rem;
    line-height: 150%;
    letter-spacing: normal;
    color: #000;
    min-height: 10rem;
    display: flex;
    align-items: center;
`;
