import { styled } from 'solid-styled-components';
import { fontFamilyAtosBold, theme } from '../../../../style/theme';

export const StyledShortcuts = styled.div`
    background-color: ${theme.palette.gray};
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    padding: 2rem 1.5rem;
`;

export const StyledShortcutsContent = styled.div`
    max-width: 25.71rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
`;

export const StyledShortcutsHeading = styled.div`
    font-weight: bold;
    font-size: 2.14rem;
    color: ${theme.palette.white};
    letter-spacing: -1px;
    font-family: ${fontFamilyAtosBold};
`;

export const StyledShortcutsHeadingRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
    background: ${theme.palette.gray};
    padding: 2rem 1.5rem;
`;
