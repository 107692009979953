import { Accessor, Show, useContext } from 'solid-js';
import { Button } from '../../ui-components/button/button';
import { Link } from '../../ui-components/link/link';
import { 
    StyledBottom,
    StyledPlaceOrderContainer,
    StyledPlaceOrderSpinner,
} from './basket.styles';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { BasketContext } from './basket-context-provider';


const BasketOrderSummary = ({ isPlacingOrder, createOrder }: { isPlacingOrder: Accessor<boolean>; createOrder: () => void }) => {
    const { productsListingPage, userState, localize } = useContext(AppContext);
    const { basket } = useContext(BasketContext);
    const { appStore } = useContext(AppContext);
    const { openLoginMenu } = appStore;

    return (
        <Show when={basket.order.orderDetails.length}>
            <StyledBottom>

                <Show when={productsListingPage.url}>
                    <Link
                        url={productsListingPage.url}
                        label={localize('back-to-products', 'Back to products')}
                        plainStyle={true}
                        arrowDirection={'left'}
                    />
                </Show>

                <Show when={!userState.user}>
                    <Button
                        label={localize('login-to-order', 'Login to place an order')}
                        onClick={() => openLoginMenu()}
                    />
                </Show>

                <Show when={userState.user}>
                    <StyledPlaceOrderContainer>
                        <Button
                            label={isPlacingOrder() 
                                ? localize('placing-order', 'Placing order...')
                                : localize('place-order', 'Place order')
                            }
                            disabled={isPlacingOrder()}
                            onClick={() => createOrder()}
                        />
                        <Show when={isPlacingOrder()}>
                            <StyledPlaceOrderSpinner>
                                <LoadingSpinner size={'1.6rem'} color='white' thickness='3px' />
                            </StyledPlaceOrderSpinner>
                        </Show>
                    </StyledPlaceOrderContainer>
                </Show>
                
            </StyledBottom>
        </Show>
    );
};

export default BasketOrderSummary;
