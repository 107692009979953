import { useLocation } from '@solidjs/router';
import { createSignal, Show, useContext } from 'solid-js';
import { Portal } from 'solid-js/web';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Text } from '../../ui-components/text/text';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { EnvelopeIcon } from '../icons-library/envelope';
import { ShareIcon } from '../icons-library/share';
import { SharePopup } from '../share-popup/share-popup';
import { StyledProductSocial, StyledShareContainer } from './share-and-save.styles';
import theme from '../../style/theme';
import { MyContentHandler } from '../my-content-handler/my-content-handler';
import { PdfToolbar } from '../pdf-builder/pdf-toolbar/pdf-toolbar';
import { BlogNewsData } from '../blog-news/blog-news';
import { ProductData } from '../product/product-types';
import { EventData } from '../event/event-types';
import { VideoData } from '../videos/videos-types';
import { Block } from '../../types/shared';
import { EntitySlug, ValidDataTypes } from '../pdf-builder/pdf-store';

export type ShareAndSaveSupportedPostTypes = 'page' | 'blog-and-news' | 'event' | 'product' | 'video-item';

export type PageData = {
    blocks: Block[];
    title: string;
    permalink: string;
    type: ShareAndSaveSupportedPostTypes;
};

export type PostData = BlogNewsData | ProductData | EventData | VideoData | PageData;

type ShareAndSaveProps = {
    title?: string;
    permalink?: string;
    type?: ShareAndSaveSupportedPostTypes;
    data?: PostData;
};

export const ShareAndSave = (props: ShareAndSaveProps) => {
    const { localize, myContentPage, page404 } = useContext(AppContext);
    const [modalVisible, setModalVisible] = createSignal(false);
    const location = useLocation();

    const openSharePopup = () => {
        setModalVisible(true);
        document.body.style.overflowY = 'hidden';
    };

    const closeSharePopup = () => {
        setModalVisible(false);
        document.body.style.overflowY = 'auto';
    };

    const shareableContent = () => {
        // It doesn't make sense to share certain pages, like the error page and the personalized content page.
        const nonShareableContent = [
            myContentPage?.url,
            page404?.url,
        ];

        return !nonShareableContent.includes(location.pathname);
    };

    return (
        <Show when={shareableContent()}>
            <StyledProductSocial>
                <StyledFlexRow justifyContent="center" gap="3rem" flexWrap="wrap">

                    <StyledShareContainer onClick={() => openSharePopup()}>
                        <Text color={'darkGray'} fontSize="small" noBlockSpacing={true}>
                            {localize('share', 'Share')}
                        </Text>
                        <EnvelopeIcon size="default" fill={theme.palette['gray']} aria-label={localize('share_envelope_icon', 'Envelope')}/>
                        <ShareIcon size="default" fill={theme.palette['gray']} aria-label={localize('share_share_icon', 'Share')}/>
                    </StyledShareContainer>

                    <MyContentHandler action='add' title={props.title} permalink={props.permalink} type='page' />

                    <PdfToolbar type={props.type as EntitySlug} data={props.data as ValidDataTypes} />

                </StyledFlexRow>
            </StyledProductSocial>
            {/* DONT REMOVE THIS! as I get hydration error for a second if portal is removed */}
            <Portal mount={document.getElementById('root')!}>
                <SharePopup visible={modalVisible()} closeModal={() => closeSharePopup()} />
            </Portal>
        </Show>
    );
};
