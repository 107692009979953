import { gql } from 'graphql-request';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';

export const GET_VIDEO_QUERY = gql`
    query GetVideo(
        $key: String!,
    ) {
        video: resource(
            key: $key
        ) {
            content
        }
    }
`;

export const GET_RELATED_VIDEOS_QUERY = gql`
    query GetRelatedVideos(
        $tags: [String]
    ) {
        relatedvideos: resources(
            type: "video-item"
            tags: $tags
        ) {
            content
        }
    }
`;

export const fetchVideo = (slug: string) => {    
    const { createCachedResource } = useContext(AppContext);

    const [videoContent] = createCachedResource(
        GET_VIDEO_QUERY,
        () => ({
            key: `/video-item/${slug}`
        }),
        true
    );

    return videoContent()?.video?.content;
};
