import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 38.571 30',
    width: 3.21,
    height: 2.86,
};

export const IdCardIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M23.571,53.429h8.571a1.071,1.071,0,1,0,0-2.143H23.571a1.071,1.071,0,1,0,0,2.143ZM34.286,32h-30A4.285,4.285,0,0,0,0,36.286V57.714A4.285,4.285,0,0,0,4.286,62h30a4.285,4.285,0,0,0,4.286-4.286V36.286A4.288,4.288,0,0,0,34.286,32Zm2.143,25.714a2.146,2.146,0,0,1-2.143,2.143h-30a2.146,2.146,0,0,1-2.143-2.143V38.429H36.429ZM2.143,36.286a2.146,2.146,0,0,1,2.143-2.143h30a2.146,2.146,0,0,1,2.143,2.143Zm21.429,8.571h8.571a1.071,1.071,0,1,0,0-2.143H23.571a1.071,1.071,0,0,0,0,2.143Zm0,4.286h8.571a1.071,1.071,0,1,0,0-2.143H23.571a1.071,1.071,0,1,0,0,2.143Zm-10.714,0a4.286,4.286,0,1,0-4.286-4.286A4.285,4.285,0,0,0,12.857,49.143Zm0-6.429a2.143,2.143,0,1,1-2.143,2.143A2.146,2.146,0,0,1,12.857,42.714Zm-6.429,15A1.071,1.071,0,0,0,7.5,56.643a3.215,3.215,0,0,1,3.214-3.214H15a3.214,3.214,0,0,1,3.214,3.214,1.071,1.071,0,0,0,2.143,0A5.357,5.357,0,0,0,15,51.286H10.714a5.357,5.357,0,0,0-5.357,5.357A1.073,1.073,0,0,0,6.429,57.714Z"
                transform="translate(0 -32)"
            />
        </SvgIcon>
    );
};
