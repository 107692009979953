import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 51 51',
    // width: 29.992
};

export const TracoePediatrictsIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path d="M25.8 1.40001C39.1 1.40001 50 12.2 50 25.6C50 39 39.1 49.9 25.8 49.9C12.5 49.9 1.5 39 1.5 25.6C1.5 12.2 12.4 1.40001 25.8 1.40001ZM25.8 0.600006C12 0.600006 0.800003 11.8 0.800003 25.6C0.800003 39.4 12 50.6 25.8 50.6C39.6 50.6 50.8 39.4 50.8 25.6C50.8 11.8 39.6 0.600006 25.8 0.600006Z"/>
            <path d="M46.2 23.9C46 23.8 42.7 20.4 35.5 20.4C31.1 20.4 28.1 21.6 26.5 22.6C25.1 20.4 22 16.9 17.1 16.9C10 16.9 6.8 24 6.7 24.4C6.3 25.2 6.7 26.2 7.6 26.5C8.4 26.9 9.4 26.5 9.7 25.7C9.7 25.7 10.7 23.4 12.7 21.8L12 40.6C12 41.5 12.7 42.3 13.6 42.3C14.5 42.3 15.2 41.6 15.2 40.7L15.5 32.8H18.5L18.8 40.7C18.8 41.6 19.6 42.3 20.4 42.3C21.3 42.3 22 41.5 22 40.6L21.3 21.8C23.1 23.3 24.1 25.3 24.3 25.6C24.4 25.8 24.5 25.9 24.6 26.1C24.6 26.1 24.6 26.2 24.7 26.2C25 26.5 25.4 26.7 25.8 26.7C26 26.7 26.1 26.7 26.3 26.6C26.4 26.6 26.5 26.6 26.5 26.5H26.6H26.7C26.8 26.5 26.9 26.4 26.9 26.3C27 26.2 28.1 25.1 30.5 24.4L28.2 33.5C28.1 33.9 28.4 34.3 28.9 34.3H30.9L30.7 40.6C30.7 41.5 31.4 42.3 32.3 42.3C32.3 42.3 32.3 42.3 32.4 42.3C33.3 42.3 34 41.6 34 40.7L34.2 34.2H35.4H36.6L36.8 40.7C36.8 41.6 37.6 42.3 38.4 42.3C38.4 42.3 38.4 42.3 38.5 42.3C39.4 42.3 40.1 41.5 40.1 40.6L39.9 34.3H41.9C42.3 34.3 42.7 33.9 42.6 33.5L40.3 24.4C42.7 25.1 43.9 26.2 44 26.3C44.6 26.9 45.7 26.9 46.3 26.3C46.8 25.6 46.8 24.5 46.2 23.9Z"/>
            <path d="M17 15.8C19.3196 15.8 21.2 13.9196 21.2 11.6C21.2 9.2804 19.3196 7.39999 17 7.39999C14.6804 7.39999 12.8 9.2804 12.8 11.6C12.8 13.9196 14.6804 15.8 17 15.8Z"/>
            <path d="M35.4 19.2C37.7196 19.2 39.6 17.3196 39.6 15C39.6 12.6804 37.7196 10.8 35.4 10.8C33.0804 10.8 31.2 12.6804 31.2 15C31.2 17.3196 33.0804 19.2 35.4 19.2Z"/>
        </SvgIcon>
    );
};


