import { useLocation } from '@solidjs/router';
import { gql } from 'graphql-request';
import { createSignal, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Section } from '../../grid-system/section/section';
import { Button } from '../../ui-components/button/button';
import { StyledErrorMessage, StyledLoginInput, StyledSuccessMessage } from '../login-form/login-form-styles';
import { StyledResetPassword } from './password-reset.style';
import { Heading } from '../../ui-components/heading/heading';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';

export const resetPasswordMutation = gql`
    mutation resetPassword($email: String!, $password: String!, $token: String!) {
        resetPassword(
            email: $email
            password: $password
            token: $token
        ) {
            status
            message
        }
    }
`;

export const careResetPasswordMutation = gql`
    mutation careResetPassword(
        $email: String!
        $password: String!
        $token: String!
        $site: String!
    ) {
        careResetPassword(
            email: $email
            password: $password
            token: $token
            site: $site
        ) {
            status
            message
        }
    }
`;


export const PasswordReset = () => {
    const { graphQlClient, localize, siteInfo } = useContext(AppContext);
    const [password,setPassword] = createSignal('');
    const [verifyPassword,setVerifyPassword] = createSignal('');
    const [error,setError] = createSignal('');
    const [message, setMessage] = createSignal('');
    const [loading, setLoading] = createSignal(false);
    const { token, email: encodedEmail } = useLocation().query;

    const submitResetPasswordForm = async () => {
        if (password() !== verifyPassword()) {
            setError(localize('passwords-do-not-match', 'Passwords do not match'));
            return;
        }

        if (password() === '') {
            setError(localize('password-is-required', 'Password is required'));
            return;
        }

        const isCareSite = siteInfo.siteType === 'atos-care';        
        const query = isCareSite ? careResetPasswordMutation : resetPasswordMutation;

        const email = decodeURIComponent(encodedEmail);        

        const vars = {
            email,
            token,
            password: password(),
            site: siteInfo.siteSlug,
        };

        setLoading(true);

        try {
            const response = await graphQlClient(query, vars, false);
            const { status, message } = isCareSite ? response.careResetPassword : response.resetPassword;
    
            if(status !== 'OK') {
                setError(message);
            } else {
                setMessage(message);
            }
        } catch (e) {
            setMessage(localize('error-has-occurred', 'An error has occurred'));
            console.log('error', e);
        }

        setLoading(false);
    };

    return (
        <Section widthType="bgFull" templateShorthand={[12]}>
            <StyledResetPassword>
                <Heading tag='h1'>{localize('reset-password', 'Reset password')}</Heading>
                <StyledLoginInput
                    value={password()}
                    onFocus={() => setError('')}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    placeholder={localize('new-password', 'New password')}
                    type="password"
                />
                <StyledLoginInput
                    value={verifyPassword()}
                    onFocus={() => setError('')}
                    onChange={(e) => setVerifyPassword(e.currentTarget.value)}
                    placeholder={localize('verify-new-password', 'Verify new password')}
                    type="password"
                />
                <Show when={error()} fallback={(<div style={{height: '22px'}}></div>)}>
                    <StyledErrorMessage>
                        {error()}
                    </StyledErrorMessage>
                </Show>
                <Show when={message()}>
                    <StyledSuccessMessage>{message()}</StyledSuccessMessage>
                </Show>
                <StyledFlexRow gap={'1.5rem'}>
                    <Button
                        label={localize('submit-password-reset-form-button-text', 'Reset')}
                        onClick={() => submitResetPasswordForm()}
                        disabled={loading()}
                    />
                    <Show when={loading()}>
                        <LoadingSpinner />
                    </Show>
                </StyledFlexRow>
            </StyledResetPassword>
        </Section>
    );
};
