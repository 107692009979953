import { gql } from 'graphql-request';
import { createMemo, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { EventData } from '../../event/event-types';

const GET_EVENTS_QUERY = gql`
    query GetEvents(
            $siteId: String,
            $type: [String],
            $tags: [String],
            $limit: Int,
            $fromDate: String,
            $toDate: String
        ) {
        events: resources(
                siteId: $siteId,
                type: $type,
                tags: $tags,
                limit: $limit,
                order: DESC,
                fromDate: $fromDate,
                toDate: $toDate
            ) {
            content
        }
    }
`;

export const fetchEvents = (props: EventsProps) => {
    const { createCachedResource } = useContext(AppContext);

    const vars = {
        type: ['event'],
        ...props?.tag?.slug
            ? { tags: [props?.tag?.slug] }
            : null,
        ...props?.limit
            ? { limit: props?.limit }
            : null,
        ...props?.fromDate
            ? { fromDate: props.fromDate }
            : null,
        ...props?.toDate
            ? { toDate: props.toDate }
            : null,
    };

    const [eventsContent] = createCachedResource(GET_EVENTS_QUERY, vars, true);

    return createMemo((): EventData[] =>
        eventsContent()
            ?.events?.map((event: EventContainer) => event?.content)
            .sort((a: EventData, b: EventData) => {
                const dateA = new Date(a?.date).getTime();
                const dateB = new Date(b?.date).getTime();
                return dateA - dateB;
            })
    );
};

export type EventContainer = {
    content: EventData;
};

type EventsProps = {
    tag?: {
        slug: string;
        name?: string;
    };
    limit?: number;
    fromDate?: string;
    toDate?: string;
};
