import { ComponentData } from './index';

type VirtualSectionArgs = {
    width: string;
    start?: string;
    end?: string;
};

// Create a basic skeleton for a single row column
const getVirtualSection = ({ width, start, end }: VirtualSectionArgs) => {
    return {
        blockName: 'next24hr/section',
        ['widthTypeFull']: false,
        ['template']: [
            {
                width,
                start,
                end,
            },
        ],
        blocks: [
            {
                blockName: 'next24hr/column',
                blocks: [],
            },
        ],
    };
};

type ConversionProps = {
    content: ComponentData[];
    skipSectionConversion: boolean | undefined;
}

// First version (1.0.0) of a wordpress blocks content converter to our classic row/column structure.
// This function makes sure that any none-columns blocks are wrapped in a row/column.
const convertToVirtualSections = (props: ConversionProps) => {
    const { content, skipSectionConversion } = props;

    if (!content) {
        return;
    }

    const parsedContent = [];

    let current: { 
        blockName: string; 
        ['widthTypeFull']: boolean;
        ['template']: {
            width: string; start?: string; end?: string;
        }[]; 
        blocks: {
            blockName: string;
            blocks: ComponentData[];
        }[]
    } = getVirtualSection({ width: '1/1' });    

    // Iterate through all top level blocks and save all blocks that
    // arent in core/columns or core/blocks in an array
    // until we encounter a core/columns, core/blocks or we hit the end.
    // When we do that, we push the array into the content array.
    // If we encounter a core/columns, we just add it.
    // The same for core/block, but we make sure that their inner content is
    // also wrapped in a row/columns with the same rules as above.
    const nonConvertibleBlocks = [
        'atos/hero',
        'atos/hero-campaign',
        'atos/products',
        'atos/products-collection',
        'atos/products-spotlight',
        'atos/products-hero',
        'atos/events',
        'atos/blogs-news',
        'atos/videos',
        'atos/manuals',
        'atos/testimony',
        'atos/search',
        'atos/my-content',
        'atos/search-widget',
        'atos/pdf-builder',
        'atos/files',
        'atos-care/basket',
    ];

    const parseContent = (contentToParse: ComponentData[] | undefined) => {
        if (!contentToParse) {
            return;
        }

        for (let i = 0, ii = contentToParse.length; i < ii; i++) {
            const block = contentToParse[i];
    
            const skipConversion = skipSectionConversion || nonConvertibleBlocks.includes(block.blockName);

            if (block.blockName === 'next24hr/section' || skipConversion) {
                // Sections don't need to be placed in sections (nor do certain other components)
                if (current.blocks[0].blocks.length > 0) {
                    parsedContent.push(current);
                    current = getVirtualSection({ width: '1/1' });
                }
    
                parsedContent.push(block);
            } else if (block.blockName === 'core/block') {
                // Reusable blocks need to be looped through;
                parseContent(block?.blocks);
            } else {
                // "Regular" blocks outside of a section - insert them into sections
                current.blocks[0].blocks.push(block);
            }
        }
    };

    parseContent(content);

    if (current.blocks.length > 0 && current.blocks[0].blocks.length > 0) {
        parsedContent.push(current);
    }

    return parsedContent;
};

export default convertToVirtualSections;
