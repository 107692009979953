/* eslint-disable indent */
import { useLocation, useNavigate, useSearchParams } from '@solidjs/router';
import { For, Match, Show, Switch, createEffect, on, onCleanup, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import {
    changeAreaOfCare,
    changeSelectedProductCategory,
    changeSelectedType,
    clearFilters,
    orderSorter,
    productsStore,
    setAvailableAreasOfCare,
    setIsMobile,
    setProductsStore,
} from '../../stores/products-store';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { FilterList } from '../../ui-components/filter-list/filter-list';
import { Heading } from '../../ui-components/heading/heading';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { Radio } from '../../ui-components/inputs/radio/radio';
import { StyledSidebar, StyledSidebarContainer, StyledSidebarContainerLine, StyledSidebarInner, gridSettings } from '../../ui-components/layouts/sidebar.style';
import { ProductCategory, ProductData } from '../product/product-types';
import { fetchProductCategories, fetchProducts } from './fetch-products';
import { MobileProductsSidebar } from './mobile/mobile-sidebar';
import { ProductCard } from './product-card/product-card';
import { CategoryWithData, NestedCategory, ProductsProps } from './products-types';
import { StyledClearAllContainer, StyledFilterHeadingContainer, StyledHeadingWrapper, StyledProductsContainer, StyledProductsList } from './products.style';
import { LoadingPlaceHolder } from '../loading-place-holder/loading-place-holder';
import { Button } from '../../ui-components/button/button';

export const createGroupedProductCard = (product: ProductData) => {
    return createProductCard(product, true);
};

const createProductCard = (product: ProductData, inGrouping?: boolean) => {
    return <ProductCard data={product} inGrouping={inGrouping} />;
};

export const extractCategories = (categories: ProductCategory[], parentRef: { [key: string]: any}, freshProductCategories: ProductCategory[]) => {
    if (!categories) return;

    categories.forEach((category) => {
        if (!parentRef[category.slug]) {
            const freshCategory = freshProductCategories.find((fresh) => fresh.categoryId === category.termId);

            if (!freshCategory) return;

            parentRef[category.slug] = {
                data: freshCategory,
            };
        }

        if (category.children.length > 0) {
            extractCategories(category.children, parentRef[category.slug], freshProductCategories);
        }
    });
};

const extractAllCategories = (products: ProductData[], freshProductCategories: ProductCategory[]) => {
    if (!products || !freshProductCategories) {
        return null;
    }

    const categoriesAggregator: NestedCategory = {} as NestedCategory;

    products.forEach((prod: ProductData) => {
        extractCategories(prod.categories, categoriesAggregator, freshProductCategories);
    });

    return categoriesAggregator;
};

export const sortAlphabetically = (target: CategoryWithData) => {
    const sortedAreasOfCare: { [key: string]: any } = {};

    Object.keys(target)
        .filter((k) => k !== 'data')
        .sort((a, b) => a.localeCompare(b))
        .forEach((key) => {
            sortedAreasOfCare[key] = target[key];
        });        

    return sortedAreasOfCare;
};

export const sortByOrder = (nestedCategories: CategoryWithData) => {
    const sortedAreasOfCare: { [key: string]: any } = {};

    Object.keys(nestedCategories)
        .map((key) => nestedCategories[key])
        .sort(orderSorter)
        .forEach((category) => {
            if (category?.data?.slug) {
                sortedAreasOfCare[category.data.slug] = category;
            }
        });
    
    return sortedAreasOfCare;
};

export const Products = (props: ProductsProps) => {
    const { viewport, localize } = useContext(AppContext);

    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const productsData = fetchProducts({ tag: props?.tag });
    const loaded = () => productsData();

    const freshProductCategories = fetchProductCategories();

    createEffect(() => {
        const parsedNestedCategories = extractAllCategories(productsData(), freshProductCategories());

        if (!parsedNestedCategories) {
            return;
        }        

        const alphabetizedAoc = sortAlphabetically(parsedNestedCategories as CategoryWithData);
        const orderedAoc = sortByOrder(alphabetizedAoc);

        setAvailableAreasOfCare(orderedAoc);
        setProductsStore({
            labels: {
                heading: localize('products', 'Products'),
                topCategoryHeading: localize('area-of-care', 'Area of care'),
                firstNestedCategoryHeading: localize('product-category', 'Product category'),
                secondNestedCategoryHeading: localize('type-of-product', 'Type of product'),
            }
        });
    });

    const populateFilters = (filters: any) => {
        const [ area, categories, types ] = filters;

        if (area) {
            const areaOfCare = productsStore.availableAreasOfCare[area];

            if (areaOfCare) {
                changeAreaOfCare(areaOfCare);
            }
        }

        if (categories) {
            const allCategories = categories.split(',');
            allCategories.forEach((selectedCategory: string) => {
                const category = productsStore?.availableProductCategories.find((category) => category.data.slug === selectedCategory);
                if (category) {
                    changeSelectedProductCategory(category);
                }
            });
        }

        if (types) {
            const allTypes = types.split(',');
            allTypes.forEach((selectedType: string) => {
                const type = productsStore?.availableTypes.find((type) => type.data.slug === selectedType);
                if (type) {
                    changeSelectedType(type);
                }
            });
        }
    };

    createEffect(
        on(
            [() => searchParams.area, () => searchParams.categories, () => searchParams.types, () => productsStore.availableAreasOfCare],
            (filters) => populateFilters(filters)
        )
    );

    onCleanup(() => {
        clearFilters();
    });

    createEffect(() => setIsMobile(viewport.width <= theme.breakpoints.MOBILE));

    const breadcrumb = () => `${props?.labels?.pageHeading} ${productsStore.areaOfCare?.name ? `/ ${productsStore.areaOfCare?.name}` : ''}`;

    const results = () => {
        const area = searchParams.area || productsStore.areaOfCare?.slug;

        const searchParamCategories = searchParams?.categories?.split(',') || [];
        const storeCategories = productsStore?.selectedProductCategories?.map((category: NestedCategory) => category.data.slug);
        const selectedCategories = searchParamCategories.length 
            ? searchParamCategories
            : storeCategories;

        const searchParamTypes = searchParams?.types?.split(',') || [];
        const storeTypes = productsStore?.selectedTypes?.map((type: NestedCategory) => type.data.slug);
        const selectedTypes = searchParamTypes.length
            ? searchParamTypes
            : storeTypes;
        
        const results = productsData()
            ?.filter((prod: ProductData) => {
                if (!area) return true;

                return prod.tags?.includes(area);
            })
            ?.filter((prod: ProductData) => {
                if (selectedCategories.length === 0) return true;
                if (!prod.tags) return false;

                const found = prod.tags.find((tag: string) => selectedCategories.includes(tag));
                return Boolean(found);
            })
            ?.filter((prod: ProductData) => {
                if (selectedTypes.length === 0) return true;                
                if (!prod.tags) return false;

                const found = prod.tags.find((tag: string) => selectedTypes.includes(tag));
                return Boolean(found);
            });

        setProductsStore({ results: results });
        return results;
    };

    const handleOnClickArea = (area: string) => {
        // Just changes the url/search params (which in turn triggers the effect to run populateFilters)
        navigate(`${location.pathname}?area=${area}`);
    };

    const handleOnClickCategory = (category: NestedCategory) => {
        // Logic to update the selected product categories, then update the url/search params
        changeSelectedProductCategory(category);
        const allSelectedProductCategorySlugs = productsStore?.selectedProductCategories?.map((category: NestedCategory) => category.data.slug);
        const allCategorySlugsJoined = allSelectedProductCategorySlugs.join(',');

        if (allCategorySlugsJoined !== '') {
            navigate(`${location.pathname}?area=${searchParams.area}&categories=${allCategorySlugsJoined}`);
        } else {
            navigate(`${location.pathname}?area=${searchParams.area}`);
        }
    };

    const handleOnClickType = (type: NestedCategory) => {
        // Logic to update the selected types, then update the url/search params
        changeSelectedType(type);
        const allSelectedTypes = productsStore?.selectedTypes?.map((category: NestedCategory) => category.data.slug);
        const allSelectedTypesJoined = allSelectedTypes.join(',');

        if (allSelectedTypesJoined !== '') {
            navigate(`${location.pathname}?area=${searchParams.area}&categories=${searchParams.categories}&types=${allSelectedTypesJoined}`);
        } else {
            navigate(`${location.pathname}?area=${searchParams.area}&categories=${searchParams.categories}`);
        }
    };

    const handleOnClickClearFilters = (e: any) => {
        clearFilters(e);
        navigate(location.pathname);
    };

    return (
        <ErrorCatcher componentName="Products list">
            <Show when={loaded()} fallback={<LoadingPlaceHolder />}>
                <Section
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    heightType={'fill'}
                    backgroundType={'color'}
                    backgroundValue={'white'}
                    removeSpacingBlock={true}
                    customCss={'padding-top: 0; padding-bottom: 0;'}
                >
                    <Grid {...gridSettings.container}>
                        <StyledSidebarContainer>
                            <StyledSidebarContainerLine>
                                <StyledSidebar>
                                    <StyledSidebarInner>
                                        <StyledHeadingWrapper>
                                            <Heading tag="h1" variant={'giantRed'}>
                                                {localize('products', 'Products')}
                                            </Heading>
                                        </StyledHeadingWrapper>

                                        <Switch>
                                            <Match when={productsStore?.isMobile}>
                                                <MobileProductsSidebar
                                                    labels={props.labels}
                                                    store={{
                                                        store: productsStore,
                                                        changeAreaOfCare,
                                                        clearFilters,
                                                        changeSelectedProductCategory,
                                                        changeSelectedType,
                                                    }}
                                                    clickHandlers={{
                                                        handleOnClickArea,
                                                        handleOnClickCategory,
                                                        handleOnClickType,
                                                        handleOnClickClearFilters,
                                                    }}
                                                />
                                            </Match>
                                            <Match when={!productsStore?.isMobile}>
                                                <StyledFilterHeadingContainer>
                                                    <Heading tag="h2" variant="medium" noBlockSpacing={true}>
                                                        {localize('filters', 'Filters')}
                                                    </Heading>
                                                </StyledFilterHeadingContainer>

                                                <FilterList listHeading={localize('area-of-care', 'Area of care')}>
                                                    <For each={Object.keys(productsStore.availableAreasOfCare)}>
                                                        {(area) => (
                                                            <li>
                                                                <Radio
                                                                    value={productsStore.availableAreasOfCare[area]?.data?.slug}
                                                                    whenClicked={() => handleOnClickArea(area)}
                                                                    name="areaOfCare"
                                                                    readableName={productsStore.availableAreasOfCare[area]?.data?.name}
                                                                    isChecked={
                                                                        productsStore.areaOfCare?.slug === productsStore.availableAreasOfCare[area]?.data?.slug
                                                                    }
                                                                />
                                                            </li>
                                                        )}
                                                    </For>
                                                </FilterList>

                                                <Show when={productsStore.availableProductCategories.length > 0}>
                                                    <FilterList listHeading={localize('product-category', 'Product category')}>
                                                        <For each={productsStore.availableProductCategories}>
                                                            {(category) => (
                                                                <li>
                                                                    <Checkbox
                                                                        value={category?.data?.slug}
                                                                        whenClicked={() => handleOnClickCategory(category)}
                                                                        name={category?.data?.name}
                                                                        isChecked={
                                                                            !!productsStore?.selectedProductCategories?.find(
                                                                                (existing) => existing?.data?.slug === category?.data?.slug
                                                                            )
                                                                        }
                                                                    />
                                                                </li>
                                                            )}
                                                        </For>
                                                    </FilterList>
                                                </Show>

                                                <Show when={productsStore.availableTypes.length > 0}>
                                                    <FilterList listHeading={localize('type-of-product', 'Type of product')}>
                                                        <For each={productsStore.availableTypes}>
                                                            {(type) => (
                                                                <li>
                                                                    <Checkbox
                                                                        value={type?.data?.slug}
                                                                        whenClicked={() => handleOnClickType(type)}
                                                                        name={type?.data?.name}
                                                                        isChecked={
                                                                            !!productsStore.selectedTypes?.find(
                                                                                (existing) => existing?.data?.slug === type?.data?.slug
                                                                            )
                                                                        }
                                                                    />
                                                                </li>
                                                            )}
                                                        </For>
                                                    </FilterList>
                                                </Show>
                                            </Match>
                                        </Switch>

                                        <Show when={productsStore.areaOfCare}>
                                            <StyledClearAllContainer>
                                                <Button
                                                    label={localize('clear-all', 'Clear all')}
                                                    onClick={(e) => handleOnClickClearFilters(e)}
                                                    variant='tertiary'
                                                    noCaps={true}
                                                />
                                            </StyledClearAllContainer>
                                        </Show>
                                    </StyledSidebarInner>
                                </StyledSidebar>
                            </StyledSidebarContainerLine>
                        </StyledSidebarContainer>

                        <StyledProductsContainer>
                            <Show when={!productsStore.isMobile}>
                                <Heading tag="h2" variant="breadcrumb">
                                    {breadcrumb()}
                                </Heading>
                            </Show>
                            <StyledProductsList>
                                <Grid {...gridSettings.listing} customCss={''}>
                                    <For each={results()}>
                                        {(product: ProductData) => {
                                            return createGroupedProductCard(product);
                                        }}
                                    </For>
                                </Grid>
                            </StyledProductsList>
                        </StyledProductsContainer>
                    </Grid>
                </Section>
            </Show>
        </ErrorCatcher>
    );
};

Products.parseProps = (atts: any) => {
    return {
        labels: {
            ...atts.labels,
            applyFiltersText: atts.labels?.showProductsButtonText,
        },
    };
};
