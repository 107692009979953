import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../../style/theme';
// import { StyledProductVideoProps } from './product-video.d';

const { spacing, mediaQueries } = theme;

export const StyledProductVideoContainer = styled.div`
    background-color: ${theme.palette.white};
    padding: ${spacing.xxxl} ${spacing.xl};

    ${mediaQueries.mobileMax} {
        padding: ${spacing.m} ${spacing.s};
    }
`;

export const StyledProductVideoCard = styled.div`
`;

export const StyledProductVideo = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 1.43rem 1.43rem 0 0;
        border-bottom: 1px solid ${theme.palette.lightGray};
        border-width: 1px;
    }
`;

export const StyledVideoDescription = styled.div<{ noBorder?: boolean }>`
    border-radius: 0 0 1.43rem 1.43rem;
    padding: 1.5rem;
    ${p => p.noBorder ? '' : `
        border: 1px solid ${theme.palette.lightGray};
    `}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledBigText = styled.p`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.64rem;
    color: ${theme.palette.darkGray};
    margin: 0 !important;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    ${theme.mediaQueries.mobileMax} {
        font-size: 1.64rem;
        margin-top: ${spacing.xxxs};
        margin-bottom: ${spacing.ms};

        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3;
    }
`;
