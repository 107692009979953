import theme, { QueryTypeSlug } from '../style/theme';

export const containerQueries = (size: QueryTypeSlug) => {    
    try {
        if (CSS.supports('container-type', 'inline-size')) {
            // container queries are supported
            return theme.containerQueries[size];
        } else {
            // use media queries as fallback
            return theme.mediaQueries[size];
        }
    } catch (e) {
        // CSS.supports is not supported in IE
        return theme.mediaQueries[size];
    }
};