import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 50 50',
    // width: 29.992
};

export const GoIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <defs>
                <clipPath id="clip-path-go">
                    <circle 
                        id="Ellipse_119" 
                        data-name="Ellipse 119" 
                        cx="22.68" 
                        cy="22.68" 
                        r="22.68" 
                        transform="translate(2.32 2.32)"
                        fill="none" 
                    />
                </clipPath>
            </defs>
            <g id="Group_3562" data-name="Group 3562">
                <path 
                    id="Path_21776" 
                    data-name="Path 21776" 
                    d="M33.23,23.34a2.45,2.45,0,1,0-2.45-2.45A2.454,2.454,0,0,0,33.23,23.34Z" 
                    fill="#fff" 
                />
                <path
                    id="Path_21777"
                    data-name="Path 21777"
                    d="M40.76,28.46l-4-.73-3.32-3.61a1.016,1.016,0,0,0-1.09-.27l-4.3,1.54a1.022,1.022,0,0,0-.67.86l-.46,4.77a1.02,1.02,0,0,0,.92,1.11h.1a1.011,1.011,0,0,0,1.01-.92l.4-4.13,1.12-.4L29.1,33.27v.06l-1.82,3.68-3.86,3.45a1.02,1.02,0,0,0,1.36,1.52l4.01-3.58a.867.867,0,0,0,.23-.31l1.4-2.83,2.9,7.67a1.01,1.01,0,0,0,.95.66.922.922,0,0,0,.36-.07,1.013,1.013,0,0,0,.59-1.31l-3.13-8.3v-.03l1.4-6.73,2.01,2.18a1.037,1.037,0,0,0,.57.31l4.34.79a1.022,1.022,0,0,0,.37-2.01Z"
                />
            </g>
            <g 
                id="Group_3564" 
                data-name="Group 3564" 
                clip-path="url(#clip-path-go)"
            >
                <g 
                    id="Group_3563" 
                    data-name="Group 3563"
                >
                    <path
                        id="Path_21778"
                        data-name="Path 21778"
                        d="M23.81,15.21a.57.57,0,0,0-.57.57V28.06H20.99V18H13.75V28.06H1.86V15.79a.57.57,0,0,0-.57-.57.576.576,0,0,0-.57.57V29.22H24.38V15.79a.57.57,0,0,0-.57-.57ZM14.89,28.06V19.15h4.94v8.91Z"
                    />
                    <path
                        id="Path_21779"
                        data-name="Path 21779"
                        d="M26.6,14.42,12.55,6.46-1.51,14.42a.577.577,0,0,0-.22.78.571.571,0,0,0,.5.29.555.555,0,0,0,.28-.07l13.5-7.64,13.49,7.64a.573.573,0,0,0,.56-1Z"
                    />
                    <path 
                        id="Path_21780" 
                        data-name="Path 21780" 
                        d="M4.73,18.03v5.36h6.06V18.03Zm4.91,4.21H5.88V19.18H9.64Z"  
                    />
                </g>
            </g>
            <path
                id="Path_21781"
                data-name="Path 21781"
                d="M25,50A25,25,0,1,1,50,25,25.028,25.028,0,0,1,25,50ZM25,1.15A23.85,23.85,0,1,0,48.85,25,23.878,23.878,0,0,0,25,1.15Z"
            />
        </SvgIcon>
    );
};
