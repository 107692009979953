import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';
import { palette } from '../../style/theme';

const defaultProps: SvgIconProps = {
    width: 4,
    height: 2.76,
    viewBox: '0 0 50 34.549',
    fill: palette.pink
};

export const QuoteOpen = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                id="Path_10783"
                data-name="Path 10783"
                d="M2.742,30.163A14.768,14.768,0,0,1,0,21.114,20.053,20.053,0,0,1,4.113,8.775Q8.226,3.292,16.613,0l1.451,2.468A21.777,21.777,0,0,0,9.435,8.089Q5.807,11.929,5.806,15.9c0,.106,0,.211,0,.314a1.717,1.717,0,0,0,2.365,1.544A10.722,10.722,0,0,1,12.258,17,11.346,11.346,0,0,1,19.6,19.4a7.773,7.773,0,0,1,2.984,6.375A7.882,7.882,0,0,1,19.6,32.013a10.937,10.937,0,0,1-7.339,2.537q-6.29,0-9.516-4.387m27.419,0a14.768,14.768,0,0,1-2.742-9.049A20.053,20.053,0,0,1,31.532,8.775Q35.645,3.292,44.032,0l1.451,2.468a21.777,21.777,0,0,0-8.629,5.621q-3.629,3.839-3.629,7.815c0,.106,0,.211,0,.314A1.717,1.717,0,0,0,35.6,17.762,10.722,10.722,0,0,1,39.677,17a11.346,11.346,0,0,1,7.339,2.4A7.773,7.773,0,0,1,50,25.776a7.882,7.882,0,0,1-2.984,6.237,10.937,10.937,0,0,1-7.339,2.537q-6.29,0-9.516-4.387"
            />
        </SvgIcon>
    );
};
