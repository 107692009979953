import { styled } from 'solid-styled-components';
import theme from '../../../../../../style/theme';
import { OverlayType } from '../../../../../../grid-system/section/section-types';

type StyledBgImagePdfProps = {
    backgroundImageSrcData: any;
    focusPosition?: string;
    overlayType?: OverlayType;
    roundCorners?: boolean;
}

export const StyledBgImagePdf = styled.div<StyledBgImagePdfProps>`

    height: 19rem;
    position: relative;
    padding: 2.5rem;
    padding-top: 1px;
    border-radius: ${p => p.roundCorners ? '0.73rem' : ''};
    width: 85%;

    ${(p) =>
        p.backgroundImageSrcData && p.backgroundImageSrcData.original !== ''
            ? `
                background-image: url(${p?.backgroundImageSrcData?.original || ''});
                background-repeat: no-repeat;
                background-size: cover;
                background-position: ${p.focusPosition || 'center'};
                &::before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: ${p.overlayType === 'on' ? 0.3 : 0};
                    border-radius: 0.73rem;
                    background-color: ${theme.palette.beige};            
                }            
            `
            : ''
}
`;