import { Shortcuts } from './shortcuts/shortcuts';
import { MenuHeader } from './menu-header/menu-header';
import { PrimaryMenu } from './primary-menu/primary-menu';
import { StyledMenuMobile, StyledMenuMobileTop } from './menu-mobile.styles';
import type { MenuProps } from '../menu-types';
import { Show, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { TabHandler } from '../menu-desktop/tab-handling';

export const MenuMobile = (props: MenuProps) => {
    const { siteInfo } = useContext(AppContext);

    const [ firstTabbableElement, setFirstTabbableElement ] = createSignal<HTMLElement | undefined>();
    const [ lastTabbableElement, setLastTabbableElement ] = createSignal<HTMLElement | undefined>();

    return (
        <TabHandler
            refs={{
                firstTabbableElement,
                lastTabbableElement,
            }}
        >
            <StyledMenuMobile>
                <div>
                    <StyledMenuMobileTop>
                        <MenuHeader
                            links={props?.headerLinks}
                            handleCloseMenuClick={props?.handleCloseMenuClick}
                            firstElement={(el: HTMLElement) => {
                                setFirstTabbableElement(el);
                            }}
                        />
                        <PrimaryMenu
                            menuTitle={props?.primaryMenuName}
                            menu={props?.primaryMenuLinks}
                        />
                    </StyledMenuMobileTop>
                    <Show when={siteInfo.siteType === 'default'}>
                        <Shortcuts
                            shortcutsHeading={props?.shortcutsHeading}
                            contactHeading={props?.contactHeading}
                            letUsContactYouLink={props?.letUsContactYouLink}
                            phoneNumber={props?.phoneNumber}
                            servicesHeading={props?.servicesHeading}
                            servicesLinks={props?.servicesLinks}
                            lastElement={(el: HTMLElement) => {
                                setLastTabbableElement(el);
                            }}
                        />
                    </Show>
                </div>
            </StyledMenuMobile>
        </TabHandler>
    );
};
