import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 50 50',
    // width: 29.992
};

export const NightHMEIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                id="Path_21803"
                data-name="Path 21803"
                d="M25,50A25,25,0,1,1,50,25,25.022,25.022,0,0,1,25,50ZM25,1.15A23.85,23.85,0,1,0,48.85,25,23.878,23.878,0,0,0,25,1.15Z"
            />
            <path
                id="Path_21804"
                data-name="Path 21804"
                d="M25.76,41.04A16.04,16.04,0,1,1,31.05,9.87a.574.574,0,0,1,0,1.08,14.88,14.88,0,0,0,0,28.1.574.574,0,0,1,0,1.08,16.124,16.124,0,0,1-5.29.91Zm0-30.94a14.9,14.9,0,0,0,0,29.79,14.686,14.686,0,0,0,3.36-.39,16.019,16.019,0,0,1,0-29,14.75,14.75,0,0,0-3.36-.39Z"
            />
        </SvgIcon>
    );
};
