import { Show, useContext } from 'solid-js';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { StyledTableItem, StyledAmountControls, StyledAmountButtons, StyledAmountValue, StyledHeader, StyledOrderTableMobile, StyledRemoveButtonMobile, StyledAddToBasketButton } from './basket.styles';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { MappedOrderDetails } from './basket';
import { ProductData, Specification } from '../product/product-types';
import { PlusIcon } from '../icons-library/plus';
import { StyledHorizontalSpace } from '../../ui-components/utility-style-components/spacing';
import { ShoppingBasketIcon } from '../icons-library/shopping-basket';
import { BasketContext } from './basket-context-provider';
import { getImageUrl } from '../product/product-shop-toolbar/product-shop-toolbar';

export const TableHeader = () => {
    const { localize } = useContext(AppContext);

    return (
        <StyledTableItem align='center'>
            <Heading tag='h5'>
                {localize('quantity', 'Quantity')}
            </Heading>
        </StyledTableItem>
    );
};

export const TableBody = (props: { orderDetails: MappedOrderDetails; }) => {
    const { decrementQuantity, incrementQuantity } = useContext(BasketContext);

    return (
        <AmountControls
            amount={props.orderDetails.line.amount}
            onDecreaseClick={() => decrementQuantity(props.orderDetails.line)}
            onIncreaseClick={() => incrementQuantity(props.orderDetails.line)}
        />
    );
};

export const AmountControls = (props: { amount: number; onDecreaseClick: () => void; onIncreaseClick: () => void; color?: string }) => (
    <StyledAmountControls>
        <StyledAmountButtons left onClick={props.onDecreaseClick}>
            -
        </StyledAmountButtons>
        <StyledAmountValue color={props.color}>
            {props.amount}
        </StyledAmountValue>
        <StyledAmountButtons onClick={props.onIncreaseClick}>
            +
        </StyledAmountButtons>
    </StyledAmountControls>
);


export const Header = () => {
    const { localize } = useContext(AppContext);

    return (
        <StyledHeader>
            <StyledFlexRow justifyContent='center'>
                <Heading tag='h1' variant='xlarge'>{
                    localize('checkout', 'Checkout')
                }</Heading>
            </StyledFlexRow>
        </StyledHeader>
    );
};

export const BasketLoading = () => {
    const { productsListingPage, localize } = useContext(AppContext);
    const { basket } = useContext(BasketContext);

    return (
        <StyledFlexRow justifyContent='center'>
            <div>
                <Show
                    when={!basket.loading}
                    fallback={<LoadingSpinner size={'2.2rem'} />}
                >
                    <Heading tag='h3'>
                        {localize('basket-is-empty', 'Your basket is empty')}
                    </Heading>
                    <Button
                        label={localize('back-to-products', 'Back to products')}
                        isLink
                        url={productsListingPage.url}
                        variant='primary'
                    />
                </Show>
            </div>
        </StyledFlexRow>
    );
};

export const RemoveButton = (props: { orderDetails: MappedOrderDetails; }) => {
    const { localize } = useContext(AppContext);
    const { removeOrderLine } = useContext(BasketContext);

    return (
        <Button
            label={localize('remove', 'Remove')}
            variant='secondary'
            small
            noBlockSpacing
            onClick={() => removeOrderLine(props.orderDetails.line)}
        />
    );
};

export const MobilePriceControls = (props: { orderDetails: MappedOrderDetails; }) => (
    <>
        <StyledOrderTableMobile>
            <TableBody orderDetails={props.orderDetails} />
        </StyledOrderTableMobile>

        <StyledRemoveButtonMobile>
            <RemoveButton orderDetails={props.orderDetails} />
        </StyledRemoveButtonMobile>
    </>
);

export const AddToBasketButton = (props: { product: ProductData; specification: Specification }) => {
    const { addNewOrderLine } = useContext(BasketContext);

    const image = getImageUrl(props.product.images);

    return (
        <StyledAddToBasketButton
            onClick={() => addNewOrderLine({
                id: props.product.id,
                externalId: props.product.externalId,
                postTitle: props.product.post_title,
                slug: props.product.slug,
                type: props.product.type,
                amount: 1,
                skuRefNumber: props.specification.refNumber,
                skuDescription: props.specification.description || '',
                skuName: props.specification.name,
                skuQuantity: parseInt(props.specification.quantity || '1'),
                imageUrl: image?.imageUrl,
                imageRatio: image?.imageRatio,
            })}
        >
            <PlusIcon size='small' />
            <StyledHorizontalSpace size={0.5} />
            <ShoppingBasketIcon size='medium' />
        </StyledAddToBasketButton>
    );
};
