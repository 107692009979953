import { styled } from 'solid-styled-components';
import { Heading } from '../../ui-components/heading/heading';
import theme from '../../style/theme';
import { Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { containerQueries } from '../../tools/check-container-support';

const StyledWrapper = styled.div<{ loaded?: boolean; }>`
    border-radius: 1.43rem;
    height: 100%;
    min-height: 27.6rem;
    border: 1px solid ${theme.palette.lightGray};
    width: 100%;
    background-color: white;
    opacity: ${p => p.loaded ? 0 : 0.5 };
    ${containerQueries('tabletMax')} {
        display: none;
    }
`;

const StyledContainer = styled.div`
    padding: 4rem;
`;

export const PostCardPlaceholder = (props: { loaded?: boolean }) => {
    const AppState = useContext(AppContext);
    
    return (
        <StyledWrapper loaded={props.loaded}>
            <Show when={props.loaded}>
                {/* loaded */}
            </Show>
            <Show when={!props.loaded}>
                <StyledContainer>
                    <Heading tag='h5' color={{ name: 'lightGray' }}>
                        {AppState.localize('loading', 'Loading...')}
                    </Heading>
                </StyledContainer>
            </Show>
        </StyledWrapper>
    );
};