import theme from '../../style/theme';
import { styled } from 'solid-styled-components';

export const StyledImageHolder = styled.div`
    border-radius: 30px;
    margin-bottom: 1.75rem;
`;

export const StyledProfilesWrapper = styled.div`
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 0;
    ${theme.mediaQueries.tabletMax} {
        flex-direction: column;
        align-items: center;
    }
`;

export const StyledProfileWrapper = styled.div`
    /* Taken from Wikström design */
    width: 260px;
    margin: 0px 27px; 
    text-align: center;
    margin-bottom: 3rem;
`;

