import { styled } from 'solid-styled-components';
import { theme } from '../../../style/theme';

export const StyledFooter = styled.footer`
    container-type: inline-size;
    min-height: 32.14rem;
    background-color: ${theme.colors.footer.background};
    color: ${theme.palette.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${theme.mediaQueries.tabletMax} {
        height: auto;
    }

    @media print {
        display: none;
    }
`;
    
export const rowBlockSpacing = `
    padding-block: ${theme.grid.standardUnit * 3}rem;
    ${theme.mediaQueries.tabletMax} {
        padding-block: ${theme.grid.standardUnit * 2}rem;
    }
`;

export const StyledLogo = styled.div`
    width: auto;
    padding-bottom: 2rem;
    ${theme.mediaQueries.tabletMax} {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`;

export const StyledFooterColumn = styled.div<{ socialMediaColumn?: boolean }>`
    padding-bottom: 1rem;
    line-height: 0;
    ${theme.mediaQueries.tabletMax} {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        & > * {
            max-width: 300px;
        }
    }

    ${theme.mediaQueries.tabletMax} {
    ${(p) =>
        p.socialMediaColumn
            ? `
            flex-direction: column-reverse;
            div + div {
                margin-top: 1rem;
            }
        `
            : ''}
    }
`;

export const StyledIcon = styled.div`
    display: flex;
    margin-right: 1rem;
`;
export const StyledPageLink = styled.div`
    margin-bottom: 0.5rem;
`;

export const StyledNewsletterText = styled.div`
    margin-bottom: 1.25rem;
`;


export const CopyrightContainer = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-block: 2rem;
`;

export const StyledCopyright = styled.div`
    display: flex;
    justify-content: flex-start;
    ${theme.mediaQueries.tabletMax} {
        display: flex;
        justify-content: center;
    }
`;

export const StyledOpeningHoursContainer = styled.div`
    margin-bottom: 3.5rem;
    ${theme.mediaQueries.tabletMax} {
        margin-bottom: 0;
    }
`;

export const StyledSocialMediaContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-left: -0.7rem;
    flex-wrap: wrap;
    & > * {
        opacity: 0.8;
        padding-inline: 0.7rem;
        padding-block: 0.4rem;
        transition: opacity 0.2s;
        &:hover {
            opacity: 1;
        }
    }
    ${theme.mediaQueries.tabletMax} {
        display: flex;
        justify-content: center;
        margin-bottom: 3.5rem;
    }
`;
