import theme from '../../style/theme';
import { styled } from 'solid-styled-components';
import { containerQueries } from '../../tools/check-container-support';

const {header} = theme;
const {desktop} = header;

export const sidebarPaddingRight = theme.grid.gapSizeMobile;

export const StyledSidebarContainer = styled.div`
    height: 100%;
    z-index: 1;

    ${theme.mediaQueries.tabletMax} {
        padding-right: unset;
    }
`;

export const StyledSidebarContainerLine = styled.div`
    border-right: ${theme.redLineThickness} solid ${theme.palette.red};
    height: 100%;

    ${theme.mediaQueries.tabletMax} {
        border-right: unset;
    }
`;

export const StyledSidebarInner = styled.div`
    ${theme.mediaQueries.tabletMin} {
        padding-top: ${desktop.height.floating.value + desktop.height.floating.unit};
    }
`;

export const StyledSidebar = styled.div`
    container-type: inline-size;
    height: auto;
    position: sticky;
    top: ${desktop.height.floating.value + desktop.height.floating.unit};
    padding-bottom: 4rem;
    margin-right: 3rem;
    ${containerQueries('tabletMax')} {
        padding-top: 3rem;
        margin-right: 1rem;
    }

    ${theme.mediaQueries.tabletMin} {
        height: calc(100vh - ${desktop.height.floating.value + desktop.height.floating.unit});
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    ${theme.mediaQueries.tabletMax} {
        position: unset;
        padding-inline: unset;
        margin-right: unset;
        padding-bottom: 0;
        z-index: 2;
    }
`;

export const gridSettings = {
    container: {
        templateShorthand: [3, 9],
        responsive: {
            largeDesktop: [4, 8],
            smallDesktop: [4, 8],
            tablet: [12],
            mobile: [12],
        }
    },
    sidebar: {
        inheritParentGrid: true,
        templateShorthand: [3],
        responsive: {
            largeDesktop: [4],
            smallDesktop: [4],
            tablet: [1],
            mobile: [1],
        },
        customCss: `
            height: 100%;
        `
    },
    listing: {
        inheritParentGrid: true,
        templateShorthand: [3, 3, 3],
        responsive: {
            largeDesktop: [4, 4],
            smallDesktop: [4, 4],
            tablet: [12],
            mobile: [12],
        }
    }
};
