import { StyledFurtherLinksBottom, StyledDocumentationLink } from './product-further-links.styles';
import { Button } from '../../../ui-components/button/button';
import { StyledFlexRow } from '../../../ui-components/utility-style-components/flex';
import { Show, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Text } from '../../../ui-components/text/text';
import { Link } from '../../../ui-components/link/link';
import { StyledPreTextLink } from '../pre-text-link/pre-text-link.styles';

type ProductFurtherLinksProps = {
    productTitle: string;
};


export const ProductFurtherLinks = (props: ProductFurtherLinksProps) => {
    const { localize, productContactFormUrl, ifuPage } = useContext(AppContext);
    
    return (
        <>
            <Show when={ifuPage.url}>
                <StyledDocumentationLink>
                    <StyledPreTextLink>
                        <span>{localize('view-all-our-manuals', 'View all our manuals') + ', '}</span> &nbsp
                        <Link url={ifuPage.url} label={localize('go-to-ifu-instructions-for-use', 'go to IFU - Instructions For Use')}/>
                    </StyledPreTextLink>
                </StyledDocumentationLink>
            </Show>
            <Show when={productContactFormUrl}>
                <StyledFurtherLinksBottom>
                    <Text fontSize='large' fontStyle='bold'>
                        {`${localize('do-you-want-to-know-more-about-product', 'Do you want to know more about')} ${props.productTitle}?`}
                    </Text>
                    <StyledFlexRow gap={'2rem'} flexWrap={'wrap'}>
                        <Button
                            label={localize('let-us-contact-you', 'Let us contact you')}
                            isLink={true}
                            url={`${productContactFormUrl}?product=${props.productTitle}`}
                        />
                        {/* <Button label={props.purchaseLink.title} variant="tertiary" isLink={true} url={props.purchaseLink.url}/> */}
                    </StyledFlexRow>
                </StyledFurtherLinksBottom>
            </Show>
        </>
    );
};
