import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const ArrowDownIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M11.274,61.372a2.146,2.146,0,0,0,3.034,0L25.023,50.657a2.145,2.145,0,0,0-3.034-3.034l-7.058,7.065V34.143a2.143,2.143,0,0,0-4.286,0V54.682L3.586,47.63A2.145,2.145,0,0,0,.553,50.664L11.268,61.379Z"
                transform="translate(0.075-32)"
            />
        </SvgIcon>
    );
};
