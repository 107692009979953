import theme from '../../style/theme';
import { styled } from 'solid-styled-components';
import { defaultTextStyle, redLineStyle, textSizes } from '../text/text.styles';
import { StyledListProps } from './list.d';


export const StyledList = styled.ul<StyledListProps>`
    list-style-position: outside;
    list-style-type: initial;
    margin: 0;
    ${defaultTextStyle}
    ${(p) => textSizes[p.fontSize]};
    ${(p) => redLineStyle({ 
        withRedLine: p.displayRedVerticalLine,
        isList: true,
    })}
    & {
        a {
            color: ${theme.palette.red};
            transition: 0.2s color;
            &:hover {
                color: ${theme.palette.pink};
            }
        }
    }
`;

export const StyledListItem = styled.li<{ colorText: string}>`
    color: ${p => p.colorText ? p.colorText : theme.colors.text.paragraph};
`;
