import { styled } from 'solid-styled-components';
import { theme, fontFamilyAtosBold } from '../../../style/theme';
import { textStyleCommon } from './hcp-header.style';
import { Link } from '@solidjs/router';

const paddingInline = '1.43rem';

const compressedButtonStyle = `
    font-size: 1.1rem;
    padding-block: 0.5rem;
`;

export const StyledButton = styled.button<{ active: boolean, floatHeader: boolean }>`
    ${textStyleCommon}
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: ${paddingInline};
    padding-block: 1rem;
    cursor: pointer;
    transition: color 0.1s ease-in-out, font-size 0.2s ease-in-out, padding-block 0.2s ease-in-out, font-family 0.2s ease-in-out;
    color: ${theme.palette.darkGray};
    ${(p: any) => (p.active ? `
        color: ${theme.palette.red};
        font-family: ${fontFamilyAtosBold};
    ` : '')};

    ${p => p.floatHeader ? compressedButtonStyle : ''};

    &:hover {
        color: ${theme.palette.pink};
    }

    ${theme.mediaQueries.desktopMax} {
        ${compressedButtonStyle}
    }
`;

const compressedDropDownItemStyle = `
    font-size: 1.1rem;
    padding-block: 0.8rem;
`;

export const StyledDropDownItem = styled(Link)<{ active: boolean, floatHeader: boolean }>`
    ${textStyleCommon}
    display: block;
    padding-block: 1.1rem;
    padding-inline: 0.75rem;
    transition: color 0.1s ease-in-out, font-size 0.2s ease-in-out, padding-block 0.2s ease-in-out;
    color: ${theme.palette.darkGray};
    ${(p: any) => (p.active ? `color: ${theme.palette.pink}` : '')};

    ${p => p.floatHeader ? compressedDropDownItemStyle : ''};

    &:hover {
        color: ${theme.palette.pink};
    }

    ${theme.mediaQueries.desktopMax} {
        ${compressedDropDownItemStyle}
    }
`;

export const StyledDropDown = styled.ul`
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3), 0px 16px 24px -8px rgba(0, 0, 0, 0.00);
    
    & > li {
        padding-bottom: 0 !important;
    }
    & > li > a:first-letter {
        text-transform: uppercase;
    }

    & > li:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }
`;

export const StyledLinkContent = styled.span<{ active: boolean }>`
    transition: color 0.1s ease-in-out;
    color: ${theme.palette.darkGray};
    ${(p) => p.active ? `color: ${theme.palette.red} !important;` : ''}
`;

const compressedLinkSpanStyle = 'font-size: 1.1rem;';

export const StyledLinkSpan = styled.span<{ active: boolean, floatHeader: boolean }>`
    ${textStyleCommon};
    color: ${theme.palette.darkGray};
    ${(p) => (p.active ? `color: ${theme.palette.pink}` : '')};
    transition: color 0.1s ease-in-out, font-size 0.2s ease-in-out;

    ${p => p.floatHeader ? compressedLinkSpanStyle : ''};

    &:hover {
        color: ${theme.palette.pink};
    }

    ${theme.mediaQueries.desktopMax} {
        ${compressedLinkSpanStyle}
    }
`;

const compressedDropdownLink = `
    padding-block: 0.5rem;
    font-size: 1.1rem;
`;

export const StyledDropdownLink = styled(Link)<{ floatHeader: boolean }>`
    display: flex;
    justify-content: center;
    padding-inline: ${paddingInline};
    padding-block: 1rem;
    cursor: pointer;
    transition: padding-block 0.2s ease-in-out, font-size 0.2s ease-in-out;

    ${p => p.floatHeader ? compressedDropdownLink : ''};

    ${theme.mediaQueries.desktopMax} {
        ${compressedDropdownLink}
    }
`;

const compressedDropdownMenuNavStyle = `
    padding-bottom: 0.5rem;
`;

export const StyledDropdownMenuNav = styled.nav<{ floatHeader: boolean }>`
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.25rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    margin-inline: -${paddingInline};
    transition: padding-top 0.2s ease-in-out, padding-bottom 0.2s ease-in-out;

    ${p => p.floatHeader
        ? `
            ${compressedDropdownMenuNavStyle}
            padding-top: 0;
        ` : ''};

    ${theme.mediaQueries.desktopMax} {
        ${compressedDropdownMenuNavStyle}
    }
`;

