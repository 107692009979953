import { createSignal, mergeProps, onMount, useContext } from 'solid-js';
import {
    StyledAuthorContainerPDF,
    StyledAuthorNamePDF,
    StyledAuthorTextContainerPDF,
    StyledHeadingWrapperPDF,
    StyledImagePDF,
    StyledQuoteOuterPDF,
    StyledRoundImageWrapperPDF,
    StyledSignaturePDF,
} from './testimony-pdf.style';
import { AppContext } from '../../../../../../app-context-provider/app-context-provider';
import { Heading } from '../../../../../../ui-components/heading/heading';
import { ImageSrcData, getBackgroundImageSrcData } from '../../../../../image-component/image-component';
import { Quote } from '../../../../../../ui-components/quote/quote';
import { SpacerPdf } from '../spacer/spacer-pdf';

const defaultProps = {};

export const TestimonyPdf = (componentProps: any) => {
    const props = mergeProps(defaultProps, componentProps);
    const { imagesServiceUrl, supportedImageFormats } = useContext(AppContext);
    const [backgroundImageSrcData, setBackgroundImageSrcData] = createSignal<ImageSrcData>();

    onMount(() => {
        if (props.src) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.src,
                width: 500,
                height: 'AUTO',
            });

            setBackgroundImageSrcData(backgroundImage);
        }
    });

    return (
        <div>
            <SpacerPdf height={2.5} />
            <StyledQuoteOuterPDF>
                <StyledHeadingWrapperPDF>
                    <Heading tag="h3" variant="smallPDF">
                        {props.eyebrowHeading}
                    </Heading>
                    <Heading tag="h2" variant="mediumPDF">
                        {props.testimonyHeading}
                    </Heading>
                </StyledHeadingWrapperPDF>
                <StyledAuthorContainerPDF>
                    <StyledRoundImageWrapperPDF>
                        <StyledImagePDF src={backgroundImageSrcData()?.original} alt={props.altText} title={props.title} />
                    </StyledRoundImageWrapperPDF>

                    <StyledAuthorTextContainerPDF>
                        <Quote forPdfBuilder={true}>
                            {props.quote.children}
                        </Quote>
                        <StyledSignaturePDF>
                            <StyledAuthorNamePDF>{props.authorName},</StyledAuthorNamePDF>
                            {props.authorShortDescription}
                        </StyledSignaturePDF>
                    </StyledAuthorTextContainerPDF>
                </StyledAuthorContainerPDF>
            </StyledQuoteOuterPDF>
            <SpacerPdf height={2.5} />
        </div>
    );
};

TestimonyPdf.parseProps = (atts: any) => {
    const { image, quote } = atts;

    const commonAtts = {
        eyebrowHeading: atts.eyebrowHeading,
        testimonyHeading: atts.testimonyHeading,
        authorName: atts.authorName,
        authorShortDescription: atts.authorShortDescription,
        quote: quote[0],
    };

    if (image) {
        return {
            src: image.url,
            width: image.width,
            focus: image.focus,
            altText: image?.altText,
            title: image?.title,
            ...commonAtts,
        };
    } else return commonAtts;
};
