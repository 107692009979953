import { styled } from 'solid-styled-components';
import theme from '../../../../style/theme';

type OutputProps = { 
    bgColor?: string; 
    withLoadingSpinner?: boolean;
};

export const StyledOutput = styled.div<OutputProps>`
    background-color: ${ p => p.bgColor ? p.bgColor : theme.palette.white};
    border-radius: ${theme.borderRadius.small};
    padding: ${theme.spacing.m};
    ${p => p.withLoadingSpinner ? `padding-inline: ${theme.spacing.s};` : ''}
    margin-top: ${theme.spacing.s};
    display: flex;
    align-items: center;

    @media print {
        display: none;
    }
`;

export const StyledLoadingSpinnerContainer = styled.div`
    min-width: 3rem;
    min-height: 3rem;
    margin-right: 1.5rem;
`;

export const StyledCollection = styled.div<{ disabled?: boolean }>`
    background-color: ${p => p.disabled ? '#F7F7F7' : theme.palette.white};
    border-radius: ${theme.borderRadius.large};
    padding-inline: 3rem;
    margin-bottom: 3rem;
    padding-block: 2.5rem;
`;


export const StyledListItemForPdf = styled.div`
    display: flex;
    align-items: flex-start;
    &::before {
        content: '•';
        margin-top: 0.15em;
        color: ${theme.palette.darkGray};
        font-size: 1.2rem;
        padding-right: 0.7rem;
    }
`;