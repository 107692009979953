import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { Show, mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 32 30',
    // width: 29.992
};

export const StarIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <Show when={props.altText}>
                <title>{props.altText}</title>
            </Show>
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                stroke="transparent" 
                d="M50.238,10.036,41.679,8.788,37.853,1.031a1.876,1.876,0,0,0-3.363,0L30.664,8.788,22.1,10.036a1.876,1.876,0,0,0-1.037,3.2l6.193,6.034L25.8,27.793a1.874,1.874,0,0,0,2.718,1.974l7.657-4.025,7.657,4.025a1.875,1.875,0,0,0,2.718-1.974l-1.465-8.524,6.193-6.034a1.876,1.876,0,0,0-1.037-3.2Zm-8.173,8.249,1.389,8.108-7.282-3.826-7.282,3.826,1.389-8.108-5.894-5.741,8.144-1.183,3.644-7.382,3.644,7.382,8.144,1.183-5.894,5.741Z"
                transform="translate(-20.5 0.013)"
            />
        </SvgIcon>
    );
};