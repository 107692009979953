import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledLoadingSpinner = styled.div<{ size?: string; color?: string, thickness?: string}>`
    width: ${p => p.size ? p.size : '32px' };
    height: ${p => p.size ? p.size : '32px' };
    border: ${p => p.thickness ? p.thickness : '5px'} solid ${p => p.color ? p.color : theme.colors.text.red};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
