import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';

export const StyledAddToOrderButton = styled.div`
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5rem;
    gap: 2rem;
`;

export const StyledList = styled.ul`
    margin-left: unset;
    display: block;
`;

export const StyledText = styled.div`
    padding-right: 2rem;
`;

export const StyledProductShopToolbar = styled.div`
    border: 1px solid ${theme.palette.lightGray};
    border-radius: 1.43rem;
    background-color: ${theme.palette.lightBeige};
    padding: ${theme.spacing.xxxl} ${theme.spacing.xl};

    ${theme.mediaQueries.mobileMax} {
        padding: ${theme.spacing.m} ${theme.spacing.s};
    }
`;

export const StyledItem = styled.li<any>`
    display: flex;
    gap: 1rem;
    cursor: pointer;
    list-style: none;
    margin-left: 0;
    border-top: 1px solid ${theme.palette.gray};
    padding: 1.5rem;
    transition: background-color 0.2s;
    &:hover {
        background-color: ${theme.palette.lightBeige};
    }
    ${theme.mediaQueries.tabletMax} {
        flex-wrap: wrap;
    }
`;

export const StyledDetailsContainer = styled.div`
    color: ${theme.palette.red};
`;

export const StyledToastContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    border-radius: 1.43rem;
    margin-bottom: 3rem;
    background-color: ${theme.palette.shopGreen};
    padding: ${theme.spacing.ms};

    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    ${theme.mediaQueries.mobileMax} {
        padding: ${theme.spacing.m} ${theme.spacing.s};
    }
`;

export const StyledToastLink = styled.div`
    white-space: nowrap;
    margin-left: auto;
`;

export const StyledNoWrap = styled.div`
    white-space: nowrap;
`;

export const StyledOrderTableMobileContainer = styled.div`
    margin-left: auto;
`;

export const StyledOrderline = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
`;

