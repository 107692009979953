import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 50 50',
    // width: 29.992
};

export const EnergyIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            {/* <path
                id="angle-down-regular"
                d="M45.364,179.462l-12.955,12.2a1.843,1.843,0,0,1-1.406.631,2.046,2.046,0,0,1-1.406-.559L16.64,179.462a2.047,2.047,0,1,1,2.813-2.975L31,187.422l11.549-10.944a2.044,2.044,0,0,1,2.893.08A2.066,2.066,0,0,1,45.364,179.462Z"
                transform="translate(-16.001 -175.918)"
                {...props}
            /> */}
            <circle id="Ellipse_120" data-name="Ellipse 120" cx="2.56" cy="2.56" r="2.56" transform="translate(21.42 8.42)" fill="#fff" />
            <path
                id="Path_21786"
                data-name="Path 21786"
                d="M35.25,25.65a7.022,7.022,0,0,0-2.94.64l-2.45-5.71.32-.44a.893.893,0,0,0,.82-.67.908.908,0,0,0-.65-1.11L26.7,17.4l-2.55-2.39a1.956,1.956,0,0,0-3.64-.31l-3.26,6.67a1.922,1.922,0,0,0-.08,1.52,1.234,1.234,0,0,0,.12.37.567.567,0,0,0-.2,1.06l1.71.94-.75,1.19a7.055,7.055,0,0,0-3.29-.81,7.16,7.16,0,1,0,4.26,1.42l1.07-1.71a.557.557,0,0,0,.07-.38l5.06,2.61L24.1,35.14a1.207,1.207,0,0,0,1.02,1.37c.06,0,.12.01.18.01a1.2,1.2,0,0,0,1.19-1.03l1.24-8.41a1.2,1.2,0,0,0-.64-1.25l-6.13-3.16,2.74-5.6,1.92,1.8a.943.943,0,0,0,.39.22l2.93.77-.21.29a.582.582,0,0,0-.07.57l2.63,6.12a7.131,7.131,0,1,0,3.95-1.2ZM20.74,32.79a5.99,5.99,0,1,1-5.99-5.99,5.916,5.916,0,0,1,2.68.64l-3.17,5.05a.57.57,0,0,0,.18.79.553.553,0,0,0,.3.09.591.591,0,0,0,.49-.27l3.17-5.05a5.983,5.983,0,0,1,2.34,4.74Zm14.51,5.99a5.985,5.985,0,0,1-3.49-10.85l2.96,6.9a.575.575,0,0,0,.53.35.635.635,0,0,0,.23-.05.568.568,0,0,0,.3-.75l-3.02-7.03a5.929,5.929,0,0,1,2.49-.55,5.99,5.99,0,0,1,0,11.98Z"
            />
            <path
                id="Path_21787"
                data-name="Path 21787"
                d="M9.78,14.21l7.99.68h.03a.292.292,0,0,0,.29-.26.283.283,0,0,0-.26-.31l-7.99-.68a.3.3,0,0,0-.31.26.283.283,0,0,0,.26.31Z"
            />
            <path
                id="Path_21788"
                data-name="Path 21788"
                d="M8.18,19.52h0l8.26-.07a.276.276,0,0,0,.28-.29.3.3,0,0,0-.29-.28l-8.26.07a.276.276,0,0,0-.28.29A.288.288,0,0,0,8.18,19.52Z"
            />
            <path
                id="Path_21789"
                data-name="Path 21789"
                d="M25,0A25,25,0,1,0,50,25,25.035,25.035,0,0,0,25,0Zm0,48.85A23.85,23.85,0,1,1,48.85,25,23.878,23.878,0,0,1,25,48.85Z"
            />
        </SvgIcon>
    );
};
