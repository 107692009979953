import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const AngleLeftIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M32.757,77.16a2.5,2.5,0,0,0,0,3.538l12.5,12.5a2.5,2.5,0,1,0,3.538-3.538L38.06,78.925l10.722-10.73a2.5,2.5,0,1,0-3.538-3.538l-12.5,12.5Z"
                transform="translate(-32.025 -63.925)"
            />
        </SvgIcon>
    );
};
