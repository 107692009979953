import { For } from 'solid-js';
import type { ProductSupplementsProps } from './product-supplements-types';
import type { DocumentType } from '../product-types';
import { FileDownloadIcon } from '../../icons-library/file-download';
import theme from '../../../style/theme';
import { Show } from 'solid-js';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';
import { Link } from '../../../ui-components/link/link';
import { StyledDocumentRow } from './product-supplements.styles';
import removeAmpersand from '../../../tools/remove-ampersand';

export const ProductSupplements = (props: ProductSupplementsProps) => {
    return (
        <For each={props.documents}>
            {(document: DocumentType) => (
                <Show when={document?.file?.url}>
                    <StyledDocumentRow>
                        <FileDownloadIcon fill={theme.palette.red} height={2.6}/>
                        <Link 
                            label={removeAmpersand(document.file?.title || document.file?.name || document.file?.filename)}
                            url={removeTrailingSlash(document.file.url)}
                            opensInNewTab={true}
                        />
                    </StyledDocumentRow>
                </Show>
            )}
        </For>
    );
};
