export type Focus = {
    x: number;
    y: number;
} | undefined | null;

export const focusPosition = (focus: Focus) => {
    
    if (!focus) {
        return 'center';
    }

    if (focus.x === null || focus.y === null) {
        return 'center';
    }

    return `${focus.x * 100}% ${focus.y * 100}%`;
};
