import { gql } from 'graphql-request';
import { createMemo, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
// import { PageContext } from '../page/page-context-provider';
import { VideoContainer, VideosProps } from './videos';
import { VideoData } from './videos-types';

const GET_VIDEOS_QUERY = gql`
    query GetVideos(
        $type: [String],
        $tags: [String],
        $limit: Int,
    ) {
        videos: resources(
            type: $type,
            tags: $tags,
            limit: $limit
            order: DESC
        ) {
            content
        }
    }
`;

export const fetchVideos = (props: VideosProps) => {    
    const { createCachedResource } = useContext(AppContext);

    const [videosContent] = createCachedResource(
        GET_VIDEOS_QUERY,
        () => ({
            type: ['video-item'],
            ...props?.tag?.slug
                ? { tags: [props?.tag?.slug] }
                : null,
            ...props?.limit
                ? { limit: props?.limit }
                : null,
        }),
        true
    );

    return createMemo((): VideoData[] => videosContent()?.videos
        ?.map((video: VideoContainer) => video.content)
        .sort((a: VideoData, b: VideoData) => {
            // newest show first
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();
            return dateB - dateA;
        }));
};