import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

const {spacing, mediaQueries} = theme;

export const StyledProductSocial = styled.div`
    position: relative;
    z-index: 1;
    padding-top: ${spacing.m};

    ${mediaQueries.mobileMax} {
        padding-top: ${spacing.s};
    }
    @media print {
        display: none;
    }
`;

export const StyledShareContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    p {
        transition: color 0.2s ease-in-out;
    }
    
    &:hover {
        svg {
            fill: ${theme.palette.pink};
            stroke: ${theme.palette.pink};
        }
        p {
            color: ${theme.palette.pink};
        }
    }
`;
