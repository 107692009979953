import { FormField, validUserTypes } from './form-types';
import { countries } from '../../tools/countries-data';

type UserTypeMap = {
    [key: string]: string;
};

export const getPlaceholder = (field: FormField, localize: (slug: string, defaultValue: string) => string) => {
    let placeholder = field.name;
    if (field.required) {
        placeholder = `${localize(field.slug, field.name)} *`;
    }
    return placeholder;
};

export const getCountryOptions = (localize: (slug: string, defaultValue: string) => string, currentSelectCountryCode: string) => {
    return (
        <>
            <option value="" disabled selected>
                {`${localize('select', 'Select')} ${localize('country','country')} *`}
            </option>
            {countries.map((country) => (
                <option selected={country.code === currentSelectCountryCode} value={country.code}>{country.name}</option>
            ))}
        </>
    );
};

export const getUserTypeOptions = (localize: (slug: string, defaultValue: string) => string) => {
    const userTypeMap: UserTypeMap = {
        'HCP': localize('i-am-a-healthcare-professional' ,'I am a Healthcare Professional'),
        'Other': localize('other' ,'Other'),
    };

    return (
        <>
            <option value="" disabled selected>
                {`${localize('select', 'Select')} ${localize('type-of-user','type of user')} *`}
            </option>
            {validUserTypes.map((userType) => (
                <option value={userType}>{localize(userType, userTypeMap[userType])}</option>
            ))}
        </>
    );
};
