import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';
import { variants } from '../../../ui-components/heading/heading.styles';
import { ImageComponent } from '../../image-component/image-component';

const {spacing, mediaQueries, palette} = theme;

export const StyledProductDescription = styled.div`
    border-radius: 1.43rem;
    background-color: ${theme.palette.lightBeige};
    padding: ${spacing.xxxl} ${spacing.xl};

    ${mediaQueries.mobileMax} {
        padding: ${spacing.m} ${spacing.s};
    }
`;

export const StyledSmallRedHeading = styled.div`
    ${variants.smallRed}
    margin-bottom: ${spacing.s};

    ${mediaQueries.mobileMax} {
        font-size: 1.64rem;
        margin-bottom: ${spacing.xxs};
    }
`;

export const StyledKeyFeaturesList = styled.div`
    margin-left: 1.36rem;
`;

export const StyledKeyFeaturesListItem = styled.span`
    font-size: 1.29rem;
    color: ${palette.darkGray};
`;

export const StyledProductDescriptionTextColumn = styled.div<StyledProductDescriptionTextColumnProps>`
    ${p => p.displayRedVerticalLine ? `
        border-left: 0.14rem solid ${palette.red};
        padding: 0 0 0 ${spacing.s};
    ` : ''}
`;
type StyledProductDescriptionTextColumnProps = {
    displayRedVerticalLine?: boolean;
};

export const StyledProductFamilyImage = styled(ImageComponent)`
    height: 11.4rem;
    width: 25.71rem;
    object-fit: cover;
    aspect-ratio: initial;

    ${mediaQueries.mobileMax} {
        margin-top: ${spacing.s};
        margin-bottom: ${spacing.m};
    }
`;