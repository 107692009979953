import { styled } from 'solid-styled-components';
import { theme } from '../../../style/theme';

const { spacing, palette } = theme;

export const StyledFeedCardWrapper = styled.div<any>`
    background: ${p => p.imageUrl ? `url(${p.imageUrl})` : palette.white};
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    
    & p {
        color: ${p => p.imageUrl ? `${palette.transparent} !important` : palette.darkGray};
    }

    &:hover{
        background: ${palette.transparent};
        & .username{
            opacity: 1;
        }

        & p {
        color: ${palette.darkGray} !important;

        }
    }

    ${p => p.useBackgroundFallback && `
        background-color: #fff;
        background-size: 50%;
        background-position: center;
    `}
`;

export const StyledFeedCardInner = styled.div`
    padding: ${spacing.xs} ${spacing.xxs};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
`;

export const StyledCardText = styled.p`
    font-size: 1.16rem;
    line-height: 130%;
    display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
`;

export const StyledIconsContainer = styled.div`
    display: flex;
    column-gap: 0.71rem;
    z-index: 10;
`;

export const StyledIconContainer = styled.div`
    margin: auto 0;
`;

export const StyledAuthorContainer = styled.div`
    transition: all 200ms ease-in-out;
    margin: auto 0;
    opacity: 0;
    & h5 {
        text-transform: none !important;
    }
`;
