import { styled } from 'solid-styled-components';
import theme from '../../style/theme';
import { containerQueries } from '../../tools/check-container-support';

export const StyledContentHubContainer = styled.div<{ withLinks?: boolean }>`
    &:not(:last-child) {
        margin-bottom: ${theme.grid.gapSize}rem;
    }
    ${p => p.withLinks ? 'padding-bottom: 4rem;' : ''}
`;

export const StyledLinksContainer = styled.div`
    position: absolute;
    padding-top: 1.5rem;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

export const StyledLinksInner = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    & > :nth-child(1) {
        padding-right: 1rem;
    }
`;

export const StyledRightPanel = styled.div`
    position: relative;
    & > :nth-child(1):not(:last-child) {
        ${containerQueries('mobileMin')} {
            margin-bottom: ${theme.grid.gapSize}rem;
        }
        ${containerQueries('mobileMax')} {
            margin-bottom: 1.14rem;
        }
    }
`;

export const StyledButtonWrapper = styled.div`
    padding-left: 1.2rem;
`;