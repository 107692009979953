import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../../style/theme';

const {spacing, palette, mediaQueries} = theme;

export const StyledProductSpecificationsTable = styled.div<{ isCare: boolean }>`
    display: grid;
    grid-template-columns: repeat(${p => p.isCare ? '5' : '4'}, auto);
    column-gap: 2.3rem;
    row-gap: ${spacing.xs};
`;

export const StyledProductSpecificationsTableHeader = styled.div`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.43rem;
    color: ${palette.darkGray};
    word-break: break-word;

    ${mediaQueries.mobileMax} {
        font-size: 1.29rem;
    }
`;

export const StyledProductSpecificationsTableColumn = styled.div<{ isCare: boolean }>`
    ${p => p.isCare ? `
        display: flex;
        align-items: center;
    ` : ''}
    font-family: ${fontFamilyAtosRegular};
    font-size: 1.29rem;
    color: ${palette.darkGray};
`;


