import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 18 16',
    width: 1.5,
    height: 1.33,
    fill: 'none',
};

export const GalleryViewStyleIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <rect y="0.5" width="7.5" height="6" fill="#918787" />
            <rect y="9.5" width="7.5" height="6" fill="#918787" />
            <rect x="10.5" y="0.5" width="7.5" height="6" fill="#918787" />
            <rect x="10.5" y="9.5" width="7.5" height="6" fill="#918787" />
        </SvgIcon>
    );
};
