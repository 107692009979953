import theme from '../../../../../style/theme';
import urlMatches from '../../../../../tools/url-match';
import { StyledRelativeContainer } from '../../../../../ui-components/utility-style-components/positioning';
import { ArrowRightIcon } from '../../../../icons-library/arrow-right';
import type { FirstLevelMenuItemLinkProps } from './first-level-menu-item-link-types';
import { StyledFirstLevelMenuItemLink } from './first-level-menu-item-link.styles';
import { removeTrailingSlash } from '../../../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../../../tools/remove-ampersand';

export const FirstLevelMenuItemLink = (props: FirstLevelMenuItemLinkProps) => {
    return (
        <StyledFirstLevelMenuItemLink
            href={removeTrailingSlash(props.link.url)}
            onClick={() => props.onLinkClick()}
            active={urlMatches(props.link.url)}
            ref={(el: HTMLElement) => {
                props.lastElement(el);
            }}
        >
            <StyledRelativeContainer top={3}>
                <ArrowRightIcon width={1.43} fill={urlMatches(removeTrailingSlash(props.link.url)) ? theme.palette.red : theme.palette.darkGray} />
            </StyledRelativeContainer>
            {removeAmpersand(props.link.title)}
        </StyledFirstLevelMenuItemLink>
    );
};
