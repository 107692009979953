import { createSignal, For, Match, Show, Switch, useContext } from 'solid-js';
import { AngleLeftIcon } from '../../components/icons-library/angle-left';
import { AngleRightIcon } from '../../components/icons-library/angle-right';
import { createSlider } from 'solid-slider';
import { StyledSliderControls, StyledSliderControlsPlaceholder, StyledSliderInner, StyledSliderWrapper } from './slider.styles';
import { SliderProps } from './slider.d';
import theme from '../../style/theme';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { convertRemToPixel, getRootFontSize } from '../../tools/html-rem-tools';
import { ErrorCatcher } from '../../tools/error-catcher';

export const Slider = (props: SliderProps) => {
    const AppState = useContext(AppContext);
    const [ isReady, setIsReady ] = createSignal(false);
    const [slides, setSlides] = createSignal([]);

    const options = {
        duration: 1000,
        loop: false,
        initial: props.initial ? props.initial : 0,

        slides: {
            perView: props.slidesPerView,
            spacing: props.spacing ? convertRemToPixel(props.spacing) : convertRemToPixel(theme.grid.standardUnit),
        },
        created: (slider: any) => {
            setSlides(slider.slides);
            setTimeout(() => {
                setIsReady(true);
            }, 150);
        },
    };
    const [sliderDesktop, { current, next, prev, moveTo }] = createSlider(options);
    const [sliderMobile, { current: sliderMobileCurrent, next: sliderMobileNext, prev: sliderMobilePrev, moveTo: sliderMobileMoveTo }] = createSlider({
        ...options,
        slides: { 
            perView: 1,
            spacing: props.spacing ? convertRemToPixel(props.spacing) : 1.07 * getRootFontSize()
        },
    });

    return (
        <ErrorCatcher componentName='Slider'>
            <StyledSliderWrapper isInModal={props.isInModal} slideHeight={props.slideHeight}>
                <Switch>
                    <Match when={AppState.viewport.width > theme.breakpoints.MOBILE}>
                        <StyledSliderInner
                            ref={el => {
                                setTimeout(() => {
                                    sliderDesktop(el);
                                }, 500);
                                    
                            }}
                            isReady={isReady()}
                            loadingTranslation={AppState.localize('loading', 'Loading...')}
                        >
                            {props.children}
                        </StyledSliderInner>
                    </Match>
                    <Match when={AppState.viewport.width <= theme.breakpoints.MOBILE}>
                        <StyledSliderInner
                            ref={el => {
                                setTimeout(() => {
                                    sliderMobile(el);
                                }, 500);
                                    
                            }}
                            isReady={isReady()}
                            loadingTranslation={AppState.localize('loading', 'Loading...')}
                        >
                            {props.children}
                        </StyledSliderInner>
                    </Match>
                </Switch>


                <Show 
                    when={AppState.viewport.width > theme.breakpoints.MOBILE ? (slides().length - props.slidesPerView + 1) > 1 : slides().length > 1}
                    fallback={<StyledSliderControlsPlaceholder />}
                >
                    <StyledSliderControls>
                        <button onClick={AppState.viewport.width > theme.breakpoints.MOBILE ? prev : sliderMobilePrev}>
                            <AngleLeftIcon
                                width={1.79}
                                height={1.07}
                                disabled={AppState.viewport.width > theme.breakpoints.MOBILE ? current() === 0 : sliderMobileCurrent() === 0}
                                customCss={'cursor: pointer;'}
                            />
                        </button>
                        <For
                            each={
                                AppState.viewport.width > theme.breakpoints.MOBILE
                                    ? slides().slice(0, slides().length - props.slidesPerView + 1)
                                    : slides().slice(0, slides().length)
                            }
                        >
                            {(_, idx) => {
                                return (
                                    <button
                                        onClick={() => (AppState.viewport.width > theme.breakpoints.MOBILE ? moveTo(idx()) : sliderMobileMoveTo(idx()))}
                                        class={`dot ${AppState.viewport.width > theme.breakpoints.MOBILE
                                            ? current() === idx()
                                                ? 'active'
                                                : ''
                                            : sliderMobileCurrent() === idx()
                                                ? 'active'
                                                : ''
                                        }`}
                                    ></button>
                                );
                            }}
                        </For>
                        <button onClick={AppState.viewport.width > theme.breakpoints.MOBILE ? next : sliderMobileNext}>
                            <AngleRightIcon
                                width={1.79}
                                height={1.07}
                                disabled={
                                    AppState.viewport.width > theme.breakpoints.MOBILE
                                        ? current() === slides().length - props.slidesPerView
                                        : sliderMobileCurrent() === slides().length - 1
                                }
                                customCss={'cursor: pointer; margin-left: 0.36rem;'}
                            />
                        </button>
                    </StyledSliderControls>
                </Show>
            </StyledSliderWrapper>
        </ErrorCatcher>
    );
};
