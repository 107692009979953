import { Link } from '@solidjs/router';
import { createSignal, Match, onMount, Show, Switch, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import theme from '../../../style/theme';
import { Heading } from '../../../ui-components/heading/heading';
import { Text } from '../../../ui-components/text/text';
import { ArrowRightIcon } from '../../icons-library/arrow-right';
import { getBackgroundImageSrcData, ImageSrcData } from '../../image-component/image-component';
import { CustomCardType } from '../card-slider';
import { StyledArrowContainer, StyledCardWrapper, StyledImage, StyledLinkTitle, StyledTextWrapper, StyliedSliderCardTitle } from './slider-card.styles';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';

type Props = {
    card: CustomCardType;
};

export const CustomCard = (props: Props) => {
    const { imagesServiceUrl, supportedImageFormats } = useContext(AppContext);
    const [backgroundImageSrcData, setBackgroundImageSrcData] = createSignal<ImageSrcData>();

    onMount(() => {
        if (props?.card?.image?.url) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.card.image.url,
                width: 500, // Assume mobile from the start
                height: 'AUTO',
            });

            setBackgroundImageSrcData(backgroundImage);
        }
    });

    const isLink = () => Boolean(props?.card?.link?.url);

    const cardWrapper = () => {
        return (
            <StyledCardWrapper cover={true} isLink={isLink()}>
                <Show when={props?.card?.image?.url}>
                    <StyledImage src={backgroundImageSrcData()?.original} alt={props.card.image?.altText || props.card.image?.title || ''} title={props.card.image?.title || ''} />
                </Show>
                <StyledTextWrapper cover={true}>
                    <Show when={props.card?.heading}>
                        <StyliedSliderCardTitle>
                            <Heading tag="h4" variant="smallGray">
                                {props.card.heading}
                            </Heading>
                        </StyliedSliderCardTitle>
                    </Show>
                    <Show when={props.card?.text}>
                        <Text fontSize="normal" lineLimit={6}>
                            {props.card.text}
                        </Text>
                    </Show>
                    <Show when={props.card.link?.url}>
                        <StyledArrowContainer>
                            <Show when={props?.card?.link?.title}>
                                <StyledLinkTitle>
                                    <Heading tag="h4" variant="productNames">
                                        {props?.card?.link?.title}
                                    </Heading>
                                </StyledLinkTitle>
                            </Show>
                            <ArrowRightIcon fill={theme.palette.red} />
                        </StyledArrowContainer>
                    </Show>
                </StyledTextWrapper>
            </StyledCardWrapper>
        );
    };

    return (
        <Switch>
            <Match when={isLink()}>
                {/* This is hacky, but it's a temp retro-active fix to allow countries that are using this component for pdf downloads */}
                <Link
                    href={removeTrailingSlash(props?.card?.link?.url) || ''}
                    target={props?.card?.link?.target ? props?.card?.link?.target : props?.card?.link?.url?.includes('wp-content') ? '_blank' : '_self'}
                >
                    {cardWrapper()}
                </Link>
            </Match>
            <Match when={!isLink()}>
                <div>
                    {cardWrapper()}
                </div>
            </Match>
        </Switch>
    );
};
