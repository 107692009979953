import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
    width: 2.5,
    height: 2.5,
    fill: 'white',
};

const customCss = `
    box-shadow: 0rem 0.21rem 0.42rem #00000029, inset 0.14rem 0.14rem 1rem #0000005e;
    border-radius: 50%;
`;

export const FacebookIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props} customCss={customCss}>
            <path
                d="M38.183,23.091A15.091,15.091,0,1,0,20.733,38V27.454H16.9V23.091h3.834V19.766c0-3.782,2.252-5.871,5.7-5.871a23.225,23.225,0,0,1,3.378.295V17.9h-1.9a2.181,2.181,0,0,0-2.459,2.357v2.833h4.185l-.669,4.362H25.449V38A15.1,15.1,0,0,0,38.183,23.091Z"
                transform="translate(-8 -8)"
            />
        </SvgIcon>
    );
};
