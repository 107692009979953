import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    width: 1.88,
    height: 2.14,
    viewBox: '0 0 26.25 30'
};

export const LockIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M14.531,22.5a1.406,1.406,0,0,1-2.812,0V18.75a1.406,1.406,0,0,1,2.813,0ZM13.125,0a7.5,7.5,0,0,1,7.5,7.5v3.75H22.5A3.753,3.753,0,0,1,26.25,15V26.25A3.753,3.753,0,0,1,22.5,30H3.75A3.752,3.752,0,0,1,0,26.25V15a3.752,3.752,0,0,1,3.75-3.75H5.625V7.5A7.5,7.5,0,0,1,13.125,0Zm0,2.813A4.687,4.687,0,0,0,8.438,7.5v3.75h9.375V7.5A4.687,4.687,0,0,0,13.125,2.813ZM3.75,14.063A.939.939,0,0,0,2.813,15V26.25a.939.939,0,0,0,.938.938H22.5a.94.94,0,0,0,.938-.937V15a.94.94,0,0,0-.937-.937Z"
            />
        </SvgIcon>
    );
};
