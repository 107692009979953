/* eslint-disable indent */
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../../../../../style/theme';

const { colors } = theme;

export const StyledQuoteOuterPDF = styled.div``;

export const StyledHeadingWrapperPDF = styled.div`
    margin-bottom: 0.6rem;
`;

export const StyledQuoteContentPDF = styled.div`
    container-type: inline-size;
    background-color: white;
    border-radius: 1.43rem;
`;

export const StyledRoundImageWrapperPDF = styled.div`
    position: relative;
    width: 27%;
    padding-bottom: 27%;
    border-radius: 50%;
    background: lightblue;
    align-self: center;
    overflow: hidden;
`;

export const StyledImagePDF = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
`;

export const StyledAuthorContainerPDF = styled.div`
    display: flex;
    width: 100%;
`;

export const StyledAuthorNamePDF = styled.div`
    font-weight: bold;
`;

export const StyledAuthorTextContainerPDF = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 1rem;
`;

export const StyledSignaturePDF = styled.p`
    font-weight: 400;
    font-family: ${fontFamilyAtosRegular};
    font-size: 1.1rem;
    line-height: 1.7rem;
    letter-spacing: 0;
    color: black;
    margin: 0;
`;

export const StyledQuoteWrapperPDF = styled.div`
    margin: auto;
    display: flex;
    align-self: flex-start;
    gap: 0 2rem;
`;

export const StyledOpeningQuoteSvgContainerPDF = styled.div``;

export const StyledQuoteContainerPDF = styled.div`
    padding-block: 1rem;
`;

export const StyledClosingQuoteSvgContainerPDF = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: end;
`;

export const StyledQuotePDF = styled.p<any>`
    font-weight: 400;
    font-family: ${fontFamilyAtosRegular};
    font-size: 2.71rem;
    line-height: 3.93rem;
    letter-spacing: 0;
    margin: 0;
    margin-bottom: 1rem;
    color: ${(p) => (p.color ? p.color : colors.text.quote)};
`;
