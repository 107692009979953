import { createStore } from 'solid-js/store';
import { createContext, createResource, onMount, ResourceReturn, sharedConfig } from 'solid-js';
import { isServer } from 'solid-js/web';
import getHashKey from '../tools/get-hash-key';
import { PageCacheType, User } from '../types/shared';
import { AlgoliaSettings, DeploymentEnvironment, LocaleKey, PageLink, ShopOptions, SiteDomain, SiteInfo } from '../types/app-state';
import { createAppStore, AppStoreData } from '../stores/app-store';
import { HeaderSettingsType } from '../../server/header-settings';

type Viewport = {width: number, height: number};

export type SyncStore = {
    appStore: AppStoreData;
};

const desktopViewportDefault = { width: 1920, height: 1200 };
const mobileViewportDefault = { width: 480, height: 900 };

type AppContextProviderProps = {
    client: (query: string, variables: any) => any;
    cache: any;
    children?: any;
    imagesServiceUrl: string;
    supportedImageFormats: {
        webp: boolean;
        avif: boolean;
    };
    viewport: Viewport;
    mobile: null | string;
    productsEntityName: string;
    blogAndNewsEntityName: string;
    eventsEntityName: string;
    videosEntityName: string;
    setStatusCode: (statusCode: number) => void;
    setCacheType: (type: PageCacheType) => void;
    page404: PageLink;
    pageRegistration: PageLink;
    searchPage: PageLink;
    productContactFormUrl: string;
    ifuPage: PageLink;
    translations: any;
    user?: User;
    siteDomains: SiteDomain[];
    siteInfo: SiteInfo;
    algoliaSettings: AlgoliaSettings;
    language: { key: string };
    announcement: { seen: boolean};
    hcpWarning: { seen: boolean};
    appDownloadPrompt: { dismissed: boolean };
    pageRequestPasswordResetUrl: string;
    pageResetPasswordUrl: string;
    pageVerifyAccountUrl: string;
    shopPage: PageLink;
    myContentPage: PageLink;
    privacyPolicyPage: string;
    deploymentEnvironment: DeploymentEnvironment;
    recaptchaSiteKey: string;
    isPdfGenerator: boolean;
    newsletterSignupPage: string;
    pdfBuilderPage: string;
    contactUsPage: PageLink;
    productsListingPage: PageLink;
    eventsListingPage: PageLink;
    hcpEventsListingPage: PageLink;
    blogsNewsListingPage: PageLink;
    hcpBlogsNewsListingPage: PageLink
    videosListingPage: PageLink;
    headerSettings: HeaderSettingsType;

    // Moved from its own out-of-context store to the app context
    syncStore: ReturnType<typeof createStore<SyncStore>>;

    // Atos Care
    shop: ShopOptions;
};

export type ContextType = {
    createCachedResource: (graphQlQuery: string, variables?: any, useCache?: boolean) => ResourceReturn<any>;
    graphQlClient: (graphQlQuery: string, variables: any, useCache?: boolean) => Promise<any>;
    imagesServiceUrl: string;
    viewport: Viewport;
    updateViewport: (viewport: Viewport) => void;
    setStatusCode: (statusCode: number) => void;
    setCacheType: (type: PageCacheType) => void;
    supportedImageFormats: {
        webp: boolean;
        avif: boolean;
    };
    productsEntityName: string;
    eventsEntityName: string;
    videosEntityName: string;
    blogAndNewsEntityName: string;
    page404: PageLink;
    pageRegistration: PageLink;
    searchPage: PageLink;
    productContactFormUrl: string;
    ifuPage: PageLink;
    localize: (slug: string, defaultValue: string) => string;
    userState: { user: User | null };
    updateUser: (user?: User) => void;
    siteDomains: SiteDomain[];
    siteInfo: SiteInfo;
    algoliaSettings: {
        searchAppId: string;
        searchApiClientKey: string;
    };
    language: { key: string };
    updateLanguage: (language: string) => void;
    announcement: {seen: boolean};
    hcpWarning: {seen: boolean};
    registerUserHasSeenAnnouncement: () => void;
    registerHcpWarningSeen: () => void;
    appDownloadPrompt: { dismissed: boolean };
    updateAppPromptDismissed: (seen: boolean) => void;
    pageRequestPasswordResetUrl: string;
    pageResetPasswordUrl: string;
    pageVerifyAccountUrl: string;
    shopPage: PageLink;
    myContentPage: PageLink;
    privacyPolicyPage: string;
    deploymentEnvironment: DeploymentEnvironment;
    recaptchaSiteKey: string;
    isPdfGenerator: boolean;
    newsletterSignupPage: string;
    pdfBuilderPage: string;
    contactUsPage: PageLink;
    productsListingPage: PageLink;
    eventsListingPage: PageLink;
    hcpEventsListingPage: PageLink;
    blogsNewsListingPage: PageLink;
    hcpBlogsNewsListingPage: PageLink
    videosListingPage: PageLink;
    appStore: ReturnType<typeof createAppStore>;
    headerSettings: HeaderSettingsType;

    // Atos Care
    shop: ShopOptions;

};

export const AppContext = createContext<ContextType>({

    createCachedResource: () => createResource(() => true),
    graphQlClient: () => new Promise((resolve) => resolve(1)),
    imagesServiceUrl: '',
    viewport: desktopViewportDefault,
    updateViewport: () => {
        /* placeholder */
    },
    setStatusCode: () => {
        /* placeholder */
    },
    setCacheType: () => {
        /* placeholder */
    },
    supportedImageFormats: {
        webp: false,
        avif: false,
    },
    productsEntityName: '',
    eventsEntityName: '',
    blogAndNewsEntityName: '',
    videosEntityName: '',
    page404: {
        title: '',
        url: '',
    },
    pageRegistration: {
        title: '',
        url: '',
    },
    searchPage: {
        title: '',
        url: '',
    },
    productContactFormUrl: '',
    ifuPage: {
        title: '',
        url: '',
    },
    localize: () => '',
    userState: { user: null },
    updateUser: () => {},
    siteDomains: [],
    siteInfo: {
        siteId: '',
        siteSlug: '',
        target: '',
        url: '',
        name: '',
        key: 'en' as LocaleKey,
        siteType: 'default',
    },
    algoliaSettings: {
        searchAppId: '',
        searchApiClientKey: '',
    },
    language: { key: '' },
    updateLanguage: () => null,
    announcement: {seen: false },
    registerUserHasSeenAnnouncement: () => null,
    hcpWarning: { seen: false},
    registerHcpWarningSeen: () => null,
    appDownloadPrompt: { dismissed: false },
    updateAppPromptDismissed: () => null,
    pageRequestPasswordResetUrl: 'strng',
    pageResetPasswordUrl: '',
    pageVerifyAccountUrl: '',
    shopPage: {
        title: '',
        url: '',
    },
    myContentPage: {
        title: '',
        url: '',
    },
    privacyPolicyPage: '',
    deploymentEnvironment: 'local',
    recaptchaSiteKey: '',
    isPdfGenerator: false,
    newsletterSignupPage: '',
    pdfBuilderPage: '',
    contactUsPage: {
        title: '',
        url: '',
    },
    productsListingPage: {
        title: '',
        url: '',
    },
    eventsListingPage: {
        title: '',
        url: '',
    },
    hcpEventsListingPage: {
        title: '',
        url: '',
    },
    blogsNewsListingPage: {
        title: '',
        url: '',
    },
    hcpBlogsNewsListingPage: {
        title: '',
        url: '',
    },
    videosListingPage: {
        title: '',
        url: '',
    },
    appStore: {} as any,
    headerSettings: {} as any,

    // Atos Care
    shop: {
        basketPage: {
            title: '',
            url: '',
        },
        orderConfirmationPage: {
            title: '',
            url: '',
        },
    },
});

export type UserStoreType = { 
    user: User | null;
}

export function AppContextProvider(props: AppContextProviderProps) {

    const [cache, setCache] = createStore(props.cache);
    const initialViewport = props.mobile ? mobileViewportDefault : props.viewport;
    const [viewport, setViewport] = createStore(initialViewport);
    const [language, setLanguage] = createStore(props.language);
    const [announcement, setAnnouncement] = createStore(props.announcement);
    const [hcpWarning, setHcpWarning] = createStore(props.hcpWarning);
    const [appPromptDismissed, setAppPromptDismissed] = createStore(props.appDownloadPrompt);
    const [userState, setUserState] = createStore<UserStoreType>({ user: null});

    const appStore = createAppStore(props.syncStore);

    function getInitialValue(key: string, useCache = true): string {
        const root = '0-0-0-0-0-0-0';
        if (!isServer && sharedConfig.context?.id !== root + '-') {
            useCache = true;
        }
        return useCache ? cache[key] : '';
    }

    const graphQlClient = async (graphQlQuery = '', variables = {}, useCache = true): Promise<any> => {
        const key = getHashKey(graphQlQuery, variables);

        if (useCache && cache[key]) {
            return Promise.resolve(cache[key]);
        }

        const response = await props.client(graphQlQuery, variables);

        if (useCache) {
            setCache({ [key]: response });
        }

        return response;
    };

    const createCachedResource = (graphQlQuery = '', variables: any = {}, useCache = true) => {
        const key = getHashKey(graphQlQuery, typeof variables === 'function' ? variables() : variables);

        if (isServer) {
            useCache = true;
        }

        async function fetcher(_: any, { refetching }: { refetching?: unknown }): Promise<any> {
            if (refetching) {
                useCache = false;
            }

            // Sometimes 'variables' is a function due to it being a signal/reactive property (e.g. location)
            if (typeof variables === 'function') {
                return graphQlClient(graphQlQuery, variables(), useCache);
            }
            return graphQlClient(graphQlQuery, variables, useCache);
        }

        const options: any = {
            initialValue: getInitialValue(key, useCache),
        };

        if (isServer && options.initialValue) {
            options.ssrLoadFrom = 'initial';
        }

        if (typeof variables === 'function') {
            return createResource(variables, fetcher, options);
        }

        return createResource(fetcher, options);
    };

    type Viewport = {
        height: number;
        width: number;
    }
    const updateViewport = (viewport: Viewport) => {
        setViewport(viewport);
    };

    const updateLanguage = (languageKey: string) => {
        setLanguage({ key: languageKey });
    };

    const registerUserHasSeenAnnouncement = () => {
        setAnnouncement({ seen: true });
    };

    const registerHcpWarningSeen = () => {
        setHcpWarning({ seen: true });
    };
    
    const updateAppPromptDismissed = (dismissed: boolean) => {
        setAppPromptDismissed({ dismissed });
    };

    const updateUser = (user?: User) => {
        setUserState({ user });
    };

    const localize = (slug: string, defaultValue: string) => {
        if(props.translations[slug]) {
            return props.translations[slug];
        }
        return defaultValue;
    };

    onMount(async () => {
        updateViewport({ width: window.innerWidth, height: window.innerHeight });

        window.addEventListener('resize', () => {
            updateViewport({ width: window.innerWidth, height: window.innerHeight });
        });
    });

    return (
        <AppContext.Provider
            value={{
                createCachedResource,
                graphQlClient,
                imagesServiceUrl: props.imagesServiceUrl,
                viewport: viewport,
                updateViewport,
                supportedImageFormats: props.supportedImageFormats,
                productsEntityName: props.productsEntityName,
                eventsEntityName: props.eventsEntityName,
                videosEntityName: props.videosEntityName,
                blogAndNewsEntityName: props.blogAndNewsEntityName,
                setStatusCode: props.setStatusCode,
                setCacheType: props.setCacheType,
                page404: props.page404,
                pageRegistration: props.pageRegistration,
                searchPage: props.searchPage,
                productContactFormUrl: props.productContactFormUrl,
                ifuPage: props.ifuPage,
                localize,
                userState,
                updateUser,
                siteDomains: props.siteDomains,
                siteInfo: props.siteInfo,
                algoliaSettings: props.algoliaSettings,
                language: language,
                updateLanguage,
                announcement: announcement,
                registerUserHasSeenAnnouncement,
                hcpWarning: hcpWarning,
                registerHcpWarningSeen,
                appDownloadPrompt: appPromptDismissed,
                updateAppPromptDismissed,
                pageRequestPasswordResetUrl: props.pageRequestPasswordResetUrl,
                pageResetPasswordUrl: props.pageResetPasswordUrl,
                pageVerifyAccountUrl: props.pageVerifyAccountUrl,
                shopPage: props.shopPage,
                myContentPage: props.myContentPage,
                privacyPolicyPage: props.privacyPolicyPage,
                deploymentEnvironment: props.deploymentEnvironment,
                recaptchaSiteKey: props.recaptchaSiteKey,
                isPdfGenerator: props.isPdfGenerator,
                newsletterSignupPage: props.newsletterSignupPage,
                pdfBuilderPage: props.pdfBuilderPage,
                contactUsPage: props.contactUsPage,
                productsListingPage: props.productsListingPage,
                eventsListingPage: props.eventsListingPage,
                hcpEventsListingPage: props.hcpEventsListingPage,
                blogsNewsListingPage: props.blogsNewsListingPage,
                hcpBlogsNewsListingPage: props.hcpBlogsNewsListingPage,
                videosListingPage: props.videosListingPage,
                appStore,
                headerSettings: props.headerSettings,
                // Atos Care
                shop: props.shop,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}
