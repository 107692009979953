import theme from '../../style/theme';
import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 41.305 41.305',
    fill: theme.palette.red,
};

export const PlayCircleIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path 
                id="circle-play-light"
                d="M20.652,2.582A18.071,18.071,0,1,1,2.582,20.652,18.071,18.071,0,0,1,20.652,2.582Zm0,38.723A20.652,20.652,0,1,0,0,20.652,20.652,20.652,0,0,0,20.652,41.3Zm5.865-20.652L16.78,26.6V14.7ZM17.143,11.9a1.957,1.957,0,0,0-1.96-.04,1.934,1.934,0,0,0-.992,1.686v14.2a1.938,1.938,0,0,0,2.953,1.646l11.617-7.1a1.938,1.938,0,0,0,0-3.308l-11.617-7.1Z"
            />
        </SvgIcon>
    );
};
