import { styled } from 'solid-styled-components';
import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';
import theme from '../../style/theme';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 28.458 27.21',
    // width: 29.992
};

const StyledWrapper = styled(SvgIcon)`
    &:hover {
        transition: none;
        & path {
            transition: stroke 0.2s, fill 0.2s;
            stroke: ${theme.palette.pink};
            fill: ${theme.palette.pink};
        }
    }
`;

export const StarFullIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <StyledWrapper {...props}>
            <path
                data-name="Icon feather-star"
                d="M15.729,3l3.933,7.968,8.8,1.286-6.364,6.2,1.5,8.757-7.866-4.137L7.862,27.21l1.5-8.757L3,12.254l8.8-1.286Z"
                transform="translate(-1.5 -1.5)"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                stroke={props.fill} 
                fill={props.fill} 
            />
        </StyledWrapper>
    );
};
