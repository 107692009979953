import { styled } from 'solid-styled-components';
import theme from '../../../../style/theme';
import isVisregTest from '../../../../tools/is-visual-regression-test';

export const pageHeight = 1142 - 19;
export const footerHeight = 100;
export const pageWidth = 814 - 20;
export const pageRightMargin = 37;
export const pageLeftMargin = 74;
export const pageTopMargin = 64;
export const pageBottomMargin = 32;
export const pageStencilHeight = pageHeight - footerHeight - pageTopMargin;
export const availableWidth = pageWidth - pageLeftMargin - pageRightMargin;

export const StyledCommonA4Body = styled.div<{ debugging: boolean }>`
    visibility: ${p => p.debugging ? 'visible' : 'hidden'};
    @media ${isVisregTest ? 'screen' : 'print'} {
        visibility: visible;
        width: ${pageWidth}px;
        height: ${pageHeight}px;
        padding-top: ${pageTopMargin}px;
        padding-right: ${pageRightMargin}px;
        padding-bottom: ${pageBottomMargin}px;
        padding-left: ${pageLeftMargin}px;
        background-color: ${theme.palette.white};
    }
`;

export const StyledCommonFittingTemplate = styled.div<{ debugging: boolean }>`
    position: absolute;
    visibility: ${p => p.debugging ? 'visible' : 'hidden'};
    background-color: rgba(255,255,0,0.3);
    height: ${pageStencilHeight}px;
    width: ${pageWidth}px;
    top: ${pageTopMargin}px;
    padding-left: ${pageLeftMargin}px;
    padding-right: ${pageRightMargin}px;
    left: 0;
`;

export const StyledDebugFittingTemplate = styled.div<{ top?: number; color?: string }>`
    position: absolute;
    background-color: rgba(255,255,0,0.3);
    height: ${pageStencilHeight}px;
    width: ${pageWidth}px;
    top: ${p => p.top ? p.top : pageTopMargin}px;
    padding-left: ${pageLeftMargin}px;
    padding-right: ${pageRightMargin}px;
    left: 0;
    z-index: 1;
    pointer-events: none;
`;
