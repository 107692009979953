import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 50 50',
    // width: 29.992
};

export const HomeIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            {/* <path
                d="M45.364,179.462l-12.955,12.2a1.843,1.843,0,0,1-1.406.631,2.046,2.046,0,0,1-1.406-.559L16.64,179.462a2.047,2.047,0,1,1,2.813-2.975L31,187.422l11.549-10.944a2.044,2.044,0,0,1,2.893.08A2.066,2.066,0,0,1,45.364,179.462Z"
                transform="translate(-16.001 -175.918)"
                {...props}
            /> */}
            <path
                id="Path_21782"
                data-name="Path 21782"
                d="M25,0A25,25,0,1,0,50,25,25.028,25.028,0,0,0,25,0Zm0,48.85A23.85,23.85,0,1,1,48.85,25,23.878,23.878,0,0,1,25,48.85Z"
            />
            <path
                id="Path_21783"
                data-name="Path 21783"
                d="M40.39,20.26a.57.57,0,0,0-.57.57V37.82h-3.5V24.07H26.84V37.82H10.18V20.83a.57.57,0,0,0-.57-.57.576.576,0,0,0-.57.57V38.97H40.97V20.83a.57.57,0,0,0-.57-.57ZM27.99,37.83V25.23h7.18v12.6H27.99Z"
            />
            <path
                id="Path_21784"
                data-name="Path 21784"
                d="M44.11,19.15,25,8.32,5.89,19.15a.573.573,0,1,0,.56,1L25,9.64,43.54,20.15a.581.581,0,0,0,.28.07.571.571,0,0,0,.5-.29.563.563,0,0,0-.22-.78Z"
            />
            <path
                id="Path_21785"
                data-name="Path 21785"
                d="M14.32,23.9v7.33H22.6V23.9Zm7.13,6.18H15.47V25.05h5.98Z"
            />
        </SvgIcon>
    );
};
