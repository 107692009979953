import { createSignal, useContext } from 'solid-js';
import { AppContext } from '../../../../app-context-provider/app-context-provider';
import { setCookie } from '../../../../tools/cookie-helpers';
import { Button } from '../../../../ui-components/button/button';
import { Heading } from '../../../../ui-components/heading/heading';
import { Modal } from '../../../../ui-components/modal/modal';
import { Text } from '../../../../ui-components/text/text';
import { StyledPdfModalText, StyledButtonWrapper } from './pdf-newsletter-signup-prompt.style';
import { StyledVerticalSpace } from '../../../../ui-components/utility-style-components/spacing';
import { useNavigate } from '@solidjs/router';
import { showNewsletterPrompt } from './newsletter-prompt-checker';

export const NewsletterSignupPromptForPdf = () => {
    const { localize, newsletterSignupPage } = useContext(AppContext);
    const [ dismissed, setDismissed ] = createSignal(false);
    const navigate = useNavigate();

    const showModal = () => {        
        if (dismissed()) return false;

        return showNewsletterPrompt(newsletterSignupPage);
    };

    const dismissNewsletterPrompt = () => {
        setCookie('dismissed-pdf-builder-newsletter-prompt', 'true');
        setDismissed(true);
    };


    return (
        <Modal visible={showModal()} onClose={() => dismissNewsletterPrompt()} darkenBackground={true} size='small'>
            <Heading tag="h1" variant="medium">{localize('pdf-builder-newsletter-cta-heading', 'Sign up for our newsletter')}</Heading>
            <StyledVerticalSpace size={1} />
            <Text fontSize='normal'>{localize('pdf-builder-newsletter-cta-text', 'Get the latest news and information')}</Text>
            <StyledVerticalSpace size={1} />
            <StyledPdfModalText>
                <StyledButtonWrapper>
                    <Button
                        label={localize('app-dismiss-prompt', 'Not now')}
                        onClick={() => dismissNewsletterPrompt()}
                        variant='secondary'
                    />
                </StyledButtonWrapper>
                <StyledButtonWrapper>
                    <Button
                        label={localize('pdf-builder-newsletter-cta-button', 'Sign up')}
                        onClick={() => {
                            dismissNewsletterPrompt();
                            navigate(newsletterSignupPage);
                        }}
                    />
                </StyledButtonWrapper>
            </StyledPdfModalText>
        </Modal>
    );
};