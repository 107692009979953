import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 29.992 30',
    width: 3.21,
    height: 2.86,
};

export const PhoneIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M28.143,18.762,22.389,16.3a3.06,3.06,0,0,0-3.586.876L17.2,19.134a17.482,17.482,0,0,1-6.328-6.275l1.966-1.61a3.082,3.082,0,0,0,.88-3.577l-2.48-5.83A3.077,3.077,0,0,0,7.724.073L2.378,1.307A3.048,3.048,0,0,0,0,4.295a25.724,25.724,0,0,0,25.7,25.7,2.949,2.949,0,0,0,2.936-2.375l1.234-5.352A2.972,2.972,0,0,0,28.143,18.762Zm-.961,2.871L25.946,26.99a.316.316,0,0,1-.247.192A22.911,22.911,0,0,1,2.812,4.295a.244.244,0,0,1,.2-.246L8.36,2.813a.233.233,0,0,1,.056-.006.262.262,0,0,1,.237.153l2.469,5.762a.255.255,0,0,1-.073.3L8.191,11.309a1.407,1.407,0,0,0-.372,1.707,20.044,20.044,0,0,0,9.111,9.111,1.423,1.423,0,0,0,1.709-.372l2.344-2.864a.241.241,0,0,1,.288-.066l5.757,2.466A.38.38,0,0,1,27.182,21.633Z"
                transform="translate(0 0.006)"
            />
        </SvgIcon>
    );
};
