import { Show, createSignal, onCleanup, onMount, useContext } from 'solid-js';
import { Grid } from '../../../grid-system/grid/grid';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { StyledRelativeContainer } from '../../../ui-components/utility-style-components/positioning';
import { AngleDownIcon } from '../../icons-library/angle-down';
import { AngleUpIcon } from '../../icons-library/angle-up';
import { PageContext } from '../../page/page-context-provider';
import { SidebarMenuMobileDropdown } from './sidebar-menu-mobile-dropdown';
import { StyledSidebarHeading, StyledSidebarNav, StyledSidebarNavHeadingRow } from './sidebar-menu-mobile.style';
import urlMatches from '../../../tools/url-match';
import { MenuItem } from '../desktop/sidebar-menu-desktop';
import removeAmpersand from '../../../tools/remove-ampersand';

export const SidebarMenuMobile = () => {
    const [open, setOpen] = createSignal(false);
    const { sidebarMenu } = useContext(PageContext);

    const openMenu = (event: Event) => {
        setOpen(true);
        event.stopPropagation();
    };

    const closeMenu = () => {
        setOpen(false);
    };

    let sidebarRef: HTMLDivElement | undefined;
    const handleOutsideClick = () => {
        const handleClickOutside = (event: Event) => {
            if (!sidebarRef?.contains(event.target as Node) && open()) {
                closeMenu();
            }
        };

        window.addEventListener('click', handleClickOutside);

        onCleanup(() => {
            window.removeEventListener('click', handleClickOutside);
        });
    };
    onMount(handleOutsideClick);


    const getActivePageName = () => {
        let activePage: MenuItem | undefined;
        sidebarMenu()?.menu?.forEach((menuItem: MenuItem) => {
            let isActive = urlMatches(menuItem.url);

            if (isActive) {
                activePage = menuItem;
                return;
            }

            if (!isActive && menuItem.children) {
                menuItem.children.find((childrenItem: MenuItem) => {
                    isActive = urlMatches(childrenItem.url);
                    if (isActive) {
                        activePage = childrenItem;
                    } else if (childrenItem.children) {
                        // We go deeper
                        childrenItem.children.find((grandChildrenItem: MenuItem) => {
                            isActive = urlMatches(grandChildrenItem.url);
                            if (isActive) {
                                activePage = grandChildrenItem;
                            }
                        });
                    }
                });
            }
            return;
        });

        return removeAmpersand(activePage?.title);
    };

    return (
        <ErrorCatcher componentName="SidebarMenuMobile">
            <StyledSidebarNav ref={sidebarRef} hideMenu={!sidebarMenu()}>
                <Grid templateShorthand={[12]} customCss={'height: inherit; row-gap: 0 !important;'} isLayoutGrid={true} inheritParentGrid={false}>
                    <StyledRelativeContainer>
                        <Show when={sidebarMenu()}>
                            <StyledSidebarNavHeadingRow onClick={(event: Event) => (open() ? closeMenu() : openMenu(event))} isOpen={open()}>
                                <StyledSidebarHeading>{getActivePageName()}</StyledSidebarHeading>
                                <Show when={!open()} fallback={<AngleUpIcon fill="white" width={1.43} height={1.43} />}>
                                    <AngleDownIcon fill="white" width={1.43} height={1.43} />
                                </Show>
                            </StyledSidebarNavHeadingRow>
                        </Show>

                        <Show when={open()}>
                            <SidebarMenuMobileDropdown menu={sidebarMenu()?.menu} />
                        </Show>
                    </StyledRelativeContainer>
                </Grid>
            </StyledSidebarNav>
        </ErrorCatcher>
    );
};
