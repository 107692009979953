
import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const FileDownloadIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M14.062,15.369a.781.781,0,0,0-.781-.781H11.719a.781.781,0,0,0-.781.781v5.469H7.755a.781.781,0,0,0-.55,1.336l4.708,4.673a.833.833,0,0,0,1.174,0l4.708-4.673a.781.781,0,0,0-.55-1.336H14.062Zm10.015-8.99L18.616.918A3.125,3.125,0,0,0,16.41,0H3.124A3.135,3.135,0,0,0,0,3.131V30.209a3.126,3.126,0,0,0,3.124,3.124H21.87A3.13,3.13,0,0,0,25,30.209V8.592a3.144,3.144,0,0,0-.923-2.213ZM16.663,3.385l4.954,4.953H16.663V3.385Zm5.212,26.824H3.124V3.131H13.539V9.9A1.558,1.558,0,0,0,15.1,11.462h6.773Z"
                fill="#726060"
            />
        </SvgIcon>
    );
};
