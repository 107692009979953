import { styled } from 'solid-styled-components';
import { Link } from '@solidjs/router';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../../../../style/theme';

export const StyledShortcutsMenuList = styled.div`
    max-width: 25.71;
    margin-bottom: 4rem;
`;

export const StyledShortcutsMenuListLinkItems = styled.ul`
    padding: 2rem 0 0 6rem;
`;

export const StyledShortcutsMenuListLinkItem = styled.li`
    cursor: pointer;
    list-style: none;
    position: relative;
    padding-bottom: 1.5rem;
`;

export const StyledShortcutsMenuListHeading = styled.div`
    font-size: 2rem;
    font-family: ${fontFamilyAtosRegular};
    color: ${theme.palette.white};
    border-bottom: 1px solid ${theme.palette.white};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2;
`;

export const StyledShortcutsMobileMenuListLink = styled(Link)`
    color: white;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.43rem;
`;
