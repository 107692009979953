import { styled } from 'solid-styled-components';
import { fontFamilyAtosLight, theme } from '../../style/theme';

export const StyledLoginMenu = styled.div`
    container-type: inline-size;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: ${theme.palette.white};
    overflow-y: auto;
    overflow-x: hidden;
`;

export const StyledLoginMenuLeftArea = styled.div`
    background-color: ${theme.palette.gray};
    height: 100%;
    box-sizing: border-box;
    padding: 3rem 6rem 0 0;
    position: relative;
    ${theme.mediaQueries.tabletMaxOriginal} {
        padding: 3rem 3rem 0 0;
    }
`;

export const StyledLoginMenuRightArea = styled.div`
    height: 100vh;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledBottomFade = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,1) 100%);
`;

export const StyledLoginMenuHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 3rem 0 1rem 0;
    position: sticky;
    right: 0;
    top: 0;
`;

export const StyledEmptyColumn = styled.div``;

// a little hacky but working fix for our need of dual colors
// in background row of menu.
export const StyledLoginMenuLeftBackgroundPanel = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10000px;
    margin-left: -10000px;
    background-color: ${theme.palette.gray};
`;

// Really stupid stuff that is needed for the loginMenu close button
// to look similar to the desktop menu
export const StyledSpacer = styled.div`
    height: 72px;
    padding-top: 2rem;
`;

export const StyledWelcomeText = styled.div`
    margin-top: 50%;
    margin-bottom: 25%;
`;

export const StyledDivider = styled.div`
    border-bottom: 2px solid #fff;
`;

export const StyledLoginInstruction = styled.p`
    font-size: 20px;
    font-family: ${fontFamilyAtosLight};
    font-weight: normal;
    color: #fff;
    margin-top: 3rem;
`;

export const StyledSignedInStatus = styled.div<{ usePadding: boolean | undefined }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${(p) =>
        p.usePadding
            ? `
        padding: 0.5rem 1.43rem;
    `
            : ''}
`;

export const StyledSignedInAs = styled.button`
    font-size: 12px;
    color: ${theme.colors.text.red};
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

export const StyledSignedInAsBold = styled.span`
    font-weight: bold;
`;

export const StyledSignOut = styled(StyledSignedInAs)`
    font-weight: bold;
`;

export const StyledLoginMenuSignedInAsContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 5rem;
`;

export const StyledLoginMenuSignedInAsContentMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 4rem;
    margin-bottom: 5rem;
    padding: 0 1rem;
`;

export const StyledLoginMenuSignedInAs = styled.div`
    font-size: 18px;
    color: ${theme.colors.text.red};
    display: flex;
    flex-direction: column;
`;

export const StyledLoginMenuSignedInAsBold = styled(StyledLoginMenuSignedInAs)`
    font-weight: bold;
`;
