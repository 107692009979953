/* eslint-disable indent */
import algoliasearch from 'algoliasearch';
import { createSignal, For, onCleanup, onMount, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { MagnifyingGlassIcon } from '../icons-library/magnifying-glass';
import {
    StyledButton,
    StyledDropdownItem,
    StyledResultsDropdown,
    StyledSearchForm,
    StyledSearchFormWrapper,
    StyledSearchInput,
} from './search.style';
import { Heading } from '../../ui-components/heading/heading';
import removeAmpersand from '../../tools/remove-ampersand';

type SearchbarProps = {
    resultSetter?: (results: SearchResults) => void;
    enabledPostTypes?: ContentTypeSlug[];
    excludedEndpoints?: string[];
    onPostSelect: (selection: ContentItem) => void;
}

export type ContentTypeSlug = 'video-item' | 'page' | 'product' | 'blog-and-news' | 'event';

export type ContentItem = {
    isHealthcareProfessional: boolean;
    objectID: string;
    postTitle: string;
    tags: string[];
    texts: string;
    type: ContentTypeSlug;
    url: string;
}

type SearchResults = {
    [key in ContentTypeSlug]: any[];
};


export const Searchbar = (props: SearchbarProps) => {
    const AppState = useContext(AppContext);
    const { localize, algoliaSettings, siteInfo, productsEntityName, eventsEntityName, blogAndNewsEntityName, videosEntityName } = useContext(AppContext);

    const [searchText, setSearchText] = createSignal('');
    const [searchResult, setSearchResult] = createSignal<SearchResults>();
    const [isDropdownVisible, setIsDropdownVisible] = createSignal(false);
    const [hasClickedSubmitButton, setHasClickedSubmitButton] = createSignal(false);

    const nameMap: { [key in ContentTypeSlug]: string } = {
        'video-item': videosEntityName,
        'page': localize('pages', 'Pages'),
        'product': productsEntityName,
        'blog-and-news': blogAndNewsEntityName,
        'event': eventsEntityName,
    };

    const { searchAppId, searchApiClientKey } = algoliaSettings;
    if (!searchAppId || !searchApiClientKey) {
        return <div>No searchAppId / searchApiClientKey was found..</div>;
    }

    const { siteId, target } = siteInfo;
    if (!siteId || !target) {
        return <div>No siteId / target was found..</div>;
    }

    const client = algoliasearch(searchAppId, searchApiClientKey); // search only api key

    const searchClient = client.initIndex(`${siteId}-${target}`);

    const searchDropdownItems = async () => {
        try {
            const algoliaResults = await searchClient.search(searchText(), { hitsPerPage: 10 });
            const hits = algoliaResults.hits as ContentItem[];

            const sortedHits: SearchResults = {
                'video-item': [],
                'page': [],
                'product': [],
                'blog-and-news': [],
                'event': [],
            };

            hits.forEach((hit: { type: ContentTypeSlug, url: string }) => {
                if (props.enabledPostTypes && !props.enabledPostTypes.includes(hit.type)) {
                    return;
                }

                if (props?.excludedEndpoints?.includes(hit.url)) {
                    return;
                }

                sortedHits[hit.type].push({
                    ...hit,
                    url: hit.url === '' ? '/' : hit.url, // Empty string is the start page
                });
            });

            setSearchResult(sortedHits);
            if (props.resultSetter) {
                props.resultSetter(sortedHits);
            }
        } catch (error) {
            console.error('error searching', error);
        }
    };

    const handleOnSearchInputChange = (event: any) => {
        setSearchText(event.target.value);
        searchDropdownItems();

        if (searchText()) {
            setIsDropdownVisible(true);
        } else {
            setIsDropdownVisible(false);
        }
    };

    const handleOnDropdownItemClick = (item: ContentItem) => {
        setSearchText('');
        props.onPostSelect(item);
        setIsDropdownVisible(false);
        setHasClickedSubmitButton(!hasClickedSubmitButton());
    };

    let formRef: any;
    let dropdownRef: any;
    onMount(() => {
        const handleClick = (event: any) => {
            const isClickInsideForm = formRef.contains(event.target);
            const isClickInsideDropdown = dropdownRef.contains(event.target);

            if (isClickInsideForm) {
                searchDropdownItems();
            }

            if (!isClickInsideDropdown && !isClickInsideForm) {
                setIsDropdownVisible(false);
            }
        };
        document.addEventListener('click', handleClick);
        onCleanup(() => document.removeEventListener('click', handleClick));
    });

    const hasSearchResults = () => {
        if (!searchResult()) {
            return false;
        }

        let hasResults = false;
        for (const key in searchResult()) {
            // Checks if any of the categories has any results
            if (( searchResult() as SearchResults)[key as ContentTypeSlug].length > 0) {
                hasResults = true;
            }
        }

        return hasResults;
    };
        

    return (
        <ErrorCatcher componentName="Searchbar">
            <StyledSearchFormWrapper>
                <StyledSearchForm novalidate role="search" ref={formRef}>
                    <StyledSearchInput
                        type="search"
                        placeholder={localize('search', 'Search...')}
                        onInput={handleOnSearchInputChange}
                        value={searchText()}
                    />
                    <StyledButton type="submit">
                        <MagnifyingGlassIcon
                            {...(AppState.viewport.width < theme.breakpoints.MOBILE
                                ? {
                                    height: 2,
                                    width: 2,
                                    opacity: 0.7,
                                } : {})}
                        />
                    </StyledButton>
                </StyledSearchForm>

                <StyledResultsDropdown isDropdownVisible={isDropdownVisible()} ref={dropdownRef}>
                    <Show when={hasSearchResults()} fallback={<StyledDropdownItem noResults={true}>{localize('no-results', 'No results...')}</StyledDropdownItem>}>

                        <For each={Object.keys(searchResult() || {}) as ContentTypeSlug[]}>
                            {(categorySlug: ContentTypeSlug) => {
                                if (props.enabledPostTypes && !props.enabledPostTypes.includes(categorySlug)) {
                                    return;
                                }

                                return (
                                    <Show when={ searchResult()?.[categorySlug].length }>
                                        <Heading tag='h5'>{ nameMap[categorySlug] }</Heading>
                                        <For each={ searchResult()?.[categorySlug] }>
                                            {(item: ContentItem) => (
                                                <StyledDropdownItem onClick={() => handleOnDropdownItemClick(item)}>
                                                    { removeAmpersand(item?.postTitle) }
                                                </StyledDropdownItem>
                                            )}
                                        </For>
                                    </Show>
                                );
                            }}
                        </For>
                        
                    </Show>
                </StyledResultsDropdown>
            </StyledSearchFormWrapper>
        </ErrorCatcher>
    );
};
