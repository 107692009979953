import { styled } from 'solid-styled-components';
import theme, { palette } from '../../style/theme';

export const StyledCategoriesContainer = styled.div`
    display: flex;
    align-items: flex-end;
    position: relative;
    padding: 2.2rem 0;
`;

export const StyledCategoriesHeadingDesktop = styled.div`
    align-self: flex-start;
    min-width: 13rem;
`;

export const StyledCategoriesContainerMobile = styled.div`
    position: relative;
    padding: 2.2rem 0;
`;

export const StyledCategoriesList = styled.ul`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: ${theme.spacing.s};
    ${theme.mediaQueries.mobileMax} {
        margin-left: unset;
        display: block;
    }
`;

export const StyledCategoryItem = styled.li<any>`
    margin-left: ${theme.spacing.s};
    cursor: pointer;
    list-style: none;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.3rem;

    ${(props) =>
        props.underlined &&
        `
        text-decoration: underline;
        text-decoration-color: ${theme.colors.text.red};
    `}

    ${theme.mediaQueries.mobileMax} {
        margin-left: 0;
        border-top: 1px solid ${palette.gray};
        padding: 1.5rem 0;
    }
`;


export const StyledShowMoreButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;
