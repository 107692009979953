import { styled } from 'solid-styled-components';
import theme from '../../../../../../style/theme';


export const StyledSliderPdf = styled.div`
    display: flex;
    gap: 1.45rem;
    border: 1px solid ${theme.palette.lightGray};
    border-radius: 0.73rem;
    padding: 0.73rem;
    align-items: center;
`;

export const StyledUrlContainer = styled.div`
    word-break: break-word;
`;

export const StyledSliderPdfBackgroundImage = styled.div<{ backgroundImageSrcData: any; focusPosition: string; }>`
    height: 9.2rem;
    flex-basis: 34%;
    border-radius: 0.36rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-block: 0.73rem;
    ${(p) =>
        p.backgroundImageSrcData && p.backgroundImageSrcData.original !== ''
            ? `
                background-image: url(${p?.backgroundImageSrcData?.original || ''});
                background-repeat: no-repeat;
                background-size: contain;
                background-position: ${p.focusPosition || 'center'};
            ` : ''
}
`;

export const StyledQrCode = styled.div`
    padding: 0.73rem;
    flex-basis: 15%;
    flex-shrink: 0;
`;

export const StyledSliderPdfDescriptionWrapper = styled.div`
    align-self: flex-start;
    padding-block: 0.73rem;
    flex-grow: 1;
`;