import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 29.992 30',
    // width: 2.29
};

export const CrossIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props} >
            <path
                d="M29.184,121.3a2.244,2.244,0,0,1-3.174,3.174L14.961,113.345,3.834,124.472A2.244,2.244,0,0,1,.66,121.3l11.131-11.123L.657,98.964A2.244,2.244,0,0,1,3.831,95.79l11.13,11.215L26.088,95.878a2.244,2.244,0,1,1,3.174,3.174L18.131,110.175Z"
                transform="translate(0 -95.133)"
            />
        </SvgIcon>
    );
};
