import theme from '../../../style/theme';
import { styled } from 'solid-styled-components';

export const StyledCategoryTagListItem = styled.li`
    margin-right: 2rem;
    &:last-child {
        margin-right: 0;
    }
`;

export const StyledCategoryTag = styled.button`
    display: flex;
    align-items: center;
    border: 1px solid ${theme.palette.gray};
    border-radius: ${theme.borderRadius.xsmall};
    padding: 0.2rem 0.5rem;
    padding-bottom: 0.2rem !important;
    margin-block: 0.75rem;
`;

export const StyledIconContainer = styled.div`
    margin-left: 0.5rem;
    margin-bottom: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;