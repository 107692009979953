import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 18 16',
    width: 1.5,
    height: 1.33,
    fill: 'none',
};

export const ListViewStyleIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <rect x="6" y="0.5" width="12" height="3" fill="#918787" />
            <rect x="6" y="6.5" width="12" height="3" fill="#918787" />
            <rect x="6" y="12.5" width="12" height="3" fill="#918787" />
            <rect y="0.5" width="3" height="3" fill="#918787" />
            <rect y="6.5" width="3" height="3" fill="#918787" />
            <rect y="12.5" width="3" height="3" fill="#918787" />
        </SvgIcon>
    );
};
