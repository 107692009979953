// These shouldnt be displayed in the frontend.
// They are added to tons of products in WP, so for now we filter them on the frontend.
export const legacyDocuments = [
    '10007_xtraflange-manual_2022-06-23_web',
    '10022_instruction-for-use-provox-tubeholder_2023-08-14_web',
    '10025-2-instructions-for-cleaning-and-sterilization_2023-03-27_web',
    '10025-2-instructions-for-cleaning-and-sterilization_2023-08-31_web',
    '10027_activeband-ifu_2020-02-04_web',
    '10027_activeband-ifu_2020-02-04_web-2',
    '10027_activeband-ifu_2023-12-12_web',
    '10029_provox-micron-hme-manual_2023-07-14_web',
    '10055_provox-flush_2022-04-28_web',
    '10105-tracoe-paraffin-oil-ifu-v3-2015',
    '10169-tracoe-comfort-ifu-v12-2020',
    '10173_provox-xtrahme-manual_2021-10-21_web',
    '10173_provox-xtrahme-manual_2024-03-25_web',
    '10217-tracoe-kids-mini-ifu-v7-2022',
    '10267_provox-adhesives-ifu_2021-10-06_web',
    '10267_provox-adhesives-ifu_2024-05-07_web',
    '10267-lv_provox-adhesives-ifu_2021-10-06_web',
    '10267es_provox-adhesive-ifu-spanish_2022-04-19_web',
    '10300-tracoe-phon-assist-i-ifu-v11-2022',
    '10315_provox-guidewire-manual_2022-03-01_web',
    '10386_provox-life-larybutton-ifu_2021-10-11_web',
    '10386_provox-life-larybutton-ifu_2024-03-05_web',
    '10386_provox-life-larybutton-ifu_2024-07-08_web',
    '10456-tracoe-connection-tube-ifu-v9-2018',
    '10511-tracoe-kids-extension-piece-ifu-v6-2023',
    '10647_ifu-illustrations-vega-puncture-set_2022-03-08_web',
    '10647_ifu-illustrations-vega-puncture-set_2023-01-19_web',
    '10648_activalve-patient_s-ifu_2020-12-04_web',
    '10660_ifu-provox-activalve-clinicians_2020-12-04_web',
    '10721_ifu-dualcare_2020-01-22_web-_3_',
    '10721_ifu-dualcare_2022-06-15_web',
    '10810_freevent-xtracare-xtracare-mini-ifu_2022-03-09_web',
    '10810_freevent-xtracare-xtracare-mini-ifu_2023-01-25_web',
    '10830_o2-adapter-ifu_2020-10-08_original',
    '10842_provox-capsule-ifu_2017-06-26_web',
    '10877_clin-ifu-provox-2_201509a_web',
    '10877_clin-ifu-provox-2_201509a_web-2',
    '10878_pat-ifu-provox2_201509a_web',
    '10878_pat-ifu-provox2_201509a_web-2',
    '10881_-ifu-provox-vega-puncture-set_2022-03-10_web',
    '10881_-ifu-provox-vega-puncture-set_2022-03-10_web-1',
    '10881_-ifu-provox-vega-puncture-set_2022-03-10_web-2',
    '10881_-ifu-provox-vega-puncture-set_2023-01-19_web',
    '10893_coming-home_ifu_2021-10-13_web',
    '10984_ifu-skinbarrier_2023-03-15_web',
    '10985_ifu-adhesive-remover_2023-03-15_web',
    '11014_ifu-luna_2019-12-09_web',
    '11014_ifu-luna_2023-07-26_web',
    '11020_provox-luna-showeraid-ifu_2021-10-19_web',
    '11020_provox-luna-showeraid-ifu_2023-06-14_web',
    '11032_provox-fh-stomasupport-ifu_2019-11-22_web',
    '11032_provox-fh-stomasupport-ifu_2023-06-13_web',
    '11086_clin-ifu-provox-vega_2020-02-06_web',
    '11086_clin-ifu-provox-vega_2020-05-14_web',
    '11087_pat-ifu-provox-vega_2022-02-08_web',
    '11088_vega-xtraseal-clinician-ifu_2020-10-07_web',
    '11094_coming-home-ifu-em_2020-10-07_web',
    '11299_coming-home_ifu_2020-10-07_web',
    '11442_provox-life-shower-ifu_2021-12-13_web',
    '11442_provox-life-shower-ifu_2023-06-15_web',
    '11466_lv-vega-xtraseal-clinician-ifu-lv_2020-09-14_web',
    '11466_vega-xtraseal-clinician-ifu_2020-09-14_web',
    '11468_clin-ifu-provox-vega_2022-03-10_web',
    '11471_pat-ifu-provox-vega_2022-03-10_web',
    '11514_ifu-twistlock_2020-03-11_web',
    '11514_ifu-twistlock_2023-06-14_web',
    '11533_freevent-dressing-pad-ifu_2020-02-04_original',
    '11533_freevent-dressing-pad-ifu_2020-02-04_original-1',
    '11534_freevent-dressing-foam-ifu_2020-03-06_web',
    '11534_freevent-dressing-foam-ifu_2023-05-16_web',
    '11535_ifu-dressing_2021-11-09_web-1',
    '11535_ifu-dressing_2023-02-28_web',
    '11545_provox-life-freehands_2023-10-05_web',
    '11545_provox-life-freehands_2024-03-05_web',
    '11554_provox-tracheofix-ifu_2023-06-12_web',
    '11554_tracheofix-foam-cover-ifu_2020-03-11_web',
    '11604_freevent-neckband-ifu_2020-01-14_web-_2_',
    '11604_freevent-neckband-ifu_2023-05-17_web',
    '11614_provox-xtrahme-manual_2024-03-25_web',
    '11621_provox-life-adhesive-ifu_2022-01-26_web',
    '11621_provox-life-adhesives-_-hmes-ifu_2022-08-23_web',
    '11621_provox-life-adhesives-hmes-ifu_2023-07-12_web',
    '11621_provox-life-adhesives-hmes-ifu_2024-01-02_web',
    '11621_provox-life-adhesives-hmes-ifu_2024-03-05_web',
    '11671_provox-life-larytube-ifu_2021-06-22_web',
    '11671_provox-life-larytube-ifu_2024-03-05_web',
    '11671_provox-life-larytube-ifu_2024-07-08_web',
    '11699_instructions-for-use-provox-brush_2024-02-14_web',
    '11735-ifu-dressing-softfoam_2020-06-09_web',
    '11735-ifu-dressing-softfoam_2024-05-21_web',
    '11747_provox-life-coming-home-ifu_2021-06-22_web',
    '11747_provox-life-coming-home-ifu_2024-03-05_web',
    '11782_stoma-sizing-guide-ifu_2023-01-26_web',
    '11939_provox-life-freehands-hme_2023-08-30_web',
    '12100_ifu-electrolarynx_2022-10-03_web',
    '12298_instructions-for-use-provox-brush_6-pcs_2022-09-12_web',
    '12298_instructions-for-use-provox-brush_6-pcs_2023-04-20_web',
    '12298_instructions-for-use-provox-brush_6-pcs_2023-04-20_web-2',
    '12298_instructions-for-use-provox-brush_6-pcs_2024-02-12_web',
    '801510-tracoe-twist-plus-p-ifu-v4-2022',
    '801511-tracoe-twist-plus-ifu-v4-2022',
    '801514-tracoe-twist-p-ifu-v3-2022',
    '801515-tracoe-twist-ifu-v3-2022',
    '801521-tracoe-smart-cuff-manager-ifu-v3-2022',
    '801526-tracoe-humid-assist-ifu-v2-2021',
    '801530-tracoe-aeris-ifu-v3-2022',
    '801580-tracoe-vario-ifu-v2-2022-2',
    '801581-tracoe-vario-p-ifu-v2-2022-2',
    '801582-tracoe-experc-vario-ifu-v1-2021',
    '801583-tracoe-experc-twist-plus-ifu-v1-2021',
    '801584-tracoe-experc-twist-ifu-v1-2021',
    '801605-tracoe-experc-ifu-v3-2021',
    '801620-tracoe-percutan-seldinger-guide-wire-ifu-v1-2021',
    '802020-tracoe-kids-silcosoft-ifu-v7-2022',
    '90716_instructions-for-use-provox-brush_2022-05-25_wed',
    '90716-instructions-for-use-provox-brush-2023-04-20_web',
    '90716-instructions-for-use-provox-brush-2023-04-20_web-2',
    '90726_instructions-for-use-provox-showeraid_2023-06-12_web',
    '90727_provox_measure_2023-09-21_web',
    '90729_provox-hme-cassette-adaptor_2023-06-12_web',
    '90734_provox-larytube-manual_2024-04-09_web',
    '90749-trachphone-manual_2023-06-26_web',
    '90755_mediflux-manual_2023-06-26_web',
    '90756_provox-silicone-glue_2023-09-01_web',
    '90760-1_therabite-ifu-region-1_2020-01-27_web',
    '90760-1_therabite-ifu-region-1_2023-12-12_web',
    '90760-2_therabite-ifu-region-2_2020-01-26_web',
    '90760-2_therabite-ifu-region-2_2023-12-12_web',
    '90760-el_therabite-ifu-el_2021-09-27_web',
    '90760-tr_therabite-ifu-tr_2021-05-06_web',
    '90761_ifu-therabite-rom-scale_2023-01-11_web',
    '90761_ifu-therabite-rom-scale_2023-12-12_web',
    '90767_baseplate-adaptor-manual_2023-06-08_web',
    '90796_provox-larybutton-ifu_2022-03-14_web',
    '90796_provox-larybutton-ifu_2022-03-14_web-2',
    '90798_provox-laryclip-manual_2020-01-29_web',
    '90798_provox-laryclip-manual_2024-01-30_web',
    '10329-lubricant-manual_2019-09-18_web',
    '90767_baseplate-adaptor-manual_2023-04-05_web',
    '10322_provox-dilator-manual_2020-02-05_web',
    '90754_provox-dilator-manual_-2022-03-22_web',
    '10740_provox-freehands-flexivoice-manual_2020-09-16_web-1',
    '10315_provox-guidewire-manual_2019-12-10_web',
    '90768-provox-hme-cap-manual-2020-03-02_web',
    '90799_provox-larybutton-sizer-kit-manual_2019-09-03_web',
    '90799_provox-larybutton-sizer-kit-manual_2019-09-03_web-2',
    '90798_provox-laryclip-manual_201610a_web-3',
    '90734_provox-larytube-manual_2022-03-14_web-2',
    '90734_provox-larytube-manual_2022-03-14_web',
    '90734-lv_provox-larytube-manual_2022-03-14_web',
    '90734-lv_provox-larytube-manual_2022-03-14_web-2',
    '10141_larytube-sizer-kit-manual_2019-09-03_web',
    '12005_baseplate-adaptor-manual_2021-01-12_web-1',
    '11284_provox-life-hme_2021-04-09_web',
    '10029_provox-micron-hme-manual_2020-10-07_web',
    '10646_provox-nid-manual_2020-02-05_web',
    '10007-lv_xtraflange-manual_2021-06-23_web',
    '10007_xtraflange-manual_2022-03-10_web',
    '10173_provox-xtrahme-manual_2020-09-29_web',
    '10173-lv_provox-xtrahme-manual_2021-10-21_web',
    '10173-lv_provox-xtrahme-manual_2021-10-21_web-2',
    '90749_trachphone_-manual_2020-01-16_web',
    '90749-trachphone-manual_2022-10-28_web',
    '90709_tympovent-manual_2020-05-12_web',
    '90709_tympovent-manual_2020-05-12_web-2',
];

