import { createGlobalStyles } from 'solid-styled-components';
import { palette } from '../style/theme';

export const GlobalStyles = () => {
    const Styles = createGlobalStyles`
        html {
            height: 100%;
            -webkit-font-smoothing: antialiased;
        }

        body {
            position: relative;
            min-height: 100%;
            display: flex;
        }
        #root {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
        html,
        body {
            padding: 0;
            margin: 0;
            background: ${palette.beige};
            color: #262626;
            font-size: 11px;
            font-family: 'Atos Regular', -apple-system, BlinkMacSystemFont, "SF Pro", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            line-height: 1.4;
            box-sizing: border-box;
            @media only screen and (min-width: 1800px) {
                font-size: 12px;
            }
            @media print {
                background: #fff;
            }
        }

        *,
        *:before,
        *:after {
            box-sizing: inherit;
        }

        *:focus-visible {
            outline: 2px solid ${palette.lightBlue};
            outline-offset: 2px;
        }

        h1 {
            font-size: 24px;
        }

        h2 {
            font-size: 21px;
        }

        h3 {
            font-size: 18px;
        }

        h4 {
            font-size: 16px;
        }

        h5, h6 {
            font-size: 14px;
        }

        @page {
            margin: 0 !important;
        }
    `;

    return <Styles />;
};
