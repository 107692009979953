import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 154.8 80',
    width: 6.71,
    height: 4.21,
};

export const ProvoxIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);
    
    return (
        <SvgIcon {...props}>
            <g>
                <g class="st0">
                    <path
                        class="st1"
                        d="M1.2,65.4c0-5.4,4-8.7,9-8.7c2.3,0,4.1,0.7,4.9,1.1V60c-0.9-0.6-2.6-1.3-4.8-1.3c-4.1,0-7.1,2.7-7.1,6.8
			c0,4.1,3,6.9,7.1,6.9c2.2,0,4.1-0.8,4.9-1.3v2c-0.6,0.4-2.5,1.2-4.9,1.2C5.1,74.2,1.2,70.8,1.2,65.4z"
                    />
                    <path
                        class="st1"
                        d="M17.1,68.1c0-3.5,2.9-6.1,6-6.1c3.2,0,6.1,2.6,6.1,6.1c0,3.5-2.9,6.1-6.1,6.1C19.9,74.2,17.1,71.6,17.1,68.1z
			 M27.3,68.1c0-2.5-1.9-4.4-4.2-4.4c-2.2,0-4.1,1.8-4.1,4.4c0,2.5,1.9,4.4,4.1,4.4C25.3,72.5,27.3,70.7,27.3,68.1z"
                    />
                    <path
                        class="st1"
                        d="M32.1,62.2h1.8v2.3c0.5-1.1,1.4-2.5,3.5-2.5c1.8,0,3,1.3,3.5,2.6c0.6-1.3,1.8-2.5,3.9-2.5c2.2,0,4,1.4,4,4.3
			V74h-1.8v-7.2c0-2.1-1.2-3-2.6-3c-2.1,0-3,1.9-3,3.3v7h-1.8v-7c0-2-1.1-3.3-2.7-3.3c-2,0-2.9,2.1-2.9,3.9V74h-1.8V62.2z"
                    />
                    <path
                        class="st1"
                        d="M52,58.4c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3C52.6,59.7,52,59.1,52,58.4z
			 M52.4,62.2h1.8V74h-1.8V62.2z"
                    />
                    <path
                        class="st1"
                        d="M58,62.2h1.8v2.4c0.4-0.9,1.6-2.6,3.8-2.6c2.6,0,4.3,1.6,4.3,4.6V74H66v-7.1c0-2.3-1.4-3.2-2.9-3.2
			c-2,0-3.3,1.9-3.3,4.1V74H58V62.2z"
                    />
                    <path
                        class="st1"
                        d="M72.1,78.3v-1.8c0.5,0.3,2.2,1.2,4.2,1.2c2.4,0,4.2-1.8,4.2-3.7v-2.2c-0.6,1-1.9,2.4-4.2,2.4
			c-3.1,0-5.6-2.6-5.6-6.1c0-3.5,2.5-6,5.6-6c2.4,0,3.8,1.6,4.2,2.4v-2.2h1.8V74c0,2.7-2.4,5.3-6,5.3C74.3,79.3,72.6,78.5,72.1,78.3
			z M80.6,68c0-2.4-1.6-4.3-4.1-4.3c-2,0-4,1.7-4,4.3c0,2.5,1.8,4.5,4,4.5C78.8,72.5,80.6,70.6,80.6,68z"
                    />
                    <path class="st1" d="M105.4,57v17h-1.9v-7.8h-8.9V74h-1.9V57h1.9v7.4h8.9V57H105.4z" />
                    <path
                        class="st1"
                        d="M108.6,68.1c0-3.5,2.9-6.1,6-6.1c3.2,0,6.1,2.6,6.1,6.1c0,3.5-2.9,6.1-6.1,6.1
			C111.5,74.2,108.6,71.6,108.6,68.1z M118.8,68.1c0-2.5-1.9-4.4-4.2-4.4c-2.2,0-4.1,1.8-4.1,4.4c0,2.5,1.9,4.4,4.1,4.4
			C116.9,72.5,118.8,70.7,118.8,68.1z"
                    />
                    <path
                        class="st1"
                        d="M123.6,62.2h1.8v2.3c0.5-1.1,1.4-2.5,3.5-2.5c1.8,0,3,1.3,3.5,2.6c0.6-1.3,1.8-2.5,3.9-2.5c2.2,0,4,1.4,4,4.3
			V74h-1.8v-7.2c0-2.1-1.2-3-2.6-3c-2.1,0-3,1.9-3,3.3v7h-1.8v-7c0-2-1.1-3.3-2.7-3.3c-2,0-2.9,2.1-2.9,3.9V74h-1.8V62.2z"
                    />
                    <path
                        class="st1"
                        d="M143.1,68c0-3.5,2.5-6,5.6-6c2.9,0,5.3,2.3,5.3,5.7c0,0.3,0,0.7,0,1h-9c0.3,2.4,2.2,3.9,4.6,3.9
			c1.8,0,3.3-0.7,3.6-1v1.8c-0.3,0.2-1.6,0.8-3.6,0.8C145.7,74.2,143.1,71.6,143.1,68z M152.2,67.3c-0.1-2.3-1.6-3.7-3.6-3.7
			c-2.1,0-3.5,1.5-3.7,3.7H152.2z"
                    />
                </g>
                <g>
                    <g>
                        <path
                            class="st1"
                            d="M139.5,14.4h-0.3v1.8h-0.8v-4.5h1.6c0.9,0,1.7,0.4,1.7,1.4c0,0.9-0.7,1.3-1.3,1.4l1.5,1.8h-1L139.5,14.4
				L139.5,14.4z M139.1,13.7h0.8c0.5,0,0.9-0.2,0.9-0.7s-0.4-0.7-0.9-0.7h-0.8V13.7z"
                        />
                        <path
                            class="st1"
                            d="M139.8,18.4c-2.4,0-4.4-2-4.4-4.4s2-4.4,4.4-4.4s4.4,2,4.4,4.4S142.2,18.4,139.8,18.4z M139.8,10.3
				c-2,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7S141.9,10.3,139.8,10.3z"
                        />
                    </g>
                    <g>
                        <path
                            class="st2"
                            d="M93.5,9.1h2c2.4-1.6,5.3-2.4,8.4-2.4s5.9,0.8,8.4,2.4h2c-2.9-2.3-6.5-3.6-10.4-3.6
				C99.9,5.5,96.4,6.8,93.5,9.1L93.5,9.1z"
                        />
                        <path
                            class="st2"
                            d="M83.5,18.3c1.9-9.5,10.3-16.7,20.4-16.7c9.1,0,16.9,5.7,19.7,13.9l0.8-1.3C121.2,6,113.2,0.4,103.9,0.4
				c-12.1,0-22,9.9-22,22c0,0.3,0,0.6,0,0.8L83.5,18.3L83.5,18.3z"
                        />
                        <path
                            class="st2"
                            d="M103.9,39.3c3.7,0,7.3-1.3,10.1-3.4h-2.2c-2.3,1.3-5.1,2.2-7.9,2.2c-6.5,0-12.3-4.1-14.6-9.8l-0.6,1.5
				C91.4,35.4,97.3,39.3,103.9,39.3L103.9,39.3z"
                        />
                        <path
                            class="st2"
                            d="M123.6,29.8c-2.9,8-10.7,13.4-19.7,13.4c-7,0-13.3-3.6-17.1-8.8l-0.5,1.3c4,5.2,10.5,8.7,17.6,8.7
				c9.2,0,17.2-5.4,20.5-13.4L123.6,29.8L123.6,29.8z"
                        />
                    </g>
                    <g>
                        <path
                            class="st1"
                            d="M28.6,18.8c0,4.4-3.2,7.5-7.8,7.5h-5.1v7.5h-4.5V11.2h9.6C25.6,11.2,28.6,14.1,28.6,18.8z M20.1,15.4h-4.3
				v6.9h4.4c2.4,0,3.6-1.5,3.7-3.4C23.9,16.6,22.6,15.4,20.1,15.4L20.1,15.4z"
                        />
                        <path
                            class="st1"
                            d="M36.3,25.4h-1.1v8.3h-4.5V11.2H39c4.6,0,8.4,2.1,8.4,7c0,4.5-3.4,6.6-6,7.1l7.1,8.5h-5.8L36.3,25.4
				L36.3,25.4z M35.2,15.3v6.1h3.6c2.3,0,3.8-0.9,3.8-3.2s-2-2.9-4.1-2.9L35.2,15.3L35.2,15.3z"
                        />
                        <path
                            class="st1"
                            d="M60.6,10.9c6.8,0,11.9,5,11.9,11.4s-5,11.8-11.9,11.8s-11.9-5.2-11.9-11.8S53.9,10.9,60.6,10.9z M60.6,29.8
				c4.1,0,7.2-3.5,7.2-7.5s-3.3-7.2-7.2-7.2s-7.2,3.2-7.2,7.2S56.8,29.8,60.6,29.8z"
                        />
                        <path class="st1" d="M76.2,11.2l5.9,18.2l6-18.2h5l-8.4,22.6h-5.1l-8.4-22.6C71.3,11.2,76.2,11.2,76.2,11.2z" />
                        <path
                            class="st1"
                            d="M103.8,10.9c6.8,0,11.9,5,11.9,11.4s-5,11.8-11.9,11.8s-11.9-5.2-11.9-11.8S97.1,10.9,103.8,10.9z
				 M103.8,29.8c4.1,0,7.2-3.5,7.2-7.5s-3.3-7.2-7.2-7.2c-3.9,0-7.2,3.2-7.2,7.2S99.9,29.8,103.8,29.8z"
                        />
                        <path
                            class="st1"
                            d="M113.8,11.2h5.1l4.9,8l4.9-8h5.1l-7.3,11.3l7.6,11.3h-5.3l-5.3-7.8l-4.7,7.8h-5.2l7.3-11.3L113.8,11.2
				L113.8,11.2z"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};
