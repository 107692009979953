export type LinkType = {
    ID: number;
    url: string;
    title: string;
    target: string;
    children?: LinkType[];
}

type FocusCoordinates = {
    y: number;
    x: number;
}

export type ImageWordpress = {
    __isimage: true;
    url: string;
    alt?: string;
    altText?: string;
    title?: string;
    width: number;
    height: number;
    focus: null | FocusCoordinates;
    ratio: number;
    jwt?: any;
    name?: string;
    sign?: any;
}

export type ImageWordpressBlock = {
    image: ImageWordpress;
    id: number;
    aspectRatio: 1 | 'original' | 'wide' | 'portrait' | 'standard';
    roundCorners: boolean;
    blockSpacing: boolean;
    tag: 'img';
    imageText: null | string;
    altText?: string;
    forPdfBuilder?: boolean;
}

export type Video = {
    title: string;
    url: string;
}

export type SiteType = 'default' | 'atos-care';

export type User = {
    email: string;
    firstName: string;
}

export enum PageCacheType {
    none = 'none',
    defaultCache = 'default-cache',
};

export type Block = {
    blockName: string;
    isLayoutSection: boolean;
    blocks: Block[];
    image?: ImageWordpressBlock;
    combineWithBelow?: boolean; // Just on section blocks
};
