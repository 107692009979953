import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

const customCss = `
    width: 2.5rem;
    height: 2.5rem;
`;

export const TwitterIconPlain = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props} customCss={customCss}>
            <path
                d="M26.786,32H3.214A3.215,3.215,0,0,0,0,35.214V58.786A3.215,3.215,0,0,0,3.214,62H26.786A3.215,3.215,0,0,0,30,58.786V35.214A3.215,3.215,0,0,0,26.786,32ZM23.511,42.634c.013.188.013.382.013.569a12.41,12.41,0,0,1-12.5,12.5A12.438,12.438,0,0,1,4.286,53.73a9.253,9.253,0,0,0,1.058.054,8.805,8.805,0,0,0,5.451-1.875,4.4,4.4,0,0,1-4.1-3.047,4.733,4.733,0,0,0,1.982-.08,4.392,4.392,0,0,1-3.516-4.312v-.054a4.389,4.389,0,0,0,1.982.556,4.383,4.383,0,0,1-1.955-3.656,4.337,4.337,0,0,1,.6-2.217,12.47,12.47,0,0,0,9.054,4.594,4.4,4.4,0,0,1,7.493-4.011,8.606,8.606,0,0,0,2.786-1.058,4.38,4.38,0,0,1-1.929,2.417,8.74,8.74,0,0,0,2.531-.683A9.244,9.244,0,0,1,23.511,42.634Z"
                transform="translate(0 -32)"
            />
        </SvgIcon>
    );
};
