import { styled } from 'solid-styled-components';

const getRelativeValueString = (val: number) => {
    if (val === 0) {
        return '0';
    } else {
        return `${val}px`;
    }
};
export const StyledRelativeContainer = styled.div<StyledRelativeContainerProps>`
    position: relative;
    ${(p) => (p.top ? `top: ${getRelativeValueString(p.top)};` : '')}
    ${(p) => (p.right ? `right: ${getRelativeValueString(p.right)};` : '')}
    ${(p) => (p.bottom ? `bottom: ${getRelativeValueString(p.bottom)};` : '')}
    ${(p) => (p.left ? `left: ${getRelativeValueString(p.left)};` : '')}
`;
type StyledRelativeContainerProps = {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
};
