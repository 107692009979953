import { OverlayType } from '../../grid-system/section/section-types';
import theme from '../../style/theme';
import { containerQueries } from '../../tools/check-container-support';

export const overlayStyle = (overlayType: OverlayType | undefined) => {
    const style = `
        &::before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.45;
            background-color: ${theme.palette.beige};            
        }
    `;
    
    switch (overlayType) {
        case undefined:
            return '';
        case 'off':
            return '';
        case 'on':
            return style;
        case 'auto':
            return `
                ${containerQueries('tabletMaxOriginal')} {
                    ${style}
                }
            `;
        default:
            return `
                ${containerQueries('tabletMaxOriginal')} {
                    ${style}
                }
            `;
    }
};
