import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 29.992 27',
    // width: 29.992
};

export const ArrowUpIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M14.3,32.653a2.146,2.146,0,0,0-3.034,0L.553,43.368A2.145,2.145,0,0,0,3.586,46.4l7.058-7.065V59.882a2.143,2.143,0,0,0,4.286,0V39.336l7.058,7.058a2.145,2.145,0,1,0,3.034-3.034L14.308,32.646Z"
                transform="translate(0.075 -32.025)"

            />
        </SvgIcon>
    );
};
