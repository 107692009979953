import theme from '../../../style/theme';
import { styled } from 'solid-styled-components';
import { inputStyle } from '../common.style';

export const StyledInput = styled.input`
    ${inputStyle}

    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    &:checked {
        border: 1px solid ${theme.palette.red};
        background-color: ${theme.palette.red};
    }
    
    ${theme.mediaQueries.mobileMax} {
        &:checked {
            border: unset;
        }
    }
`;