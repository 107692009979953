import { createSignal, For, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { Slider } from '../../ui-components/slider/slider';
import { CustomCard } from './slider-card/slider-card';
import { Grid } from '../../grid-system/grid/grid';
import { StyledCardSliderContainer } from './card-slider.styles';
import { CardTypeFactory, PostTypeSlug, PostTypeType } from './card-type-factory';
import { ImageWordpress, LinkType } from '../../types/shared';
import { CustomVideoSliderCard } from './slider-video-card/custom-slider-video-card';
import { VideoModal } from '../video-modal/video-modal';
import { VideoData } from '../videos/videos-types';


export type CustomCardType = {
    heading?: string;
    text?: string;
    image?: ImageWordpress;
    link?: LinkType;
    customizeVideo?: boolean;
    type: 'custom' | PostTypeSlug;
    selectedPostSlug: string;
    video?: VideoData;
}

export type CardSliderProps = {
    cards: CustomCardType[];
    cardsPerView: number;
    dynamicCards: boolean;
    posttype: PostTypeType;
    tag?: {
        slug: string;
        name: string;
        taxonomy: string;
        parent: number;
    };
}

export const CardSlider = (props: CardSliderProps) => {    
    const {viewport} = useContext(AppContext);

    const [ showVideosModal, setShowVideosModal ] = createSignal(false);
    const [ initialVideoSlug, setInitialVideoSlug ] = createSignal('');

    const findInitialVideoIndex = () => {
        const video = customVideoCards().find((card) => card.selectedPostSlug === initialVideoSlug());
        return video ? customVideoCards().indexOf(video) : 0;
    };
        
    const customVideoCards = () => {
        return props.cards.filter((card) => card.type === 'video-item' && card.customizeVideo);
    };

    const createCards = () => {
        return <CardTypeFactory dynamicCards={true} posttype={props.posttype} tag={props.tag} insideSlider={true} />;
    };

    const createSingleCard = (type: PostTypeSlug, postSlug: string) => {
        return (
            <CardTypeFactory
                dynamicCards={false}
                posttype={{
                    label: '',
                    value: type,
                }}
                postSlug={postSlug}
            />
        );
    };
    
    const createSliderCards = () => {
        let perView = props.cardsPerView;
        let gapSize = theme.grid.gapSize;

        switch (true) {
            case viewport.width <= theme.breakpoints.DESKTOP_LARGE && viewport.width > theme.breakpoints.TABLET_SMALL + 100:
                gapSize = theme.grid.gapSizeTablet / 2;
                perView = perView > 1 ? 2 : 1;
                break;
            case viewport.width <= theme.breakpoints.TABLET_SMALL + 100:
                perView = 1;
                break;
        }

        if (props.dynamicCards && props.posttype?.value) {
            const cards = createCards();
            return (
                <Slider slidesPerView={perView} spacing={gapSize} slideHeight={'auto'}>
                    {cards}
                </Slider>
            );
        }

        return (
            <Slider slidesPerView={perView} spacing={gapSize} slideHeight={'auto'}>
                <For each={props.cards as CustomCardType[]}>
                    {(card: CustomCardType) => {
                        if (card.type === 'custom') {
                            return <CustomCard card={card} />;
                        }

                        if (card.type === 'video-item' && card.customizeVideo) {                            
                            return (
                                <CustomVideoSliderCard
                                    card={card}
                                    onClick={() => {
                                        setInitialVideoSlug(card.selectedPostSlug);
                                        setShowVideosModal(true);
                                    }}
                                />
                            );
                        }
                        
                        return createSingleCard(card.type, card.selectedPostSlug);
                    }}
                </For>
            </Slider>
        );

    };

    return (
        <StyledCardSliderContainer>
            <Grid templateShorthand={[ 12 ]}>
                <div>{createSliderCards()}</div>
            </Grid>
            <Show when={showVideosModal()}>
                <VideoModal
                    show={true}
                    closeModal={() => setShowVideosModal(false)}
                    videos={customVideoCards()}
                    initialVideoIndex={findInitialVideoIndex()}
                />
            </Show>
        </StyledCardSliderContainer>
    );
};


CardSlider.parseProps = (atts: any) => {
    return {
        dynamicCards: atts.dynamicCards,
        cards: atts.cards,
        cardsPerView: atts.cardsPerView,
        posttype: atts.posttype,
        tag: atts.tag,
    };
};
