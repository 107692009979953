import { EventData, Tag } from '../../event/event-types';

export const getUniqueTags = (events: EventData[]) => {
    const uniqueTags: Tag[] = [];

    events?.forEach((event) => {
        event?.wordpressTags && event?.wordpressTags.forEach((tag) => {
            const existingTag = uniqueTags.find((t) => t.slug === tag.slug);
            if (!existingTag) {
                uniqueTags.push(tag);
            }
        });
    });

    return uniqueTags;
};
