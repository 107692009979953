import { For, Show, useContext } from 'solid-js';
import { Text } from '../../../../ui-components/text/text';
import { Button } from '../../../../ui-components/button/button';
import { Heading } from '../../../../ui-components/heading/heading';
import { EntitySlug, pdfStore, setPdfStore } from '../../pdf-store';
import { StyledFlexRow } from '../../../../ui-components/utility-style-components/flex';
import { StyledVerticalSpace } from '../../../../ui-components/utility-style-components/spacing';
import { StyledPostItem, StyledRemoveButton, StyledTextContainer } from './arrangement.style';
import { AppContext } from '../../../../app-context-provider/app-context-provider';

export const Arrangement = () => {
    const { localize } = useContext(AppContext);

    const dragStart = (e: DragEvent) => {
        const target = e.target as HTMLElement;
        const id = target?.dataset.id;
        if (!id) return;
        e.dataTransfer?.setData('text/plain', id);
    };

    const dragOver = (e: DragEvent) => e.preventDefault();

    const drop = (e: DragEvent) => {
        e.preventDefault();

        const draggedItemId = e.dataTransfer?.getData('text/plain');
        const target = e.target as HTMLElement;
        const nearestElement: HTMLElement | null = target.closest('[data-id]');

        if (!nearestElement) return;

        const droppedOntoItemId = nearestElement.dataset.id;
        const workingCollectionPostsCopy = [ ...pdfStore.workingCollectionPosts ];
        const oldIndex = workingCollectionPostsCopy.findIndex((item) => item.objectID === draggedItemId);
        const item = workingCollectionPostsCopy.splice(oldIndex, 1)[0];

        let newIndex = workingCollectionPostsCopy.findIndex((item) => item.objectID === droppedOntoItemId);
        if (newIndex === workingCollectionPostsCopy.length - 1) {
            newIndex++;
        }

        workingCollectionPostsCopy.splice(newIndex, 0, item);
        setPdfStore('workingCollectionPosts', workingCollectionPostsCopy);
    };

    const clearWorkingCollection = () => {
        setPdfStore((state) => ({
            'undo': [ ...state.workingCollectionPosts ],
            'workingCollectionPosts': []
        }));
    };

    const removeItem = (id: string) => {
        const collection = [ ...pdfStore.workingCollectionPosts ];
        const index = collection.findIndex((item) => item.objectID === id);
        collection.splice(index, 1);
        setPdfStore('workingCollectionPosts', collection);
    };

    return (
        <>
            <div>
                <StyledVerticalSpace size={ 3 } />
                <StyledFlexRow justifyContent='end'>
                    <Show when={ pdfStore.workingCollectionPosts.length }>
                        <Button
                            label={localize('clear-all', 'Clear All')}
                            variant='tertiary'
                            onClick={ () => clearWorkingCollection() }
                            customCss='margin-right: 1.5rem;'
                        />
                        <Button
                            label={localize('pdf-builder-create', 'Create')}
                            onClick={ () => setPdfStore('status', 'naming') }
                        />
                    </Show>
                    <Show when={ pdfStore.undo.length }>
                        <Button
                            label={localize('undo', 'Undo')}
                            variant='secondary'
                            onClick={ () => {
                                setPdfStore('workingCollectionPosts', pdfStore.undo);
                                setPdfStore('undo', []);
                            } }
                        />
                    </Show>
                </StyledFlexRow>
            </div>

            <StyledFlexRow justifyContent='space-between'>
                <Heading tag="h4" variant='small'>
                    {localize('pdf-arrangement', 'Arrangement')}
                </Heading>
                <Show when={ pdfStore.workingCollectionPosts.length > 1 } fallback={<StyledVerticalSpace size={ 3 } />}>
                    <Text fontSize='small' noBlockSpacing={ true } fontStyle='italic' color='gray'>
                        {localize('pdf-drag-and-drop-to-reorder', 'Drag and drop to reorder')}
                    </Text>
                </Show>
            </StyledFlexRow>

            <Show when={ !pdfStore.workingCollectionPosts.length }>
                <Text fontSize='small' fontStyle='italic'>
                    {localize('pdf-arrangement-explainer', 'Add posts to your PDF by searching for them and clicking on them')}
                </Text>
            </Show>

            <Show when={ pdfStore.workingCollectionPosts.length }>
                <div
                    onDragStart={ (e) => dragStart(e) }
                    onDragOver={ (e) => dragOver(e) }
                    onDrop={ (e) => drop(e) }
                >
                    <For each={ pdfStore.workingCollectionPosts }>{(post) => (
                        <StyledPostItem
                            draggable
                            data-id={ post.objectID }
                        >
                            <StyledTextContainer>
                                <Heading tag='h3' variant='small' noBlockSpacing={ true }>{pdfStore.entityNameMap[post?.type as EntitySlug]}</Heading>
                                <Text fontSize='normal'>{post.postTitle}</Text>
                            </StyledTextContainer>
                            <StyledRemoveButton>
                                <Button label={localize('remove', 'Remove')} variant='tertiary' onClick={ () => removeItem(post.objectID) } />
                            </StyledRemoveButton>
                        </StyledPostItem>
                    )}</For>
                </div>
            </Show>
        </>
    );
};