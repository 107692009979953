/* eslint-disable indent */

import { styled } from 'solid-styled-components';
import theme, { palette } from '../../style/theme';
import { overlayStyle } from '../../ui-components/layouts/background-image-overlay';
import { HeroComponentStyleProps, MobileImageContainerProps } from './hero-types';
import { containerQueries } from '../../tools/check-container-support';

export const StyledHeroWrapper = styled.div<HeroComponentStyleProps | undefined>`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;

    ${(p) =>
        p.backgroundImageSrcData && p.backgroundImageSrcData.original !== ''
            ? `
                background-image: url(${p.backgroundImageSrcData.original});
                background-repeat: no-repeat;
                background-size: cover;
                background-position: ${p.focusPosition || 'center'};
                ${overlayStyle(p.overlayType)}
            `
            : ''
    }

    h2 {
        max-width: 57.1rem;
    }

    p {
        margin: 1.43rem 0;
        max-width: 50rem;
        padding-bottom: 0 !important;
    }

    ${theme.mediaQueries.mobileMax} {
        background: none;
        ${p => p.withHeroMenu ? `
            height: auto;
            min-height: 100vh;
        ` : `
            height: 100vh;
            min-height: 65vh;
        `}
    }

    @media only screen and (max-height: 700px) {
        h2 {
            max-width: 70rem;
        }
    }
`;

export const StyledEmptyColumn = styled.div`
    ${containerQueries('mobileMax')} {
        display: none;
    }
`;

export const StyledMenusContainer = styled.div`
    display: flex;
    gap: 2.5rem;
    padding-block: 2.14rem;

    ${theme.mediaQueries.tabletMaxOriginal} {
        flex-direction: column;
    }
`;

export const ExpandableContainerWrapper = styled.div<{ layerPriority: number}>`
    width: 100%;
    min-width: 220px;
    z-index: ${(p) => p.layerPriority};

    ${theme.mediaQueries.desktopLargeMax} {
        max-width: 270px;
        width: 100%;
    }

    ${theme.mediaQueries.tabletMaxOriginal} {
        max-width: 300px;
    }
`;

export const StyledMenuItemsList = styled.ul`
    border-top: 1px solid ${palette.darkGray};
    margin-inline: 0.5rem;
    padding-block: 1.8rem;
`;

export const StyledMenuItem = styled.li`
    padding-block: 0.9rem;
`;

export const StyledInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 1;
    ${theme.mediaQueries.mobileMax} {
        justify-content: flex-start;
    }
`;

export const StyledTextWrapper = styled.div`
    margin-block: 2.4rem;
`;

export const StyledHeadingWrapper = styled.div`
    transform: translateX(-5px);
`;

export const StyledContentWrapper = styled.div<{ isAtosCare?: boolean, withHeroMenu?: boolean }>`
    margin-top: 23vh;
    margin-bottom: 0vh;
    display: block;
    padding-inline: 1.5rem;

    ${containerQueries('mobileMax')} {
        margin-top: 27%;
        ${(p) => p.withHeroMenu ? '' : 'margin-bottom: 10%;'}
    }

    ${(p) =>
        p.isAtosCare ? `
            margin-top: 32vh;

            ${containerQueries('mobileMax')} {
                margin-top: calc(82px + 27%);
            }
        ` : ''}

`;

export const StyledHeadings = styled.div`
    ${theme.mediaQueries.mobileMax} {
        text-align: center;
        margin-bottom: 3rem;
    }
`;

export const StyledLinkWrapper = styled.div`
    padding: 2.14rem 0;
`;

export const StyledScrollDownContainer = styled.div`
    margin: 0 auto;
    margin-top: auto;
    margin-bottom: 3rem;
    ${theme.mediaQueries.mobileMax} {
        display: none;
    }
    svg {
        cursor: pointer;
        width: 2.14rem;
        height: 2.14rem;
        fill: ${palette.white};
    }
    & > button {
        border: none;
        background: transparent;
    }
`;

export const StyledMobileImageContainer = styled.div<MobileImageContainerProps>`
    display: none;

    ${theme.mediaQueries.mobileMax} {
        ${p => p.withHeroMenu ? `
            height: 25rem;
        ` : ''}

        display: flex;
        flex-grow: 1;
        justify-content: center;
        margin-inline: -1.14rem;

        ${(p) =>
            p.backgroundImageSrcData && p.backgroundImageSrcData.original !== ''
                ? `
                background-image: url(${p.backgroundImageSrcData.original});
                background-position: ${p.focusPosition || 'center'};
                background-repeat: no-repeat;
                background-size: cover;
            `
                : ''}
    }

    #learnMoreMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-end;
        margin-bottom: 1rem;
    }
`;

export const StyledIconWrapper = styled.div`
    margin-top: 0.7rem;
`;

export const StyledSwirlMobile = styled.div`
    position: absolute;
    width: 100%;
    bottom: -8rem;
    pointer-events: none;
    & svg {
        width: 100%;
    }
`;

export const StyledSwirlDesktop = styled.div`
    position: absolute;
    width: 100%;
    bottom: calc(0px - 0.3 * 100vw / 2);
    pointer-events: none;
`;

export const StyledHeroAndSwirlContainer = styled.div`
    position: relative;
`;

export const StyledScrollDownButton = styled.button`
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
`;