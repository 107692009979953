import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';

export const StyledSecondaryMenu = styled.ul`
    display: flex;
    flex-direction: column;

    & > *:last-child {
        margin-bottom: 4rem;
    }

    li {
        padding-bottom: unset;
    }

    a {
        font-size: 1.6rem;
        padding-block: 1rem;
        padding-left: 1rem;
        display: block;

        span {
            padding-left: unset;
        }
    }
`;

export const StyledSecondLevelItems = styled.ul`
    border-top: 1px solid ${theme.palette.red};
`;

export const StyledSecondLevelItem = styled.li`
    border-bottom: 1px solid ${theme.palette.red};
    a {
        padding-left: 2rem;
    }
`;

export const StyledThirdLevelItems = styled.ul`
    border-top: 1px solid ${theme.palette.red};

    li + li {
        border-top: 1px solid ${theme.palette.red};
    }
`;

export const StyledThirdLevelItem = styled.li`
    a {
        padding-left: 3rem;
        font-size: 1.3rem;
        display: block;
    }
`;