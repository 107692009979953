import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { Show, mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const MagnifyingGlassIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <Show when={props.altText}>
                <title>{props.altText}</title>
            </Show>
            <path
                d="M29.537,27.6l-7.852-7.852a11.824,11.824,0,0,0,2.637-7.559,12.211,12.211,0,1,0-4.629,9.548l7.852,7.852A1.62,1.62,0,0,0,28.594,30a1.406,1.406,0,0,0,.994-.412A1.37,1.37,0,0,0,29.537,27.6ZM2.812,12.188a9.375,9.375,0,1,1,9.375,9.375A9.386,9.386,0,0,1,2.812,12.188Z"
                transform="translate(0)"
            />
        </SvgIcon>
    );
};