import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../style/theme';

export const StyledLoginForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
    margin-top: 4rem;
    ${theme.mediaQueries.mobileMax} {
        height: 100%;
        padding: 0 1rem;
    }
`;

export const StyledLoginFormContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;

    ${theme.mediaQueries.mobileMax} {
        width: 100%;
    }
`;

export const StyledLoginInput = styled.input`
    padding: 1rem;
    font-size: 20px;
    border: none;
    border-bottom: 1px solid gray;
    font-family: ${fontFamilyAtosRegular};
    background: transparent;
    width: 100%;
    margin-bottom: 2rem;
    
    &:focus-visible {
        outline: none;
    }
    `;
    
export const StyledErrorMessage = styled.div`
    color: ${theme.colors.text.red};
    font-size: 16px;
    font-family: ${fontFamilyAtosRegular};
    `;
    
export const StyledSuccessMessage = styled.div`
    color: ${theme.colors.text.paragraph};
    font-size: 16px;
    font-family: ${fontFamilyAtosRegular};
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: center;
    flex-direction: column;
    margin-block: 1rem;
`;
