import { gql } from 'graphql-request';
import { createSignal, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Section } from '../../grid-system/section/section';
import { Button } from '../../ui-components/button/button';
import { StyledErrorMessage, StyledLoginInput, StyledSuccessMessage } from '../login-form/login-form-styles';
import { StyledResetPassword } from '../password-reset/password-reset.style';
import { Heading } from '../../ui-components/heading/heading';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';

export const requestPasswordResetMutation = gql`
    mutation requestPasswordReset(
        $email: String!,
        $clientOrigin: String!,
        $resetPasswordUrl: String!
    ) {
        requestPasswordReset(
            email: $email
            clientOrigin: $clientOrigin
            resetPasswordUrl: $resetPasswordUrl
        ) {
            status
            message
        }
    }
`;

export const careRequestPasswordResetMutation = gql`
    mutation careRequestPasswordReset(
        $email: String!,
        $clientOrigin: String!
        $resetPasswordUrl: String!
        $site: String!
    ) {
        careRequestPasswordReset(
            email: $email
            clientOrigin: $clientOrigin
            resetPasswordUrl: $resetPasswordUrl
            site: $site
        ) {
            status
            message
        }
    }
`;

export const RequestPasswordReset = () => {
    const { graphQlClient, localize, pageResetPasswordUrl, siteInfo } = useContext(AppContext);
    const [email,setEmail] = createSignal('');
    const [error,setError] = createSignal('');
    const [message, setMessage] = createSignal('');
    const [loading, setLoading] = createSignal(false);

    const submitResetPasswordForm = async () => {
        setLoading(true);

        if(!email()) {
            setError(`${localize('email' ,'Email')} ${localize('is-required' ,'Is required')}`);
            return;
        }
        
        const isCareSite = siteInfo.siteType === 'atos-care';        
        const query = isCareSite ? careRequestPasswordResetMutation : requestPasswordResetMutation;
        const origin = window.location.origin;

        const vars = {
            email: email(),
            resetPasswordUrl: pageResetPasswordUrl,
            clientOrigin: origin,
            site: siteInfo.siteSlug
        };

        const response = await graphQlClient(query, vars, false);
        const { status, message } = isCareSite ? response.careRequestPasswordReset : response.requestPasswordReset;

        setLoading(false);

        if (status !== 'OK') {
            console.log('error', message);
            setError(localize('error-has-occurred', 'An error has occurred, please try again later'));
            return;
        }

        setMessage(localize('password-reset-request-success-message', 'Password reset request sent. Check your email for further instructions'));
    };

    return (
        <Section widthType="bgFull" templateShorthand={[12]}>
            <StyledResetPassword>
                <Heading tag='h1' >{localize('request-password-reset' ,'Request password reset')}</Heading>
                <StyledLoginInput
                    value={email()}
                    onFocus={() => setError('')}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    placeholder={localize('email' ,'Email')}
                />
                <Show when={error()} fallback={<div style={{ height: '22px' }}></div>}>
                    <StyledErrorMessage>{error()}</StyledErrorMessage>
                </Show>
                <Show when={message()}>
                    <StyledSuccessMessage>{message()}</StyledSuccessMessage>
                </Show>
                <Show when={!message() && !error()}>
                    <StyledFlexRow gap={'1.5rem'}>
                        <Button
                            label={localize('submit-request-password-reset-form-button-text', 'Submit request')}
                            onClick={() => submitResetPasswordForm()}
                            disabled={loading()}
                        />
                        <Show when={loading()}>
                            <LoadingSpinner />
                        </Show>
                    </StyledFlexRow>
                </Show>
            </StyledResetPassword>
        </Section>
    );
};
