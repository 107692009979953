import { gql } from 'graphql-request';
import { For, Show, createEffect, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../../../app-context-provider/app-context-provider';
import { Button } from '../../../../ui-components/button/button';
import { Text } from '../../../../ui-components/text/text';
import { StyledFlexRow } from '../../../../ui-components/utility-style-components/flex';
import { StyledVerticalSpace } from '../../../../ui-components/utility-style-components/spacing';
import { Collection, pdfStore, setPdfStore } from '../../pdf-store';
import { StyledListItemForPdf, StyledLoadingSpinnerContainer, StyledOutput, StyledCollection } from './collection-single.style';
import theme from '../../../../style/theme';
import { LoadingSpinner } from '../../../loading-spinner/loading-spinner';
import { fetchPosts } from '../../pdf-fetch-posts';
import { incrementUserPdfPrintCount } from '../pdf-newsletter-signup-prompt/newsletter-prompt-checker';
import removeAmpersand from '../../../../tools/remove-ampersand';

const GET_PDF_QUERY = gql`
    query GeneratePdfFile (
        $domain: String!,
        $environment: String!,
        $postKeys: [String],
    ) {
        resource: generatePdfFile(
            domain: $domain, 
            environment: $environment,
            postKeys: $postKeys,
        ) {
            buffer, 
            success,
            message,
            maxConcurrent,
        }
    }
`;

const maxRetryAttempts = 2;

export const CollectionSingle = (props: { collection: Collection}) => {
    const { graphQlClient, deploymentEnvironment, localize, siteInfo } = useContext(AppContext);
    const [ generationRetryCount, setGenerationRetryCount ] = createSignal(0);

    const requestFile = async (collection: Collection, retryCount: number = 0) => {
        try {
            setPdfStore('collections', col => col.title === collection.title, 'downloaded', false);
            setPdfStore('status', 'generating');
            setGenerationRetryCount(retryCount);
            setPdfStore('output', {
                id: collection.title,
                blob: ''
            });
            
            const postKeys = collection.orderedPosts.map((item) => item.key);
            const file = await fetchFile(postKeys, collection);

            if (!file) {
                return;
            }
            
            setPdfStore('output', 'blob', file);
            setPdfStore('collections', col => col.title === collection.title, 'downloaded', true);
            setPdfStore('status', '');

            triggerDownload(collection.title, file);

        } catch (err: any) {
            console.log('PDF file request error: ', err);
            setPdfStore('status', 'error');
            return;
        }
    };

    const fetchFile = async (postKeys: string[], collection: Collection) => {
        try {
            const variables = {
                domain: siteInfo?.currentDomain,
                environment: deploymentEnvironment,
                postKeys,
            };
    
            const response = await graphQlClient(GET_PDF_QUERY, variables, false);
    
            if (!response || !response.resource) {
                throw new Error('No response from server');
            }
    
            const retryAttemptsReached = generationRetryCount() >= maxRetryAttempts;
            
            if (response.resource.maxConcurrent && !retryAttemptsReached) {
                // Retry download twice if the PDF service is busy
                setPdfStore('status', 'retrying-generating');
                const count = generationRetryCount() + 1;
                setGenerationRetryCount(count);
    
                setTimeout(() => {
                    requestFile(collection, count);
                }, 8000);
    
                return;
            }            
    
            if (response.resource.maxConcurrent && retryAttemptsReached) {
                setPdfStore('status', 'server-busy');
                return;
            }
    
            if (!response.resource.success) {                
                throw new Error(response.resource.message);
            }
    
            return response.resource.buffer;
        } catch (err: any) {
            console.log('PDF fetch file error: ', err);
            setPdfStore('status', 'error');
            return;
        }
    };

    const triggerDownload = (title: string, blob: string) => {
        const link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${blob}`;
        link.download = `${title}.pdf`;
        link.click();
    };

    const printPdf = async (collection: Collection) => {
        setPdfStore('renderComplete', false);
        setPdfStore('status', 'printing');
        setPdfStore('title', collection.title);

        const postKeys = collection.orderedPosts.map((item) => item.key);
        let data = JSON.stringify(postKeys);
        
        await fetchPosts({ graphQlClient, data });
        data = '';
    };

    createEffect(() => {
        if (pdfStore.status === 'printing' && pdfStore.renderComplete) {
            setPdfStore('status', '');
            window.print();
        }
    });

    const busy = () => {
        // Disables the download and print buttons while the PDF file generator is busy
        return (
            pdfStore.status === 'generating'
            || pdfStore.status === 'printing'
            || pdfStore.status === 'retrying-generating'
            || pdfStore.status === 'checking-availability'
        );
    };

    return (
        <StyledCollection disabled={ busy() }>
            <Text
                fontSize='normal'
                fontStyle='bold'
                displayRedVerticalLine={ false }
                color={ pdfStore.status === 'generating'
                    ? pdfStore.output.id === props.collection.title ? 'darkestGray' : 'lightGray'
                    : 'darkestGray'
                }
            >
                {`${props.collection.title}.pdf`}
            </Text>

            <For each={ props.collection.orderedPosts }>{(item) => (
                <StyledListItemForPdf>
                    <Text
                        noBlockSpacing={ true }
                        fontSize='small'
                        displayRedVerticalLine={ false }
                        color={ pdfStore.status === 'generating'
                            ? pdfStore.output.id === props.collection.title ? 'darkestGray' : 'lightGray'
                            : 'darkestGray'
                        }
                    >
                        {removeAmpersand(item.postTitle)}
                    </Text>
                </StyledListItemForPdf>
            )}</For>

            <StyledVerticalSpace size={ 1 } />

            <StyledFlexRow justifyContent='end' flexWrap='wrap'>
                <Button
                    label={ props.collection.downloaded
                        ? localize('pdf-download-again', 'Download again')
                        : localize('pdf-download', 'Download')
                    }
                    disabled={ busy() }
                    variant='tertiary'
                    arrow={ true }
                    onClick={ () => {
                        incrementUserPdfPrintCount();
                        requestFile(props.collection);
                    }}
                />
                <Button
                    label={ localize('pdf-print', 'Print') }
                    disabled={ busy() }
                    variant='tertiary'
                    arrow={ true }
                    customCss={ 'margin-left: 1.5rem;' }
                    onClick={ () => {      
                        incrementUserPdfPrintCount();
                        printPdf(props.collection);
                    } }
                />
            </StyledFlexRow>

            <Show when={ pdfStore.status === 'printing' && pdfStore.title === props.collection.title }>
                <StyledOutput withLoadingSpinner={true} >
                    <StyledLoadingSpinnerContainer>
                        <LoadingSpinner />
                    </StyledLoadingSpinnerContainer>

                    <Text fontSize='small'>
                        { localize('pdf-preparing-print', 'Preparing print') }
                    </Text>
                </StyledOutput>
            </Show>

            <Show when={ pdfStore.status === 'generating' && pdfStore.output.id === props.collection.title }>
                <StyledOutput withLoadingSpinner={true} >
                    <Show when={ !pdfStore.output.blob }>
                        <StyledLoadingSpinnerContainer>
                            <LoadingSpinner />
                        </StyledLoadingSpinnerContainer>

                        <Text fontSize='small'>
                            { localize(
                                'pdf-generating-file',
                                'Generating file, download will occur once complete'
                            ) }
                        </Text>
                    </Show>
                </StyledOutput>
            </Show>

            <Show when={ pdfStore.status === 'retrying-generating' }>
                <StyledOutput>
                    <Text fontSize='small'>{
                        `${localize(
                            'pdf-file-generator-busy-retrying',
                            'File generator busy, retrying in a few seconds'
                        )} ${localize(
                            'pdf-file-generator-attempt',
                            'Attempt'
                        )} ${ generationRetryCount() } / ${ maxRetryAttempts }`
                    }</Text>
                </StyledOutput>
            </Show>

            <Show when={ pdfStore.status === 'server-busy' }>
                <StyledOutput bgColor='#F7F7F7'>
                    <Text fontSize='small'>
                        { localize(
                            'pdf-file-generator-at-capacity',
                            'File generator at capacity, please try again later'
                        ) }
                    </Text>
                </StyledOutput>
            </Show>

            <Show when={ pdfStore.status === 'error' }>
                <StyledOutput bgColor={theme.palette.lightPink}>
                    <Text fontSize='small'>
                        {localize(
                            'pdf-download-error',
                            'An error has occurred, please try again later or contact support if the problem persists.'
                        ) }
                    </Text>
                </StyledOutput>
            </Show>
        </StyledCollection>
    );
};
