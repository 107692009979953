import { createRoot, createSignal } from 'solid-js';

function createHeaderStore() {
    const [floatHeader, setFloatHeader] = createSignal(false);

    return {
        floatHeader,
        setFloatHeader,
    };
}

export default createRoot(createHeaderStore);
