import { Match, Switch, mergeProps } from 'solid-js';
import { QuoteClose } from '../../components/icons-library/quote-close';
import { QuoteOpen } from '../../components/icons-library/quote-open';
import { QuoteProps } from './quote.d';
import {
    StyledClosingQuoteSvgContainer,
    StyledOpeningQuoteSvgContainer,
    StyledQuote,
    StyledQuoteContainer, StyledQuotePdf, StyledQuoteWrapper
} from './quote.styles';

const defaultProps = {};

export const Quote = (componentProps: QuoteProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <StyledQuoteWrapper>
            <StyledOpeningQuoteSvgContainer>
                <QuoteOpen width={ props.forPdfBuilder ? 3 : undefined } />
            </StyledOpeningQuoteSvgContainer>

            <StyledQuoteContainer>
                <Switch>
                    <Match when={props.forPdfBuilder}>
                        <StyledQuotePdf innerHTML={props.children} />
                    </Match>
                    <Match when={!props.forPdfBuilder}>
                        <StyledQuote innerHTML={props.children} color={props.color} />
                    </Match>
                </Switch>
            </StyledQuoteContainer>

            <StyledClosingQuoteSvgContainer>
                <QuoteClose width={ props.forPdfBuilder ? 3 : undefined } />
            </StyledClosingQuoteSvgContainer>
        </StyledQuoteWrapper>
    );
};

Quote.parseProps = (atts: any) => {
    return {
        ...atts,
        color: atts.color?.color
    };
};
