import { createSignal } from 'solid-js';
import { getCookie, setCookie } from '../../../../tools/cookie-helpers';
import { pdfStore } from '../../pdf-store';

const [ countClientside, setCountClientside ] = createSignal(0);


export const showNewsletterPrompt = (newsletterSignupPage: string) => {
    const dismissedPrompt = getCookie('dismissed-pdf-builder-newsletter-prompt');
    const countOfViews = getCookie('viewed-pdf-builder-count');

    if (countClientside() === 0 && !countOfViews) {
        return false;
    }

    if (!newsletterSignupPage) {
        return false;
    }

    const count = parseInt(countOfViews);

    if (count < 3) {
        return false;
    }

    return dismissedPrompt !== 'true' && pdfStore.status === '' ? true : false;
};

export const incrementUserPdfPrintCount = () => {
    const dismissedPrompt = getCookie('dismissed-pdf-builder-newsletter-prompt');
    const countOfViews = getCookie('viewed-pdf-builder-count');

    if (dismissedPrompt === 'true') {
        return;
    }

    if (!countOfViews) {
        setCookie('viewed-pdf-builder-count', '1');
        setCountClientside(1);
        return;
    }        

    const count = parseInt(countOfViews);

    if (count >= 3 ) {
        return;
    }

    const newCount = count + 1;
    setCookie('viewed-pdf-builder-count', newCount.toString());
    setCountClientside(newCount);
};