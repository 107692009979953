/* eslint-disable indent */

import { styled } from 'solid-styled-components';
import { GridStyleProps } from './grid.d';
import * as Responsive from './grid.responsive.style';
import { gridBase, gridItems } from '../grid-helpers';

const SIDE_GUTTERS = 3;
const CONTENT_COL_START = SIDE_GUTTERS; // Ends on 15 - the first/last two cols are "margins"

export const StyledGrid = styled.div<GridStyleProps>` 
    ${p => {        
        const gridBaseProps = {
            childSpans: p.childSpans,
            isLayoutGrid: p.isLayoutGrid,
            autoResponsiveness: p.autoResponsiveness,
            inheritParentGrid: p.inheritParentGrid,
            gridColumns: p.gridColumns,
            widthType: p.widthType,
        };

        const gridItemsProps = {
            childSpans: p.childSpans,
            autoResponsiveness: p.autoResponsiveness,
            inheritParentGrid: p.inheritParentGrid,
            responsive: p.responsive,
            childCount: p.childCount,
            start: p.isLayoutGrid ? CONTENT_COL_START : 1,
        };

        const responsiveProps = {
            childSpans: p.childSpans,
            isLayoutGrid: p.isLayoutGrid,
            autoResponsiveness: p.autoResponsiveness,
            inheritParentGrid: p.inheritParentGrid,
            responsive: p.responsive,
            childCount: p.childCount,
            withSidebarMenu: p.withSidebarMenu,
        };
        
        return `
            ${gridBase(gridBaseProps)}
            ${gridItems(gridItemsProps)}
            ${Responsive.desktopCss(responsiveProps)}
            ${Responsive.tabletCss(responsiveProps)}
            ${Responsive.mobileCss(responsiveProps)}
            ${p.customCss ? p.customCss : ''}
        `;
    }}
`;
