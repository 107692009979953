import { Labels, Status } from '../events-types';

export const statusMap: Record<string, Status> = {
    All: 'statusAll',
    Past: 'statusPast',
    Upcoming: 'statusUpcoming',
    'On Demand': 'statusOnDemand',
    Laryngectomy: 'laryngectomy',
    Tracheostomy: 'tracheostomy',
};


export const statusNameToStatusTranslated = (
    statusName: string, 
    localize: (slug: string, defaultValue: string) => string,
    labelsArr?: Labels
) => {
    const labels = {
        ...labelsArr,
        laryngectomy: localize('laryngectomy', 'Laryngectomy'),
        tracheostomy: localize('tracheostomy', 'Tracheostomy'),
        statusOnDemand: localize('on-demand', 'On Demand'),
    };
    
    const statusMapped = statusMap[statusName];
    
    return labels?.[statusMapped] || statusName;
};
