import { Navigator } from '@solidjs/router';
import {
    TopCategory,
    changeSelectedEventCategory,
    changeSelectedEventLanguage,
    changeSelectedEventTag,
    eventsStore,
    resetCategoriesAndTags,
    setStatus,
    setAreaOfCare,
} from '../../../stores/events-store';
import { Tag } from '../../event/event-types';

const createClickHelper = (navigate: Navigator ) => {
    const buildQueryString = (params: Record<string, string | undefined>) => {
        const queryString = Object.keys(params)
            .map((key) => (params[key] ? `${key}=${params[key]}` : ''))
            .filter((param) => param !== '')
            .join('&');
        return queryString ? `?${queryString}` : '';
    };

    const updateSearchParams = (updateFn: () => void) => {
        updateFn();

        const params = {
            status: eventsStore.status.slug,
            languages: eventsStore.selectedLanguages.join(','),
            categories: eventsStore.selectedCategories.map((category) => category.slug).join(','),
            tags: eventsStore.selectedTags.map((tag) => tag.slug).join(','),
            areaOfCare: eventsStore.areaOfCare.slug,
        };

        navigate(`${location.pathname}${buildQueryString(params)}`, { scroll: false });
    };

    const handleOnClickStatus = (status: string) => {
        const newStatus = eventsStore.availableStatuses.find((item) => item.slug === status);
        newStatus && updateSearchParams(() => setStatus(newStatus));
    };

    const handleOnClickLanguage = (language: string) => {
        resetCategoriesAndTags();
        updateSearchParams(() => changeSelectedEventLanguage(language));
    };

    const handleOnClickCategory = (category: TopCategory) => {
        updateSearchParams(() => changeSelectedEventCategory(category));
    };

    const handleOnClickTag = (tag: Tag) => {
        updateSearchParams(() => changeSelectedEventTag(tag));
    };

    const handleOnClickAreaOfCare = (areaOfCare: string) => {
        const newAreaOfCare = eventsStore.availableAreaOfCares.find((item) => item.slug === areaOfCare);
        newAreaOfCare && updateSearchParams(() => setAreaOfCare(newAreaOfCare));
    };

    return {
        handleOnClickStatus,
        handleOnClickLanguage,
        handleOnClickCategory,
        handleOnClickTag,
        handleOnClickAreaOfCare,
    };
};

export default createClickHelper;
