import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../style/theme';

export const StyledVerifyAccount = styled.div`
    max-width: 480px;
`;

export const StyledVerifyAccountText = styled.h2`
    font-size: 22px;
    font-family: ${fontFamilyAtosRegular};
    color: ${theme.colors.text.h2};
`;

export const StyledSignIn = styled.span`
    color: ${theme.colors.text.red};
    cursor: pointer;
`;
