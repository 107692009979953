
import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 20 18',
    width: 1.43,
    height: 1.29,
};

export const FilterIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <g transform="translate(0 -33.2)">
                <path id="Path_21636" data-name="Path 21636" d="M0,64.721A.72.72,0,0,1,.714,64H19.286a.721.721,0,0,1,0,1.442H.714A.72.72,0,0,1,0,64.721Zm0,7.211a.72.72,0,0,1,.714-.721H19.286a.721.721,0,0,1,0,1.442H.714A.72.72,0,0,1,0,71.932Zm19.286,7.932H.714a.721.721,0,0,1,0-1.442H19.286a.721.721,0,0,1,0,1.442Z" transform="translate(0 -29.5)" />
                <circle id="Ellipse_109" data-name="Ellipse 109" cx="1.975" cy="1.975" r="1.975" transform="translate(2.414 33.2)" />
                <circle id="Ellipse_110" data-name="Ellipse 110" cx="1.975" cy="1.975" r="1.975" transform="translate(12.282 40.457)" />
                <circle id="Ellipse_111" data-name="Ellipse 111" cx="1.975" cy="1.975" r="1.975" transform="translate(5.24 47.714)" />
            </g>
        </SvgIcon>
    );
};
