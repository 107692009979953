import { styled } from 'solid-styled-components';
import theme, {fontFamilyAtosBold} from '../../style/theme';
import { StyledDocumentTypeFlagTextSmallProps } from './document-type-flag-types';

export const StyledDocumentTypeFlag = styled.div`
    width: 7.14rem;
    height: 2.86rem;
    position: absolute;
    right: 1rem;
    top: 2rem;
    ${theme.mediaQueries.mobileMin} {
        z-index: 2;
    }
`;

export const StyledDocumentTypeFlagText = styled.span`
    font-size: 2.07rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-transform: capitalize;
    color: ${theme.palette.white};
    position: absolute;
    top: 0.93rem;
    left: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7.14rem;
`;

export const StyledDocumentTypeFlagSmall = styled.div`
    position: absolute;
    right: -34px;
    top: 1.7rem;
    height: 2.8rem;
    z-index: 2;
    display: flex;
    pointer-events: none;
`;

export const StyledDocumentTypeFlagTextSmall = styled.div<StyledDocumentTypeFlagTextSmallProps>`
    font-size: 1.5rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-transform: capitalize;
    color: ${theme.palette.white};

    padding-left: 0.8rem;
    padding-right: 0.4rem;

    background: ${(p) => p.backgroundColor};
    border-radius: 1rem 0 0 1rem;
    border: 1px solid white;
    border-right: none;

    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    transform: translateX(1px);
`;

export const StyledIconPlaceholder = styled.div`
    width: 3rem;
    svg {
        height: 4.17rem;
    }
`;
