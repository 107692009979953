import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 43.48 49.99',
    // width: 29.992
};

export const LarytubeIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <g transform="translate(-3.3)">
                <path
                    id="Path_21856"
                    data-name="Path 21856"
                    d="M43.2,13.3h-.3A18.877,18.877,0,0,0,25,0,18.877,18.877,0,0,0,7.1,13.3H6.8a3.543,3.543,0,0,0-3.5,3.5v3.8a3.543,3.543,0,0,0,3.5,3.5h.3A18.968,18.968,0,0,0,17.6,35.9V48.4A1.561,1.561,0,0,0,19.1,50H31a1.561,1.561,0,0,0,1.5-1.6V35.9A18.693,18.693,0,0,0,43,24.1h.3a3.543,3.543,0,0,0,3.5-3.5V16.8A3.631,3.631,0,0,0,43.2,13.3ZM31.3,48.4a.446.446,0,0,1-.3.4H19.1c-.2,0-.3-.2-.3-.4v-12a18.591,18.591,0,0,0,12.6,0v12ZM43.2,23h-.8a.458.458,0,0,0-.5.4,17.52,17.52,0,0,1-33.8,0,.675.675,0,0,0-.6-.4H6.8a2.416,2.416,0,0,1-2.4-2.3V16.9a2.348,2.348,0,0,1,2.4-2.3h.8a.458.458,0,0,0,.5-.4A17.557,17.557,0,0,1,41.9,14a.664.664,0,0,0,.5.4h.8a2.416,2.416,0,0,1,2.4,2.3v3.8h0A2.456,2.456,0,0,1,43.2,23Z"
                />
                <path
                    id="Path_21857"
                    data-name="Path 21857"
                    d="M17.9,11.7a9.959,9.959,0,0,0,0,14.1A9.82,9.82,0,0,0,25,28.7a10.243,10.243,0,0,0,7.1-2.9,9.959,9.959,0,0,0,0-14.1A10.08,10.08,0,0,0,17.9,11.7ZM31.2,25h0a8.753,8.753,0,0,1-12.5,0A8.85,8.85,0,0,1,25,9.9a9.113,9.113,0,0,1,6.3,2.6A8.842,8.842,0,0,1,31.2,25Z"
                />
            </g>
        </SvgIcon>
    );
};
