import { ErrorBoundary, useContext } from 'solid-js';
import { styled } from 'solid-styled-components';
import { AppContext } from '../app-context-provider/app-context-provider';

type ErrorCatcherProps = {
    componentName: string;
    children: any;
}

const StyledErrorCatcher = styled.div`
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: lightcoral;
    color: white;
    padding: 1rem;
`;

export const ErrorCatcher = (props: ErrorCatcherProps) => {
    const { siteInfo } = useContext(AppContext);
    
    return (
        <ErrorBoundary fallback={(err: Error) => {
            const message = `Error caught in ${props.componentName} on ${siteInfo.name} - ${siteInfo.url}: `;
            console.log(message, err);
            
            return (
                <StyledErrorCatcher>
                    {message}: {err?.toString()}
                </StyledErrorCatcher>
            );
        }}>
            {props.children}
        </ErrorBoundary>
    );
};