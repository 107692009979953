import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const AngleUpIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M16.64,156.827l12.955-12.2A1.843,1.843,0,0,1,31,144a2.046,2.046,0,0,1,1.406.559l12.955,12.2a2.046,2.046,0,1,1-2.813,2.972L31,148.784,19.452,159.727a2.044,2.044,0,0,1-2.893-.08A1.964,1.964,0,0,1,16.64,156.827Z"
                transform="translate(-16.001 -138)"
            />
        </SvgIcon>
    );
};
