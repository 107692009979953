import { createContext, createSignal } from 'solid-js';

type EventContextType = {
    gotoEventId?: string;
    isDisabledEvent?: boolean;
}

export const EventContext = createContext<EventContextType>({
    gotoEventId: undefined,
    isDisabledEvent: undefined
});

type EventContextProviderProps = {
    children: any;
    gotoEventId?: string;
    isDisabledEvent?: boolean;
}

export const EventContextProvider = (props: EventContextProviderProps) => {    
    const [ gotoEventId ] = createSignal(props.gotoEventId);
    const [ isDisabledEvent ] = createSignal(props.isDisabledEvent);

    return (
        <EventContext.Provider value={{ 
            gotoEventId: gotoEventId(),
            isDisabledEvent: isDisabledEvent()
        }}>
            {props.children}
        </EventContext.Provider>
    );
};
