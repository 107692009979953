import { For, JSX, Owner, Show, createEffect, createSignal, getOwner, runWithOwner } from 'solid-js';
import { OutputObject, pdfStore, setPdfStore } from '../../pdf-store';
import { FooterPDF } from '../components/footer/pdf-footer';
import { StyledVerticalPixelSpace } from '../../../../ui-components/utility-style-components/spacing';
import { ProductData } from '../../../product/product-types';
import { BlogNewsData } from '../../../blog-news/blog-news';
import { StyledBlankPageWrapper, StyledA4, StyledDebugHeight, HeightIndicator } from './pdf-renderer.style';
import { StyledDebugFittingTemplate, pageHeight, pageStencilHeight, pageTopMargin } from '../components/shared-components.style';
import { PageData } from '../../../share-and-save/share-and-save';
import isVisregTest from '../../../../tools/is-visual-regression-test';
import { DebugRightMarginRuler } from './debugging-tools/right-margin-ruler';
import { debugWrapper } from './debugging-tools/element-wrapper';
import { EventData } from '../../../event/event-types';

const debugging = false;

export type ComponentPiece = {
    canBeBrokenUp?: boolean;
    elements: (post: ProductData & BlogNewsData & PageData & EventData ) => JSX.Element | JSX.Element[];
    topOpeningComponent?: () => JSX.Element;
    bottomClosingComponent?: () => JSX.Element;
    finalClosingComponent?: () => JSX.Element;
};

type PdfRendererProps = {
    post: OutputObject;
    template: any;
    fittingTemplate: any;
    components?: ComponentPiece[];
    isLastPost: boolean;
};

export const PdfRenderer = (props: PdfRendererProps) => {
    const [ packingElements, setPackingElements ] = createSignal(false);
    const [ evaluatedElement, setEvaluatedElement ] = createSignal<JSX.Element>();
    const [ currentStackHeight, setCurrentStackHeight ] = createSignal(0);
    const [ pages, setPages ] = createSignal<JSX.Element[]>([]);
    const [ currentPage, setCurrentPage ] = createSignal<any[]>([]);
    const [ segmentableComponent, setSegmentableComponent ] = createSignal<SegmentableComponent>();
    const [ pageHeights, updatePageHeights ] = createSignal<number[]>([]);

    if (debugging) setPdfStore('debugging', true);

    const A4Body = props.template;
    const FittingTemplate = props.fittingTemplate;
    
    const owner = getOwner();

    /**
     * This "heightIndicator" is used to assess the height of a rendered element.
     * It is situated below the assessed element and therefore its offsetTop equals the height of the element.
     * (We can't grab the height of the rendered DOM element itself for technical reasons: it requires a ref, which we can't set on the component itself due to the way the code is structured).
     */
    let heightIndicator: HTMLDivElement | undefined;

    const verticalSpace = 33;

    createEffect(() => {        
        if (!props?.post?.data) return;
        if (!props?.components) return;
        packIntoPage(props.components, props.post.data);
    });


    const domElementPainted = async () => {
        return new Promise<void>((resolve) => {
            let count = 0;
            const duration = 50;

            const check = () => {
                if (heightIndicator?.offsetTop !== undefined || count++ >= 10) {
                    resolve();
                    return;
                }

                setTimeout(check, duration);
            };

            check();
        });
    };

    const assessedHeight = async (element: JSX.Element | (() => JSX.Element)) => {        
        runWithOwner(owner as Owner, () => {
            setEvaluatedElement(element);
        });

        await domElementPainted();
        const height = Math.floor(heightIndicator!.offsetTop);

        setPdfStore('debugMeasuredElementHeight', height);
        
        const resolvedHeight: number = await new Promise((res) => (
            setTimeout(() => {
                setEvaluatedElement(<></>);
                res(height);
            }, debugging ? 50 : 1)
        ));

        return resolvedHeight;
    };

    /**
     * This function writes an element to the current page, taking into account the height of the element and any 
     * segmentable components.
     * If the element doesn't fit on the current page, it closes the current page and starts a new one.
     * If the element is part of a segmentable component, it may need to render the opener or closer of the component.
     * @param element The JSX element to write to the page.
     * @param isLast A boolean indicating whether this is the last element to be written to the page. If it's a 
     * segmentable component, this is used to determine whether to render the final closing element.
     */
    const writeToPage = async (element: JSX.Element, isLast?: boolean) => {
        let currentElement = element;
        const elementHeight = await assessedHeight(element);
        let newElementHeight = elementHeight;
        let reservedHeight = 0;
        let isOpeningElement = false;

        // Check if the element is part of a segmentable component
        const segmentable = segmentableComponent();

        if (segmentable && segmentable.open) {
            /**
             * If the segmentable component is open, reserve space for its bottom element, 
             * because we don't want to split a segmentable component across pages, hence
             * we need to account for the space its bottom element will take up when we measure 
             * the height of the current element.
             */
            reservedHeight = isLast ? segmentable.finalBottom.height : segmentable.bottom.height;
        }

        if (segmentable && !segmentable.open) {
            /**
             * We're either at the start of a segmentable component, or we're continuing a segmentable component
             * on a new page. Either way we need to open it (i.e. begin by rendering its opening element).
             */
            newElementHeight = elementHeight + segmentable.top.height;

            // Render the segmentable's opener before the current element
            runWithOwner(owner as Owner, () => {
                currentElement = debugWrapper(<>
                    {segmentable.top.el}
                    {currentElement}
                </>, false, segmentable.top.height + newElementHeight);
            });

            isOpeningElement = true;
            segmentable.open = true;

            const openerCursorPosition = currentStackHeight() + newElementHeight + segmentable.bottom.height;
            const openerCursorPositionToEvaluate = openerCursorPosition;

            if (openerCursorPositionToEvaluate > pageStencilHeight) {
                // Element does not fit in stencil, close current page
                closePage();
                startNewPageWithLeftoverElement(currentElement);
                setCurrentStackHeight(newElementHeight);

                return;
            }
        }

        
        const newCursorPosition = currentStackHeight() + newElementHeight;
        const cursorPositionToEvaluate = newCursorPosition + reservedHeight;
        
        if (cursorPositionToEvaluate <= pageStencilHeight) {
            // Element fits in stencil, add to page
            setCurrentStackHeight(newCursorPosition);
            setCurrentPage((prev) => [...prev, debugWrapper(currentElement)]);
            return;
        }

        if (segmentable && !isOpeningElement) {
            // Element does not fit in stencil, close current page
            setCurrentPage((prev) => [...prev, debugWrapper(segmentable.bottom.el, true)]);

            // Render the segmentable's opener before the current element
            runWithOwner(owner as Owner, () => {
                currentElement = debugWrapper(<>
                    {segmentable.top.el}
                    {currentElement}
                </>, false, segmentable.top.height + elementHeight);
            });
        }

        // Close the current page and start a new one with the leftover element
        closePage();
        startNewPageWithLeftoverElement(currentElement);
        setCurrentStackHeight(segmentable ? segmentable!.top.height + elementHeight : elementHeight);
    };

    const startNewPageWithLeftoverElement = (element: JSX.Element) => {
        setCurrentPage([element]);
    };

    const closeSegmentableComponent = () => {
        const activeComponent = segmentableComponent();

        if (activeComponent && activeComponent.open) {
            setCurrentPage((prev) => [
                ...prev, 
                debugWrapper(activeComponent.finalBottom.el, false, activeComponent.finalBottom.height),
                debugWrapper(<StyledVerticalPixelSpace size={verticalSpace} />, false, verticalSpace),
            ]);
            setCurrentStackHeight(prev => prev + activeComponent.finalBottom.height + verticalSpace);
        }

        setSegmentableComponent(undefined);
    };
    
    type SegmentableComponentPiece = {
        el: JSX.Element | (() => JSX.Element);
        height: number;
    };

    type SegmentableComponent = {
        open: boolean;
        top: SegmentableComponentPiece;
        bottom: SegmentableComponentPiece;
        finalBottom: SegmentableComponentPiece;

    };

    const prepareForSegmentation = async (component: ComponentPiece) => {
        /**
         * We need to have the top/bottom/final elements and their heights, at hand, in order to calculate if 
         * elements fit on any given page.
         * 
         * The final element is optional, but its purpose is to provide a way to close the component proper,
         * whereas the bottom element is used to provide a way to close the component on the current page,
         * when it is continued it on the next.
         */

        const topComponent = component.topOpeningComponent ? component.topOpeningComponent : <div />;
        const topComponentHeight = await assessedHeight(topComponent) || 0;

        const bottomComponent = component.bottomClosingComponent ? component.bottomClosingComponent : <div />;
        const bottomComponentHeight = await assessedHeight(bottomComponent) || 0;

        const top = {
            el: topComponent,
            height: topComponentHeight,
        };

        const bottom = {
            el: bottomComponent,
            height: bottomComponentHeight,
        };

        let finalBottom = bottom;

        if (component.finalClosingComponent) {
            finalBottom = {
                el: component.finalClosingComponent,
                height: await assessedHeight(component.finalClosingComponent),
            };
        }

        const segmentableComponent: SegmentableComponent = {
            open: false,
            top,
            bottom,
            finalBottom,
        };

        setSegmentableComponent(segmentableComponent);
    };

    /**
     * Packs the given components into a page, creating new pages if/when components don't fit.
     */
    const packIntoPage = async (components: ComponentPiece[], postData: ProductData & BlogNewsData & PageData & EventData) => {
        setPackingElements(true);

        for (const component of components) {
            if (component.canBeBrokenUp) {
                /**
                 * Component can be broken up into multiple parts and continued across pages if needed.
                 */
                await prepareForSegmentation(component);
                let segments: Element[] = [];

                runWithOwner(owner as Owner, () => {
                    segments = component.elements(postData) as Element[];
                });

                for (const [index, segment] of segments.entries()) {
                    await writeToPage(segment, index === segments.length - 1);
                }

                closeSegmentableComponent();
                continue;
            }


            await writeToPage(component.elements(postData));

            setCurrentPage((prev) => [
                ...prev, 
                debugWrapper(<StyledVerticalPixelSpace size={verticalSpace} />),
            ]);
            setCurrentStackHeight(prev => prev + verticalSpace);
        }

        closePage();
        setPackingElements(false);

        if (props.isLastPost) {
            /**
             * We're done with the last page of the last post, so after giving the images some
             * time to load fully we set the renderComplete flag to true.
             */
            setTimeout(() => {
                window.pdfRenderComplete = true;                
                setPdfStore('renderComplete', true);
            }, 500);
        }
    };

    const closePage = () => {
        updatePageHeights(prev => [...prev, currentStackHeight()]);
        setPages((prev) => [...prev, currentPage()]);
    };

    return (
        <>
            <StyledBlankPageWrapper>
                <Show when={!packingElements() && pages()}>
                    <For each={pages()}>
                        {(page, index) => {                            
                            return (
                                <StyledA4 id="A4">
                                    <Show when={debugging || isVisregTest}>
                                        <DebugRightMarginRuler height={pageHeights()[ index() ]}/>
                                    </Show>
                                    <Show when={debugging}>
                                        <StyledDebugFittingTemplate top={(index() * pageHeight) + pageTopMargin }/>
                                    </Show>
                                    <A4Body>
                                        { page }
                                    </A4Body>
                                    <FooterPDF
                                        postTitle={props.post.data.post_title}
                                        url={props.post.data.permalink}
                                        pageNumber={index() + 1}
                                        finalPage={index() === pages().length - 1}
                                        totalPageCount={pages().length}
                                    />
                                </StyledA4>
                            );
                        }}
                    </For>
                </Show>
            </StyledBlankPageWrapper>

            <Show when={packingElements()}>
                <FittingTemplate>
                    {evaluatedElement()}
                    <HeightIndicator debugging={debugging} id="height-indicator" ref={ heightIndicator } />

                    <Show when={debugging}>
                        <StyledDebugHeight>
                            Height: {pdfStore.debugMeasuredElementHeight}
                        </StyledDebugHeight>
                    </Show>
                </FittingTemplate>
            </Show>

        </>
    );
};