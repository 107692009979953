import { styled } from 'solid-styled-components';

export const StyledMenuMobile = styled.div`
    padding: 1rem;
    z-index: 10;
    overflow-y: scroll;
`;

export const StyledMenuMobileTop = styled.div`
    padding: 0 1rem;
`;