import { Show } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { BlogNewsData } from '../blog-news/blog-news';
import { BlogsNewsComponent } from './blogs-news-component';
import { fetchBlogsAndNews } from './fetch-blogs-and-news';
import { LoadingPlaceHolder } from '../loading-place-holder/loading-place-holder';

export type PostContainer = {
    content: BlogNewsData;
};

export const gridSettings = {
    container: {
        templateShorthand: [4, 4, 4],
    },
};

export type BlogsNewsProps = {
    tag?: {
        slug: string;
        name?: string;
    };
    limit?: number;
    audience?: string;
}

export const BlogsNews = (props: BlogsNewsProps) => {

    const postsData = fetchBlogsAndNews(props);
    const loaded = () => postsData();

    return (
        <ErrorCatcher componentName='Blog and news list'>
            <Show when={loaded()} fallback={<LoadingPlaceHolder />}>
                <BlogsNewsComponent posts={postsData()} audience={props.audience} />
            </Show>
        </ErrorCatcher>
    );
};

BlogsNews.parseProps = (atts: any) => {
    return {
        audience: atts.audience,
    };
};
