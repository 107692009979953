import { Show, useContext } from 'solid-js';
import { ErrorCatcher } from '../../../../../../tools/error-catcher';
import { Heading } from '../../../../../../ui-components/heading/heading';
import { Text } from '../../../../../../ui-components/text/text';
import { HeroComponentProps } from '../../../../../hero/hero-types';
import { ImageSrcData, getBackgroundImageSrcData } from '../../../../../image-component/image-component';
import { StyledHeroDescriptionPDF, StyledHeroPdf, StyledHeroTextsPdf, StyledLinkTextSpacer } from './hero-pdf.style';
import { SpacerPdf } from '../spacer/spacer-pdf';
import { focusPosition } from '../../../../../../tools/get-image-focus';
import { AppContext } from '../../../../../../app-context-provider/app-context-provider';
import { prettifyUrl } from '../../pretty-url';

export const HeroPdf = (props: HeroComponentProps) => {
    const { imagesServiceUrl, supportedImageFormats, siteInfo } = useContext(AppContext);

    const getBackgroundImage = () => {
        if (props.src) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.src,
                width: 1500, // Assume mobile from the start
                height: 'AUTO',
            });
            return backgroundImage;
        }
    };

    return (
        <ErrorCatcher componentName='Hero-PDF'>
            <div>
                <StyledHeroPdf
                    backgroundImageSrcData={getBackgroundImage()}
                    focusPosition={focusPosition(props.focus)}
                >
                    <StyledHeroTextsPdf>
                        <Show when={props.eyebrowHeading} fallback={<SpacerPdf height={1.45}/>}>
                            <SpacerPdf height={2.5} />
                            <Heading tag={'h1'} variant='heroEyebrowPDF'>{props.eyebrowHeading}</Heading>
                        </Show>
                        
                        <Heading tag={'h2'} variant='heroPDF'>{props.largerHeading}</Heading>
                        
                        <Show when={props.description}>
                            <StyledHeroDescriptionPDF>
                                <Text fontSize="largestPDF" displayRedVerticalLine={false} noBlockSpacing={false} color='black'>
                                    {props.description}
                                </Text>
                            </StyledHeroDescriptionPDF>
                        </Show>

                        <Show when={props.customLink?.url}>
                            <SpacerPdf height={2.5} />
                            <div style={{display: 'flex', 'align-items': 'center'}}>
                                <Text
                                    fontSize="largerPDF"
                                    displayRedVerticalLine={false}
                                    noBlockSpacing={true}
                                    color='red'
                                    underlined={true}
                                >
                                    {props.customLink?.label}
                                </Text>
                                <StyledLinkTextSpacer>
                                    <Text
                                        fontSize="largePDF"
                                        displayRedVerticalLine={false}
                                        noBlockSpacing={true}
                                        fontStyle='italic'
                                    >
                                        {`(${prettifyUrl(props.customLink?.url, siteInfo)})`}
                                    </Text>
                                </StyledLinkTextSpacer>
                            </div>
                        </Show>
                    </StyledHeroTextsPdf>

                </StyledHeroPdf>
                <SpacerPdf height={4.5} />
            </div>
        </ErrorCatcher>
    );
};

HeroPdf.parseProps = (atts: any) => {
    // This is the raw image object coming from gutenberg. We dont want all details from it.
    const { image } = atts;

    const commonAtts = {
        altText: atts.altText,
        imageText: atts.imageText,
        eyebrowHeading: atts.eyebrowHeading,
        largerHeading: atts.largerHeading,
        description: atts.description,
        labels: atts.labels,
        customLink: atts.customLink?.[0],
    };

    if (image) {
        return {
            src: image.url,
            width: image.width,
            focus: image.focus,
            overlayType: atts.overlayType,
            ...commonAtts,
        };
    } else return commonAtts;
};
