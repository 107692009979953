import { For, Show } from 'solid-js';
import { Grid } from '../../../grid-system/grid/grid';
import { Slider } from '../../../ui-components/slider/slider';
import { Text } from '../../../ui-components/text/text';
import { StyledFlexColumn } from '../../../ui-components/utility-style-components/flex';
import { StyledSmallRedHeading } from '../product-description/product-description.styles';
import { ProductVideoCard } from './product-video-card';
import { ProductVideoData } from './product-video.d';
import { StyledProductVideoContainer } from './product-video.styles';
import removeAmpersand from '../../../tools/remove-ampersand';

export const ProductVideo = (props: ProductVideoData) => {
    return (
        <StyledProductVideoContainer>
            <Grid templateShorthand={[5, 5]} responsive={{ mobile: [12], tablet: [12], smallDesktop: [12] }}>
                <StyledFlexColumn>
                    <StyledSmallRedHeading>{removeAmpersand(props.videoData?.title)}</StyledSmallRedHeading>
                    <Text fontSize="small" color={'darkGray'} displayRedVerticalLine={true}>
                        {props.videoData?.description}
                    </Text>
                </StyledFlexColumn>
                <StyledFlexColumn>
                    <Show when={props.videoData?.videos?.length}>
                        <Slider slidesPerView={1} slideHeight="auto">
                            <For each={props.videoData?.videos}>{(video) => <ProductVideoCard video={video} />}</For>
                        </Slider>
                    </Show>
                </StyledFlexColumn>
            </Grid>
        </StyledProductVideoContainer>
    );
};
