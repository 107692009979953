export const setCookie = (name: string, value: string, days = 100) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `; expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const getCookie = (name: string) => {
    const nameEQ = name + '=';
    const cookie = document.cookie
        .split(';')
        .map((c) => c.trim())
        .find((c) => c.indexOf(nameEQ) === 0);
    return cookie ? cookie.substring(nameEQ.length) : '';
};
