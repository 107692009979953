import { styled } from 'solid-styled-components';
import { fontFamilyAtosBold, theme } from '../../../../style/theme';

export const StyledShortcuts = styled.div`
    background-color: ${theme.palette.gray};
    height: 100%;
    box-sizing: border-box;
    padding: 3rem 6rem 0 0;
    position: relative;
    height: 100%;
`;

export const StyledShortcutsContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;
`;

export const StyledLogoContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledLogo = styled.div`
    width: auto;
    position: relative;
    svg {
        fill: white;
    }
    g {
        fill: white;
    }
    path {
        fill: white;
    }
`;

export const StyledShortcutsHeading = styled.h3`
    font-weight: bold;
    font-size: 2.86rem;
    color: ${theme.palette.white};
    letter-spacing: -1px;
    font-family: ${fontFamilyAtosBold};
    margin: 11.2rem 0 3rem 0;
`;

// a little hacky but working fix for our need of dual colors
// in background row of menu.
export const StyledShortcutsBackgroundPanel = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10000px;
    margin-left: -10000px;
    background-color: ${theme.palette.gray};
`;
