import { styled } from 'solid-styled-components';
import { variants, common } from './heading.styles';
import { HeadingProps, HeadingVariant, HeadingTag, HeadingWordpressBlock } from './heading.d';
import { ErrorCatcher } from '../../tools/error-catcher';
import removeAmpersand from '../../tools/remove-ampersand';

export const VariantTagMap = {
    'hero': 'hero',
    'heroEyebrow': 'heroEyebrow',
    'h1': 'xxlarge',
    'h2': 'xlarge',
    'h3': 'large',
    'h4': 'medium',
    'h5': 'small',
    'span': 'medium',
};

export const Heading = (props: HeadingProps) => {
    const headingVariant = props.variant ? props.variant : VariantTagMap[props.tag];

    const StyledComponent = styled[props.tag as HeadingTag]`
        ${common};
        ${variants[headingVariant as HeadingVariant]};
        ${props.noBlockSpacing ? `
            margin-top: 0;
            margin-bottom: 0;
        ` : ''};
        ${props.capitalizeFirstLetter ? `
            &:first-letter {
                text-transform: uppercase;
            }
        ` : ''};
        ${props.textTransform ? `
            text-transform: ${props.textTransform};
        ` : ''};
        ${ props.color?.color ? `color: ${props.color.color};` : '' }
        ${ props.lineLimit ? `
            display: -webkit-box;
            -webkit-line-clamp: ${props.lineLimit};
            -webkit-box-orient: vertical;
            overflow: hidden;
        ` : '' }

    `;

    return (
        <ErrorCatcher componentName='Heading'>
            <StyledComponent innerHTML={removeAmpersand(props.children)} class={'heading-' + headingVariant?.toString()}/>
        </ErrorCatcher>
    );
};

Heading.parseProps = (atts: HeadingWordpressBlock) => {
    /* 
        we are not able to change it in Gutenberg, but most headings had default value of none.
        to avoid syncing all pages i just remove it here for now
    */
    delete atts.textTransform; 
    return {
        ...atts,
        variant: atts.manualSize ? atts.size : null,
        tag: atts.tag,
        color: atts.forPdfBuilder ? undefined : atts.color,
    };
};
