import { createSignal, For, JSXElement, mergeProps, Show, useContext } from 'solid-js';
import { createSlider } from 'solid-slider';
import { StyledSliderControls, StyledSliderWrapper } from './slider.styles';
import { SliderWithThumbnailsProps } from './slider.d';
import { AngleLeftIcon } from '../../components/icons-library/angle-left';
import { AngleRightIcon } from '../../components/icons-library/angle-right';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { Modal } from '../modal/modal';
import { Slider } from './slider';

const defaultProps: any = {
    spacing: 15,
    thumbnailsPerView: 3,
};

export const SliderWithThumbnails = (componentProps: SliderWithThumbnailsProps) => {
    const props = mergeProps(defaultProps, componentProps);

    const AppState = useContext(AppContext);
    const [slides, setSlides] = createSignal([] as JSXElement[]);
    const [isModalVisible, setIsModalVisible] = createSignal(false);

    const sliderOptions = {
        duration: 1000,
        loop: false,
        slides: {
            perView: 1,
            spacing: props.spacing,
        },
        created: (slider: any) => {
            slider.slides.forEach((slide: any) =>
                slide.addEventListener('click', () => {
                    setIsModalVisible(true);
                    document.body.style.overflowY = 'hidden';
                })
            );
            setSlides(slider.slides);
        },
        slideChanged() {
            thumbnailsMoveTo(current());
        },
    };

    const thumbnailOptions = {
        duration: 1000,
        loop: false,
        slides: {
            perView: props.thumbnailsPerView,
            spacing: props.spacing,
        },
        created: (slider: any) => {
            slider.slides.forEach((slide: any, idx: number) =>
                slide.addEventListener('click', () => {
                    thumbnailsMoveTo(idx);
                    moveTo(idx);
                })
            );
            setSlides(slider.slides);
        },
        slideChanged() {
            // sliderMoveTo(current());
        },
    };

    const [slider, { current, next, prev, moveTo }] = createSlider(sliderOptions);
    const [sliderThumbnails, { current: thumbnailsCurrent, next: thumbnailsNext, prev: thumbnailsPrev, moveTo: thumbnailsMoveTo }] =
        createSlider(thumbnailOptions);

    const handleClickPrev = () => {
        prev();
        thumbnailsPrev();
    };

    const handleClickNext = () => {
        next();
        thumbnailsNext();
    };

    return (
        <StyledSliderWrapper slideHeight={props.slideHeight}>
            <div ref={slider} class="slider">
                {props.children}
            </div>
            <Show when={slides().length > 1}>
                <Show when={AppState.viewport.width > theme.breakpoints.MOBILE}>
                    <div ref={sliderThumbnails} class="thumbnail">
                        {props.children}
                    </div>
                </Show>

                <StyledSliderControls>
                    <button onClick={handleClickPrev}>
                        <AngleLeftIcon width={1.79} height={1.07} disabled={current() === 0} customCss={'cursor: pointer'} />
                    </button>
                    <For
                        each={AppState.viewport.width > theme.breakpoints.MOBILE ? slides().slice(0, slides().length - props.thumbnailsPerView + 1) : slides()}
                    >
                        {(_, idx) => {
                            return (
                                <button
                                    onClick={() => {
                                        thumbnailsMoveTo(idx());
                                        moveTo(idx());
                                    }}
                                    class={`dot ${
                                        AppState.viewport.width > theme.breakpoints.MOBILE
                                            ? thumbnailsCurrent() === idx()
                                                ? 'active'
                                                : ''
                                            : current() === idx()
                                                ? 'active'
                                                : ''
                                    }`}
                                ></button>
                            );
                        }}
                    </For>
                    <button onClick={handleClickNext}>
                        <AngleRightIcon
                            width={1.79}
                            height={1.07}
                            disabled={thumbnailsCurrent() === slides().length - 1 || current() === slides().length - 1}
                            customCss={'cursor: pointer; margin-left: 0.36rem;'}
                        />
                    </button>
                </StyledSliderControls>
                <Modal visible={isModalVisible()} onClose={() => setIsModalVisible(false)}>
                    <Slider slidesPerView={1} initial={current()} isInModal={true} slideHeight={'21.43rem'}>
                        {props.children}
                    </Slider>
                </Modal>
            </Show>
        </StyledSliderWrapper>
    );
};
