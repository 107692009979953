import { createEffect, For, Match, mergeProps, Show, Switch } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { ListProps } from './list.d';
import { StyledList, StyledListItem } from './list.styles';
import { TextFontSize } from '../../ui-components/text/text.d';


const defaultProps = {
    fontSize: 'normal' as TextFontSize,
    colorText: {
        color: '#403131',
        name: 'paragraphColor'
    },
    displayRedVerticalLine: true,
};

export const List = (componentProps: ListProps) => {    

    const props = mergeProps(defaultProps, componentProps);

    // eslint-disable-next-line prefer-const
    let ref: HTMLUListElement | undefined = undefined;

    // This will make sure that any tags with the attachment datatype are opened in a new tab.
    // The main reason behind it is to make sure that files are opened without solidjs
    createEffect(() => {
        ref?.querySelectorAll('[data-type="attachment"]').forEach((atag: any) => {
            atag.target = '_blank';
        });
    });

    return (
        <ErrorCatcher componentName='List'>
            <Show when={props.items && props.items?.length > 0} fallback={<></>}>
                <StyledList
                    ref={ref}
                    fontSize={props.fontSize}
                    displayRedVerticalLine={props.displayRedVerticalLine}
                    class={props.displayRedVerticalLine ? 'atos-lined-text' : 'atos-text'}
                >
                    <Switch>
                        <Match when={props.items}>
                            <For each={props.items}>{ (item: string) => {
                                return item && <StyledListItem colorText={props?.colorText?.color} innerHTML={item} />;
                            }}</For>
                        </Match>
                        <Match when={props.children}>
                            <For each={props.children}>{ (child: string) => {
                                return child && <li>{child}</li>;
                            }}</For>
                        </Match>
                    </Switch>

                </StyledList>
            </Show>
        </ErrorCatcher>
    );
};

List.parseProps = (atts: any) => {
    let parsedListItems: any = [];
    
    if (Array.isArray(atts.content)) {
        parsedListItems = atts?.content?.map((item: any) => {
            if (!item || !item.innerHTML) {
                return '';
            }
            let parsedItem = item.innerHTML;
            parsedItem = parsedItem.replace('\n<li>', '');
            parsedItem = parsedItem.replace('</li>\n', '');
            return parsedItem;
        });
    }

    return {
        items: parsedListItems,
        fontSize: atts.fontSize || 'normal',
        displayRedVerticalLine: atts.displayRedVerticalLine,
        colorText: atts.colorText || {
            color: '#403131',
            name: 'paragraphColor'
        }
    };
};
