// function to validate first name
export const validateFirstName = (firstName: string, localize: LocalizeFunction) => {
    // check if first name contains only letters (any unicode characters)
    if (!/^[\p{L}\p{M}\s]+$/u.test(firstName)) {
        return `${localize('first-name', 'First name')} ${localize('should-contain-only-letters', 'should contain only letters')}`;
    }
    return '';
};

// function to validate last name
export const validateLastName = (lastName: string, localize: LocalizeFunction) => {
    // check if first name contains only letters (any unicode characters)
    if (!/^[\p{L}\p{M}\s]+$/u.test(lastName)) {
        return `${localize('last-name', 'Last name')} ${localize('should-contain-only-letters', 'should contain only letters')}`;
    }
    return '';
};

// function to validate email
export const validateEmail = (email: string, localize: LocalizeFunction) => {
    // check if email is valid (any unicode characters)
    if (!/^[\p{L}\p{N}\p{M}\p{P}\p{S}]+@([\p{L}\p{N}\p{M}\p{P}\p{S}]+\.)+[\p{L}\p{M}]{2,}$/u.test(email)) {
        return `${localize('email', 'Email')} ${localize('is-not-valid', 'is not valid')}`;
    }
    return '';
};

// function to validate street
export const validateStreet = (/* street: string, localize: LocalizeFunction */) => {
    return '';
};

// function to validate city
export const validateCity = (/* city: string, localize: LocalizeFunction */) => {
    return '';
};

// function to validate postal code
export const validatePostalCode = (postalCode: string, localize: LocalizeFunction) => {
    // check if postal code is valid using regex
    if (!/^[a-zA-Z0-9 ]{3,10}$/.test(postalCode)) {
        return `${localize('postal-code', 'Postal code')} ${localize('is-not-valid', 'is not valid')}`;
    }
    return '';
};

// function to validate country
export const validateCountry = (/* country: string, localize: LocalizeFunction */) => {
    return '';
};

// function to validate phone
export const validatePhone = (phone: string, localize: LocalizeFunction) => {
    // check if phone number is valid using regex
    if (!/^[0-9+]+$/.test(phone)) {
        return `${localize('phone', 'Phone')} ${localize('is-not-valid', 'is not valid')}`;
    }
    return '';
};

// function to validate mobile
export const validateMobile = (phone: string, localize: LocalizeFunction) => {
    // check if phone number is valid using regex
    if (!/^[0-9+]+$/.test(phone)) {
        return `${localize('mobile', 'Mobile')} ${localize('is-not-valid', 'is not valid')}`;
    }
    return '';
};

export const validateUserType = (userType: string, localize: LocalizeFunction) => {
    const validUserTypes = ['Relative', 'HCP', 'Patient', 'Other'];
    if (!validUserTypes.includes(userType)) {
        return `${localize('user-type', 'User type')} ${localize('is-not-valid', 'is not valid')}`;
    }
    return '';
};

type ValidationResponse = {
    valid: boolean;
    errorMessage: string;
}
type LocalizeFunction = (slug: string, defaultValue: string) => string;
export const validate = (
    key: string,
    value: string,
    localize: LocalizeFunction
): ValidationResponse => {
    let valid = false;
    let errorMessage = '';

    switch (key) {
        case 'firstname': {
            const error = validateFirstName(value, localize);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        } case 'lastname': {
            const error = validateLastName(value, localize);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        } case 'email': {
            const error = validateEmail(value, localize);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        } case 'country': {
            const error = validateCountry(/* value, localize */);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        } case 'userType': {
            const error = validateUserType(value, localize);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        } case 'zip': {
            const error = validatePostalCode(value, localize);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        } case 'street': {
            const error = validateStreet(/* value, localize */);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        } case 'city': {
            const error = validateCity(/* value, localize */);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        } case 'phone': {
            const error = validatePhone(value, localize);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        } case 'mobile': {
            const error = validateMobile(value, localize);
            if(error) {
                valid = false;
                errorMessage = error;
            } else {
                valid = true;
            }
            break;
        }
        
        default:
            valid = true;
    }
    return {
        valid,
        errorMessage
    };
};