import { For, createEffect, createSignal, useContext } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Button } from '../../ui-components/button/button';
import { ButtonProps } from '../../ui-components/button/button-types';
import { DocumentHolder } from '../files/document-holder';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { gql } from 'graphql-request';

const GET_FILES_QUERY = gql`
    query GetFiles($documentHoldersIds: [ID!]!) {
        documentHolders: getDocumentHoldersByIds(documentHoldersIds: $documentHoldersIds) {
            id
            slug
        }
    }
`;
    

export const File = (props: FileProps) => {
    const { localize, createCachedResource } = useContext(AppContext);
    const [documentHoldersIds, setDocumentHoldersIds] = createSignal(props.documentHoldersIds ? [...props.documentHoldersIds] : []);


    const [ documentHoldersData ] = createCachedResource(
        GET_FILES_QUERY,
        () => ({
            documentHoldersIds: documentHoldersIds(),
        }),
        true
    );

    createEffect(() => {
        const newDocumentHoldersIds = props.documentHoldersIds ? [...props.documentHoldersIds] : [];
        setDocumentHoldersIds(newDocumentHoldersIds);
    });

    const getLabel = () => {        
        if (props.button.label?.toLowerCase() === 'download') {
            return localize('download', 'Download');
        }

        return props.button.label;
    };

    return (
        <ErrorCatcher componentName='File'>
            <For each={documentHoldersData()?.documentHolders || []}>{(documentHolder: DocumentHolder) => (
                <Button {...props.button} label={getLabel()} url={`/downloads/${documentHolder.id}/${documentHolder.slug}`} isLink/>
            )}</For>
        </ErrorCatcher>
    );
};

export type FileProps = {
    button: ButtonProps;
    documentHoldersIds: number[];
}

File.parseProps = (atts: any) => {
    return {
        documentHoldersIds: atts.documentHolders || [],
        button: atts.innerBlocks[0],
    };
};
