import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';

const { spacing, mediaQueries } = theme;

export const StyledProductIntroLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.m};
    padding-top: ${spacing.s};
    padding-left: ${spacing.xl};

    ${mediaQueries.mobileMax} {
        padding: 0 ${spacing.s};
        gap: 0;
    }
`;

export const StyledLearnMore = styled.div`
    padding-left: ${spacing.xxs};
    margin-top: ${spacing.xxs};
    margin-bottom: ${spacing.m};
`;

export const StyledProductIntroRight = styled.div`
    text-align: center;
    padding-top: ${spacing.s};
    padding-right: ${spacing.xl};

    ${mediaQueries.tabletMax} {
        padding: 0 ${spacing.s};
    }
`;

export const StyledProductImage = styled.img`
    object-fit: contain;
    object-position: center;
    height: 100%;
`;

export const StyledProductInfo = styled.div``;

export const StyledProductActions = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledProductIconTabRow = styled.div`
    height: 2.57px;
    position: relative;
    margin: 0 -3rem 0 -3rem;

    div {
        top: 0;
    }
`;