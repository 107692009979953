/* eslint-disable indent */
import { Link } from '@solidjs/router';
import { For, Show, useContext } from 'solid-js';
import { AppContext } from '../../../../app-context-provider/app-context-provider';
import { toggleCountrySelectionMenu } from '../../../../stores/locale-store';
import theme from '../../../../style/theme';
import urlMatches from '../../../../tools/url-match';
import { HeaderNavigationShortcuts } from '../../../header/header-navigation-shortcuts';
import { AtosCareLogo } from '../../../icons-library/atos-care-logo';
import { AtosLogo } from '../../../icons-library/atos-logo-desktop';
import { CrossIcon } from '../../../icons-library/cross';
import { StyledSpacer } from '../../../login-menu/login-menu.styles';
import type { LinkType, MenuHeaderMobileProps, MenuHeaderProps } from '../../menu-header-types';
import {
    StyledMenuHeader,
    StyledMenuHeaderChangeLanguage,
    StyledMenuHeaderIconLinks,
    StyledMenuHeaderLink,
    StyledMenuHeaderLinkPatientOrHcp,
    StyledMenuHeaderLinkPatientOrHcpContainer,
    StyledMenuHeaderLinks,
    StyledMenuHeaderTop,
} from './menu-header.styles';
import { removeTrailingSlash } from '../../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../../tools/remove-ampersand';
import { StarIcon } from '../../../icons-library/star';
import { GlobeIcon } from '../../../icons-library/globe';

export const MenuHeader = (props: MenuHeaderProps & MenuHeaderMobileProps) => {
    const { viewport, myContentPage, shopPage, localize, siteInfo, contactUsPage, shop, appStore } = useContext(AppContext);

    const handleOnChangeLanguageClick = () => {
        props.handleCloseMenuClick();
        toggleCountrySelectionMenu();
    };

    return (
        <StyledMenuHeader>
            <StyledMenuHeaderTop>
                <Link
                    href="/"
                    onClick={() => props.handleCloseMenuClick()}
                    ref={(el: HTMLElement) => {
                        if (props.firstElement) {
                            props.firstElement(el);
                        }
                    }}
                >
                    <Show when={siteInfo.siteType === 'default'} fallback={<AtosCareLogo width={8} height={4.34} />}>
                        <AtosLogo width={9.36} />
                    </Show>
                </Link>
                <StyledMenuHeaderIconLinks>
                    <HeaderNavigationShortcuts />
                    <StyledSpacer />
                    <button onClick={() => props.handleCloseMenuClick()}>
                        <CrossIcon
                            {...(viewport.width < theme.breakpoints.MOBILE
                                ? {
                                    height: 2.5,
                                    width: 2.5,
                                    opacity: 0.7,
                                }
                                : { height: 1.71, width: 1.79 })}
                        />
                    </button>
                </StyledMenuHeaderIconLinks>
            </StyledMenuHeaderTop>

            <StyledMenuHeaderLinks>
                <Show when={!siteInfo?.siteType || siteInfo?.siteType === 'default'}>
                    <Show when={myContentPage.url && myContentPage.url !== ''}>
                        <StyledMenuHeaderLink
                            href={removeTrailingSlash(myContentPage.url)}
                            onClick={() => props.handleCloseMenuClick()}
                            active={urlMatches(myContentPage.url)}
                        >
                            <StarIcon
                                altText={localize('header_my_content_icon', 'Link to My content')}
                                aria-label={localize('header_my_content_icon', 'Link to My content')}
                                fill={theme.palette.gray}
                                height={1.5}
                                width={1.5}
                            />

                            <span>{localize('my-content', 'My content')}</span>
                        </StyledMenuHeaderLink>
                    </Show>

                    <StyledMenuHeaderChangeLanguage onClick={handleOnChangeLanguageClick}>
                        <GlobeIcon
                            altText={localize('header_country_selection_icon', 'Open country selection menu')}
                            aria-label={localize('header_country_selection_icon', 'Open country selection menu')}
                            fill={theme.palette.gray}
                            height={1.5}
                            width={1.5}
                        />

                        <span>{localize('change-language', 'Change language')}</span>
                    </StyledMenuHeaderChangeLanguage>

                    <Show when={shopPage?.url && shopPage.url !== ''}>
                        <StyledMenuHeaderLink
                            href={removeTrailingSlash(shopPage.url)}
                            onClick={() => props.handleCloseMenuClick()}
                            active={urlMatches(shopPage.url)}
                        >
                            {localize('shop', 'Shop')}
                        </StyledMenuHeaderLink>
                    </Show>
                </Show>

                <Show when={siteInfo?.siteType === 'atos-care'}>
                    <Show when={shop.basketPage?.url && shop.basketPage.url !== ''}>
                        <StyledMenuHeaderLink
                            href={removeTrailingSlash(shop.basketPage.url)}
                            onClick={() => props.handleCloseMenuClick()}
                            active={urlMatches(shop.basketPage.url)}
                        >
                            {removeAmpersand(shop.basketPage.title)}
                        </StyledMenuHeaderLink>
                    </Show>

                    <Show when={contactUsPage?.url && contactUsPage.url !== ''}>
                        <StyledMenuHeaderLink
                            href={removeTrailingSlash(contactUsPage.url)}
                            onClick={() => props.handleCloseMenuClick()}
                            active={urlMatches(contactUsPage.url)}
                        >
                            {removeAmpersand(contactUsPage.title)}
                        </StyledMenuHeaderLink>
                    </Show>
                </Show>

                <StyledMenuHeaderLinkPatientOrHcpContainer>
                    <For each={props?.links}>
                        {(link: LinkType, index) => (
                            <Show when={link.url && link.url !== ''}>
                                <StyledMenuHeaderLinkPatientOrHcp
                                    href={removeTrailingSlash(link.url)}
                                    onClick={() => props.handleCloseMenuClick()}
                                    active={urlMatches(link.url) || (appStore.isHcp() && index() === 1) }
                                >
                                    {removeAmpersand(link.title)}
                                </StyledMenuHeaderLinkPatientOrHcp>
                            </Show>
                        )}
                    </For>
                </StyledMenuHeaderLinkPatientOrHcpContainer>

            </StyledMenuHeaderLinks>
        </StyledMenuHeader>
    );
};
