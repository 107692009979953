import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const ArrowLeftIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M.653,75.274a2.146,2.146,0,0,0,0,3.034L11.368,89.023A2.145,2.145,0,0,0,14.4,85.989L7.336,78.931H27.882a2.143,2.143,0,0,0,0-4.286H7.343l7.052-7.058a2.145,2.145,0,1,0-3.034-3.034L.646,75.268Z"
                transform="translate(-0.025 -63.925)"
            />
        </SvgIcon>
    );
};
