import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 42.857 30',
    width: 4.17,
    height: 3.678,
};


export const EmergencyIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M35.825,36.167l4.286-2.143a1.081,1.081,0,0,0,.5-1.438,1.128,1.128,0,0,0-1.493-.477l-4.286,2.143a1.059,1.059,0,0,0-.435,1.438A1.075,1.075,0,0,0,35.825,36.167ZM11.786,51.281a1.106,1.106,0,0,0,1.071-1.071v-7.5a8.571,8.571,0,0,1,17.143,0v7.5a1.066,1.066,0,0,0,1.071,1.071,1.106,1.106,0,0,0,1.071-1.071v-7.5a10.714,10.714,0,0,0-21.428,0v7.5A1.066,1.066,0,0,0,11.786,51.281ZM15,41.638a1.066,1.066,0,0,0,1.071,1.071,1.106,1.106,0,0,0,1.071-1.071,3.211,3.211,0,0,1,3.214-3.214,1.071,1.071,0,1,0,0-2.143A5.36,5.36,0,0,0,15,41.638ZM5.357,42.709H1.071a1.071,1.071,0,0,0,0,2.143H5.357a1.071,1.071,0,0,0,0-2.143Zm36.428,0H37.5a1.071,1.071,0,0,0,0,2.143h4.286a1.071,1.071,0,0,0,0-2.143ZM2.743,34.024l4.228,2.143a1.128,1.128,0,0,0,1.493-.477,1.081,1.081,0,0,0-.5-1.438L3.686,32.109a1.067,1.067,0,1,0-.943,1.915Zm30.471,19.4H9.643a3.211,3.211,0,0,0-3.214,3.214v2.143a3.211,3.211,0,0,0,3.214,3.214H33.214a3.211,3.211,0,0,0,3.214-3.214V56.638A3.211,3.211,0,0,0,33.214,53.423Zm1.071,5.357a1.106,1.106,0,0,1-1.071,1.071H9.643a1.066,1.066,0,0,1-1.071-1.071V56.638a1.066,1.066,0,0,1,1.071-1.071H33.214a1.106,1.106,0,0,1,1.071,1.071Z"
                transform="translate(0 -31.995)"
            />
        </SvgIcon>
    );
};
