import { useContext } from 'solid-js';
import { ErrorCatcher } from '../../../../../../tools/error-catcher';
import { ImageSrcData, getBackgroundImageSrcData } from '../../../../../image-component/image-component';

import { Focus, focusPosition } from '../../../../../../tools/get-image-focus';
import { AppContext } from '../../../../../../app-context-provider/app-context-provider';
import { ImageWordpress } from '../../../../../../types/shared';
import { StyledBgImagePdf } from './bg-image-pdf.style';
import { OverlayType } from '../../../../../../grid-system/section/section-types';
import { SpacerPdf } from '../spacer/spacer-pdf';

type BackgroundImagePDFProps = {
    src: string;
    focus: Focus;
    overlayType?: OverlayType;
    roundCorners?: boolean;
};

export const BackgroundImagePDF = (props: BackgroundImagePDFProps) => {
    const { imagesServiceUrl, supportedImageFormats } = useContext(AppContext);    

    const getBackgroundImage = () => {        
        if (props.src) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.src,
                width: 960,
                height: 'AUTO',
            });
            return backgroundImage;
        }
    };

    return (
        <ErrorCatcher componentName='Hero-PDF'>
            <div>
                <SpacerPdf height={1} />
                <StyledBgImagePdf
                    backgroundImageSrcData={getBackgroundImage()}
                    focusPosition={focusPosition(props.focus)}
                    overlayType={props.overlayType}
                    roundCorners={props.roundCorners}
                >
                </StyledBgImagePdf>
                <SpacerPdf height={2} />
            </div>
        </ErrorCatcher>
    );
};

BackgroundImagePDF.parseProps = (atts: { image: ImageWordpress, overlayType: OverlayType, roundCorners: boolean }) => {
    const { image, overlayType, roundCorners } = atts;

    return {
        src: image?.url,
        focus: image?.focus,
        overlayType,
        roundCorners,
    };
};
