import { styled } from 'solid-styled-components';
import { StyledCommonA4Body, StyledCommonFittingTemplate } from '../shared-components.style';
import { pdfStore } from '../../../pdf-store';

const articleRightPadding = 125;

const StyledArticleBody = styled.div`
    padding-right: ${articleRightPadding}px;
`;

export const ArticleA4Body = (props: any) => {
    return (
        <StyledCommonA4Body debugging={pdfStore.debugging}>
            <StyledArticleBody>
                {props.children}
            </StyledArticleBody>
        </StyledCommonA4Body>
    );
};





const StyledArticleFittingTemplate = styled.div`
    padding-right: ${articleRightPadding}px;
`;

export const ArticleFittingTemplate = (props: any) => {
    return (
        <StyledCommonFittingTemplate debugging={pdfStore.debugging}>
            <StyledArticleFittingTemplate>
                {props.children}
            </StyledArticleFittingTemplate>
        </StyledCommonFittingTemplate>
    );
};
