const isExternalLink = (url: string) => {
    if(/(http(s?)):\/\//i.test(url)) {
        return true;
    }
    return false;
};


export const removeTrailingSlash = (url?: string) => {
    if (!url) {
        return '';
    }

    // Skip if it's external
    if (isExternalLink(url)) {
        return url;
    }

    // Link to homepage
    if (url === '/') {
        return url;
    }

    // Remove trailing slash
    url = url.replace(/\/$/, '');

    // Remove trailing slash before question mark
    url = url.replace(/\/\?/, '?');

    return url;
};

