import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../../../style/theme';

export const StyledModalInner = styled.div`
    text-align: initial;
    padding-top: 2rem;
`;

export const StyledModalButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
`;

export const StyledErrorMessage = styled.div`
    color: ${theme.colors.text.red};
    font-size: 16px;
    font-family: ${fontFamilyAtosRegular};
`;
