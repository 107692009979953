import { styled } from 'solid-styled-components';
import theme from '../../style/theme';
import { containerQueries } from '../../tools/check-container-support';

const { spacing } = theme;

export const SearchWidgetWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-block: ${spacing.xxxl};

    ${containerQueries('tabletMin')} {
        padding-inline: ${spacing.xxxl};
    }

    .searchFormWrapper {
        margin-top: 2rem;
    }
`;
