import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 32 32',
    width: 2.2,
    height: 2.2,
};

export const PrintPdfIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <g stroke-linecap="round" stroke-linejoin="round" stroke-width="3" fill="none" /* stroke="#918787" */>
                <path d="M7,24H1V11A3,3,0,0,1,4,8H28a3,3,0,0,1,3,3V24H25">
                </path>
                <line x1="12" y1="21" x2="20" y2="21">
                </line>
                <line x1="12" y1="26" x2="20" y2="26">
                </line>
                <polyline points="7 4 7 1 25 1 25 4">
                </polyline>
                <rect x="7" y="16" width="18" height="15">
                </rect>
            </g>
        </SvgIcon>
    );
};