import { Show, For, useContext } from 'solid-js';
import { pdfStore, Collection } from '../../pdf-store';
import { StyledCollection } from './collection-single.style';
import { CollectionSingle } from './collection-single';
import { AppContext } from '../../../../app-context-provider/app-context-provider';
import { Text } from '../../../../ui-components/text/text';
import { Heading } from '../../../../ui-components/heading/heading';
import { styled } from 'solid-styled-components';

export const StyledCollectionList = styled.div`
    margin-left: 5rem;
`;

export const CollectionsList = () => {
    const { localize } = useContext(AppContext);

    return (
        <StyledCollectionList>
            <Heading tag="h4" variant='small'>
                {localize('pdf-builder-my-documents', 'My Documents')}
            </Heading>
            <Show
                when={pdfStore.collections?.length}
                fallback={
                    <StyledCollection>
                        <Text align="center" fontSize="small" displayRedVerticalLine={false} noBlockSpacing={false} fontStyle='italic'>
                            {localize('pdf-builder-no-compositions', 'No created PDFs yet')}
                        </Text>
                    </StyledCollection>
                }
            >
                <For each={pdfStore.collections}>{(collection) => (
                    <CollectionSingle collection={collection as Collection} />
                )}</For>
            </Show>
        </StyledCollectionList>
    );
};