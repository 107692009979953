import { StyledFlexColumn } from '../../../ui-components/utility-style-components/flex';
import type { ProductDescriptionProps } from './product-description-types';
import { Text } from '../../../ui-components/text/text';
import { StyledProductDescription, StyledSmallRedHeading, StyledProductDescriptionTextColumn, StyledProductFamilyImage } from './product-description.styles';
import theme from '../../../style/theme';
import { Show, useContext } from 'solid-js';
import { Feature } from '../product-types';
import { Grid } from '../../../grid-system/grid/grid';
import { PreTextLink } from '../pre-text-link/pre-text-link';
import { StyledVerticalSpace } from '../../../ui-components/utility-style-components/spacing';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { List } from '../../../ui-components/list/list';
import { Button } from '../../../ui-components/button/button';
import { Heading } from '../../../ui-components/heading/heading';
import { useLocation } from '@solidjs/router';

export const ProductDescription = (props: ProductDescriptionProps) => {
    const { viewport, localize, productsListingPage } = useContext(AppContext);
    
    const location = useLocation() as { state?: { productsPageQuery?: string } };

    return (
        <StyledProductDescription>
            <StyledSmallRedHeading>{props.subTitle}</StyledSmallRedHeading>
            <Grid templateShorthand={[5,5]} responsive={{mobile: [12], tablet: [12], smallDesktop: [12]}}>
                <StyledFlexColumn>
                    <StyledProductDescriptionTextColumn displayRedVerticalLine={viewport.width <= theme.breakpoints.MOBILE ? false : true}>
                        <Text
                            fontSize="normal"
                            splitParagraph={true}
                        >
                            {props.longDescription}
                        </Text>
                        <Show when={props.features && props.features.length > 0}>
                            <Heading tag='h4' variant='tinyRed'>{localize('key-features', 'Key Features')}</Heading>
                            <List displayRedVerticalLine={false} items={props.features.map((feature: Feature) => feature?.text)} />
                        </Show>
                    </StyledProductDescriptionTextColumn>
                    <Show when={!props.hideOverviewLink && productsListingPage?.url}>
                        <Button
                            customCss='margin-top: 3rem;'
                            label={localize('back-to-products', 'Back to products')}
                            isLink={true}
                            url={productsListingPage?.url + (location.state?.productsPageQuery || '')}
                        />
                    </Show>
                </StyledFlexColumn>
                <StyledFlexColumn>
                    <Show when={props.productFamilyImage?.url}>
                        <StyledProductFamilyImage
                            altText={props.productFamilyImage.alt || ''}
                            src={props.productFamilyImage.url}
                            title={props.productFamilyImage.name || ''}
                            aspectRatio="original"
                            height={160}
                            imageText=""
                            roundCorners={false}
                            width={308}
                            spacer={false}
                        />
                    </Show>
                    <Show when={props.productFamilyLink.link?.url}>
                        <StyledVerticalSpace size={viewport.width <= theme.breakpoints.MOBILE ? 2 : 6} />
                        <PreTextLink link={props.productFamilyLink.link} preText={props.productFamilyLink.preText} />
                    </Show>
                    <Show when={props.productLearnMoreLink.link?.url}>
                        <StyledVerticalSpace size={2}/>
                        <PreTextLink link={props.productLearnMoreLink.link} preText={props.productLearnMoreLink.preText} />
                    </Show>
                </StyledFlexColumn>
            </Grid>
        </StyledProductDescription>
    );
};
