import { createSignal, onMount, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { LockedContent } from '../../ui-components/layouts/locked-content';
import { StyledRelativeContainer } from '../../ui-components/utility-style-components/positioning';
import { ArrowRightIcon } from '../icons-library/arrow-right';
import { getBackgroundImageSrcData, ImageSrcData } from '../image-component/image-component';
import { StyledBottomRow, StyledCardTitle, StyledIconContainer, StyledImage, StyledPostCard } from './page-post-card.styles';
import { MyContentHandler } from '../my-content-handler/my-content-handler';
import { AtosLogoRelative } from '../icons-library/atos-logo-relative';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';
import removeAmpersand from '../../tools/remove-ampersand';

export const PagePostCard = (props: any) => {
    const { imagesServiceUrl, supportedImageFormats, userState, pageRegistration } = useContext(AppContext);
    const [backgroundImageSrcData, setBackgroundImageSrcData] = createSignal<ImageSrcData>();

    onMount(() => {
        if (props.data.seo?.og_image) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: typeof props.data.seo?.og_image === 'string' ? props.data.seo?.og_image : props.data.seo?.og_image?.url,
                width: 500,
                height: 'AUTO',
            });

            setBackgroundImageSrcData(backgroundImage);
        }
    });

    const isHiddenForPublic = () => {
        return props.data.isHealthcareProfessional 
            && !props.data.isVisibleForPublic 
            && !userState.user 
            && pageRegistration.url !== '';
    };

    return (
        <ErrorCatcher componentName="Page post card">
            <div>
                <div>
                    <StyledPostCard href={removeTrailingSlash(props.data.permalink) || '/'}>
                        <Show
                            when={props.data.seo?.og_image}
                            fallback={
                                <StyledIconContainer>
                                    <AtosLogoRelative />
                                </StyledIconContainer>
                            }
                        >
                            <StyledImage
                                src={backgroundImageSrcData()?.original}
                                alt={props.data.seo?.og_image?.alt || props.data.seo?.og_image?.title || props.data.seo?.og_image?.url || ''}
                                title={props.data.seo?.og_image?.url || ''}
                            />
                        </Show>

                        <StyledBottomRow>
                            <StyledCardTitle>{removeAmpersand(props.data.post_title)}</StyledCardTitle>
                            <StyledRelativeContainer top={9}>
                                <ArrowRightIcon fill={theme.palette.red} />
                            </StyledRelativeContainer>
                        </StyledBottomRow>

                        <Show when={isHiddenForPublic()}>
                            <LockedContent isColumn={true} />
                        </Show>
                    </StyledPostCard>
                </div>
                <MyContentHandler action='remove' permalink={props?.data?.permalink} />
            </div>
        </ErrorCatcher>
    );
};
