import { styled } from 'solid-styled-components';
import { theme } from '../../../style/theme';

const { header, grid } = theme;
const { desktop, mobile } = header;

const desktopHeight = {
    staticHeight: desktop.height.fixed.value + desktop.height.fixed.unit,
};

const mobileHeight = mobile.height.value + mobile.height.unit;

export const StyledHeader = styled.header`
    container-type: inline-size;
    height: ${desktopHeight.staticHeight};
    position: static;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    ${theme.mediaQueries.tabletMax} {
        height: ${mobileHeight};
    }

    @media print {
        display: none;
    }
`;

export const StyledLogo = styled.div`
    width: auto;
`;

export const InnerHeader = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: inherit;
    z-index: 2;
    padding-top: 0.36rem;
`;

export const FrostedBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    margin-inline: ${grid.standardUnit * 2 * -1}rem;
    right: 0;
    height: inherit;
    z-index: -1;
    background: transparent;
    border-radius: 0 0 ${grid.standardUnit}rem ${grid.standardUnit}rem;

    ${theme.mediaQueries.desktopMax} {
        margin-inline: ${grid.gapSizeTablet * -1}rem;
    }

    ${theme.mediaQueries.tabletMax} {
        background: ${theme.palette.beige};
        margin-inline: ${grid.gapSizeMobile * -1}rem;
        border-radius: 0 0 ${grid.gapSizeMobile}rem ${grid.gapSizeMobile}rem;
    }
`;

export const StyledMiddleColumn = styled.div`
    display: flex;
    ${theme.mediaQueries.desktopMax} {
        display: none;
    }
`;

export const StyledRightColumn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
`;
