import { Match, Show, Switch, createEffect, createSignal, useContext } from 'solid-js';
import { Heading } from '../../../../../../ui-components/heading/heading';
import { CustomCardType } from '../../../../../card-slider/card-slider';
import { generateQRCode } from '../../generate-qr';
import { StyledQrCode, StyledSliderPdf, StyledSliderPdfBackgroundImage, StyledSliderPdfDescriptionWrapper, StyledUrlContainer } from './slider-pdf.style';
import { AppContext } from '../../../../../../app-context-provider/app-context-provider';
import { Text } from '../../../../../../ui-components/text/text';
import { gql } from 'graphql-request';
import { ImageWordpress } from '../../../../../../types/shared';
import { ImageSrcData, getBackgroundImageSrcData } from '../../../../../image-component/image-component';
import { prettifyUrl } from '../../pretty-url';
import { SpacerPdf } from '../spacer/spacer-pdf';
import { PostTypeType } from '../../../../../card-slider/card-type-factory';
import { focusPosition } from '../../../../../../tools/get-image-focus';

const GET_PRODUCT_CATEGORIES_QUERY = gql`
    query {
        resource(key: "/wp-json/rawb/v1/product-categories") {
            content
            key
        }
    }
`;

const GET_POST = gql`
    query GetPost(
        $key: String
    ) {
        post: resource(
            key: $key
        ) {
            content
        }
    }
`;

type CardData = {
    title: string;
    url: string;
    qr: string;
    text?: string;
    image?: ImageWordpress;
};

type SliderPDFProps = {
    card: CustomCardType;
    dynamicCards: boolean;
    posttype: PostTypeType;
    tag?: {
        slug: string;
        name: string;
        taxonomy: string;
        parent: number;
    };
}

export const CardSliderPdf = (props: SliderPDFProps) => {
    const {
        productsListingPage,
        blogsNewsListingPage,
        videosListingPage,
        siteInfo,
        localize,
        graphQlClient,
        imagesServiceUrl,
        supportedImageFormats,
    } = useContext(AppContext);

    const protocol = `${siteInfo.protocol}://`;
    const rootPath = `${protocol}${siteInfo.currentDomain}`;

    const [ url, setUrl ] = createSignal('');
    const [ noUrl, setNoUrl ] = createSignal(false);
    const [ linkText, setLinkText ] = createSignal('');
    const [ postListingPageTitle, setPostListingPageTitle ] = createSignal('');

    const [ cardTitle, setCardTitle ] = createSignal('');
    const [ cardUrl, setCardUrl ] = createSignal('');
    const [ cardQr, setCardQr ] = createSignal('');

    const getBackgroundImage = () => {        
        if (props.card?.image?.url) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.card.image.url,
                width: 225,
                height: 'AUTO',
            });
            return backgroundImage;
        }
    };

    const createProductsUrl = async () => {

        if (props.tag?.taxonomy === props.posttype.tagSlug) {
            /**
             * The tag prop is from the database "tags" column, which is not the same as the WP "tag" taxonomy.
             * Both WP tags and categories are stored in the same column in the database. If the taxonomy type is
             * the tagSlug then we exit early because we don't have a filter for the tag taxonomy and hence can't 
             * create a url for it. We only support filtering by category, which can be one of three 
             * levels: area (of care), category, and type.
             */
            setSignals(
                productsListingPage.title,
                localize('products', 'Products'),
                `${productsListingPage.url}`
            );

            return;
        }

        const { resource: { content: { productCategories } } } = await graphQlClient(GET_PRODUCT_CATEGORIES_QUERY, {}, true);

        const category = props.tag;
        if (!category || category?.parent === 0) {
            const query = category?.parent === 0 ? `?area=${category?.slug}` : '';

            setSignals(
                productsListingPage.title,
                localize('products', 'Products'),
                `${productsListingPage.url}${query}`
            );

            return;
        }

        let query = '';

        if (category?.parent && category?.parent !== 0) {
            const parent = productCategories?.find((c: any) => c.categoryId === category?.parent);

            if (!parent.parent || parent.parent === 0) {
                query = `?area=${parent?.slug}&categories=${category?.slug}`;
            }

            if (parent.parent && parent.parent !== 0) {
                const grandParent = productCategories?.find((c: any) => c.categoryId === parent.parent);
                query = `?area=${grandParent?.slug}&categories=${parent?.slug}&types=${category?.slug}`;
            }
        }

        setSignals(
            productsListingPage.title,
            localize('products', 'Products'),
            `${productsListingPage.url}${query}`
        );
    };

    const setSignals = (title: string, typeName: string, path: string) => {
        setPostListingPageTitle(title);
        const text = path.includes('?') ? localize('pdf-relevant', 'Relevant') : localize('all', 'All');
        setLinkText(`${text} ${typeName}`);        
        setUrl(createUrl(path));
    };

    const createUrl = (path: string) => {
        return `${protocol}${prettifyUrl(path, siteInfo)}`;
    };

    const createCards = async () => {
        let cardData: CardData | undefined;

        if (props.card.type === 'custom') {
            const url = props.card.link?.url ? createUrl(props.card.link?.url) : '';
            
            cardData = {
                title: props.card.heading || '',
                url: url ? prettifyUrl(url, siteInfo) : '',
                qr: props.card.link?.url ? generateQRCode(url) : '',
                ...props.card,
            };

            setCardTitle(cardData.title);
            setCardUrl(cardData.url);
            setCardQr(cardData.qr);
            return;
        }

        const key = `/${props.card.type}/${props.card.selectedPostSlug}`;
        const resource = await graphQlClient(GET_POST, { key }, false);
        const post = resource.post;

        const url = post?.content?.url
            ? createUrl(post?.content?.url)
            : rootPath;

        setCardTitle(post?.content?.post_title);
        setCardUrl(url);
        setCardQr(generateQRCode(url));

        return;
    };

    createEffect(() => {
        let path = '';
        let typeName = '';
        let listingPageTitle = '';
        let query = '';

        if (!props.dynamicCards) {
            createCards();
            return;
        }

        if (props.posttype.value === 'product') {
            createProductsUrl();
            return;
        }

        switch (props.posttype.value) {
            case 'blog-and-news':
                path = blogsNewsListingPage.url;
                typeName = `${localize('blogs', 'Blogs')} & ${localize('news', 'News')}`;
                query = props.tag?.slug ? `?category=${props.tag?.slug}` : '';
                listingPageTitle = blogsNewsListingPage.title;
                break;
            case 'video-item':
                path = videosListingPage.url;
                typeName = localize('videos', 'Videos');
                query = props.tag?.slug ? `?category=${props.tag?.slug}` : '';
                listingPageTitle = videosListingPage.title;
                break;
            default:
                break;
        }
        
        if (!path) {            
            setNoUrl(true);
        }

        setSignals(listingPageTitle, typeName, `${path}${query}`);
    });

    return (
        <div>
            <Switch>
                <Match when={props.dynamicCards && !noUrl()}>
                    <StyledSliderPdf>
                        <StyledSliderPdfDescriptionWrapper>

                            <Heading tag='h5' variant='mediumPDF' color={{ name: 'black', color: 'black' }}>
                                {linkText()}
                            </Heading>

                            <Show when={props.tag?.name && (props.tag?.taxonomy !== props.posttype.tagSlug)}>
                                <Heading tag='h4' variant='smallPDF' color={{ name: 'black', color: 'black' }}>
                                    {`${localize('filters', 'Filters')}: ${props.tag?.name}`}
                                </Heading>
                            </Show>

                            <StyledUrlContainer>
                                <Text fontSize='normalPDF'>
                                    {`${postListingPageTitle()} - <em>${prettifyUrl(url(), siteInfo)}</em>`}
                                </Text>
                            </StyledUrlContainer>

                        </StyledSliderPdfDescriptionWrapper>
                        <StyledQrCode innerHTML={generateQRCode(url())} />
                    </StyledSliderPdf>
                    <SpacerPdf height={2.2} />
                </Match>

                <Match when={!props.dynamicCards}>
                    <StyledSliderPdf>
                        <Show when={props.card?.image?.url}>
                            <StyledSliderPdfBackgroundImage
                                backgroundImageSrcData={getBackgroundImage()}
                                focusPosition={focusPosition(props.card?.image?.focus)}
                            >
                            </StyledSliderPdfBackgroundImage>
                        </Show>
                        <StyledSliderPdfDescriptionWrapper>

                            <Heading tag='h5' variant='mediumPDF' color={{ name: 'black', color: 'black' }}>
                                {cardTitle()}
                            </Heading>

                            <Show when={props.card.text}>
                                <Text fontSize='normalPDF'>
                                    {props.card.text}
                                </Text>
                            </Show>
                            <Show when={cardUrl()}>
                                <StyledUrlContainer>
                                    <Text fontSize='normalPDF' fontStyle='italic'>
                                        {cardUrl()}
                                    </Text>
                                </StyledUrlContainer>
                            </Show>

                        </StyledSliderPdfDescriptionWrapper>
                        <Show when={cardQr()}>
                            <StyledQrCode innerHTML={cardQr()} />
                        </Show>
                    </StyledSliderPdf>
                    <SpacerPdf height={2.2} />

                </Match>
            </Switch>
        </div>
    );
};

CardSliderPdf.parseProps = (atts: any) => {
    return {
        dynamicCards: atts.dynamicCards,
        card: atts.card,
        posttype: atts.posttype,
        tag: atts.tag,
    };
};