import { parseErrorMessage } from './parse-errors';

declare const grecaptcha: any;

export const validateCaptcha = async (action: string, recaptchaSiteKey: string  ): Promise<string> => {
    return new Promise((resolve, reject) => {
        grecaptcha.enterprise.ready(() => {
            try {
                grecaptcha.enterprise.execute(recaptchaSiteKey, { action })
                    .then(async (token: string) => {
                        resolve(token);
                    })
                    .catch((e: any) => {
                        const errMessage = parseErrorMessage(e);
                        reject(errMessage);
                    });
            } catch (e) {
                console.log('Error executing captcha: ', e);
                reject(e);
            }
        });
    });
};