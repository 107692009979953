import { styled } from 'solid-styled-components';
import { pageStencilHeight, pageTopMargin } from '../../components/shared-components.style';

const StyledRuler = styled.div<{ top: number, height: number, color: string }>`
    position: absolute;
    top: ${p => p.top}px;
    right: 0;
    color: black;
    z-index: 1000;
    height: ${p => p.height}px;
    width: 30px;
    padding: 3px;
    background: ${p => p.color};
`;

const StyledVerticalLine = styled.div<{ top: number }>`
    position: absolute;
    top: ${p => p.top}px;
    left: 0;
    right: 0;
    z-index: 1001;
    height: 1px;
    background: #bbb;
`;

type RightMarginRulerProps = {
    height: number;
}

export const DebugRightMarginRuler = (props: RightMarginRulerProps) => {
    return (
        <div>
            <StyledRuler top={pageTopMargin} height={props.height} color='lightblue'>
                {props.height}
            </StyledRuler>
            <StyledVerticalLine top={pageTopMargin + props.height}/>
            <StyledRuler top={pageTopMargin + props.height} height={pageStencilHeight - props.height} color='lightgreen'>
                {pageStencilHeight - props.height}
            </StyledRuler>
       

        </div>
    );
};