import { Show } from 'solid-js';
import theme from '../../../style/theme';
import { Text } from '../../../ui-components/text/text';
import { CrossIcon } from '../../icons-library/cross';
import { StyledCategoryTag, StyledCategoryTagListItem, StyledIconContainer } from './mobile-category-tag.style';

type MobileCategoryTagProps = {
    onClick: (e: any) => void;
    category: { name: string } | undefined;
};
export const MobileCategoryTag = (props: MobileCategoryTagProps) => {
    return (
        <Show when={props.category}>
            <StyledCategoryTagListItem>
                <StyledCategoryTag onClick={props.onClick}>
                    <Text color={'gray'} noBlockSpacing={true} fontSize="small">
                        {props.category?.name}
                    </Text>
                    <StyledIconContainer>
                        <CrossIcon width={1} height={1} fill={theme.palette.gray} />
                    </StyledIconContainer>
                </StyledCategoryTag>
            </StyledCategoryTagListItem>
        </Show>
    );
};
