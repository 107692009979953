import { Switch, Match } from 'solid-js';
import { EnvelopeIcon } from '../../../../icons-library/envelope';
import { PhoneIcon } from '../../../../icons-library/phone';
import { StyledShortcutMenuIcon } from './shortcuts-menu-icons.styles';

export const ShortcutMenuIcons = (props: ShortcutMenuIconsProps) => {
    return (
        <StyledShortcutMenuIcon>
            <Switch fallback={<p>${`icon with name ${props.name} not implemented`}</p>}>
                <Match when={props.name === 'envelope'}>
                    <EnvelopeIcon fill="white" width={3.57} height={1.71} />
                </Match>
                <Match when={props.name === 'phone'}>
                    <PhoneIcon fill="white" width={3.57} />
                </Match>
            </Switch>
        </StyledShortcutMenuIcon>
    );
};
type ShortcutMenuIconsProps = {
    name?: 'envelope' | 'phone';
};
