import { StyledPreTextLink } from './pre-text-link.styles';
import type { PreTextLinkProps } from './pre-text-link-types';
import { Link } from '../../../ui-components/link/link';
import removeAmpersand from '../../../tools/remove-ampersand';

export const PreTextLink = (props: PreTextLinkProps) => {
    return (
        <StyledPreTextLink>
            <span>{props.preText}</span> &nbsp
            <Link url={props.link?.url} label={removeAmpersand(props.link?.title)} opensInNewTab={props.link?.target === '_blank'}/>
        </StyledPreTextLink>
    );
};
