import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../../../../style/theme';

export const StyledPdfArticleHeader = styled.div`
    position: relative;
    background-color: white;
    border-radius: 1.43rem;
`;

export const StyledPdfArticleMetaInfo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6rem;
    font-family: ${fontFamilyAtosBold}; 
    font-weight: 800;
    font-size: 1.29rem;
    color: ${theme.palette.darkGray};
`;

export const StyledPdfArticleDate = styled.div`
    display:flex;
    flex-direction: row;
    gap: 1rem;
`;

export const StyledPdfArticleDateText = styled.div`
    color: ${theme.palette.black};    
`;

export const StyledPdfAuthor = styled.div`
    color: #000;
`;
