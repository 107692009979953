import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { Show, mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    width: 2.1,
    height: 2.4,
    // viewBox: '0 0 26.25 30'
};

export const LockOpenIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <Show when={props.altText}>
                <title>{props.altText}</title>
            </Show>
            <path d="M16.1718 23.4942C16.4354 23.2305 16.5836 22.8729 16.5836 22.5L16.5846 18.75C16.5846 18.377 16.4364 18.0192 16.1726 17.7555C15.9088 17.4917 15.5511 17.3435 15.1781 17.3435C14.805 17.3435 14.4473 17.4917 14.1835 17.7555C13.9198 18.0192 13.7716 18.377 13.7716 18.75V22.5C13.7716 22.8729 13.9197 23.2305 14.1834 23.4942C14.4471 23.7579 14.8047 23.906 15.1776 23.906C15.5505 23.906 15.9081 23.7579 16.1718 23.4942Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4809 2.1967C19.0743 0.790176 17.1667 0 15.1776 0C13.1884 0 11.2808 0.790176 9.87427 2.1967C9.07418 2.99679 8.47353 3.95904 8.10431 5.0062C7.93152 5.49626 8.23397 6.0078 8.73026 6.16175L9.46074 6.38834C10.0394 6.56785 10.6374 6.18785 10.8788 5.63216C11.1111 5.09759 11.4432 4.60592 11.8634 4.18579C12.7423 3.30681 13.9345 2.813 15.1776 2.813C15.7932 2.81287 16.4027 2.934 16.9715 3.16949C17.5403 3.40497 18.0571 3.7502 18.4924 4.18544C18.9278 4.62068 19.2731 5.13741 19.5087 5.70613C19.7443 6.27485 19.8656 6.88441 19.8656 7.5V11.25H10.4906V11.2476H7.67757V11.25H5.80257C4.80817 11.2505 3.85465 11.6458 3.15151 12.3489C2.44836 13.0521 2.0531 14.0056 2.05257 15V26.25C2.0531 27.2444 2.44836 28.1979 3.15151 28.9011C3.85465 29.6042 4.80817 29.9995 5.80257 30H24.5526C25.5469 29.9992 26.5003 29.6039 27.2033 28.9008C27.9064 28.1977 28.3018 27.2443 28.3026 26.25V15C28.3018 14.0057 27.9064 13.0523 27.2033 12.3492C26.5003 11.6461 25.5469 11.2508 24.5526 11.25H22.6776V7.5C22.6776 5.51088 21.8874 3.60322 20.4809 2.1967ZM5.14059 14.338C5.3162 14.1624 5.55422 14.0635 5.80257 14.063H24.5536C24.8018 14.0638 25.0397 14.1628 25.2153 14.3383C25.3908 14.5139 25.4898 14.7517 25.4906 15V26.251C25.4898 26.4994 25.3907 26.7375 25.2149 26.913C25.0391 27.0886 24.801 27.1875 24.5526 27.188H5.80357C5.55488 27.1877 5.31645 27.0888 5.1406 26.913C4.96474 26.7371 4.86583 26.4987 4.86557 26.25V15C4.8661 14.7517 4.96499 14.5136 5.14059 14.338Z"/>
        </SvgIcon>
    );
};
