import { ShortcutsMenu } from './shortcuts-menu/shortcuts-menu';
import { StyledVerticalSpace } from '../../../../ui-components/utility-style-components/spacing';
import {
    StyledShortcuts,
    StyledLogo,
    StyledShortcutsContent,
    StyledLogoContent,
    StyledShortcutsHeading,
    StyledShortcutsBackgroundPanel,
} from './shortcuts.styles';
import type { ShorcutsDesktopProps, ShortCutMenuLinkItem, ShortcutsProps } from '../../types/shortcuts-types';
import { AtosLogo } from '../../../icons-library/atos-logo-desktop';

export const Shortcuts = (props: ShortcutsProps & ShorcutsDesktopProps) => {
    const contactLinks = () => [
        {
            ...props.letUsContactYouLink,
            ...{ icon: 'envelope' },
        },
        {
            title: props.phoneNumber,
            url: `tel:${props.phoneNumber}`,
            icon: 'phone',
        },
    ] as ShortCutMenuLinkItem[];

    return (
        <StyledShortcuts>
            <StyledShortcutsContent>
                <StyledLogoContent>
                    <StyledLogo>
                        <AtosLogo />
                    </StyledLogo>
                </StyledLogoContent>
                <StyledShortcutsHeading>{props.shortcutsHeading}</StyledShortcutsHeading>
                <div>
                    <ShortcutsMenu
                        menuHeading={props.contactHeading}
                        links={contactLinks()}
                        firstElement={props.firstElement}
                    />
                    <StyledVerticalSpace size={1} />
                    <ShortcutsMenu
                        menuHeading={props.servicesHeading}
                        links={props.servicesLinks}
                    />
                </div>
            </StyledShortcutsContent>
            <StyledShortcutsBackgroundPanel />
        </StyledShortcuts>
    );
};
