import { createStore } from 'solid-js/store';
import { SiteDomain } from '../types/app-state';

type SetLanguageProps = {
    site: SiteDomain;
    siteInfo: any;
    isCurrent: boolean;
    updateLanguage: (language: string) => void;
};

const [localeMenuState, setLocaleMenuState] = createStore({ isOpen: false });

export const localeMenuIsOpen = () => {
    return localeMenuState.isOpen;
};

export const openCountrySelectionMenu = () => {
    setLocaleMenuState({ isOpen: true });
};

export const closeCountrySelectionMenu = () => {
    setLocaleMenuState({ isOpen: false });
};

export const toggleCountrySelectionMenu = () => {
    setLocaleMenuState({ isOpen: !localeMenuState.isOpen });
};

export const setLanguage = async (props: SetLanguageProps) => {
    const { site, siteInfo, isCurrent, updateLanguage } = props;

    await saveCookie(site.key);
    updateLanguage(site.key);
    if (!isCurrent) {
        goTo(site, siteInfo);
        return;
    }
};

export const saveCookie = async (language: string) => {
    await fetch('/set-language', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ language: language }),
    });
};

export const goTo = (site: SiteDomain, siteInfo: any) => {
    const siteUrl = site?.domains[siteInfo.target];

    try {
        if (site && siteUrl && window) {
            window.location.href = window.location.protocol + '//' + siteUrl;
        }
    } catch (e) {
        console.log('No window object', e);
    }
};

export const linkTo = (site: SiteDomain, siteInfo: any) => {
    const siteUrl = site?.domains[siteInfo.target];

    if (!site && !siteUrl) {
        return '#';
    }

    try {
        if (window) {
            return window.location.protocol + '//' + siteUrl;
        }

        return `https://${siteUrl}`;
    } catch (e) {
        return `https://${siteUrl}`;
    }
};
