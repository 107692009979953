/* eslint-disable indent */
import { useNavigate } from '@solidjs/router';
import algoliasearch from 'algoliasearch/lite';
import { createSignal, For, onCleanup, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { MagnifyingGlassIcon } from '../icons-library/magnifying-glass';
import { SearchWidgetWrapper } from './search-widget.styles';
import { StyledButton, StyledDropdownItem, StyledResultsDropdown, StyledSearchForm, StyledSearchFormWrapper, StyledSearchInput } from '../search/search.style';
import { Section } from '../../grid-system/section/section';
import { Column } from '../../grid-system/column/column';
import removeAmpersand from '../../tools/remove-ampersand';

// this is a search widget, used on i.e 404 page.

export const SearchWidget = () => {
    const AppState = useContext(AppContext);
    const { localize, algoliaSettings, siteInfo, searchPage } = useContext(AppContext);

    if (!searchPage.url || searchPage.url === '') {
        return <div>please check if search page is set in Site settings - General options</div>;
    }

    const navigate = useNavigate();

    const { searchAppId, searchApiClientKey } = algoliaSettings;

    const [searchText, setSearchText] = createSignal('');
    const [isDropdownVisible, setIsDropdownVisible] = createSignal(false);
    const [searchResult, setSearchResult] = createSignal([]);

    if (!searchAppId || !searchApiClientKey) {
        return <div>No searchAppId / searchApiClientKey was found..</div>;
    }
    const client = algoliasearch(searchAppId, searchApiClientKey); // search only api key

    const { siteId, target } = siteInfo;
    if (!siteId || !target) {
        return <div>No siteId / target was found..</div>;
    }

    const searchClient = client.initIndex(`${siteId}-${target}`);

    const search = async () => {
        try {
            const { hits }: any = await searchClient.search(searchText(), { hitsPerPage: 10 });

            setSearchResult(hits);
        } catch (error) {
            console.error('error searching', error);
        }
    };

    const formSubmit = (event: any) => {
        event.preventDefault();
    };

    onMount(async () => {
        search();
    });

    let formRef: any;
    let dropdownRef: any;
    onMount(() => {
        const handleClick = (event: any) => {
            const isClickInsideForm = formRef.contains(event.target);
            const isClickInsideDropdown = dropdownRef.contains(event.target);

            if (!isClickInsideDropdown && !isClickInsideForm) {
                setIsDropdownVisible(false);
            }
        };
        document.addEventListener('click', handleClick);
        onCleanup(() => document.removeEventListener('click', handleClick));
    });

    const handleOnSearchInputChange = (event: any) => {
        setSearchText(event.target.value);

        if (searchText()) {
            setIsDropdownVisible(true);
        } else {
            setIsDropdownVisible(false);
        }

        search();
    };

    const handleOnDropdownItemClick = (title: string) => {
        searchPage.url && navigate(`${searchPage.url}?s=${title}`);
    };

    return (
        <ErrorCatcher componentName="SearchWidget">
            <Section widthType='bgFull' isLayoutSection={true}>
                <Column>
                    <SearchWidgetWrapper>
                        <Heading tag="h3" variant="medium" textTransform="none">
                            {localize('atos-search-tool', 'Atos search tool')}
                        </Heading>
                        <Text color={'darkGray'} fontSize="small" displayRedVerticalLine={false}>
                            {localize('did-you-look-for-something-specific', 'Did you look for something specific? Please use our search tool and try again.')}
                        </Text>

                        <StyledSearchFormWrapper class="searchFormWrapper">
                            <StyledSearchForm onSubmit={formSubmit} novalidate role="search" ref={formRef}>
                                <StyledSearchInput
                                    type="search"
                                    placeholder={localize('use-atos-search-tool-for-what-you-are-looking-for', 'Write a keyword for what you are looking for…')}
                                    onInput={handleOnSearchInputChange}
                                    value={searchText()}
                                    onClick={() => setIsDropdownVisible(true)}
                                />
                                <StyledButton type="submit">
                                    <MagnifyingGlassIcon
                                        {...(AppState.viewport.width < theme.breakpoints.MOBILE
                                            ? {
                                                height: 2,
                                                width: 2,
                                                opacity: 0.7,
                                            }
                                            : {})}
                                    />
                                </StyledButton>
                            </StyledSearchForm>

                            <StyledResultsDropdown isDropdownVisible={isDropdownVisible()} ref={dropdownRef}>
                                <For each={searchResult()} fallback={<StyledDropdownItem noResults={true}>{localize('no-results', 'No results...')}</StyledDropdownItem>}>
                                    {(item: any) => <StyledDropdownItem onClick={() => handleOnDropdownItemClick(item?.postTitle)}>{removeAmpersand(item?.postTitle)} </StyledDropdownItem>}
                                </For>
                            </StyledResultsDropdown>
                        </StyledSearchFormWrapper>
                    </SearchWidgetWrapper>
                </Column>
            </Section>
        </ErrorCatcher>
    );
};
