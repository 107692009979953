import { styled } from 'solid-styled-components';
import { Link } from '@solidjs/router';
import theme, { fontFamilyAtosBold } from '../../../../../style/theme';

export const StyledFirstLevelMenu = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledListItem = styled.li<{ isOpen: boolean }>`
    background: ${p => p.isOpen ? theme.palette.lightBeige : 'transparent'};
    margin-inline: -1rem;
    padding-inline: 1rem;
    border-radius: 1rem;
    margin-top: -0.5rem;
    padding-top: 0.5rem;
`;

export const StyledFirstLevelMenuItem = styled.button`
    text-align: start;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.29rem;
    color: ${theme.palette.darkGray};
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    cursor: pointer;
    padding-block: 0.5rem;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    &:hover {
        color: ${theme.palette.red};

        svg {
            stroke: ${theme.palette.red};
        }
    }
`;

export const StyledSecondaryMenuItem = styled(Link)`
    display: block;
    color: ${theme.palette.darkGray};
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.29rem;
    text-decoration: none;
    padding: 0.8rem 1rem;

    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const StyledSecondaryMenuItemParentLink = styled(StyledSecondaryMenuItem)`
    color: ${theme.palette.darkGray};
    border-color: ${theme.palette.darkGray};

    &:hover {
        color: ${theme.palette.red};
    }
`;

export const StyledUnorderedList = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
`;
