import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../style/theme';

const common = `
    color: ${theme.palette.darkGray};
    border-radius: 1rem;
    cursor: pointer;
    border: none;
    letter-spacing: 0.45px;
    font-family: ${fontFamilyAtosRegular};
    font-size: clamp(1.14rem, 1.14rem + 1.14vw,  1.29rem);
    font-weight: 400;
    line-height: 1.57rem;
    text-align: center;
    transition: background 0.2s;
    margin-right: 1rem;
    padding: 1.5rem 3.36rem;


    ${theme.mediaQueries.mobileMax} {
        font-size: 1.4rem;
        letter-spacing: 0.4px;
        line-height: 1.36rem;
    }
`;

export const StyledViewStyleButton = styled.button<{ isActive: boolean }>`
    ${common};
    background: ${(p) => (p.isActive ? theme.palette.beige : theme.palette.transparent)};
    display: flex;

    svg {
        margin-right: 1rem;
        align-self: center;
    }
`;
