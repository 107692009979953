import { createEffect, createSignal, For, onCleanup, Show } from 'solid-js';
import { Section } from '../../../grid-system/section/section';
import { Button } from '../../../ui-components/button/button';
import { Heading } from '../../../ui-components/heading/heading';
import { Checkbox } from '../../../ui-components/inputs/checkbox/checkbox';
import { StyledFlexRow } from '../../../ui-components/utility-style-components/flex';
import { CrossIcon } from '../../icons-library/cross';
import { StyledHeadingContainer, StyledList, StyledMobileFiltersModal } from './mobile-filters-modal.style';
import { Portal } from 'solid-js/web';
import { NestedCategory, ProductsStoreAndMethods } from '../products-types';


type MobileFilterModalProps = {
    closeModal: () => void;
    applyFiltersText: string;
    productCategoriesHeading: string;
    typesHeading: string;
    store: ProductsStoreAndMethods;
    clickHandlers: {
        handleOnClickArea: (area: string) => void;
        handleOnClickCategory: (category: NestedCategory) => void;
        handleOnClickType: (type: NestedCategory) => void;
        handleOnClickClearFilters: (e: any) => void;
    }
}

export const MobileFilterModal = (props: MobileFilterModalProps) => {
    const [ inputRef, setInputRef ] = createSignal<HTMLInputElement | undefined>(undefined);

    createEffect(() => {
        if (!inputRef()) return;

        inputRef()!.tabIndex = -1;
        inputRef()!.focus();
        inputRef()!.tabIndex = 0;
    });

    const onKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.closeModal();
        }
    };

    document.addEventListener('keydown', onKeyPress);

    onCleanup(() => {
        document.removeEventListener('keydown', onKeyPress);
    });
        
    return (
        <Portal mount={document.getElementById('app')!}>
            <StyledMobileFiltersModal onScroll={e => e.preventDefault()}>
                <Section 
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    backgroundValue={'white'}
                    backgroundType={'color'}
                    heightType={'fill'}
                    customCss='border-radius: 1.07rem;'
                >
                    <div>
                        <StyledFlexRow justifyContent='end'>
                            <CrossIcon size='small' onClick={props.closeModal} />
                        </StyledFlexRow>

                        <StyledHeadingContainer>
                            <Heading noBlockSpacing={true} tag='h3' variant='small'>
                                {props.productCategoriesHeading}
                            </Heading>
                        </StyledHeadingContainer>
                        <StyledList>
                            <For each={props.store.store.availableProductCategories}>{(category, index) => (
                                <li>
                                    <Checkbox
                                        value={category?.data?.slug}
                                        inputRef={(el: HTMLInputElement) => index() === 0 && setInputRef(el)}
                                        whenClicked={() => props.clickHandlers.handleOnClickCategory(category)}
                                        name={category?.data?.name}
                                        isChecked={!!props.store.store?.selectedProductCategories?.find(existing => existing?.data?.slug === category?.data?.slug)}
                                    />
                                </li>
                            )}</For>
                        </StyledList>

                        <Show when={props.store.store?.availableTypes?.length > 0}>
                            <StyledHeadingContainer>
                                <Heading noBlockSpacing={true} tag='h3' variant='small'>
                                    {props.typesHeading}
                                </Heading>
                            </StyledHeadingContainer>
                            <StyledList>
                                <For each={props.store.store.availableTypes}>{(type) => (
                                    <li>
                                        <Checkbox
                                            value={type?.data?.slug}
                                            whenClicked={() => props.clickHandlers.handleOnClickType(type)}
                                            name={type?.data?.name}
                                            isChecked={!!props.store.store.selectedTypes?.find(existing => existing?.data?.slug === type?.data?.slug)}
                                        />
                                    </li>
                                )}</For>
                            </StyledList>
                        </Show>

                        <StyledFlexRow justifyContent='center'>
                            <Button label={props.applyFiltersText} onClick={props.closeModal} />
                        </StyledFlexRow>
                    </div>
                </Section>
            </StyledMobileFiltersModal>
        </Portal>
    );
};