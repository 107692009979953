import { createEffect, createSignal, For, onMount, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Heading } from '../../ui-components/heading/heading';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { Radio } from '../../ui-components/inputs/radio/radio';
import {
    gridSettings,
    StyledSidebar,
    StyledSidebarContainer,
    StyledSidebarContainerLine,
    StyledSidebarInner
} from '../../ui-components/layouts/sidebar.style';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { DocumentType, ProductCategory, ProductData } from '../product/product-types';
import { fetchProductCategories, fetchProducts } from '../products/fetch-products';
import { FilterList } from '../../ui-components/filter-list/filter-list';
import { MobileProductsSidebar } from '../products/mobile/mobile-sidebar';
import { extractCategories, sortAlphabetically, sortByOrder } from '../products/products';
import { CategoryWithData, NestedCategory } from '../products/products-types';
import {
    StyledClearAllContainer, StyledFilterHeadingContainer, StyledProductsContainer
} from '../products/products.style';
import {
    changeAreaOfCare,
    changeSelectedProductCategory,
    changeSelectedType,
    clearFilters, IfuProductsStore,
    setAvailableAreasOfCare, setIfuProductsStore, setIsMobile, setIsTablet
} from '../../stores/instructions-for-use-products-store';
import { StyledDocument, StyledDocumentRow, StyledDocumentsList, StyledHeadingWrapper, StyledSearchInput, StyledTopRow } from './instructions-for-use.styles';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';
import { Button } from '../../ui-components/button/button';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';

const extractAllCategories = (products: ProductData[], freshProductCategories: ProductCategory[]) => {
    if (!products || !freshProductCategories) {
        return null;
    }

    const categoriesAggregator: NestedCategory = {} as NestedCategory;

    products
        .filter((prod: ProductData) => {
            const hasDocuments = prod.documents?.length > 0;
            if (!hasDocuments) {
                return false;
            }
        
            // Some files have urls that are false. Don't know why (you probably just have to re-save the products in wordpress),
            // but it may lead to categories without products in results. So we filter out those products here.
            const filesWithUrl = prod.documents.filter(doc => {
                return doc?.file?.url;
            });
        
            if (filesWithUrl.length === 0) {
                return false;
            }
            
            return true;
        })
        .forEach((prod: ProductData) => {
            extractCategories(prod.categories, categoriesAggregator, freshProductCategories);
        });

    return categoriesAggregator;
};

export const InstructionsForUse = () => {
    const {viewport, localize} = useContext(AppContext);
    const [searchQuery, setSearchQuery] = createSignal('');

    const productsData = fetchProducts({});
    const loaded = () => productsData();

    const freshProductCategories = fetchProductCategories();
    
    onMount(() => {
        const parsedNestedCategories = extractAllCategories(productsData(), freshProductCategories());

        if (!parsedNestedCategories) {
            return;
        }
        
        const alphabetizedAoc = sortAlphabetically(parsedNestedCategories as CategoryWithData);
        const orderedAoc = sortByOrder(alphabetizedAoc);

        setAvailableAreasOfCare(orderedAoc);
        setIfuProductsStore({
            labels: {
                heading: localize('ifu-heading', 'IFU - Instructions for use'),
                topCategoryHeading: localize('area-of-care', 'Area of care'),
                firstNestedCategoryHeading: localize('product-category', 'Product category'),
                secondNestedCategoryHeading: localize('type-of-product', 'Type of product'),

                filtersHeading: localize('filters', 'Filters'),
                applyFiltersText: localize('apply-filters', 'Apply filters'),
            }
        });
    });

    createEffect(() => setIsTablet(viewport.width <= theme.breakpoints.TABLET));
    createEffect(() => setIsMobile(viewport.width <= theme.breakpoints.MOBILE));

    const breadcrumb = () => `${localize('ifus', 'IFUs')} ${IfuProductsStore.areaOfCare?.name ? `/ ${IfuProductsStore.areaOfCare?.name}` : ' / All'}`;

    const documents = () =>  {
        const results = productsData()
            ?.filter((prod: ProductData) => {
                const area = IfuProductsStore.areaOfCare?.slug;

                if (!area) return true;
                return prod.tags?.includes(area);
            })
            ?.filter((prod: ProductData) => {
                if (IfuProductsStore?.selectedProductCategories?.length === 0) return true;

                const allCategorySlugs = IfuProductsStore?.selectedProductCategories?.map((category: NestedCategory) => category.data.slug); 

                return !!prod.tags?.find((tag: string) => allCategorySlugs.includes(tag));
            })
            ?.filter((prod: ProductData) => {
                if (IfuProductsStore.selectedTypes?.length === 0) return true;

                const allTypeSlugs = IfuProductsStore.selectedTypes?.map(type => type.data.slug);

                return !!prod.tags?.find((tag: string) => allTypeSlugs.includes(tag));
            })
            ?.filter((prod: ProductData) => {
                // Search logic
                if(searchQuery() === '') {
                    return true;
                }

                if(prod.post_title.toLowerCase().includes(searchQuery())) {
                    return true;
                }
                const documents = prod.documents;
                const documentTitles = documents
                    .filter((doc: DocumentType) => {
                        return doc?.file?.url;
                    })
                    .map((doc: DocumentType) => doc.file.title.toLowerCase());

                if(documentTitles.filter((title: string) => title.includes(searchQuery())).length > 0) {
                    return true;
                }

                const keywords = documents.reduce((acc: string[], doc: DocumentType) => {
                    return [...acc, ...doc.keywords];
                },[]);

                const queryInKeyWords = keywords.filter((key: string) => key.toLowerCase().includes(searchQuery()) ).length > 0;
                return queryInKeyWords;
            })
            ?.sort((a: ProductData, b: ProductData) => {
                return a.post_title.localeCompare(b.post_title);
            }).reduce((accumulated: DocumentType[],product: ProductData) => {
                // go through and make sure we do not have duplicate documents
                // i.e. the same document is linked to two different products
                product.documents.forEach((document: DocumentType) => {
                    const found = accumulated.find(({file: {url}}) => url === document.file.url);

                    if(!found) {
                        accumulated.push(document);
                    }
                });
                return accumulated;
            },[]);

        setIfuProductsStore({ results: results });
        return results;
    };

    return (
        <ErrorCatcher componentName='IFU'>
            <Show when={loaded()}>
                <Section 
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    heightType={'fill'}
                    backgroundType={'color'}
                    backgroundValue={'white'}
                    removeSpacingBlock={true}
                    customCss={'padding-top: 0; padding-bottom: 0;'}
                >
                    <Grid {...gridSettings.container}>
                        <StyledSidebarContainer>
                            <StyledSidebarContainerLine>
                                <StyledSidebar>
                                    <StyledSidebarInner>
                                        <Show when={IfuProductsStore?.isTablet}>
                                            <StyledHeadingWrapper>
                                                <Heading tag='h1' variant='breadcrumb'>{breadcrumb()}</Heading>
                                                <Heading tag='h2' variant='xxlarge'>
                                                    {localize('ifu-heading', 'IFU - Instructions for use')}
                                                </Heading>
                                            </StyledHeadingWrapper>

                                            <StyledVerticalSpace size={3} />

                                            <StyledFlexRow justifyContent={IfuProductsStore.isMobile ? 'center' : 'end'}>
                                                <StyledSearchInput
                                                    oninput={(e: any) => setSearchQuery(e.target.value.toLowerCase())} 
                                                    placeholder={localize('search', 'Search...')}
                                                />
                                            </StyledFlexRow>

                                            <StyledVerticalSpace size={2} />

                                            <Show when={IfuProductsStore?.isMobile}>
                                                <MobileProductsSidebar
                                                    store={{
                                                        store: IfuProductsStore,
                                                        changeAreaOfCare,
                                                        clearFilters,
                                                        changeSelectedProductCategory,
                                                        changeSelectedType,
                                                    }}
                                                    labels={IfuProductsStore?.labels}
                                                />
                                            </Show>
                                        </Show>
                                        <Show when={!IfuProductsStore?.isMobile}>
                                            <StyledFilterHeadingContainer>
                                                <Heading tag='h2' variant='medium' noBlockSpacing={true}>{localize('filters', 'Filters')}</Heading>
                                            </StyledFilterHeadingContainer>

                                            <FilterList listHeading={localize('area-of-care', 'Area of care')}>
                                                <For each={Object.keys(IfuProductsStore.availableAreasOfCare)}>{(area) => (
                                                    <li>
                                                        <Radio
                                                            value={IfuProductsStore.availableAreasOfCare[area]?.data?.slug}
                                                            whenClicked={() => changeAreaOfCare(IfuProductsStore.availableAreasOfCare[area])}
                                                            name='areaOfCare'
                                                            readableName={IfuProductsStore.availableAreasOfCare[area]?.data?.name}
                                                            isChecked={IfuProductsStore.areaOfCare?.slug === IfuProductsStore.availableAreasOfCare[area]?.data?.slug}
                                                        />
                                                    </li>
                                                )}</For>
                                            </FilterList>

                                            <Show when={IfuProductsStore.availableProductCategories.length > 0}>
                                                <FilterList listHeading={localize('product-category', 'Product category')}>
                                                    <For each={IfuProductsStore.availableProductCategories}>{(category) => (
                                                        <li>
                                                            <Checkbox
                                                                value={category?.data?.slug}
                                                                whenClicked={() => changeSelectedProductCategory(category)}
                                                                name={category?.data?.name}
                                                                isChecked={!!IfuProductsStore?.selectedProductCategories?.find(existing => existing?.data?.slug === category?.data?.slug)}
                                                            />
                                                        </li>
                                                    )}</For>
                                                </FilterList>
                                            </Show>

                                            <Show when={IfuProductsStore.availableTypes.length > 0}>
                                                <FilterList listHeading={localize('type-of-product', 'Type of product')}>
                                                    <For each={IfuProductsStore.availableTypes}>{(type) => (
                                                        <li>
                                                            <Checkbox
                                                                value={type?.data?.slug}
                                                                whenClicked={() => changeSelectedType(type)}
                                                                name={type?.data?.name}
                                                                isChecked={!!IfuProductsStore.selectedTypes?.find(existing => existing?.data?.slug === type?.data?.slug)}
                                                            />
                                                        </li>
                                                    )}</For>
                                                </FilterList>
                                            </Show>
                                        </Show>

                                        <Show when={IfuProductsStore.areaOfCare}>
                                            <StyledClearAllContainer>
                                                <Button
                                                    label={localize('clear-all', 'Clear all')}
                                                    onClick={(e) => clearFilters(e)}
                                                    variant='tertiary'
                                                    noCaps={true}
                                                />
                                            </StyledClearAllContainer>
                                        </Show>
                                    </StyledSidebarInner>
                                </StyledSidebar>
                            </StyledSidebarContainerLine>
                        </StyledSidebarContainer>

                        <StyledProductsContainer>
                            <Show when={!IfuProductsStore.isTablet}>
                                <StyledTopRow>
                                    <Heading tag='h2' variant='xxlarge'>
                                        {localize('ifu-heading', 'IFU - Instructions for use')}
                                    </Heading>
                                    <StyledSearchInput
                                        oninput={(e: any) => setSearchQuery(e.target.value.toLowerCase())}
                                        placeholder={localize('search', 'Search...')}
                                    />
                                </StyledTopRow>
                            </Show>
                            <Show when={!IfuProductsStore.isTablet}>
                                <Heading tag='h1' variant='breadcrumb'>{breadcrumb()}</Heading>
                            </Show>
                            <StyledDocumentsList>
                                <For each={documents()}>
                                    {(document: DocumentType) => (
                                        <Show when={document?.file?.url}>
                                            <StyledDocumentRow>
                                                <StyledDocument href={removeTrailingSlash(document.file.url)} target="_blank">
                                                    {document.file?.title}
                                                </StyledDocument>
                                            </StyledDocumentRow>
                                        </Show>
                                    )}
                                </For>
                            </StyledDocumentsList>
                        </StyledProductsContainer>
                    </Grid>
                </Section>
            </Show>
        </ErrorCatcher>
    );
};

InstructionsForUse.parseProps = (atts: any) => {    
    return {
        labels: atts.labels,
    };
};
