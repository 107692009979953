import { gql } from 'graphql-request';
import { createMemo, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ProductData } from '../product/product-types';
import { ProductContainer } from './products-types';

const GET_PRODUCTS_QUERY = gql`
    query GetProducts(
        $tags: [String],
        $limit: Int
    ) {
        products: resources(
            type: ["product"]
            tags: $tags
            limit: $limit
            order: DESC
        ) {
            content
        }
    }
`;

const GET_PRODUCT_CATEGORIES = gql`
    query GetProductCategories(
        $key: String
    ) {
        resource(
            key: $key
        ) {
            key
            content
        }
    }
`;

export const fetchProductCategories = () => {
    const { createCachedResource } = useContext(AppContext);

    const [productCategories] = createCachedResource(
        GET_PRODUCT_CATEGORIES,
        {
            key: '/wp-json/rawb/v1/product-categories',
        },
        true
    );

    return createMemo(() => {
        const categories = productCategories()?.resource?.content?.productCategories;
        if (!categories) return [];
        return categories;
    });
};


type ProductsFetchprops = {
    tag?: {
        slug: string;
    };
    limit?: number;
};

export const fetchProducts = (props: ProductsFetchprops) => {
    const { createCachedResource } = useContext(AppContext);
    const [productsContent] = createCachedResource(
        GET_PRODUCTS_QUERY,
        () => ({
            ...(props?.tag?.slug ? { tags: [props?.tag?.slug] } : null),
            ...(props?.limit ? { limit: props?.limit } : null),
        }),
        true
    );

    return createMemo((): ProductData[] =>
        productsContent()
            ?.products?.map((product: ProductContainer) => product.content)
            // We sort products them oldest first because editors have used the date to prioritize them artificially
            .sort((a: ProductData, b: ProductData) => {
                // Ordered based on the post's order ranking and date
                const dateA = new Date(a.date).getTime();
                const dateB = new Date(b.date).getTime();
                const orderRankA = (a.productOrderRanking && parseInt(a.productOrderRanking, 10)) || 10;
                const orderRankB = (b.productOrderRanking && parseInt(b.productOrderRanking, 10)) || 10;

                if (orderRankA !== orderRankB) {
                    return orderRankA - orderRankB;
                }

                return dateA - dateB;
            })
    );
};
