import { gql } from 'graphql-request';
import { createMemo, For, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { gridSettings, StyledSidebarContainerLine } from '../../ui-components/layouts/sidebar.style';
import { Slider } from '../../ui-components/slider/slider';
import { ProductContainer, ProductsData } from '../products/products-types';
import { ProductsSpotlightProps } from './products-spotlight.d';
import {
    StyledButtonWrapper,
    StyledCollectionHeadingWrapper,
    StyledCollectionSidebar,
    StyledProductsSpotlightContainer,
    StyledSpotlightSidebarContainer,
} from './products-spotlight.style';
import { createGroupedProductCard } from '../products/products';

const GET_PRODUCTS_QUERY = gql`
    query GetProducts(
        $tags: [String]
    ) {
        products: resources(
            type: ["product"]
            tags: $tags
        ) {
            content
        }
    }
`;

export const ProductsSpotlight = (props: ProductsSpotlightProps) => {
    const { createCachedResource, viewport } = useContext(AppContext);
    const [productsContent] = createCachedResource(GET_PRODUCTS_QUERY, () => ({ tags: [props?.tag?.slug] }), true);
    const productsData = createMemo((): ProductsData => productsContent()?.products);

    const isTablet = () => viewport.width <= theme.breakpoints.TABLET;

    const createSliderCards = () => {
        let productsPerView = 3;
        let gapSize = theme.grid.standardUnit;

        switch (true) {
            case viewport.width <= theme.breakpoints.DESKTOP_LARGE && viewport.width > theme.breakpoints.TABLET:
                gapSize = theme.grid.gapSizeTablet;
                productsPerView = 2;
                break;
            case viewport.width <= theme.breakpoints.TABLET:
                productsPerView = 1;
                break;
        }

        const sliderGroups: ProductContainer[][] = [];

        const customSlidesProductSlugs: string[] | [] = props.customSlides && Object.values(props.customSlides).filter((slide: string) => slide !== '');

        // Custom sorting function
        const customSort = (a: ProductContainer, b: ProductContainer) => {
            if(!customSlidesProductSlugs.length){
                return 0;
            }

            const slugA = a.content.slug;
            const slugB = b.content.slug;
        
            if (slugA === slugB) return 0;
        
            const indexA = customSlidesProductSlugs.indexOf(slugA);
            const indexB = customSlidesProductSlugs.indexOf(slugB);
        
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
        
            return indexA - indexB;
        };
        

        let count = 0;
        productsData()
            .map((prod: ProductContainer) => prod)
            .sort((a: ProductContainer, b: ProductContainer) => {
                return a.content.post_title.localeCompare(b.content.post_title);
            })
            .sort(customSort)
            .forEach((product: ProductContainer, index: number) => {
                if (!sliderGroups[count]) sliderGroups[count] = [];

                sliderGroups[count].push(product);

                const productNumber = index + 1;
                if (productNumber % productsPerView === 0) {
                    count++;
                }
            });

        return (
            <Slider slidesPerView={1} spacing={gapSize} slideHeight={'auto'}>
                <For each={sliderGroups}>
                    {(group: ProductContainer[]) => (
                        <Grid {...gridSettings.listing} customCss={'height: auto;'}>
                            <For each={group}>{(product: ProductContainer) => {
                                return createGroupedProductCard(product.content);
                            }}</For>
                        </Grid>
                    )}
                </For>
            </Slider>
        );
    };

    return (
        <ErrorCatcher componentName="Products spotlight">
            <Show when={productsData()}>
                <Section
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    backgroundType={'color'}
                    backgroundValue={props.backgroundColor?.name || 'white'}
                    removeSpacingBlock={true}
                    customCss={'padding-top: 0 !important; padding-bottom: 0 !important;'}
                    {...(props.sectionIdentifier
                        ? {
                            sectionIdentifier: props.sectionIdentifier,
                        }
                        : {})}
                >
                    <Grid {...gridSettings.container}>
                        <StyledSpotlightSidebarContainer>
                            <StyledSidebarContainerLine>
                                <StyledCollectionSidebar>
                                    <StyledCollectionHeadingWrapper>
                                        <DynamicModuleGenerator skipSectionConversion={true} content={[props?.mainHeading]} />
                                        <DynamicModuleGenerator skipSectionConversion={true} content={[props?.subHeading]} />
                                    </StyledCollectionHeadingWrapper>

                                    <Show when={!isTablet()}>
                                        <StyledButtonWrapper>
                                            <DynamicModuleGenerator skipSectionConversion={true} content={[props?.button]} />
                                        </StyledButtonWrapper>
                                    </Show>
                                </StyledCollectionSidebar>
                            </StyledSidebarContainerLine>
                        </StyledSpotlightSidebarContainer>

                        <StyledProductsSpotlightContainer>
                            <div>{createSliderCards()}</div>
                        </StyledProductsSpotlightContainer>

                        <Show when={isTablet()}>
                            <StyledButtonWrapper isTablet={isTablet()}>
                                <DynamicModuleGenerator skipSectionConversion={true} content={[props?.button]} />
                            </StyledButtonWrapper>
                        </Show>
                    </Grid>
                </Section>
            </Show>
        </ErrorCatcher>
    );
};

ProductsSpotlight.parseProps = (atts: any) => {
    return {
        ...atts,
        mainHeading: atts.innerBlocks[0],
        subHeading: atts.innerBlocks[1],
        button: atts.innerBlocks[2],
        tag: atts.tag,
        backgroundColor: atts.backgroundColor,
        sectionIdentifier: atts.sectionIdentifier,
        customSlides: atts.customSlides || [],
    };
};
