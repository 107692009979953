import { Show, createSignal, onCleanup, onMount, useContext } from 'solid-js';
import { isServer } from 'solid-js/web';
import { Portal } from 'solid-js/web';
import { ErrorCatcher } from '../../tools/error-catcher';
import { StyledAppBanner, StyledAppHeading, StyledAppIcon, StyledAppInfo, StyledBannerContent, StyledButtonsContainer, StyledLede } from './native-app-prompt.style';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { AppContext } from '../../app-context-provider/app-context-provider';

export const NativeAppPrompt = () => {
    const { localize, updateAppPromptDismissed, appDownloadPrompt, siteDomains, siteInfo } = useContext(AppContext);
    const [ showPrompt, setShowPrompt ] = createSignal(false);
    let timeoutId: any;

    onMount(() => {
        const currentSite = siteDomains.find((site) => site.siteId === siteInfo.siteId);
        
        if (currentSite?.showInApp && !appDownloadPrompt.dismissed) {
            timeoutId = setTimeout(() => {
                // hide if its usa
                setShowPrompt(currentSite?.country === 'usa' ? false : true);
            }, 5000);
        }
    });

    onCleanup(() => {
        clearTimeout(timeoutId);
    });

    const isAppleDevice = () => {
        const isAppleDevice = navigator && /iphone|ipad/i.test(navigator.userAgent);
        return isAppleDevice;
    };

    const isMobileDevice = () => {
        const isMobileDevice = /Android|webOS|iPhone|iPad|Tablet|Kindle|Windows Phone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);        
        return isMobileDevice;
    };

    const dismissPrompt = () => {
        saveCookie();
        updateAppPromptDismissed(true);
        setShowPrompt(false);
    };

    const saveCookie = async () => {
        await fetch('/dismiss-app-prompt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ dismissAppPrompt: true }),
        });        
    };

    const downloadUrl = () => isAppleDevice()
        ? 'https://apps.apple.com/gb/app/atos-mylife/id1472333248'
        : 'https://play.google.com/store/apps/details?id=com.atos.mylife';


    if (isServer) {
        return <></>;
    }
    
    return (
        <ErrorCatcher componentName='App prompt'>
            <Show when={showPrompt() && isMobileDevice()} fallback={<></>}>

                <Portal mount={document.getElementById('root')!}>
                    <StyledAppBanner>
                        <StyledBannerContent>
                            <StyledAppInfo>
                                <StyledAppIcon alt="Atos MyLife app logo" src="/applogo.png" />
                                <div>
                                    <StyledAppHeading>
                                        Atos MyLife app
                                    </StyledAppHeading>
                                    <Heading tag='h5' variant='small' noBlockSpacing={true}>{localize('app-tagline', 'Living with laryngectomy')}</Heading>
                                    <StyledLede>
                                        <Text fontSize='small'>{localize('app-prompt-text', 'Install the laryngectomy app for a better experience.')}</Text>
                                    </StyledLede>
                                </div>

                            </StyledAppInfo>
                            <StyledButtonsContainer>
                                <Button
                                    label={localize('app-dismiss-prompt', 'Not now')}
                                    variant='secondary'
                                    onClick={() => dismissPrompt()}
                                />
                                <div onClick={() => dismissPrompt()}>
                                    <Button isLink={true} label={localize('app-install-button', 'Install')} url={downloadUrl()} />
                                </div>
                            </StyledButtonsContainer>
                        </StyledBannerContent>
                    </StyledAppBanner>
                </Portal>

            </Show>
        </ErrorCatcher>
    );

};