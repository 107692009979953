import { useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Text } from '../../text/text';
import theme from '../../../style/theme';
import { StyledInput } from './radio.style';
import { StyledLabel } from '../common.style';
import { ErrorCatcher } from '../../../tools/error-catcher';

type RadioProps = {
    whenClicked?: () => void;
    value: string;
    isChecked: boolean;
    name: string;
    id?: string;
    readableName?: string;
}

export const Radio = (props: RadioProps) => {
    const {viewport} = useContext(AppContext);
    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    const id = () => `radio-${(props.id || props.value || '')}`;

    return (
        <ErrorCatcher componentName='Radio'>
            <StyledLabel for={id()}>
                <StyledInput
                    id={id()}
                    onInput={props.whenClicked}
                    type='radio'
                    value={props.value}
                    checked={props.isChecked}
                    name={props.name}
                />
                <Text 
                    noBlockSpacing={true}
                    fontSize={isMobile() ? 'normal' : 'small'}
                    {...props.isChecked && {
                        color: theme.palette.red
                    }}
                >
                    {props.readableName}
                </Text>
            </StyledLabel>
        </ErrorCatcher>
    );
};
