import { Switch, Match } from 'solid-js';
import { HomeIcon } from '../../icons-library/product/home';
import { GoIcon } from '../../icons-library/product/go';
import { ProductIconsProps } from './product-icon-tab-types';
import { EnergyIcon } from '../../icons-library/product/energy';
import { FreeHandsIcon } from '../../icons-library/product/free-hands';
import { NightHMEIcon } from '../../icons-library/product/night-hme';
import { ProtectIcon } from '../../icons-library/product/protect';
import { ShowerIcon } from '../../icons-library/product/shower';
import { NightAdhesiveIcon } from '../../icons-library/product/night-adhesive';
import { StandardAdhesiveIcon } from '../../icons-library/product/standard-adhesive';
import { StabilityAdhesiveIcon } from '../../icons-library/product/stability-adhesive';
import { SensitiveAdhesiveIcon } from '../../icons-library/product/sensitive';
import { LarytubeIcon } from '../../icons-library/product/larytube';
import { LarybuttonIcon } from '../../icons-library/product/larybutton';
import { TracoeNeonatesIcon } from '../../icons-library/product/tracoe-neonates';
import { TracoePediatrictsIcon } from '../../icons-library/product/tracoe-pediatrics';

export const ProductIcons = (props: ProductIconsProps) => {
    const size = props.size ?? 3.57;

    return (
        <Switch>
            <Match when={props.name === 'home'}>
                <HomeIcon fill="white" width={size} height={size} />
            </Match>
            <Match when={props.name === 'go'}>
                <GoIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'energy'}>
                <EnergyIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'free-hands'}>
                <FreeHandsIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'night-hme'}>
                <NightHMEIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'protect'}>
                <ProtectIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'shower'}>
                <ShowerIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'night-adhesive'}>
                <NightAdhesiveIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'standard-adhesive'}>
                <StandardAdhesiveIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'stability-adhesive'}>
                <StabilityAdhesiveIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'sensitive-adhesive'}>
                <SensitiveAdhesiveIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'larytube'}>
                <LarytubeIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'larybutton'}>
                <LarybuttonIcon fill="white"  width={size} height={size} />
            </Match>
            <Match when={props.name === 'tracoe-neonates'}>
                <TracoeNeonatesIcon fill='white' width={size} height={size} />
            </Match>
            <Match when={props.name === 'tracoe-pediatric'}>
                <TracoePediatrictsIcon fill='white' width={size} height={size} />
            </Match>
        </Switch>
    );
};
