import { useContext, Show, For, createSignal, onMount } from 'solid-js';

import { gql } from 'graphql-request';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { Heading } from '../../ui-components/heading/heading';
import { StyledLoadMoreButtonContainer, StyledFeedContent } from './feed.styles';
import { FeedCard } from './social-media-feed-card/feed-card';
import { Button } from '../../ui-components/button/button';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';

const GET_FEED_QUERY = gql`
    query GetFeed {
        feed: getFeed {
            username
            image {
                url
                expiresAt
            }
            text
            url
            type
        }
    }
`;


export const Feed = (props: any) => {
    const AppState = useContext(AppContext);
    const [ feedResponse, setFeed ] = createSignal<any>([]);

    const [page, setPage] = createSignal(1);
    const perPageDesktop = 6;
    const perPageMobile = 3;

    const fetchFeed = async () => {
        const feed = await AppState.graphQlClient(GET_FEED_QUERY, {}, true);
        setFeed(feed);
    };

    onMount(() => {
        fetchFeed();
    });

    const placeholders = () => {
        const amount = AppState.viewport.width > theme.breakpoints.MOBILE ? perPageDesktop : perPageMobile;
        const items = [];
        for (let x = 0; x < amount; x++) {
            items.push(<FeedCard text={AppState.localize('loading', 'Loading')} icon={'facebook'} url={''} username={AppState.localize('loading', 'Loading')} />);
        }
        return (
            <>{items}</>
        );
    };

    return (
        <ErrorCatcher componentName='Social media feed'>
            <Heading tag="h3" variant="small">
                {props.eyebrowHeading}
            </Heading>
            <Heading tag="h2" variant="xlarge">
                {props.heading}
            </Heading>
            <StyledFeedContent>
                <Grid templateShorthand={[4, 4, 4]} responsive={{ mobile: [12], tablet: [12] }}>
                    <Show when={feedResponse()?.feed?.length} fallback={placeholders()}>
                        <For
                            each={
                                AppState.viewport.width > theme.breakpoints.MOBILE
                                    ? feedResponse().feed.slice(0, page() * perPageDesktop)
                                    : feedResponse().feed.slice(0, page() * perPageMobile)
                            }
                        >
                            {(item: any) => <FeedCard text={item.text} icon={item.type} url={item.url} username={item.username} image={item.image} />}
                        </For>
                    </Show>
                </Grid>
            </StyledFeedContent>
            <StyledLoadMoreButtonContainer>
                <Button variant="tertiary" label={AppState.localize('load-more-posts', 'Load more posts')} onClick={() => setPage(page() + 1)}></Button>
            </StyledLoadMoreButtonContainer>
        </ErrorCatcher>
    );
};

Feed.parseProps = (atts: any) => {
    return atts;
};
