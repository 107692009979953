export const SwirlMobile = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="480" height="219" viewBox="0 0 375 219">
            <g id="Mask_Group_30" data-name="Mask Group 30" transform="translate(0 -686)" style="mix-blend-mode: normal;isolation: isolate">
                <g id="Group_2685" data-name="Group 2685" transform="translate(-49.716 752.5)" opacity="0.324">
                    <g id="Group_1468" data-name="Group 1468" transform="translate(12.644 -2)" >
                        <path id="Path_171" data-name="Path 171" d="M449.144,219.763c-16.07,0-16.024,21.284-32.082,21.284-16,0-16.115-25.06-32.217-25.06-15.906,0-16.019,41.244-31.947,41.244-16.082,0-16.041-27.932-32.082-27.932s-16.111,27.233-32.082,27.233-13.855-22.1-29.957-22.1-15.957,31.089-32.082,31.089-18.1-48.321-34.206-48.321-16.111,32.463-32.082,32.463S144.383,229.5,128.327,229.5c-16.041,0-16,15.2-32.082,15.2-15.928,0-16.041-6.836-31.947-6.836-16.1,0-16.219,9.2-32.217,9.2-16.058,0-16.012-27.292-32.082-27.292" transform="translate(0 -139.375)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_172" data-name="Path 172" d="M0,215.667c16.462,0,16.6,26.055,32.749,25.987a28.806,28.806,0,0,0,13.092-2.846c5.481-2.588,9.415-6.123,19.273-6.268,15.669-.257,16.016,5.923,31.769,5.726,6.319-.076,10.213-2.435,13.651-5.221,5.243-4.216,9.2-9.425,18.713-9.305,15.894.239,16.223,19.993,32.4,19.993,16.594,0,16.6-32.769,33.316-32.769,15.9,0,18.082,46.651,33.818,46.9,15.678.188,15.686-29.484,31.332-29.281,15.62.171,13.733,21.574,29.273,21.684,12.819.1,15.271-17.628,24.552-23.883a12.2,12.2,0,0,1,7.016-2.147c15.748-.012,15.941,26.792,31.6,26.72,15.618-.109,15.946-39.915,31.581-40.006,15.673-.051,16.013,24.011,31.7,24.011,16.679,0,16.624-19.892,33.316-19.892" transform="translate(0 -136.126)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_173" data-name="Path 173" d="M0,209.4c16.855,0,17.177,24.819,33.416,24.682a30.932,30.932,0,0,0,13.158-2.757c5.632-2.484,9.673-5.985,19.356-6.274,15.431-.515,16.014,5.01,31.59,4.615,6.24-.151,10.172-2.477,13.736-5.139,5.443-4.066,9.539-8.977,18.91-8.715,15.732.44,16.335,19.815,32.71,19.815,17.217,0,17.218-33.074,34.55-33.074,15.7,0,18.082,44.982,33.429,45.481,15.231.375,15.392-27.848,30.582-27.474,15.137.373,13.481,21.053,28.589,21.272,12.476.2,15.028-16.758,24.183-22.763A12.182,12.182,0,0,1,321.081,217c15.455-.023,15.883,25.689,31.121,25.509,15.308-.18,15.85-38.625,31.214-38.768,15.243-.143,15.808,22.961,31.178,22.961,17.3,0,17.235-18.5,34.55-18.5" transform="translate(0 -130.705)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_174" data-name="Path 174" d="M0,203.11c17.248,0,17.753,23.544,34.083,23.378a33.563,33.563,0,0,0,13.224-2.669c5.784-2.381,9.932-5.821,19.439-6.28,15.2-.733,16.012,4.1,31.413,3.506,6.161-.227,10.137-2.513,13.82-5.058,5.652-3.906,9.88-8.517,19.107-8.125,15.572.662,16.447,19.636,33.024,19.636,17.839,0,17.837-33.379,35.783-33.379,15.5,0,18.082,43.312,33.04,44.062,14.785.563,15.1-26.228,29.832-25.667,14.655.558,13.229,20.531,27.905,20.861,12.132.293,14.784-15.888,23.813-21.644a12.171,12.171,0,0,1,6.728-1.98c15.162-.035,15.824,24.566,30.641,24.3,15-.271,15.754-37.314,30.848-37.531,14.814-.212,15.6,21.912,30.659,21.912,17.921,0,17.847-17.109,35.784-17.109" transform="translate(0 -125.263)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_175" data-name="Path 175" d="M0,196.821c17.641,0,18.329,22.3,34.749,22.073a36.394,36.394,0,0,0,13.29-2.581c5.935-2.278,10.191-5.676,19.522-6.286,14.961-.979,16.01,3.185,31.235,2.395,6.082-.3,10.1-2.548,13.9-4.977,5.863-3.745,10.222-8.056,19.305-7.534,15.411.884,16.559,19.457,33.337,19.457,18.462,0,18.457-33.685,37.017-33.685,15.3,0,18.082,41.642,32.651,42.642,14.338.751,14.8-24.609,29.082-23.859,14.171.743,12.976,20.009,27.221,20.449,11.789.391,14.554-15,23.443-20.523a12.22,12.22,0,0,1,6.585-1.9c14.87-.046,15.766,23.442,30.161,23.086,14.688-.363,15.658-36,30.481-36.293,14.385-.281,15.4,20.863,30.141,20.863,18.541,0,18.458-15.717,37.017-15.717" transform="translate(0 -119.821)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_176" data-name="Path 176" d="M0,190.531c18.033,0,18.9,21.05,35.416,20.769a39.412,39.412,0,0,0,13.356-2.492c6.091-2.162,10.45-5.53,19.6-6.292,14.726-1.226,16.008,2.273,31.057,1.285,6-.377,10.071-2.583,13.989-4.895,6.075-3.584,10.564-7.594,19.5-6.944,15.252,1.108,16.671,19.278,33.652,19.278,19.085,0,19.076-33.99,38.251-33.99,15.1,0,18.082,39.973,32.262,41.223,13.892.938,14.509-22.989,28.332-22.052,13.688.929,12.723,19.488,26.537,20.037,11.445.489,14.314-14.125,23.073-19.4a12.248,12.248,0,0,1,6.441-1.813c14.576-.058,15.708,22.317,29.68,21.875,14.38-.455,15.562-34.691,30.115-35.055,13.955-.349,15.193,19.814,29.622,19.814,19.163,0,19.07-14.325,38.251-14.325" transform="translate(0 -114.377)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_177" data-name="Path 177" d="M0,184.242c18.426,0,19.48,19.8,36.083,19.464a43.062,43.062,0,0,0,13.422-2.4c6.243-2.057,10.709-5.386,19.688-6.3,14.491-1.473,16.005,1.36,30.879.175,5.924-.453,10.038-2.617,14.074-4.814,6.288-3.423,10.907-7.131,19.7-6.354,15.093,1.334,16.782,19.1,33.966,19.1,19.708,0,19.7-34.3,39.485-34.3,14.9,0,18.082,38.3,31.874,39.8,13.445,1.126,14.213-21.37,27.582-20.244,13.2,1.112,12.471,18.965,25.854,19.625,11.1.587,14.072-13.251,22.7-18.283a12.3,12.3,0,0,1,6.3-1.73c14.283-.07,15.65,21.191,29.2,20.663,14.071-.548,15.466-33.379,29.749-33.817,13.525-.416,14.987,18.764,29.1,18.764,19.784,0,19.682-12.933,39.485-12.933" transform="translate(0 -108.935)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_178" data-name="Path 178" d="M0,177.952c18.819,0,20.057,18.559,36.75,18.16A47.3,47.3,0,0,0,50.238,193.8c6.394-1.952,10.969-5.241,19.771-6.3,14.256-1.722,16,.447,30.7-.935,5.845-.529,10.007-2.651,14.159-4.732,6.5-3.26,11.249-6.667,19.9-5.764,14.934,1.56,16.895,18.92,34.28,18.92,20.33,0,20.315-34.6,40.719-34.6,14.7,0,18.082,36.634,31.484,38.383,13,1.313,13.917-19.752,26.832-18.436,12.719,1.3,12.22,18.416,25.17,19.213,10.76.662,13.831-12.377,22.333-17.163a12.379,12.379,0,0,1,6.154-1.646c13.991-.081,15.593,20.064,28.72,19.452,13.762-.641,15.369-32.065,29.382-32.58,13.1-.481,14.782,17.716,28.583,17.716,20.405,0,20.294-11.541,40.719-11.541" transform="translate(0 -103.492)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_179" data-name="Path 179" d="M0,171.663c19.211,0,20.632,17.315,37.417,16.855a52.266,52.266,0,0,0,13.554-2.227c6.545-1.847,11.228-5.1,19.854-6.31,14.022-1.971,16-.494,30.523-2.045,5.764-.616,9.976-2.684,14.243-4.651,6.717-3.1,11.592-6.2,20.1-5.174,14.775,1.788,17.006,18.741,34.593,18.741,20.954,0,20.934-34.906,41.953-34.906,14.495,0,18.082,34.964,31.1,36.964,12.552,1.5,13.619-18.135,26.082-16.629,12.233,1.478,11.969,17.891,24.486,18.8,10.417.757,13.589-11.5,21.964-16.044a12.479,12.479,0,0,1,6.01-1.562c13.7-.093,15.536,18.936,28.24,18.241,13.455-.736,15.272-30.751,29.016-31.342,12.665-.544,14.577,16.666,28.065,16.666,21.026,0,20.905-10.149,41.953-10.149" transform="translate(0 -98.05)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_180" data-name="Path 180" d="M0,165.373c19.6,0,21.208,16.072,38.084,15.551a58.168,58.168,0,0,0,13.62-2.139c6.7-1.743,11.489-4.955,19.937-6.316,13.788-2.222,15.994-1.409,30.345-3.155,5.685-.692,9.945-2.716,14.328-4.57,6.933-2.932,11.935-5.737,20.292-4.584,14.617,2.018,17.118,18.562,34.908,18.562,21.576,0,21.553-35.211,43.187-35.211,14.293,0,18.082,33.295,30.707,35.544,12.106,1.689,13.322-16.519,25.332-14.821,11.747,1.66,11.717,17.367,23.8,18.389,10.074.852,13.347-10.63,21.594-14.924A12.615,12.615,0,0,1,322,166.22c13.405-.1,15.479,17.807,27.76,17.03,13.146-.832,15.175-29.435,28.649-30.1,12.234-.607,14.372,15.617,27.545,15.617,21.647,0,21.517-8.757,43.187-8.757" transform="translate(0 -92.607)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_181" data-name="Path 181" d="M0,159.084c20,0,21.784,14.83,38.751,14.246a65.3,65.3,0,0,0,13.686-2.05c6.847-1.638,11.748-4.813,20.02-6.322,13.555-2.473,15.991-2.323,30.167-4.265,5.606-.768,9.915-2.748,14.413-4.488,7.149-2.766,12.278-5.271,20.49-3.994,14.459,2.249,17.23,18.383,35.222,18.383,22.2,0,22.173-35.517,44.421-35.517,14.092,0,18.082,31.625,30.318,34.125,11.66,1.876,13.024-14.9,24.582-13.014,11.261,1.841,11.465,16.842,23.118,17.977,9.731.948,13.1-9.757,21.224-13.8a12.778,12.778,0,0,1,5.723-1.4c13.112-.116,15.422,16.676,27.279,15.818,12.839-.929,15.077-28.119,28.283-28.866,11.8-.668,14.166,14.568,27.026,14.568,22.268,0,22.128-7.365,44.421-7.365" transform="translate(0 -87.164)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_182" data-name="Path 182" d="M0,152.795c20.39,0,22.36,13.589,39.418,12.942a74.06,74.06,0,0,0,13.752-1.962c7-1.535,12.008-4.671,20.1-6.328,13.321-2.726,15.988-3.237,29.989-5.376,5.526-.844,9.886-2.779,14.5-4.407,7.366-2.6,12.621-4.8,20.687-3.4,14.3,2.482,17.343,18.2,35.535,18.2,22.822,0,22.792-35.822,45.655-35.822,13.891,0,18.082,29.956,29.929,32.7,11.213,2.064,12.725-13.289,23.832-11.206,10.774,2.02,11.214,16.318,22.435,17.565,9.388,1.044,12.861-8.884,20.854-12.684a12.992,12.992,0,0,1,5.58-1.312c12.819-.128,15.365,15.543,26.8,14.606,12.531-1.027,14.98-26.8,27.917-27.628,11.374-.728,13.961,13.518,26.508,13.518,22.889,0,22.74-5.973,45.655-5.973" transform="translate(0 -81.722)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_183" data-name="Path 183" d="M0,146.5c20.783,0,22.935,12.349,40.085,11.637A84.99,84.99,0,0,0,53.9,156.268c7.149-1.431,12.268-4.531,20.186-6.334,13.087-2.98,15.985-4.151,29.811-6.486,5.447-.92,9.856-2.809,14.582-4.325,7.583-2.432,12.964-4.335,20.885-2.813,14.143,2.717,17.454,18.026,35.849,18.026,23.445,0,23.411-36.128,46.889-36.128,13.691,0,18.082,28.286,29.54,31.285,10.767,2.252,12.426-11.676,23.083-9.4,10.287,2.2,10.962,15.794,21.751,17.154,9.046,1.139,12.618-8.012,20.484-11.564a13.261,13.261,0,0,1,5.436-1.229c12.526-.139,15.308,14.41,26.319,13.4,12.224-1.126,14.882-25.481,27.55-26.39,10.943-.785,13.756,12.469,25.989,12.469,23.51,0,23.352-4.581,46.889-4.581" transform="translate(0 -76.279)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_184" data-name="Path 184" d="M0,140.215c21.175,0,23.511,11.111,40.752,10.333a99.211,99.211,0,0,0,13.884-1.785c7.3-1.328,12.528-4.391,20.269-6.34,12.854-3.236,15.982-5.064,29.633-7.6,5.368-1,9.826-2.839,14.667-4.244,7.8-2.263,13.307-3.865,21.082-2.223,13.985,2.953,17.566,17.847,36.164,17.847,24.068,0,24.031-36.433,48.123-36.433,13.49,0,18.082,26.617,29.151,29.866,10.32,2.439,12.126-10.064,22.333-7.591,9.8,2.374,10.71,15.271,21.067,16.741,8.7,1.236,12.374-7.14,20.115-10.444a13.606,13.606,0,0,1,5.292-1.145c12.234-.151,15.252,13.274,25.838,12.184,11.917-1.227,14.784-24.161,27.184-25.153,10.512-.841,13.551,11.42,25.469,11.42,24.131,0,23.963-3.19,48.123-3.19" transform="translate(0 -70.836)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_185" data-name="Path 185" d="M0,133.925c21.568,0,24.087,9.874,41.418,9.028a118.1,118.1,0,0,0,13.95-1.7c7.45-1.224,12.788-4.252,20.352-6.346,12.62-3.492,15.979-5.977,29.455-8.706,5.288-1.071,9.8-2.869,14.752-4.162,8.018-2.094,13.649-3.394,21.279-1.633,13.826,3.192,17.678,17.668,36.477,17.668,24.69,0,24.65-36.738,49.356-36.738,13.289,0,18.082,24.947,28.763,28.446,9.874,2.627,11.826-8.454,21.583-5.784,9.311,2.549,10.459,14.748,20.383,16.33,8.361,1.332,12.13-6.268,19.745-9.324a14.036,14.036,0,0,1,5.148-1.062c11.941-.163,15.2,12.137,25.358,10.973,11.61-1.33,14.687-22.838,26.818-23.915,10.081-.895,13.345,10.371,24.95,10.371,24.752,0,24.575-1.8,49.357-1.8" transform="translate(0 -65.393)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_186" data-name="Path 186" d="M0,127.636c21.961,0,24.663,8.637,42.085,7.723,4.917-.258,9.277-.909,14.016-1.608,7.6-1.122,13.047-4.114,20.435-6.352,12.385-3.751,15.976-6.889,29.277-9.816,5.209-1.146,9.768-2.9,14.836-4.081,8.236-1.924,13.991-2.922,21.477-1.043,13.667,3.432,17.79,17.489,36.791,17.489,25.313,0,25.269-37.044,50.591-37.044,13.087,0,18.082,23.278,28.374,27.026,9.427,2.815,11.526-6.847,20.833-3.976,8.823,2.722,10.207,14.226,19.7,15.918,8.018,1.429,11.886-5.4,19.375-8.2a14.572,14.572,0,0,1,5-.978c11.648-.175,15.139,11,24.878,9.761,11.3-1.434,14.588-21.513,26.451-22.677,9.651-.947,13.14,9.321,24.432,9.321,25.373,0,25.187-.406,50.59-.406" transform="translate(0 -59.951)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_187" data-name="Path 187" d="M0,121.347c22.354,0,25.239,7.4,42.752,6.419,4.843-.272,9.241-.883,14.082-1.52,7.752-1.019,13.307-3.977,20.518-6.358,12.15-4.011,15.973-7.8,29.1-10.927,5.13-1.222,9.739-2.925,14.921-4,8.453-1.752,14.334-2.449,21.674-.453,13.507,3.675,17.9,17.31,37.105,17.31,25.936,0,25.889-37.349,51.824-37.349,12.887,0,18.082,21.608,27.985,25.607,8.98,3,11.225-5.242,20.083-2.168,8.335,2.892,9.956,13.7,19.015,15.506,7.676,1.527,11.641-4.527,19.005-7.084a15.254,15.254,0,0,1,4.861-.895c11.355-.186,15.082,9.856,24.4,8.55,11-1.542,14.49-20.186,26.085-21.439,9.22-1,12.935,8.273,23.912,8.273,25.994,0,25.8.986,51.824.986" transform="translate(0 -54.509)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_188" data-name="Path 188" d="M0,115.057c22.746,0,25.815,6.169,43.419,5.115,4.769-.286,9.206-.858,14.148-1.432,7.9-.916,13.565-3.841,20.6-6.363,11.915-4.272,15.971-8.713,28.921-12.037,5.051-1.3,9.709-2.953,15.005-3.918,8.671-1.58,14.675-1.975,21.872.138,13.347,3.919,18.014,17.131,37.419,17.131,26.559,0,26.508-37.654,53.058-37.654,12.686,0,18.082,19.938,27.6,24.187,8.534,3.19,10.926-3.639,19.333-.361,7.847,3.059,9.7,13.182,18.331,15.094,7.333,1.625,11.4-3.657,18.635-5.964a16.167,16.167,0,0,1,4.718-.811c11.062-.2,15.025,8.712,23.917,7.338,10.689-1.651,14.392-18.856,25.718-20.2,8.789-1.044,12.729,7.223,23.393,7.223,26.615,0,26.41,2.378,53.058,2.378" transform="translate(0 -49.065)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_189" data-name="Path 189" d="M0,108.767c23.139,0,26.392,4.938,44.086,3.81,4.695-.3,9.169-.842,14.214-1.343,8.052-.828,13.823-3.706,20.684-6.37,11.678-4.535,15.968-9.623,28.743-13.147,4.972-1.371,9.68-2.98,15.09-3.837,8.888-1.407,15.016-1.5,22.069.728,13.186,4.166,18.126,16.952,37.734,16.952,27.181,0,27.127-37.96,54.292-37.96,12.485,0,18.082,18.27,27.207,22.768,8.088,3.378,10.627-2.039,18.583,1.447,7.36,3.224,9.453,12.662,17.647,14.682,6.991,1.724,11.15-2.789,18.266-4.844a17.342,17.342,0,0,1,4.574-.728c10.769-.209,14.968,7.565,23.437,6.127,10.381-1.763,14.3-17.524,25.352-18.963,8.359-1.089,12.524,6.174,22.874,6.174,27.236,0,27.022,3.77,54.292,3.77" transform="translate(0 -43.622)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_190" data-name="Path 190" d="M0,102.477c23.532,0,26.968,3.707,44.753,2.505,4.621-.312,9.134-.816,14.28-1.255,8.2-.725,14.081-3.571,20.767-6.376,11.441-4.8,15.966-10.534,28.565-14.257,4.893-1.446,9.65-3.007,15.174-3.755,9.106-1.233,15.357-1.024,22.267,1.318,13.023,4.414,18.238,16.773,38.047,16.773,27.8,0,27.747-38.265,55.526-38.265,12.283,0,18.082,16.6,26.818,21.348,7.641,3.566,10.329-.442,17.833,3.254,6.875,3.386,9.2,12.142,16.963,14.27,6.648,1.823,10.9-1.921,17.9-3.724a18.971,18.971,0,0,1,4.43-.645c10.476-.221,14.911,6.416,22.957,4.916,10.074-1.879,14.2-16.187,24.986-17.726,7.929-1.13,12.319,5.125,22.356,5.125,27.857,0,27.633,5.162,55.526,5.162" transform="translate(0 -38.179)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_191" data-name="Path 191" d="M0,96.188c23.925,0,27.545,2.478,45.42,1.2,4.547-.325,9.1-.79,14.346-1.166,8.355-.621,14.339-3.437,20.85-6.382C91.819,84.776,96.58,78.4,109,74.474A78.4,78.4,0,0,1,124.262,70.8c9.322-1.059,15.7-.547,22.463,1.908,12.859,4.665,18.35,16.595,38.361,16.595,28.427,0,28.366-38.571,56.76-38.571,12.083,0,18.082,14.93,26.43,19.929,7.195,3.753,10.033,1.151,17.083,5.062,6.391,3.545,8.949,11.623,16.28,13.858,6.3,1.923,10.658-1.055,17.526-2.6a21.28,21.28,0,0,1,4.287-.561c10.183-.232,14.852,5.264,22.477,3.7,9.764-2,14.1-14.847,24.619-16.488,7.5-1.17,12.114,4.075,21.836,4.075,28.478,0,28.245,6.554,56.76,6.554" transform="translate(0 -32.737)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_192" data-name="Path 192" d="M0,89.9c24.317,0,28.122,1.251,46.087-.1,4.473-.337,9.064-.765,14.412-1.078C69,88.2,75.094,85.413,81.431,82.33,92.395,77,97.4,70,109.64,65.853a66.02,66.02,0,0,1,15.344-3.592c9.539-.884,16.036-.068,22.661,2.5,12.694,4.918,18.462,16.416,38.675,16.416,29.05,0,28.985-38.876,57.994-38.876,11.882,0,18.082,13.261,26.041,18.509,6.748,3.94,9.741,2.741,16.333,6.87,5.91,3.7,8.7,11.1,15.6,13.446,5.961,2.024,10.412-.189,17.156-1.484a24.707,24.707,0,0,1,4.143-.477c9.891-.244,14.793,4.109,22,2.493,9.454-2.122,14.007-13.5,24.253-15.25C376.9,65.2,381.742,69.43,391.15,69.43c29.1,0,28.857,7.946,57.994,7.946" transform="translate(0 -27.295)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_193" data-name="Path 193" d="M0,83.609c24.71,0,28.7.026,46.754-1.408,4.4-.349,9.029-.739,14.478-.99,8.657-.414,14.85-3.173,21.016-6.393,10.722-5.6,15.969-13.243,28.031-17.588a56.846,56.846,0,0,1,15.429-3.511c9.756-.708,16.374.411,22.858,3.089,12.527,5.173,18.574,16.237,38.99,16.237,29.672,0,29.6-39.182,59.228-39.182,11.681,0,18.082,11.591,25.652,17.089,6.3,4.128,9.452,4.328,15.583,8.677,5.433,3.854,8.445,10.588,14.912,13.034,5.618,2.126,10.166.675,16.786-.364a30.434,30.434,0,0,1,4-.394c9.6-.256,14.733,2.951,21.516,1.281,9.142-2.25,13.913-12.152,23.887-14.012,6.644-1.239,11.7,1.977,20.8,1.977,29.72,0,29.468,9.338,59.228,9.338" transform="translate(0 -21.852)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_194" data-name="Path 194" d="M0,77.32c25.1,0,29.276-1.2,47.421-2.713,4.325-.361,8.993-.713,14.544-.9,8.809-.311,15.105-3.042,21.1-6.4,10.479-5.871,15.967-14.155,27.853-18.7a50.062,50.062,0,0,1,15.513-3.429c9.972-.532,16.712.891,23.056,3.679,12.358,5.43,18.686,16.058,39.3,16.058,30.3,0,30.224-39.487,60.462-39.487,11.48,0,18.082,9.922,25.263,15.67,5.855,4.316,9.168,5.911,14.833,10.484,4.96,4,8.191,10.072,14.228,12.623,5.273,2.228,9.92,1.537,16.416.756,1.28-.154,2.557-.276,3.856-.31,9.3-.267,14.67,1.789,21.036.07C353.71,62.337,358.7,53.927,368.4,51.947c6.217-1.269,11.5.928,20.279.928,30.341,0,30.08,10.729,60.462,10.729" transform="translate(0 -16.409)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_195" data-name="Path 195" d="M0,71.03c25.5,0,29.854-2.419,48.088-4.017,4.251-.372,8.958-.687,14.61-.813a40.83,40.83,0,0,0,21.182-6.405c10.234-6.142,15.965-15.068,27.675-19.808a45.113,45.113,0,0,1,15.6-3.348c10.188-.337,17.048,1.372,23.253,4.269,12.187,5.689,18.8,15.879,39.617,15.879,30.918,0,30.843-39.792,61.7-39.792,11.279,0,18.082,8.252,24.874,14.25,5.409,4.5,8.891,7.492,14.083,12.292,4.492,4.153,7.936,9.557,13.544,12.21,4.928,2.332,9.674,2.4,16.047,1.876,1.248-.1,2.48-.19,3.712-.227,9.012-.279,14.606.624,20.556-1.141,8.509-2.525,13.733-9.43,23.154-11.536,5.792-1.3,11.292-.122,19.76-.122,30.962,0,30.691,12.121,61.7,12.121" transform="translate(0 -10.966)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_196" data-name="Path 196" d="M0,64.741c25.888,0,30.434-3.62,48.755-5.322,4.178-.38,8.923-.662,14.676-.724A38.275,38.275,0,0,0,84.7,52.283c9.976-6.43,15.963-15.981,27.5-20.918A40.883,40.883,0,0,1,127.875,28.1c10.4-.168,17.383,1.854,23.451,4.859,12.014,5.951,18.91,15.7,39.931,15.7,31.541,0,31.463-40.1,62.93-40.1,11.078,0,18.082,6.583,24.486,12.83,4.962,4.691,8.621,9.071,13.333,14.1,4.03,4.3,7.674,9.052,12.861,11.8,4.576,2.445,9.429,3.258,15.677,3,1.216-.051,2.4-.106,3.569-.144,8.719-.291,14.539-.545,20.075-2.353,8.187-2.673,13.648-8.056,22.788-10.3,5.369-1.318,11.087-1.171,19.241-1.171,31.583,0,31.3,13.513,62.93,13.513" transform="translate(0 -5.524)" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_197" data-name="Path 197" d="M0,58.451c32.139,0,32.048-7.262,64.163-7.262,32,0,32.23-31.631,64.434-31.631,31.812,0,32.038,20.97,63.894,20.97,32.164,0,32.082-40.4,64.163-40.4,32.111,0,32.221,42.821,64.164,42.821S352.777,28.04,384.981,28.04,416.9,42.946,449.144,42.946" transform="translate(0 -0.081)" fill="none" stroke="#fff" stroke-width="1"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};