import { styled } from 'solid-styled-components';

export const StyledIfuPdfHeadingWrapper = styled.div`
    margin-top: 2.5rem;
    display: flex;
    gap: 3rem;
`;

export const StyledIfuPdfHeadingWrapperAreaOfCare = styled.div`
    flex-shrink: 0;
`;
