import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular, palette } from '../../style/theme';

import { calcFontSize } from '../../ui-components/heading/heading.styles';

export const SearchResultCardWrapper = styled.div`
    background: ${palette.white};
    border-radius: 1rem;
    margin: 2rem 0;
    padding: 1rem 2rem;
    text-align: left;

    p {
        margin: 1.43rem 0;
    }
`;

export const StyledResultsHeading = styled.h4`
    ${calcFontSize({
        minMaxFont: [1.8, 1.6],
        minMaxLine: [2.1, 2.3],
    })}
    letter-spacing: 0;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    margin: 1.43rem 0;

    &:first-letter {
        text-transform: uppercase;
    }
    ${theme.mediaQueries.mobileMax} {
        font-size: 1.8rem;
        line-height: 2.1rem;
    }
`;

export const StyledSearchResultsWrapper = styled.div`
    container-type: inline-size;
    margin: 5rem 0;
`;

export const StyledColumn = styled.div`
    text-align: center;
`;

export const StyledLink = styled(Link)<any>`
    font-weight: 400;
    font-family: ${fontFamilyAtosRegular};
    margin-bottom: 1.2em;
    margin-top: 0;
    font-size: 2rem;
    letter-spacing: 0;

    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${palette.darkGray};

    &:hover {
        color: ${palette.pink};
    }
`;

export const StyledShowMoreButton = styled.button<any>`
    background: none;
    border-radius: 4.29rem;
    cursor: pointer;
    border: none;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    width: fit-content;
    padding-inline: clamp(3.36rem, 3.36rem + 0.35vw, 4.29rem);
    padding-block: clamp(1.21rem, 1.21rem + 1.21vw, 1.79rem);
    color: ${theme.palette.red};
    text-decoration: underline;
    padding: 0;
    font-size: 1.43rem;
    letter-spacing: 0;
    line-height: 2.7rem;
    transition: color 0.2s;

    &:hover {
        color: ${theme.palette.pink};
        svg {
            fill: ${theme.palette.pink};
        }
    }

    ${theme.mediaQueries.mobileMax} {
        font-size: 1.4rem;
        letter-spacing: 0.4px;
        line-height: 2.36rem;
    }

    svg {
        margin: auto;
    }
`;

export const StyledShowMoreMobileWrapper = styled.div`
    text-align: center;
    margin-top: 1rem;
`;