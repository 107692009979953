import { mergeProps, Show, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import theme from '../../../style/theme';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { navigateToAnchor } from '../../../tools/scroll-to-element';
import urlMatches from '../../../tools/url-match';
import { CustomLinkProps } from '../../../ui-components/link/link-types';
import { StyledArrowLink, StyledArrowLinkAsButton } from './custom-link.style';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../tools/remove-ampersand';

const defaultProps = {
    opensInNewTab: false,
    darkMode: false,
    noBlockSpacing: false,
};

type CustomLinkPropsWithSize = CustomLinkProps & {
    size?: 'smaller' | 'medium';
};

export const CustomSidebarMenuLink = (componentProps: CustomLinkPropsWithSize) => {
    const { viewport, siteInfo, } = useContext(AppContext);
    const isSmallScreen = () => viewport.width <= theme.breakpoints.TABLET;
    const props = mergeProps(defaultProps, componentProps);

    if (!props.url || !props.label) {
        return <></>;
    }

    return (
        <ErrorCatcher componentName="CustomSidebarMenuLink">
            <Show when={props.plainStyle && props.url}>
                <Show when={props.anchorLink}>
                    <StyledArrowLinkAsButton
                        darkMode={props.darkMode}
                        labelColor={props.labelColor}
                        size={props.size}
                        bold={props.bold}
                        active={urlMatches(props.url)}
                        onClick={(e: any) => navigateToAnchor({ 
                            event: e, 
                            id: props.anchorLink!,
                            smallScreen: isSmallScreen(),
                            isCareSite: siteInfo.siteType === 'atos-care',
                        })}
                    >
                        {removeAmpersand(props.label)}
                    </StyledArrowLinkAsButton>
                </Show>
                <Show when={!props.anchorLink}>
                    <StyledArrowLink
                        href={removeTrailingSlash(props.url)}
                        darkMode={props.darkMode}
                        labelColor={props.labelColor}
                        size={props.size}
                        bold={props.bold}
                        active={urlMatches(props.url)}
                    >
                        {removeAmpersand(props.label)}
                    </StyledArrowLink>
                </Show>
            </Show>
        </ErrorCatcher>
    );
};

CustomSidebarMenuLink.parseProps = (atts: any) => {
    let anchorLink = '';
    if (atts?.url?.includes('#')) {
        anchorLink = atts.url.replace('#', '');
    }

    return {
        ...atts,
        anchorLink,
    };
};
