import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../style/theme';

export const StyledTopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
`;

export const StyledDocumentsList = styled.ul`
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${theme.mediaQueries.tabletMax} {
        margin-inline: 1.5rem;
    }
`;

export const StyledDocument = styled(Link)`
    color: ${theme.palette.red};
    text-decoration: underline;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.43rem;
`;

export const StyledDocumentRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledSearchInput = styled.input`
    border: 1px solid ${theme.palette.gray};
    padding: 1rem;
    border-radius: 1.1rem;
    width: 100%;
    max-width: 37rem;
    height: 5.29rem;
    font-size: 1.43rem;
    font-family: ${fontFamilyAtosRegular};
    font-weight: 400;
    color: ${theme.palette.darkGray};

    ${theme.mediaQueries.tabletMax} {
        width: 100%;
        margin-inline: 1.5rem;
        max-width: 100%;
    }
`;

export const StyledClearAllContainer = styled.div`
    margin-top: 3rem;
    ${theme.mediaQueries.mobileMax} {
        margin-top: 0;
        display: flex;
        justify-content: center;
    }
`;

export const StyledHeadingWrapper = styled.div`
    margin-top: 1rem;

    ${theme.mediaQueries.tabletMax} {
        padding-inline: 1.5rem;
    }
`;

export const StyledMobileFilterButtonContainer = styled.div`
    padding-inline: 1.5rem;
`;

export const StyledMobileFilterButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 1rem;
    border: 1px solid ${theme.palette.gray};
    border-radius: ${theme.borderRadius.small};
    width: 100%;
`;

export const StyledCategoryTagsContainer = styled.ul<{ isCollectionPage?: boolean}>`
    display: flex;
    flex-flow: wrap;
    padding-inline: 1.5rem;
    ${p => p.isCollectionPage ? '' : 'padding-top: 1.5rem;'}
`;

export const StyledFilterIconAndTextWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const StyledFilterIconWrapper = styled.div`
    position: absolute;
    left: -2.5rem;
    padding-right: 1rem;
`;


export const StyledMobileFiltersModal = styled.div`
    container-type: inline-size;
    background: ${theme.palette.white};
    height: 100vh;
    position: fixed;
    top: ${theme.header.mobile.height.value + theme.header.mobile.height.unit};
    left: 0;
    right: 0;
    z-index: 3;
    padding-inline: 1.5rem;
`;

export const StyledList = styled.ul`
    margin-bottom: 2rem;
`;

export const StyledHeadingContainer = styled.div`
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${theme.palette.gray};
    margin-bottom: 1.5rem;
`;
