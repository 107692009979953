import { Heading } from '../../ui-components/heading/heading';
import { For, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { gql } from 'graphql-request';
import { formatTextDate } from '../../tools/date-format';
import OrderHistoryOrderline from './order-history-orderline';
import { StyledOrderHistory, StyledUserOrderHistoryTable, StyledUserOrdersHistory } from './user-order-history.styles';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';

const GET_USER_ORDERS = gql`
    query getOrdersByUser {
        orders: getOrdersByUser {
            id
            createdAt
            orderDetails {
                id
                postTitle
                slug
                type
                skuRefNumber
                skuDescription
                skuName
                skuQuantity
                amount
                externalId
                imageUrl
                imageRatio
            }
            status
            createdAt
        }
    }
`;

export type OrderHistory = {
    id: number;
    createdAt: string;
    orderDetails: OrderDetails[];
    status: string;
}

export type OrderDetails = {
    id: number;
    postTitle: string;
    slug: string;
    type: string;
    skuRefNumber: string;
    skuDescription: string;
    skuName: string;
    skuQuantity: number;
    amount: number;
    imageUrl?: string;
    imageRatio?: number;
}

export type OrdersHistory = {
    orders: OrderHistory[];
};

export const UserOrderHistory = () => {
    const { localize, createCachedResource, siteInfo } = useContext(AppContext);

    if (siteInfo.siteType !== 'atos-care') return null;

    // TODO: When the user logs out, the order history should refetch
    // TODO: When the user creates an order, the order history should refetch
    const [ orderHistory ]: [() => OrdersHistory | undefined, { refetch: () => void }] = createCachedResource( GET_USER_ORDERS );

    const sortByDate = (a: OrderHistory, b: OrderHistory) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    };        

    return (
        <StyledUserOrdersHistory>
            <Heading tag='h3'>
                {localize('order-history', 'Order History')}
            </Heading>
            
            <StyledVerticalSpace size={1} />

            <Show
                fallback={(
                    <Heading tag='h4' variant='tinyGray'>
                        {localize('no-orders-found', 'No orders found')}
                    </Heading>
                )}
                when={orderHistory()?.orders?.length}
            >
                <For each={orderHistory()?.orders.sort(sortByDate)}>{(order) => (
                    <StyledOrderHistory>
                        <div style={{ display: 'flex', gap: '1.5rem', 'align-items': 'center', 'justify-content': 'space-between', 'padding-right': '1rem' }}>
                            <Heading tag='h5'>
                                {`${formatTextDate(order.createdAt, siteInfo.key)}`}
                            </Heading>
                        </div>

                        <StyledUserOrderHistoryTable>
                            <For each={order.orderDetails}>{(details) => (
                                <OrderHistoryOrderline orderDetails={details} />
                            )}</For>
                        </StyledUserOrderHistoryTable>
                    </StyledOrderHistory>
                )}</For>

            </Show>
        </StyledUserOrdersHistory>
    );
};
