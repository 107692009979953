import { ComponentPiece } from '../../pdf-renderer/pdf-renderer';
import { extractBlocks } from '../extract-blocks';
import { PageData } from '../../../../share-and-save/share-and-save';
import { components as componentsProducts } from '../products/products-components';
import { components as componentsIfu } from '../ifu/ifu-components';
import { AppContext } from '../../../../../app-context-provider/app-context-provider';
import { useContext } from 'solid-js';

const overrideBlocks = [
    'atos/products',
    'atos/manuals',
];

export const hasBlock = (blockName: string[], blocks: any[]) => {
    let foundBlock = false;

    for (const block of blocks) {
        if (blockName.includes(block.blockName)) {
            foundBlock = true;
            break;
        }
    }

    return foundBlock;
};


const body: ComponentPiece = {
    canBeBrokenUp: true,
    elements: (data: PageData) => {
        const context = useContext(AppContext);
        /**
         * With certain blocks, such as products, events, etc (blocks which are
         * intended to be the only block on the page) we want to override the default
         * behaviour of the page parser and render the blocks in a different way.
         */
        if (hasBlock(overrideBlocks, data.blocks)) {
            return <></>;
        }

        return extractBlocks(context, data);
    }
};

export const components = [
    body,
    ...componentsProducts,
    ...componentsIfu,
];
