import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 134 74',
    // width: 134,
    // height: 74,
    width: 12.2,
    height: 6.73,
};

export const AtosCareLogoPdfFooter = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M30.7825 38.7377C30.7825 30.7856 36.3047 24.803 43.7298 24.803C47.9833 24.803 50.4459 26.7353 51.3787 27.8129V25.3604H58.9158V52.2636H51.5653V49.5138C50.334 51.2231 47.8714 52.821 43.9536 52.821C36.4912 52.821 30.7825 46.8755 30.7825 38.7377ZM51.6399 38.7005C51.6399 34.3529 48.9534 31.343 45.1849 31.343C41.603 31.343 38.3941 34.0184 38.3941 38.7748C38.3941 43.4569 41.5283 46.2438 45.1103 46.2438C48.9534 46.2067 51.6399 43.011 51.6399 38.7005Z"
                fill="#918787"
            />
            <path
                d="M63.3931 25.3229H70.8555V29.7077C71.7883 27.6639 74.3255 24.7655 78.3179 24.7655C79.4373 24.7655 80.5939 24.877 81.1909 24.9885V32.4574C80.2954 32.346 79.1014 32.3088 78.3925 32.3088C73.8778 32.3088 71.042 35.616 71.042 39.8893V52.2633H63.3931V25.3229Z"
                fill="#918787"
            />
            <path
                d="M81.7876 38.589C81.7876 30.3025 88.0933 24.803 95.1826 24.803C102.085 24.803 107.719 30.0053 107.757 38.3661C107.757 39.2579 107.719 40.447 107.645 41.0044H89.2127C89.9216 44.4602 93.0931 46.3181 97.2721 46.3181C100.966 46.3181 104.436 44.6831 105.817 43.8656V50.8515C104.66 51.5204 101.638 52.821 97.1602 52.821C87.6456 52.821 81.7876 47.0242 81.7876 38.589ZM100.332 35.7649C99.8839 33.2009 98.0556 31.0457 94.8841 31.0457C91.6753 31.0457 89.8097 33.2381 89.3246 35.7649H100.332Z"
                fill="#918787"
            />
            <path
                d="M0 34.4272C0 23.7253 8.13403 16.8137 18.8799 16.8137C23.2454 16.8137 26.6781 17.9657 28.5437 18.746V26.6609C26.5289 25.6204 23.5812 24.0598 19.3276 24.0598C12.2384 24.0598 7.53703 27.9986 7.53703 34.613C7.53703 41.2273 12.6488 45.6121 18.8426 45.6121C22.8723 45.6121 26.9766 43.7541 28.8422 42.7137V50.6286C27.499 51.4089 23.5066 52.8953 18.8426 52.8953C8.3579 52.8209 0 45.3148 0 34.4272Z"
                fill="#918787"
            />
            <path
                d="M67.6838 58.7292C67.5718 58.7292 67.4972 58.8779 67.5718 58.9522C75.1462 69.1709 86.9741 74.7077 99.5483 73.9273C120.033 72.6639 135.554 54.8647 133.875 34.39C132.234 14.5841 114.734 -0.390998 94.8096 0.946732C82.422 1.76423 71.9 8.60152 65.8928 18.4487C65.8182 18.5602 65.8928 18.746 66.0421 18.746H67.1614C67.1987 18.746 67.2733 18.7088 67.3107 18.6716C72.5717 10.9054 81.0415 5.33151 90.9292 3.99378C111.376 1.244 129.771 17.3711 129.547 37.9201C129.361 55.4964 115.555 70.2486 97.6454 71.3633C86.601 72.0322 75.4447 67.1272 69.2136 58.8407C69.1763 58.8035 69.1389 58.7664 69.0643 58.7664H67.6838V58.7292Z"
                fill="#BEB9B9"
            />
            <path
                d="M42.5355 12.2431V10.348H44.3638V12.2431H46.0428V13.7667H44.3638V16.4421C44.3638 16.9995 44.7369 17.2225 45.2593 17.2225C45.5951 17.2225 46.0801 17.0367 46.0801 17.0367V18.6717C46.0801 18.6717 45.3712 18.8946 44.6623 18.8575C43.1325 18.8203 42.4982 18.1143 42.4982 16.5536V13.7667H41.3042V12.2431H42.5355Z"
                fill="#F0414B"
            />
            <path
                d="M46.7146 15.5133C46.7146 13.4696 48.2817 12.1318 50.2219 12.1318C52.1995 12.1318 53.7666 13.4696 53.7666 15.5133C53.7666 17.5571 52.1995 18.8948 50.2219 18.8948C48.2817 18.8576 46.7146 17.5571 46.7146 15.5133ZM51.901 15.4762C51.901 14.4729 51.1547 13.8412 50.2592 13.8412C49.3264 13.8412 48.5802 14.4729 48.5802 15.4762C48.5802 16.4795 49.3264 17.1855 50.2219 17.1855C51.1174 17.2226 51.901 16.4795 51.901 15.4762Z"
                fill="#F0414B"
            />
            <path
                d="M56.7138 17.3713C57.1616 17.3713 57.4601 17.1484 57.4601 16.8511C57.4601 16.4795 57.1989 16.368 56.378 16.0708C55.2213 15.6992 54.4751 15.439 54.4751 14.0642C54.4751 12.9494 55.4079 12.0947 56.975 12.0947C58.4675 12.0947 59.0272 12.5406 59.0272 12.5406V14.2128C59.0272 14.2128 58.2063 13.6183 57.1616 13.6183C56.6392 13.6183 56.3407 13.804 56.3407 14.0642C56.3407 14.3243 56.5273 14.5472 57.3481 14.7702C58.5421 15.1418 59.4003 15.5877 59.4003 16.8511C59.4003 17.8544 58.6167 18.8949 56.6765 18.8949C55.3706 18.8949 54.5124 18.4118 54.5124 18.4118V16.5538C54.4751 16.5167 55.4079 17.3713 56.7138 17.3713Z"
                fill="#F0414B"
            />
            <path
                d="M35.0357 18.7461L35.4461 16.7767H38.9908L39.4012 18.7461H41.2295L39.9609 12.8007C39.5878 10.9427 38.4684 10.2367 37.1625 10.2367C35.9312 10.2367 34.6999 10.9427 34.3641 12.8007L33.0955 18.7461H35.0357ZM36.2297 12.9122C36.3789 12.2433 36.7894 12.0203 37.2371 12.0203C37.6475 12.0203 38.058 12.2061 38.2445 12.9122L38.655 15.0674H35.8192L36.2297 12.9122Z"
                fill="#F0414B"
            />
        </SvgIcon>
    );
};
