import { Show, mergeProps } from 'solid-js';
import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';

const defaultProps: SvgIconProps = {
    // width: 36.522,
    // viewBox: '0 0 36.522 30',
};

export const MenuBarsIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <Show when={props.altText}>
                <title>{props.altText}</title>
            </Show>
            <path
                d="M0,65.957A1.957,1.957,0,0,1,1.957,64H34.565a1.957,1.957,0,0,1,0,3.913H1.957A1.954,1.954,0,0,1,0,65.957ZM0,79a1.954,1.954,0,0,1,1.957-1.957H34.565a1.957,1.957,0,0,1,0,3.913H1.957A1.954,1.954,0,0,1,0,79ZM34.565,94H1.957a1.957,1.957,0,0,1,0-3.913H34.565a1.957,1.957,0,0,1,0,3.913Z"
                transform="translate(0 -64)"
            />
        </SvgIcon>
    );
};
