import { styled } from 'solid-styled-components';
import theme from '../../../../../../style/theme';


export const StyledVideoPdf = styled.div`
    display: flex;
    gap: 1.45rem;
    border: 1px solid ${theme.palette.lightGray};
    border-radius: 0.73rem;
    padding: 0.73rem;
    align-items: center;
`;

export const StyledYoutubeThumbnail = styled.img`
    height: 100%;
    width: auto;
    max-width: none !important;
`;

export const StyledVideoPdfImage = styled.div`
    height: 9.2rem;
    flex-basis: 34%;
    border-radius: 0.36rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`;

export const StyledQrCode = styled.div`
    padding: 0.73rem;
    flex-basis: 15%;
    flex-shrink: 0;
`;

export const StyledVideoPdfDescriptionWrapper = styled.div`
    align-self: flex-start;
    padding-block: 0.73rem;
    flex-grow: 1;
`;