import { styled } from 'solid-styled-components';
import { footerHeight, pageWidth, pageBottomMargin, pageLeftMargin, pageRightMargin } from '../shared-components.style';

export const StyledBlankPageFooter = styled.div`
    position: absolute;
    bottom: 0;
    padding-left: ${pageLeftMargin}px;
    padding-right: ${pageRightMargin}px;
    padding-bottom: ${pageBottomMargin}px;
    height: ${footerHeight}px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: ${pageWidth}px;
    {/*
        background-color: rgba(0,0,0,0.1);
        opacity: 0.5;
    */}
`;

export const StyledFooterItems = styled.div`
    display: flex;
    align-items: flex-end;
    z-index: 1;
    position: relative;
`;

export const StyledCopyrightItems = styled.div`
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-top: 0.4rem;
`;

export const StyledFooterItem = styled.div`
    padding-right: 1.5rem;
`;

export const StyledFooterLogo = styled.div`
    margin-left: auto;
`;

export const StyledFooterLogoInner = styled.div`
    margin-bottom: -5px;
`;

export const StyledFooterPostTitle = styled.div`
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const StyledFooterSwirl = styled.div`
    position: absolute;
    width: 100%;
    bottom: -60px;
    left: 0;
`;

export const StyledFooterTexts = styled.div`
    width: 100%;
`;

export const StyledPdfDivider = styled.div`
    width: 1px;
    height: 10px;
    background-color: #726060;
    margin-inline: 0.7rem;
`;
