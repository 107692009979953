import { styled } from 'solid-styled-components';
import { theme } from '../../style/theme';

export const StyledFooter = styled.footer`
    container-type: inline-size;
    min-height: 32.14rem;
    background-color: ${theme.colors.footer.background};
    color: ${theme.palette.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${theme.mediaQueries.tabletMax} {
        height: auto;
    }

    @media print {
        display: none;
    }
`;
    
export const rowBlockSpacing = `
    padding-block: ${theme.grid.standardUnit * 3}rem;
    ${theme.mediaQueries.tabletMax} {
        padding-block: ${theme.grid.standardUnit * 2}rem;
    }
`;

export const StyledLogo = styled.div`
    width: auto;
    padding-bottom: 2rem;
`;

export const StyledFooterColumn = styled.div`
    padding-bottom: 1rem;
`;

export const StyledIcon = styled.div`
    display: flex;
    margin-right: 1rem;
`;


export const CopyrightContainer = styled.div`
    border-top: 1px solid white;
    padding-block: 2rem;
`;

export const StyledCopyright = styled.div`
    display: flex;
    justify-content: flex-start;
    ${theme.mediaQueries.tabletMax} {
        display: flex;
        justify-content: center;
    }
`;
