import { Show, onMount, lazy, useContext, Switch, Match } from 'solid-js';
import { Route, Routes } from '@solidjs/router';
import { GlobalStyles } from './global.styles';
import { StyledAppRoot, StyledAppInner, StyledPdfFactoryRoot } from './app.styles';
import { PageContainer } from '../components/page/page';
import { HeaderContainer } from '../components/header/header';
import { FooterContainer } from '../components/footer/footer';
import { AppContext } from '../app-context-provider/app-context-provider';
import { Title } from '@solidjs/meta';
import { LoginMenu } from '../components/login-menu/login-menu';
import { ErrorCatcher } from '../tools/error-catcher';
import { Announcement } from '../components/announcement/announcement';
import { LocaleController } from '../components/language-selector/locale-controller';
import { NativeAppPrompt } from '../components/native-app-prompt/native-app-prompt';
import { NewsletterSignupPromptForPdf } from '../components/pdf-builder/pdf-dashboard/pdf-newsletter-signup-prompt/pdf-newsletter-signup-prompt';
import { FooterAtosCareContainer } from '../components/footer/atos-care/footer-atos-care';
import { HeaderAtosCareContainer } from '../components/header/atos-care/header-atos-care';
import { BasketContextProvider } from '../components/basket/basket-context-provider';
import { getCookie } from '../tools/cookie-helpers';
import { Menu } from '../components/menu/menu';
import { HeaderMedicalHcpContainer } from '../components/header/medical-hcp/hcp-header';
import theme from '../style/theme';
import { HeaderCampaignContainer } from '../components/header/campaign-header/campaign-header';

// Lazy load everything else
const Product = lazy(() => import('../components/product/product').then(imported => ({ default: imported.Product })));
const Event = lazy(() => import('../components/event/event').then(imported => ({ default: imported.Event })));
const BlogNews = lazy(() => import('../components/blog-news/blog-news').then(imported => ({ default: imported.BlogNews })));
const Video = lazy(() => import('../components/video/video').then(imported => ({ default: imported.Video })));
const Search = lazy(() => import('../components/search/search').then(imported => ({ default: imported.Search })));
const PdfFactory = lazy(() => import('../components/pdf-builder/pdf-factory/pdf-factory').then(imported => ({ default: imported.PdfFactory })));

export const App = () => {
    const { 
        productsEntityName,
        eventsEntityName,
        blogAndNewsEntityName,
        videosEntityName,
        searchPage,
        userState,
        isPdfGenerator,
        siteInfo,
        updateUser,
        appStore,
        viewport,
        headerSettings,
    } = useContext(AppContext);

    onMount(() => {
        getSession(); 
    });

    const getSession = async () => {
        const sessionCookie = getCookie('user_info');
        if (!sessionCookie) return;
    
        try {
            const decodedCookie = decodeURIComponent(sessionCookie);            
            const parsedUser = JSON.parse(decodedCookie);
            updateUser(parsedUser);
        } catch (error) {
            console.error('Could not parse session cookie', error);
        }
    };

    const isOriginalTablet = () => viewport.width <= theme.breakpoints.ORIGINAL_TABLET;

    const showHcpHeader = () => headerSettings.activateHcpHeader && appStore.isHcp() && !isOriginalTablet();

    return (
        <>
            <Show when={siteInfo?.siteType === 'atos-care'} fallback={<Title>Atos Medical</Title>}>
                <Title>Atos Care</Title>
            </Show>

            <GlobalStyles />
            <StyledAppRoot>
                <Announcement />

                <LocaleController />


                <NativeAppPrompt />
                <NewsletterSignupPromptForPdf />

                <BasketContextProvider siteInfo={siteInfo} userState={userState}>

                    <Switch>
                        <Match when={siteInfo?.siteType === 'atos-care'}>
                            <HeaderAtosCareContainer isHcp={appStore.isHcp()} />
                        </Match>
                        <Match when={appStore.isCampaignPage()}>
                            <HeaderCampaignContainer/>
                        </Match>
                        <Match when={siteInfo?.siteType === 'default' && !showHcpHeader()}>
                            <HeaderContainer />
                        </Match>
                        <Match when={siteInfo?.siteType === 'default' && showHcpHeader()}>
                            <HeaderMedicalHcpContainer />
                        </Match>
                    </Switch>

                    <LoginMenu user={userState.user} />

                    <Menu />

                    <StyledPdfFactoryRoot id="pdf-factory-root" />

                    <ErrorCatcher componentName="App">
                        <StyledAppInner id="app">
                            <Show when={isPdfGenerator}>
                                <PdfFactory />
                            </Show>

                            <Show when={!isPdfGenerator}>
                                <Routes>
                                    {/*
                                    NOTE: When adding new routes here -> If you want to render a menu on the
                                    route and its subroute components, you need to render the menu component inside 
                                    a portal leading back here. We need context from the page/post to be able to 
                                    get the correct menu from the db. Hence this annoying setup.

                                    NOTE 2: We only add these dynamic routes if there's a value for the entity, otherwise 
                                    it will act as a "catch all"-route and catch stuff that we normally want our PageContainer to handle.

                                    NOTE 3: Dont forget to add menu portal when creating new routes!
                                    */}
                                    {productsEntityName && <Route path={`/${productsEntityName}/:productSlug`} element={<Product />} />}
                                    {eventsEntityName && <Route path={`/${eventsEntityName}/:eventSlug`} element={<Event />} />}
                                    {blogAndNewsEntityName && <Route path={`/${blogAndNewsEntityName}/:postSlug`} element={<BlogNews />} />}
                                    {videosEntityName && <Route path={`/${videosEntityName}/:videoSlug`} element={<Video />} />}
                                    {searchPage?.url !== '' && <Route path={`${searchPage?.url}`} element={<Search />} />}
                                    {/* {shop.basketPage?.url !== '' && <Route path={`${shop.basketPage?.url}`} element={<Basket />} />} */}
                                    <Route path="/*" element={<PageContainer />} />
                                </Routes>
                            </Show>
                        </StyledAppInner>
                    </ErrorCatcher>
                </BasketContextProvider>
                <Show when={siteInfo?.siteType === 'default'} fallback={<FooterAtosCareContainer />}>
                    <FooterContainer />
                </Show>
            </StyledAppRoot>
        </>
    );
};
