import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';

export const StyledCardWrapper = styled.button<{ cover: boolean; noBorder?: boolean }>`
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-inline: 0;
    height: 100%;
    width: 100%;
    min-height: 35.7rem;
    overflow: hidden;
    ${(p) =>
        p.noBorder
            ? ''
            : `
        border: 1px solid ${theme.palette.lightGray};
    `}
    border-radius: ${theme.borderRadius.medium};
    background-color: ${theme.palette.white};
    ${(p) =>
        !p.cover
            ? `
        padding-inline: 3.5rem;
        padding-top: 1.5rem;
    `
            : ''}
    color: ${theme.colors.text.paragraph};
    padding-bottom: 2rem;
    transition: transform 0.2s, box-shadow 0.2s;
    &:visited {
    }
    &:hover {
        box-shadow: 0.2rem 0.2rem 0.86rem 0px rgba(0, 0, 0, 0.09);
        transform: scale(0.97);
    }
    ${theme.mediaQueries.mobileMax} {
        margin-bottom: 1rem;
        &:hover {
            box-shadow: unset;
            transform: unset;
        }
    }
`;

export const StyledImage = styled.img`
    height: 19.3rem;
    margin-bottom: 2.5rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

export const StyledTextWrapper = styled.div<{ cover: boolean }>`
    ${(p) => (p.cover ? 'padding-inline: 2.5rem;' : '')}
    margin-bottom: 1rem;
`;

export const StyledArrowContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const StyledLinkTitle = styled.div`
    margin-right: 0.7rem;
`;

export const StyliedSliderCardTitle = styled.div`
    margin-bottom: 1.5rem;
    margin-right: 0.7rem;

    display: flex;
    align-items: center;
    column-gap: 1rem;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: auto;
`;

export const StyledPlayIconWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
