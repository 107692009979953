import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 58 50',
    // width: 29.992
};

export const LarybuttonIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                id="Path_21797"
                data-name="Path 21797"
                d="M29,50A25.032,25.032,0,0,1,5.1,32.23H4.67A4.677,4.677,0,0,1,0,27.55v-5.1a4.677,4.677,0,0,1,4.67-4.68H5.1a24.957,24.957,0,0,1,47.8,0h.43A4.677,4.677,0,0,1,58,22.45v5.1a4.684,4.684,0,0,1-4.67,4.68H52.9A25.032,25.032,0,0,1,29,50ZM4.67,19.31a3.145,3.145,0,0,0-3.14,3.14v5.1a3.145,3.145,0,0,0,3.14,3.14h1a.771.771,0,0,1,.74.56,23.424,23.424,0,0,0,45.17,0,.762.762,0,0,1,.74-.56h1.01a3.145,3.145,0,0,0,3.14-3.14v-5.1a3.145,3.145,0,0,0-3.14-3.14H52.32a.771.771,0,0,1-.74-.56,23.423,23.423,0,0,0-45.17-.01.762.762,0,0,1-.74.56h-1Z"
            />
            <path
                id="Path_21798"
                data-name="Path 21798"
                d="M28.97,38.37a13.34,13.34,0,1,1,9.42-22.78h0a13.34,13.34,0,0,1-9.42,22.78Zm0-25.15a11.8,11.8,0,1,0,8.34,3.45h0a11.758,11.758,0,0,0-8.34-3.45Z"
            />
        </SvgIcon>
    );
};
