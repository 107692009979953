import { getCookie, setCookie } from '../../tools/cookie-helpers';

import myContentStore from '../../stores/my-content-store';

const { setMyContentHasItems } = myContentStore;

export type MyContentItem = {
    title: string;
    type: string;
    permalink?: string;
};

export const getMyContent = () => {
    const cookieValue = getCookie('myContent');
    const decodedCookieValue = decodeURIComponent(cookieValue);
    return decodedCookieValue ? JSON.parse(decodedCookieValue) : [];
};

export const addMyContent = (object: MyContentItem) => {
    const myContent = (getMyContent() as MyContentItem[]) || [];
    const existingItem = myContent.find((item) => item.permalink === object.permalink);
    if (existingItem) {
        return;
    }

    myContent.push(object);
    const newMyContent = JSON.stringify(myContent);
    const newMyContentEncoded = encodeURIComponent(newMyContent);
    setCookie('myContent', newMyContentEncoded);
    setMyContentHasItems(true);
};

export const deleteMyContentItemByPermalink = (permalink?: string) => {
    const myContent = (getMyContent() as MyContentItem[]) || [];

    if (!myContent) {
        return;
    }

    let filteredContent: MyContentItem[] = [];
    filteredContent = myContent.filter((item) => item.permalink !== permalink);

    const newMyContent = JSON.stringify(filteredContent);
    setCookie('myContent', newMyContent);

    if (filteredContent.length === 0) {
        setMyContentHasItems(false);
    }
};
