import { styled } from 'solid-styled-components';

export const StyledButtonWrapper = styled.div`
    padding-inline: 1rem;
`;

export const StyledPdfModalText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;