import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, palette } from '../../style/theme';

type StyledSliderWrapperProps = {
    slideHeight?: string | undefined;
    isInModal?: boolean | undefined;
}

type StyledSliderInnerProps = {
    isReady: boolean;
    loadingTranslation: string;
}
export const StyledSliderInner = styled.div<StyledSliderInnerProps>`
    display: flex;
    overflow: clip;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    ${p => p.isReady
        ? '' : `
        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            background: ${palette.beige};
            z-index: 3;
            ${theme.mediaQueries.mobileMax} {
                bottom: 1rem;
            }
        }
        &:after {
            content: '${p.loadingTranslation}';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: ${palette.white};
            border-radius: 1.43rem;
            border: 1px solid ${theme.palette.lightGray};
            pointer-events: none;
            z-index: 4;
            opacity: 0.5;

            font-size: 1.3rem;
            line-height: 3rem;
            letter-spacing: 0;
            font-family: ${fontFamilyAtosBold};
            font-weight: 800;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 0;
            ${theme.mediaQueries.desktopMax} {
                letter-spacing: -0.08px;
            }
            ${theme.mediaQueries.mobileMax} {
                letter-spacing: -0.0114rem;
                font-size: 1.3rem;
                line-height: 3rem;
                bottom: 1rem;
            }
        }
    `};
`;

export const StyledSliderWrapper = styled.div<StyledSliderWrapperProps>`
    .keen-slider {
        display: flex;
        overflow: hidden;
    }
    .keen-slider:not([data-keen-slider-disabled]) {
        width: auto;
        height: ${(p) => (p.slideHeight ? p.slideHeight : '21.43rem')};
        ${p => p.isInModal ? `
            width: auto !important;
            height: 35.71rem;
            ${theme.mediaQueries.mobileMax}{
                height: 25rem;
            }
        ` : ''};
    }

    .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
        flex-direction: row-reverse;
    }

    .keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
        flex-wrap: wrap;
    }

    .keen-slider:not([data-keen-slider-disabled])[data-keen-slider-moves] * {
        pointer-events: none;
    }

    .slider > div {
        cursor: pointer;
    }

    .thumbnail.keen-slider {
        margin-top: 0.71rem;
        height: 8.57rem !important;
    }

    .thumbnail > div {
        cursor: pointer;
        background-size: cover;
    }
`;

export const StyledSliderControlsPlaceholder = styled.div`
    height: 3.9rem;
`;

export const StyledSliderControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.43rem 0;
    padding: 0.71rem 0;

    .dot {
        border: none;
        width: 0.79rem;
        height: 0.79rem;
        background: #beb9b9;
        border-radius: 50%;
        margin: 0 0.36rem;
        padding: 0.36rem;
        cursor: pointer;
    }

    .dot:focus {
        outline: none;
    }

    .dot.active {
        background: ${palette.darkGray};
    }

    button {
        display: inline-flex;
        align-items: center;
        border: none;
        background: transparent;
        padding: 0.71rem 0;
    }
`;

export const StyledEmptyCard = styled.div`
    background: pink;
    color: #3636a8;
`;
