import { styled } from 'solid-styled-components';
import { palette, theme } from '../../../style/theme';
import { Link } from '@solidjs/router';

type StyledProductCardWrapperProps = {
    inGrouping?: boolean;
    noBorder?: boolean;
    isHiddenForPublic: boolean;
    isServer: boolean;
}

export const StyledProductCardWrapper = styled(Link)<StyledProductCardWrapperProps>`
    padding: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${p => p.isServer ? 'auto' : '100%'};
    min-height: 38.6rem;
    ${p => p.noBorder ? '' : `
        border: 1px solid ${theme.palette.lightGray};
    `}
    border-radius: ${theme.borderRadius.medium};
    background-color: ${theme.palette.white};
    color: unset;
    text-decoration: unset;
    padding: clamp(${1.5}rem, 2.7vw, ${3.5}rem);
    padding-bottom: 2rem;
    transition: transform 0.2s, box-shadow 0.2s;
    &:visited {}
    &:hover {
        box-shadow: 0.2rem 0.2rem 0.86rem 0px rgba(0,0,0,0.09);
        transform: scale(0.97);
    }
    ${theme.mediaQueries.mobileMax} {
        margin-bottom: 1rem;
        &:hover {
            box-shadow: unset;
            transform: unset;
        }
    }
    ${p => !p.inGrouping ? `
        ${theme.mediaQueries.tabletMax} {
            margin-block: 2rem;
        }
        ${theme.mediaQueries.mobileMax} {
            margin-block: 1rem !important;
        }
    ` : ''}

${(p) =>
        p.isHiddenForPublic
            ? `
    pointer-events: none;
    `
            : ''}
`;

export const StyledProductCardInner = styled.div`

`;

export const StyledPlusIconContainer = styled.div`
    margin-right: -1.5rem;
    ${theme.mediaQueries.tabletMax} {
        margin-right: 0;
    }
`;

export const StyledBottomIconContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding-top: 1rem;
`;

export const StyledThumbnailWrapper = styled.div`
    width: 100%;
    min-width: 10.71rem;
    margin: 0 auto;
    height: auto;
    min-height: 14rem;
    overflow: hidden;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
`;

export const StyledDeleteFromMyContentButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;

    & > svg:hover,
    path {
        path {
            fill: ${palette.pink};
        }
    }
`;
