/* eslint-disable indent */

import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../../style/theme';
import { StyledLinkProps } from '../../../ui-components/link/link-types';

const sizeMap = {
    smaller: '1.5rem',
    medium: '1.7rem',
};

type StyledLinkPropsWithSize = StyledLinkProps & {
    size?: 'smaller' | 'medium';
};

const arrowLinkStyle = (p: StyledLinkPropsWithSize) => `
    text-decoration: none;
    font-size: ${p.size ? sizeMap[p.size] : sizeMap.medium};
    font-family: ${p.bold ? fontFamilyAtosBold : fontFamilyAtosRegular};
    ${p.bold ? 'font-weight: 800;' : ''}
    display: flex;
    align-items: center;
    color: ${p.labelColor ? p.labelColor : theme.palette.gray};
    transition: 0.2s color;

`;
export const StyledArrowLink = styled(Link)<StyledLinkPropsWithSize>`
    ${(p) => arrowLinkStyle(p)}
    cursor: pointer;
    ${(p) => (p.active ? `color: ${theme.palette.white}; opacity: 1 !important;` : '')}
`;

export const StyledArrowLinkAsButton = styled.span<StyledLinkPropsWithSize>`
    ${(p) => arrowLinkStyle(p)}
`;
