import { createStore } from 'solid-js/store';
import { CareRegistrationFormStore, RegistrationFormStore } from './form-types';

type Field = {
    [key: string]: string | boolean;
};

type FormStore = {
    fields: CareRegistrationFormStore & RegistrationFormStore;
    error: string;
}

const defaultCareFields = {
    street: '',
    postcode: '',
    'is-caregiver': false,
    'patient-name': '',
    'patient-street': '',
    'patient-postcode': '',
    'patient-city': '',
    'patient-email': '',
};

const defaultFormStore: FormStore = {
    fields: {
        email: '',
        firstname: '',
        lastname: '',
        country: '',
        city: '',
        'user-type': '',
        institution: '',
        password: '',
        verifyPassword: '',
        consent: false,
        referer: '',
        site: '',
        ...defaultCareFields,
    },
    error: '',
};

export const [formStore, setFormStore] = createStore<FormStore>({...defaultFormStore});

export const resetFormStore = () => {
    setFormStore({...defaultFormStore});
};

export const setError = (error: string) => {
    setFormStore('error', error);
};

export const setFormStoreField = (field: Field) => {
    setFormStore('fields', {...formStore.fields, ...field});
};
