import { styled } from 'solid-styled-components';
import theme from '../../../../style/theme';

export const StyledPostItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: ${theme.palette.white};
    border-radius: ${theme.borderRadius.small};
    padding: ${theme.spacing.xs};
    margin-bottom: ${theme.spacing.xs};
    cursor: move;
    &:last-child {
        margin-bottom: 4rem;
    }
`;

export const StyledRemoveButton = styled.div`
    display: flex;
`;

export const StyledTextContainer = styled.div`
    padding-right: 1.5rem;
`;