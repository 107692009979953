import { styled } from 'solid-styled-components';
import { availableWidth } from '../../shared-components.style';
import theme from '../../../../../../style/theme';

export const StyledHeroPdf = styled.div<{ backgroundImageSrcData: any; focusPosition: string; }>`
    position: relative;
    padding: 2.5rem;
    padding-top: 1px;
    min-height: 21rem;
    border-radius: 0.73rem;
    margin-right: -125px;

    ${(p) =>
        p.backgroundImageSrcData && p.backgroundImageSrcData.original !== ''
            ? `
                background-image: url(${p?.backgroundImageSrcData?.original || ''});
                background-repeat: no-repeat;
                background-size: cover;
                background-position: ${p.focusPosition || 'center'};
                &::before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0.45;
                    border-radius: 0.73rem;
                    background-color: ${theme.palette.beige};            
                }            
            `
            : ''
}
`;

export const StyledHeroDescriptionPDF = styled.div`
    width: 80%;
`;

export const StyledHeroTextsPdf = styled.div`
    position: relative;
    z-index: 0;
    width: 85%;
`;

export const StyledHeroImageContainerPDF = styled.div`
    position: absolute;
    width: ${availableWidth}px;
`;

export const StyledLinkTextSpacer = styled.div`
    padding-left: 0.5rem;
`;
