import { Switch, Match } from 'solid-js';
import theme from '../../style/theme';
import { DocumentTypeFlagTypes } from './document-type-flag-types';
import {
    StyledDocumentTypeFlagSmall,
    StyledDocumentTypeFlagTextSmall,
    StyledIconPlaceholder,
} from './document-type-flag.styles';
import removeAmpersand from '../../tools/remove-ampersand';

export const DocumentTypeFlag = (props: DocumentTypeFlagTypes) => {
    const getFillColors = () => {
        const fillColor = props.type === 'event' ? theme.palette.pink : theme.palette.darkGray;
        const secondaryFillColor = props.type === 'event' ? theme.palette.red : theme.palette.darkestGray;

        return { fillColor, secondaryFillColor };
    };

    const getLabelName = () => {
        let labelName = props.customText || props.date || props.type;
        labelName = labelName.length > 15 ? `${labelName.slice(0, 15)}...` : labelName;
        return removeAmpersand(labelName);
    };

    return (
        <Switch>
            <Match when={props.size === 'small'}>
                <StyledDocumentTypeFlagSmall>
                    <StyledDocumentTypeFlagTextSmall backgroundColor={getFillColors().fillColor}>{getLabelName()}</StyledDocumentTypeFlagTextSmall>
                    <StyledIconPlaceholder>
                        <svg viewBox="9.1 5.9 37.8 100">
                            <g>
                                <path
                                    d="M28.3,20.9v49.9H9.1V8h6.3c3.5,0,6.7,1.3,9.2,3.8h0c0.9,0.9,1.7,2,2.3,3.1c0.1,0.2,0.2,0.3,0.2,0.5v0
		C27.9,17.1,28.3,19,28.3,20.9z"
                                    fill={getFillColors().fillColor}
                                />
                                <polygon points="25.5,74.8 11,74.8 11,89.2" fill={getFillColors().secondaryFillColor} />
                                <path
                                    d="M15.4,6H9.1v2h6.3c3.5,0,6.7,1.3,9.2,3.8c0.9,0.9,1.7,2,2.3,3.1c0.1,0.2,0.2,0.3,0.2,0.5v0
		c0.8,1.7,1.2,3.6,1.2,5.5v49.9H9.1V94l21.3-21.2V20.9C30.3,12.7,23.6,6,15.4,6z M11,89.2V74.8h14.5L11,89.2z"
                                    fill="white"
                                />
                            </g>
                        </svg>
                    </StyledIconPlaceholder>
                </StyledDocumentTypeFlagSmall>
            </Match>
            {/* TODO: compared to design, our current SMALL flag looks more like LARGE, so i am not sure if we shall make it smaller?
            or just make the LARGE one a bit bigger than current SMALL?.. */}
            {/* <Match when={props.size === 'large'}>
            </Match> */}
        </Switch>
    );
};
