import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../style/theme';


export const StyledAppBanner = styled.div`
    position: fixed;
    bottom: -2px;
    width: 100%;
    background-color: #f8f8f8;
    border-top: 2px solid #d8d8d8;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-block: 1.5rem;
`;

export const StyledLede = styled.div`
    padding-bottom: 1.5rem;
    padding-bottom: 0.8rem;
`;

export const StyledBannerContent = styled.div`
    width: 100%;
    margin-inline: 1.5rem;
`;


export const StyledButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
  
export const StyledAppIcon = styled.img`
    width: 64px;
    height: 64px;
    border-radius: 20%;
    margin-right: 2rem;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
`;

export const StyledAppInfo = styled.div`
    display: flex;
`;

export const StyledAppHeading = styled.h4`
    letter-spacing: -0.08rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    letter-spacing: -0.05rem;
    font-size: 2.2rem;
    line-height: 3rem;
    margin-top: 0 !important;
    margin-bottom: 0em;
    word-break: break-word;
    hyphens: auto;
    color: ${theme.colors.text.heading};
`;