import {
    StyledFooter,
    StyledLogo,
    CopyrightContainer,
    StyledCopyright,
    rowBlockSpacing,
    StyledFooterColumn
} from './footer.styles';
import { Column } from '../../grid-system/column/column';
import { gql } from 'graphql-request';
import { useContext, Show, For } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Text } from '../../ui-components/text/text';
import { Grid } from '../../grid-system/grid/grid';
import { AtosLogoWhite } from '../icons-library/atos-logo-desktop-white';
import { Link, useLocation } from '@solidjs/router';
import { ErrorCatcher } from '../../tools/error-catcher';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';


const GET_FOOTER_QUERY = gql`
    query GetFooter($key: String!) {
        footer: resource(key: $key) {
            content
            key
        }
    }
`;

type ColumnItem = {
    title: string;
    url: string;
    target: string;
}
type FooterColumn = {
    header: string;
    columnItems: ColumnItem[]
};

const getTemplate = (columns = []) => {
    let uneven = false;
    let remainder = 0;
    const numberOfColumns = columns.length + 1; // One static (with logotype)
    let colWidth = 12 / numberOfColumns;

    if (12 % numberOfColumns !== 0) {
        // Column count including static one not evenly divisible
        uneven = true;
        remainder = 12 % numberOfColumns;
        colWidth = Math.floor(colWidth);
    }

    const template = [];
    template.push(uneven ? colWidth + remainder : colWidth);
    // And the rest is from WP.
    columns.forEach(() => {
        template.push(colWidth);
    });

    return template;
};

export const FooterContainer = () => {

    const { createCachedResource, localize } = useContext(AppContext);
    const [ footerContent ] = createCachedResource(GET_FOOTER_QUERY, () => ({ key: '/wp-json/rawb/v1/footer' }), true);
    const location = useLocation();

    const copyrightText = footerContent()?.footer?.content?.copyrighttext;

    const handleOnClickCookieConsent = (e: any) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.CookieConsent.renew();
    };

    return (
        <ErrorCatcher componentName='Footer'>
            <StyledFooter>

                <Show when={footerContent()?.footer?.content?.columns}>
                    <Grid 
                        templateShorthand={getTemplate(footerContent().footer.content.columns)} 
                        isLayoutGrid={true}
                        customCss={rowBlockSpacing}>
                        <StyledLogo>
                            <AtosLogoWhite aria-label={localize('atos_medical_logotype', 'Atos Medical Logo')} />
                        </StyledLogo>

                        <For each={footerContent().footer.content.columns}>
                            {(column: FooterColumn, index) => {
                                if (!column) {
                                    return null;
                                }
                                return (
                                    <StyledFooterColumn>
                                        <Text fontSize='small' color={'white'} displayRedVerticalLine={false} fontStyle='bold'>{column.header}</Text>
                                        <For each={column?.columnItems}>{(columnItem) => {
                                            if (!columnItem || !columnItem.title || !columnItem.url) {
                                                return null;
                                            }
                                            
                                            return (
                                                <Link href={removeTrailingSlash(columnItem.url)} target={columnItem.target}>
                                                    <Text fontSize={'small'} color={'white'} noBlockSpacing={true} displayRedVerticalLine={false} backgroundColor='transparent'>
                                                        {columnItem.title}
                                                    </Text>
                                                </Link>
                                            );
                                        }}</For>
                                        <Show when={index() === 0}>{
                                            <Link href={location.pathname} onClick={handleOnClickCookieConsent}>
                                                <Text fontSize={'small'} color={'white'} noBlockSpacing={true} displayRedVerticalLine={false} backgroundColor='transparent'>
                                                    {localize('cookie-consent', 'Cookie consent')}
                                                </Text>
                                            </Link>

                                        }</Show>
                                    </StyledFooterColumn>
                                );
                            }}
                        </For>
                    </Grid>
                </Show>

                <Grid templateShorthand={[12]} customCss={'height: 20%'} isLayoutGrid={true}>
                    <CopyrightContainer>
                        <Column>
                            { copyrightText && (
                                <StyledCopyright>
                                    <Text align='center' fontSize='small' color='white' displayRedVerticalLine={false}>
                                        {`${localize('copyright', 'Copyright')} ${copyrightText}`}
                                    </Text>
                                </StyledCopyright>
                            )}
                        </Column>
                    </CopyrightContainer>
                </Grid>
            </StyledFooter>
        </ErrorCatcher>
    );
};
