
import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 33.75 30',
    // width: 33.75,
};

export const ShareIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M30,38.429a6.427,6.427,0,0,1-11.277,4.219l-6.007,3.007a6.061,6.061,0,0,1,.08,1.286,6.715,6.715,0,0,1-.08,1.406l6.007,2.946a6.628,6.628,0,0,1,4.848-2.21,6.459,6.459,0,1,1-6.429,6.489,6.375,6.375,0,0,1,.141-1.346l-6.007-3.007A6.444,6.444,0,1,1,6.429,40.511a6.628,6.628,0,0,1,4.848,2.21l6.007-2.946A6.429,6.429,0,1,1,30,38.429ZM6.368,50.214a3.214,3.214,0,1,0,0-6.429,3.214,3.214,0,1,0,0,6.429Zm17.2-15a3.214,3.214,0,1,0,3.214,3.214A3.215,3.215,0,0,0,23.571,35.214Zm0,23.571a3.214,3.214,0,1,0-3.214-3.214A3.215,3.215,0,0,0,23.571,58.786Z"
                transform="translate(0 -32)"
            />
        </SvgIcon>
    );
};
