import { Show } from 'solid-js';
import { ProductIcons } from '../../../../product/product-icon-tab/product-icons';
import { StyledPdfProductIconTab, StyledPdfIconTabRow, StyledPdfArticleIconTab, StyledPdfEventIconTab } from './icon-tab.styles';
import { Heading } from '../../../../../ui-components/heading/heading';
import { ProductIconName } from '../../../../product/product-icon-tab/product-icon-tab-types';

type PdfIconTabProps = {
    type: 'product' | 'article' | 'event';
    productIconName?: ProductIconName;
    label?: string;
};

export const PdfIconTab = (props: PdfIconTabProps) => {
    
    return (
        <StyledPdfIconTabRow>
            <Show when={props.type === 'product' && props.productIconName}>
                <StyledPdfProductIconTab productIconName={props.productIconName!}>
                    <ProductIcons name={props.productIconName!} size={2.9} />
                </StyledPdfProductIconTab>
            </Show>

            <Show when={props.type === 'article'}>
                <StyledPdfArticleIconTab>
                    <Heading tag='h3' variant='mediumBoldPDF' color={{ name: 'white', color: 'white' }}>{props.label}</Heading>
                </StyledPdfArticleIconTab>
            </Show>

            <Show when={props.type === 'event'}>
                <StyledPdfEventIconTab>
                    <Heading tag='h3' variant='mediumBoldPDF' color={{ name: 'white', color: 'white' }}>{props.label}</Heading>
                </StyledPdfEventIconTab>
            </Show>
        </StyledPdfIconTabRow>
    );
};
