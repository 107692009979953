import { SiteInfo } from '../../../../types/app-state';

export const prettifyUrl = (url: string = '', siteInfo: SiteInfo) => {
    let cleanUrl = url;

    if (siteInfo.currentDomain && cleanUrl.includes(siteInfo.currentDomain)) {
        const indexOfDomain = cleanUrl.indexOf(siteInfo.currentDomain);
        const endOfDomain = indexOfDomain + siteInfo.currentDomain.length;
        cleanUrl = cleanUrl.substring(endOfDomain);
    }

    cleanUrl = cleanUrl.replace(/\/$/, ''); // Remove trailing slash
    cleanUrl = cleanUrl.replace(/^\//, ''); // Remove starting slash
    
    return `${siteInfo.currentDomain}/${cleanUrl}`;  
};

