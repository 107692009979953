import theme from '../../../style/theme';
import { styled } from 'solid-styled-components';

export const StyledMobileFilterButtonContainer = styled.div`
    padding-inline: 1.5rem;
`;

export const StyledMobileFilterButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 1rem;
    border: 1px solid ${theme.palette.gray};
    border-radius: ${theme.borderRadius.small};
    width: 100%;
`;

export const StyledCategoryTagsContainer = styled.ul<{ isCollectionPage?: boolean}>`
    display: flex;
    flex-flow: wrap;
    padding-inline: 1.5rem;
    ${p => p.isCollectionPage ? '' : 'padding-top: 1.5rem;'}
`;

export const StyledFilterIconAndTextWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const StyledFilterIconWrapper = styled.div`
    position: absolute;
    left: -2.5rem;
    padding-right: 1rem;
`;