import { styled } from 'solid-styled-components';
import theme, { palette } from '../../style/theme';

export const StyledButtonRow = styled.div`
    display: flex;
    justify-content: start;
    gap: 3rem;

    ${theme.mediaQueries.tabletMax} {
        flex-direction: column;
        gap: 0;

        button {
            width: 100%;
        }
    }
`;

export const StyledHCPWarning = styled.div`
    text-align: left;
    padding: 0 6rem;

    ${theme.mediaQueries.tabletMax} {
        padding: 0;
    }
`;

export const StyledDivider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${palette.red};
    margin-bottom: 2rem;
`;