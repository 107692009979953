import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledUserOrderHistoryTable = styled.div`
    background-color: ${theme.palette.lightBeige};
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    gap: 2.2rem;
    display: flex;
    flex-direction: column;
`;

export const StyledHistoryOrderLineWrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const StyledHistoryOrderLine = styled.div`
    display: flex;
    gap: 1rem;
`;

export const StyledTextContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledUserOrdersHistory = styled.div`
    padding: 1rem;
    margin-top: 1rem;
`;

export const StyledOrderHistory = styled.div`
    margin-bottom: 3rem;
    opacity: 0;
    animation: fadeIn 0.4s ease-in-out forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

export const StyledImageHolder = styled.div`
    flex-shrink: 0;
    align-self: flex-start;
    width: 100px;
    padding-right: 1rem;
    ${theme.mediaQueries.tabletMax} {
        margin-bottom: 1.5rem;
        padding-right: 0;
    }
    ${theme.mediaQueries.tabletMax} {
        width: 60px;
    }
`;
