const typesAndCategoriesTranslationsMap: { [key: string]: string } = {
    'technical-information': 'Technical Information',
    'product-statements': 'Product Statements',
    'ec-certificate': 'EC Certificate',
    'mdsap': 'MDSAP',
    'iso-13485': 'ISO 13485',
    'iso-13485-mdsap': 'ISO 13485 & MDSAP',
    'essentials': 'Essentials',
    'hmes-speaking-valves': 'HMEs & Speaking Valves',
    'percutaneous-dilation': 'Percutaneous Dilation',
    'technical-equipment': 'Technical Equipment',
    'kids': 'Kids',
    'tracheostomy-tubes': 'Tracheostomy Tubes',
    'free-sales-certificate': 'Free Sales Certificate',
    'tracheostomy-products': 'Tracheostomy Products',
    'laryngectomy-products': 'Laryngectomy Products',
    'provox-voice-rehabilitation': 'Provox Voice Rehabilitation',
    'provox-accessories': 'Provox Accessories',
    'provox-freehands': 'Provox FreeHands',
    'provox-hme-accessories': 'Provox HME Accessories',
    'provox-hme-cassettes': 'Provox HME Cassettes',
    'provox-lary-products': 'Provox Lary Products',
    'provox-life-products': 'Provox Life Products',
    'trachphone': 'TrachPhone',
    'provox': 'Provox',
    'freevent': 'FreeVent',
    'jaw-mobility': 'Jaw Mobility',
    'therabite-system': 'TheraBite System',
    'company-certificates': 'Company Certificates',
    'mdd-extension-letter': 'MDD Extension Letter',
    'declaration-of-conformity': 'Declaration of Conformity',
    'general': 'General',
    'complaint-report': 'Complaint Report',
};

export const getFileSlugTranslations = (
    localize: (slug: string, defaultTranslation: string) => string,
    slug: string
) => {
    const translated = typesAndCategoriesTranslationsMap[slug];

    if (translated) {
        return localize(slug, translated);
    }

    // If no translation is found, format the slug to be more readable (NB: won't be translatable)
    const formattedSlug = slug
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return formattedSlug;
};
