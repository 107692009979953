import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledCardSliderContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 3rem;
        ${theme.mediaQueries.tabletMax} {
            margin-bottom: 1.5rem;
        }
    }

    &:not(:first-child) {
        margin-top: 1rem;
        ${theme.mediaQueries.tabletMax} {
            margin-top: 0.5rem;
        }    
    }
`;
// padding-block: 5rem;
// padding-bottom: 2rem;

// ${theme.mediaQueries.mobileMax} {
//     padding-block: 0;
//     padding-bottom: 0;
// }
