import { Text } from '../../text/text';
import theme from '../../../style/theme';
import {
    StyledCheckmark,
    StyledInput
} from './checkbox.style';
import { StyledLabel } from '../common.style';
import { ErrorCatcher } from '../../../tools/error-catcher';

type CheckboxProps = {
    whenClicked: (e?: any) => void;
    value: string;
    isChecked: boolean;
    name: string;
    // Sorry, name was taken.
    inputName?: string;
    hasErrors?: boolean;
    id?: string;
    inputRef?: (el: HTMLInputElement) => void;
}


export const Checkbox = (props: CheckboxProps) => {    

    return (
        <ErrorCatcher componentName='Checkbox'>
            <StyledLabel for={props.id || props.value} hasErrors={props.hasErrors}>
                <StyledInput
                    id={props.id || props.value}
                    onInput={props.whenClicked}
                    type='checkbox'
                    value={props.value}
                    checked={props.isChecked}
                    name={props.inputName}
                    ref={props.inputRef}
                />
                <StyledCheckmark />
                <Text 
                    noBlockSpacing={true}
                    fontSize='small'
                    {...props.isChecked && {
                        color: theme.palette.red
                    }}
                >
                    {props.name}
                </Text>
            </StyledLabel>
        </ErrorCatcher>
    );
};
