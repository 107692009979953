import { createEffect, createSignal, For, on, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import theme from '../../style/theme';
import { navigateToAnchor } from '../../tools/scroll-to-element';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import { AngleDownIcon } from '../icons-library/angle-down';
import {
    SearchResultCardWrapper,
    StyledColumn,
    StyledLink,
    StyledResultsHeading,
    StyledSearchResultsWrapper,
    StyledShowMoreButton,
    StyledShowMoreMobileWrapper,
} from './search-result.styles';
import { ResultItemType, SearchResultCardProps, Document } from './search-types';
import { StyledRedDivider, StyledSearchResultsHeadingsSection, StyledSearchResultsHeadingsWrapper } from './search.style';
import removeAmpersand from '../../tools/remove-ampersand';

export type ShowMoreButtonProps = {
    currentPage: number;
    resultsPerPage: number;
    totalResults: number;
    onClick: () => void;
};



export const SearchResults = (props: any) => {
    const AppState = useContext(AppContext);
    const { localize, siteInfo, } = useContext(AppContext);

    const isMobile = () => AppState.viewport.width <= theme.breakpoints.MOBILE;

    const [articlesAndBlogPostsPage, setArticlesAndBlogPostsPage] = createSignal(1);
    const [pagesAndProductsPage, setPagesAndProductsPage] = createSignal(1);
    const [videosPage, setVideosPage] = createSignal(1);
    const [eventsAndWebinarsPage, setEventsAndWebinarsPage] = createSignal(1);
    const [documentsPage, setDocumentsPage] = createSignal(1);

    const resultsPerPage = 3;
    const videoResultsPerPage = 3;

    const captionText = (currentPage: number, resultsPerPage: number, totalResults: number) => {
        return `(${localize('showing', 'Showing')} ${Math.min(currentPage * resultsPerPage, totalResults)} / ${totalResults} ${localize(
            'results',
            'results'
        )})`;
    };

    const resetPages = () => {
        setArticlesAndBlogPostsPage(1);
        setPagesAndProductsPage(1);
        setVideosPage(1);
        setEventsAndWebinarsPage(1);
        setDocumentsPage(1);
    };

    createEffect(
        on(
            () => props.hasClickedSubmitButton,
            () => resetPages(),
            {
                defer: true,
            }
        )
    );

    const mapSearchResultHeading = (heading: ResultItemType) => {
        const valuesMap: any = {
            'blog-and-news': localize('blog', 'Blog and news'),
            product: localize('product', 'Product'),
            page: localize('page', 'Page'),
            'video-item': localize('video', 'Video'),
            event: localize('event', 'Event'),
            document: localize('document', 'Document'),
        };

        return valuesMap[heading] || localize(heading, heading);
    };

    const SearchResultCard = (props: SearchResultCardProps) => {
        return (
            <StyledLink href={props?.url} target="_blank" rel="noopener noreferrer">
                <SearchResultCardWrapper>
                    <p>{mapSearchResultHeading(props.type)}</p>
                    <StyledResultsHeading>{removeAmpersand(props.title)}</StyledResultsHeading>
                </SearchResultCardWrapper>
            </StyledLink>
        );
    };

    const ShowMoreButton = (props: ShowMoreButtonProps) => {
        return (
            <Show when={Math.min(props.currentPage * props.resultsPerPage, props.totalResults) !== props.totalResults}>
                <StyledShowMoreButton type="button" onClick={props.onClick}>
                    <div>{localize('show-more', 'show more')}</div>
                    <AngleDownIcon fill={theme.palette.red} {...{ height: 2.14, width: 2.86 }} />
                </StyledShowMoreButton>
            </Show>
        );
    };

    const groupDocumentsByType = (): Record<string, Document[]> => {
        return props.documents?.reduce((acc: Record<string, Document[]>, document: Document) => {
            const typeName = Array.isArray(document.tags) && document.tags.length > 0 ? document.tags[0] : 'Unknown';
            if (!acc[typeName]) {
                acc[typeName] = [];
            }
            acc[typeName].push(document);
            return acc;
        }, {} as Record<string, Document[]>);
    };

    return (
        <>
            <StyledSearchResultsHeadingsWrapper id="searchResultsHeading">
                <Section templateShorthand={[12]} widthType={'bgFull'}>
                    <Grid templateShorthand={[12]}>
                        <StyledSearchResultsHeadingsSection>
                            <Heading tag="h2" variant="xlarge">
                                {localize('search-results', 'Search results')}
                            </Heading>
                            <StyledRedDivider />

                            <Show when={!isMobile()}>
                                <Button
                                    label={localize('new-search', 'New search')}
                                    variant="primary"
                                    onClick={(event: any) => navigateToAnchor({ 
                                        event, 
                                        id: 'search', 
                                        smallScreen: isMobile(),
                                        isCareSite: siteInfo.siteType === 'atos-care',
                                    })}
                                />
                            </Show>
                        </StyledSearchResultsHeadingsSection>
                    </Grid>
                </Section>
            </StyledSearchResultsHeadingsWrapper>
            <StyledSearchResultsWrapper>
                <Section templateShorthand={[12]} widthType={'bgFull'}>
                    <Grid templateShorthand={[6, 6]} responsive={{ mobile: [12] }}>
                        <StyledColumn>
                            <Heading tag="h3" variant="large">
                                {localize('pages-and-products', 'Pages and Products')}
                            </Heading>

                            <For each={props.pagesAndProducts.slice(0, pagesAndProductsPage() * resultsPerPage)}>
                                {(item: any) => <SearchResultCard type={item.type} title={removeAmpersand(item.postTitle)} url={item.url ? item.url : '/'} />}
                            </For>

                            <Text color={'darkGray'} fontSize="small" displayRedVerticalLine={false}>
                                {captionText(pagesAndProductsPage(), resultsPerPage, props.pagesAndProducts.length)}
                            </Text>

                            <ShowMoreButton
                                currentPage={pagesAndProductsPage()}
                                resultsPerPage={resultsPerPage}
                                totalResults={props.pagesAndProducts.length}
                                onClick={() => setPagesAndProductsPage(pagesAndProductsPage() + 1)}
                            />
                        </StyledColumn>
                        <StyledColumn>
                            <Heading tag="h3" variant="large">
                                {localize('articles-and-blog-posts', 'Blogs and news')}
                            </Heading>

                            <For each={props.articlesAndBlogPosts.slice(0, articlesAndBlogPostsPage() * resultsPerPage)}>
                                {(item: any) => <SearchResultCard type={item.type} title={removeAmpersand(item.postTitle)} url={item.url} />}
                            </For>

                            <Text color={'darkGray'} fontSize="small" displayRedVerticalLine={false}>
                                {captionText(articlesAndBlogPostsPage(), resultsPerPage, props.articlesAndBlogPosts.length)}
                            </Text>

                            <ShowMoreButton
                                currentPage={articlesAndBlogPostsPage()}
                                resultsPerPage={resultsPerPage}
                                totalResults={props.articlesAndBlogPosts.length}
                                onClick={() => setArticlesAndBlogPostsPage(articlesAndBlogPostsPage() + 1)}
                            />
                        </StyledColumn>
                        <StyledColumn>
                            <Heading tag="h3" variant="large">
                                {localize('events-and-webinars', 'Events and webinars')}
                            </Heading>
                            <For each={props.eventsAndWebinars.slice(0, eventsAndWebinarsPage() * resultsPerPage)}>
                                {(item: any) => <SearchResultCard type={item.type} title={removeAmpersand(item.postTitle)} url={item.url} />}
                            </For>

                            <Text color={'darkGray'} fontSize="small" displayRedVerticalLine={false}>
                                {captionText(eventsAndWebinarsPage(), resultsPerPage, props.eventsAndWebinars.length)}
                            </Text>

                            <ShowMoreButton
                                currentPage={eventsAndWebinarsPage()}
                                resultsPerPage={resultsPerPage}
                                totalResults={props.eventsAndWebinars.length}
                                onClick={() => setEventsAndWebinarsPage(eventsAndWebinarsPage() + 1)}
                            />
                        </StyledColumn>
                        <StyledColumn>
                            <Heading tag="h3" variant="large">
                                {localize('documents', 'Documents')}
                            </Heading>

                            <For each={Object.entries(groupDocumentsByType())}>
                                {([typeName, documents]) => (
                                    <div>
                                        <For each={documents.slice(0, documentsPage() * resultsPerPage)}>
                                            {(document) => (
                                                <SearchResultCard
                                                    type={typeName}
                                                    title={removeAmpersand(document.postTitle)}
                                                    url={document.url}
                                                />
                                            )}
                                        </For>
                                    </div>
                                )}
                            </For>

                            <Text color={'darkGray'} fontSize="small" displayRedVerticalLine={false}>
                                {captionText(documentsPage(), resultsPerPage, props.documents.length)}
                            </Text>

                            <ShowMoreButton
                                currentPage={documentsPage()}
                                resultsPerPage={resultsPerPage}
                                totalResults={props.documents.length}
                                onClick={() => setDocumentsPage(documentsPage() + 1)}
                            />
                        </StyledColumn>

                        <StyledColumn>
                            <Heading tag="h3" variant="large">
                                {localize('videos', 'Videos')}
                            </Heading>

                            <For each={props.videos.slice(0, videosPage() * videoResultsPerPage)}>
                                {/* TODO: fix page for a single video */}
                                {(item: any) => <SearchResultCard type={item.type} title={removeAmpersand(item.postTitle)} url={item.url ? item.url : '/'} />}
                            </For>

                            <Text color={'darkGray'} fontSize="small" displayRedVerticalLine={false}>
                                {captionText(videosPage(), videoResultsPerPage, props.videos.length)}
                            </Text>

                            <ShowMoreButton
                                currentPage={videosPage()}
                                resultsPerPage={videoResultsPerPage}
                                totalResults={props.videos.length}
                                onClick={() => setVideosPage(videosPage() + 1)}
                            />
                        </StyledColumn>
                        <Show when={isMobile()}>
                            <StyledShowMoreMobileWrapper>
                                <Button
                                    label={localize('new-search', 'New search')}
                                    variant="primary"
                                    onClick={(event: any) => navigateToAnchor({ 
                                        event, 
                                        id: 'search',
                                        smallScreen: isMobile(),
                                        isCareSite: siteInfo.siteType === 'atos-care',
                                    })}
                                />
                            </StyledShowMoreMobileWrapper>
                        </Show>
                    </Grid>
                </Section>
            </StyledSearchResultsWrapper>
        </>
    );
};

/* // i use split and map to hightlight the text
    .split(new RegExp(`(${inputValue()})`, 'gi'))
    .map((part: string) => (part.toLowerCase() === inputValue().toLowerCase() ? <mark>{part}</mark> : part)) */
