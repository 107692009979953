import { gql } from 'graphql-request';
import { ImageComponent } from '../../../../../image-component/image-component';
import { VideoBlock, VideoEmbedComponentProps } from '../../../../../video-embed/video-embed-types';
import { StyledQrCode, StyledVideoPdf, StyledVideoPdfDescriptionWrapper, StyledVideoPdfImage, StyledYoutubeThumbnail } from './video-pdf.style';
import { Heading } from '../../../../../../ui-components/heading/heading';
import { Match, Show, Switch, createEffect, createSignal, useContext } from 'solid-js';
import { AtosLogo } from '../../../../../icons-library/atos-logo-desktop';
import { AppContext } from '../../../../../../app-context-provider/app-context-provider';
import { YoutubeIcon } from '../../../../../icons-library/youtube-icon';
import { VimeoIcon } from '../../../../../icons-library/vimeo-icon';
import { generateQRCode } from '../../generate-qr';
import { SpacerPdf } from '../spacer/spacer-pdf';

const CHECK_THUMBNAIL_EXISTS = gql`
    query CheckThumbnail ($thumbnailUrl: String!) {
        resource: checkThumbnail(thumbnailUrl: $thumbnailUrl) {
            exists
        }
    }
`;


export const VideoPdf = (props: VideoEmbedComponentProps) => {
    const { graphQlClient } = useContext(AppContext);
    const [thumbnail, setThumbnail] = createSignal('');
    const [thumbnailSource, setThumbnailSource] = createSignal('');
    const [videoProvider, setVideoProvider] = createSignal< 'youtube' | 'vimeo'>();

    createEffect(() => {        
        getThumbnailUrl(props?.image?.url, props.url);

        if (props.url.includes('youtu')) {
            setVideoProvider('youtube');
        }

        if (props.url.includes('vimeo')) {
            setVideoProvider('vimeo');
        }

    });

    const getYoutubeVideoId = (url: string) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/; // eslint-disable-line
        const match = url.match(regExp);
    
        if (match && match[2].length === 11) {
            return match[2];
        } else {
            return;
        }
    };

    const getThumbnailUrl = async (customThumbnail: string | undefined, videoLink: string) => {
        if (customThumbnail) {
            setThumbnailSource('custom');
            setThumbnail('/wp-content/uploads/2023/03/Thomas_Moors.jpg');
            return;
        }
            
        if (props.url.includes('youtu')) {
            const id = getYoutubeVideoId(videoLink);
            if (!id) {
                return;
            }

            const thumbnailUrl = `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
    
            try {
                const variables = { thumbnailUrl };
                const response = await graphQlClient(CHECK_THUMBNAIL_EXISTS, variables, true);
    
                if (!response || !response.resource || !response.resource.exists) {
                    return;
                }
        
                setThumbnail(thumbnailUrl);
                setThumbnailSource('youtube');
            } catch (error) {
                console.log('error', error);   
            }
        }

        return;
    };

    return (
        <div>
            <SpacerPdf height={2.2} />
            <StyledVideoPdf>
                <StyledVideoPdfImage>
                    <Switch>
                        <Match when={thumbnailSource() === 'custom'}>
                            <ImageComponent
                                src={thumbnail()}
                                height={'100%'}
                                width={225}
                                altText={''}
                                title={''}
                                imageText={''}
                                roundCorners={false}
                                focus={{ x: 0.5, y: 0.5 }}
                                ratio={1.77}
                                spacer={false}
                                forPdfBuilder={true}
                            />
                        </Match>
                        <Match when={thumbnailSource() === 'youtube'}>
                            <StyledYoutubeThumbnail src={thumbnail()} />
                        </Match>
                        <Match when={thumbnailSource() === ''}>
                            <AtosLogo width={12} />
                        </Match>
                    </Switch>
                </StyledVideoPdfImage>
                <StyledVideoPdfDescriptionWrapper>
                    <Heading
                        tag='h5'
                        variant='mediumPDF'
                        color={{ name: 'black', color: 'black'}}
                    >
                        { props.title }
                    </Heading>
                    <Show when={videoProvider() === 'youtube'}>
                        <YoutubeIcon/>
                    </Show>
                    <Show when={videoProvider() === 'vimeo'}>
                        <VimeoIcon />
                    </Show>
                </StyledVideoPdfDescriptionWrapper>
                <StyledQrCode innerHTML={generateQRCode(props.url)} />

            </StyledVideoPdf>
            <SpacerPdf height={2.2} />
        </div>
    );
};

VideoPdf.parseProps = (atts: VideoBlock) => {
    return {
        url: atts.url,
        title: atts.title,
        shortDescription: atts.shortDescription,
        image: atts.image,
    };
};
