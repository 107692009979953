import { createSignal } from 'solid-js';
import { produce } from 'solid-js/store';

export type AppStoreData = {
    isHcp: boolean;
    isCampaignPage: boolean;
};

export const createAppStore = (syncStore: any) => {

    const [state, setState] = syncStore;
    const [menuOpen, setMenuOpen] = createSignal(false);
    const [loginMenuOpen, setLoginMenuOpen] = createSignal(false);

    const open = () => {
        setMenuOpen(true);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'hidden';
    };
    const close = () => {
        setMenuOpen(false);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'auto';
    };
    const openLoginMenu = () => {
        setLoginMenuOpen(true);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'hidden';
    };
    const closeLoginMenu = () => {
        setLoginMenuOpen(false);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'auto';
    };

    return {
        menuOpen,
        open,
        close,
        loginMenuOpen,
        openLoginMenu,
        closeLoginMenu,
        setHcp: (value: boolean) => {
            setState(
                produce((state: any) => {
                    state.appStore.isHcp = value;
                })
            );
        },
        isHcp: () => state.appStore.isHcp,
        setCampaignPage: (value: boolean) => {
            setState(
                produce((state: any) => {
                    state.appStore.isCampaignPage = value;
                })
            );
        },
        isCampaignPage: () => state.appStore.isCampaignPage,
        setHasHero: (value: boolean) => {
            setState(
                produce((state: any) => {
                    state.appStore.hasHero = value;
                })
            );
        },
        hasHero: () => state.appStore.hasHero,
    };

};

