import { Text } from '../../ui-components/text/text';
import { BusinessCardProps } from './business-card.d';
import { StyledBusinessCard, StyledAtag, BusinessInner, SvgWrapper } from './business-card.styles';
import { EnvelopeIcon } from '../icons-library/envelope';
import { Match, Switch, useContext } from 'solid-js';
import { PhoneIcon } from '../icons-library/phone';
import { ProvoxIcon } from '../icons-library/provox';
import { IdCardIcon } from '../icons-library/id-card';
import { EmergencyIcon } from '../icons-library/emergency';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';

export const BusinessCard = (componentProps: BusinessCardProps) => {
    const AppState = useContext(AppContext);
    const { localize } = AppState;
    
    const businessCardInner = () => {
        return (
            <StyledBusinessCard bordered={componentProps.bordered}>
                <SvgWrapper>
                    <Switch>
                        <Match when={componentProps.icon === 'email'}>
                            <EnvelopeIcon aria-label={localize('business_card_email', 'Email')}/>
                        </Match>
                        <Match when={componentProps.icon === 'phone'}>
                            <PhoneIcon aria-label={localize('business_card_phone', 'Phone')}/>
                        </Match>
                        <Match when={componentProps.icon === 'idCard'}>
                            <IdCardIcon aria-label={localize('business_card_id_card', 'ID Card')}/>
                        </Match>
                        <Match when={componentProps.icon === 'emergency'}>
                            <EmergencyIcon aria-label={localize('business_card_emergency', 'Emergency')}/>
                        </Match>
                        <Match when={componentProps.icon === 'myLife'}>
                            {/* @TODO: fix later to proper icon */}
                            <img src="https://i.postimg.cc/fLNs9tbX/1200x630wa-2.png" width="3.2rem" height="3.2rem" alt={localize('business_card_my_life', 'My Life')}/>
                        </Match>
                        <Match when={componentProps.icon === 'provox'}>
                            <ProvoxIcon aria-label={localize('business_card_provox', 'Provox')}/>
                        </Match>
                    </Switch>
                </SvgWrapper>
                <BusinessInner>
                    <Text fontSize="larger" color={'red'} underlined align="center">
                        {componentProps.text ? componentProps.text : AppState.localize('contact', 'Contact')}
                    </Text>
                </BusinessInner>
            </StyledBusinessCard>
        );
    };

    return (
        <ErrorCatcher componentName='Business card'>
            <Switch
                fallback={
                    <StyledAtag href={`${componentProps.url}`} target="_blank" rel="noopener noreferrer">
                        {businessCardInner()}
                    </StyledAtag>
                }
            >
                <Match when={componentProps.icon === 'email'}>
                    <StyledAtag href={`mailto:${componentProps.url}`} target="_blank" rel="noopener noreferrer">
                        {businessCardInner()}
                    </StyledAtag>
                </Match>

                <Match when={componentProps.icon === 'phone'}>
                    <StyledAtag href={`tel:${componentProps.url}`}>{businessCardInner()}</StyledAtag>
                </Match>
            </Switch>
        </ErrorCatcher>
    );
};

BusinessCard.parseProps = (atts: any) => {
    const commonAtss = {
        icon: atts.icon,
        text: atts.text,
        bordered: atts.bordered,
        url: atts.url,
    };
    return commonAtss;
};
