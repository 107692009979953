import { createSignal, For, JSXElement, onMount, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { Link } from '../../ui-components/link/link';
import { Text } from '../../ui-components/text/text';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import { StyledLoadingSpinner } from '../loading-spinner/loading-spinner.styles';
import { StyledErrorMessage, StyledSuccessMessage } from '../login-form/login-form-styles';
import { FormField, FormStoreKey } from './form-types';
import {
    StyledRegistrationForm,
    StyledFormInput,
    StyledFormSelect,
    StyledCheckboxGroup,
    StyledFormSelectWrapper,
    StyledIconWrapper,
    StyledPatientInfo
} from './registration-form-styles';
import { validateCaptcha } from '../../tools/validate-captcha';
import { AngleDownIcon } from '../icons-library/angle-down';
import { validate } from './validation';
import { getCountryOptions, getPlaceholder, getUserTypeOptions } from './helpers';
import { formStore, resetFormStore, setError, setFormStoreField } from './registration-form-store';
import { useLocation } from '@solidjs/router';
import { logIn } from '../../tools/login-user';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { atosGlobalCountrySlugToCountryCode } from '../../tools/countries-data';


type RegistrationFormProps = {
    createUser: (captchaToken: string) => Promise<UserServiceResponse>;
    fields: FormField[];
    navigationComponents: JSXElement;
    successMessageComponents: JSXElement;
};

export type UserServiceResponse = {
    [key: string]: {
        status: string;
        message: string;
    };
};


export const RegistrationForm = (props: RegistrationFormProps) => {
    const { localize, privacyPolicyPage, recaptchaSiteKey, siteInfo, updateUser } = useContext(AppContext);
    const [success, setSuccess] = createSignal(false);
    const [arrivedFromUrl, setArrivedFromUrl] = createSignal('');
    const [loading,setLoading] = createSignal(false);
    
    const location = useLocation() as { state?: { fromUrl?: string } };

    onMount(() => {
        if (location.state?.fromUrl) {
            setArrivedFromUrl(location.state.fromUrl);
            delete location.state.fromUrl;   
        }

        if (!document.querySelector('#grecaptcha')) {
            const scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.id = 'grecaptcha';
            scriptTag.src = `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaSiteKey}`;
            document.head.appendChild(scriptTag);
        }

        const currentSelectCountryCode = atosGlobalCountrySlugToCountryCode[siteInfo.siteSlug as keyof typeof atosGlobalCountrySlugToCountryCode];
        if (currentSelectCountryCode) {
            setFormStoreField({ country: currentSelectCountryCode});
        }
    });

    const isBusinessLead = () => formStore.fields[ 'user-type' ] === 'HCP' || formStore.fields[ 'user-type' ] === 'Other';

    const submit = async () => {
        const extraFields = [];

        if (isBusinessLead()) {
            extraFields.push({
                name: 'Company',
                slug: 'company',
                required: true,
            });
        }
        const validForm = validate({ fields: [...props.fields, ...extraFields], localize });

        if (!validForm) return;

        setLoading(true);

        try {            
            const captchaToken = await validateCaptcha('register_user', recaptchaSiteKey);

            if (!captchaToken) throw new Error('Captcha validation failed - probably a bot.');

            const response: UserServiceResponse = await props.createUser(captchaToken);
            
            const { status, message } = siteInfo.siteType === 'atos-care' ? response.createCareUser : response.createUser;
            if (status !== 'OK') throw new Error(message);

            setLoading(false);
            setSuccess(true);

            if (siteInfo.siteType === 'atos-care') {
                await handleLogin();
            }

            resetFormStore();
        } catch (e: any) {
            setLoading(false);
            console.log('Error submitting form: ', e);
                    
            if (e.message === 'USRE') {
                setError(localize('email-already-in-use', 'A user with that email already exists'));
            } else {
                setError(localize('form-submit-error', 'Error submitting the form, please try again later'));
            }
        }
    };

    const handleLogin = async () => {
        const args = {
            email: formStore.fields.email,
            password: formStore.fields.password,
            siteType: siteInfo.siteType,
            siteSlug: siteInfo.siteSlug,
        };

        const { success, message } = await logIn(args, updateUser, recaptchaSiteKey);

        if (!success) {
            if (message === 'invalid-credentials') {
                setError(localize('invalid-credentials', 'Invalid credentials'));
            }
        }
    };


    return (
        <StyledRegistrationForm>
            <Show when={privacyPolicyPage && privacyPolicyPage !== '' }>
                <Show when={!success()}>
                    <Heading tag="h4">{localize('user-information', 'User information')}</Heading>
                    <For each={props.fields}>
                        {(field: FormField) => {
                            if (field.slug === 'country') {

                                const currentSelectCountryCode = atosGlobalCountrySlugToCountryCode[siteInfo.siteSlug as keyof typeof atosGlobalCountrySlugToCountryCode];
                                return (
                                    <StyledFormSelectWrapper>
                                        <StyledFormSelect 
                                            value={formStore.fields['country']}
                                            name='country'
                                            autocomplete={field.autocomplete}
                                            onChange={(e: any) => setFormStoreField({ country: e.target.value })}
                                        >
                                            {getCountryOptions(localize, currentSelectCountryCode)}
                                        </StyledFormSelect>
                                        <StyledIconWrapper>
                                            <AngleDownIcon height={1.2} width={1.2} min-width="1.2" min-height="1.2" fill="black" />
                                        </StyledIconWrapper>
                                    </StyledFormSelectWrapper>
                                );
                            } else if (field.slug === 'user-type') {
                                return (
                                    <StyledFormSelectWrapper>
                                        <StyledFormSelect
                                            value={formStore.fields['user-type']}
                                            name='user-type'
                                            autocomplete={field.autocomplete}
                                            onChange={(e: any) => setFormStoreField({ 'user-type': e.target.value })}
                                        >
                                            {getUserTypeOptions(localize)}
                                        </StyledFormSelect>
                                        <StyledIconWrapper>
                                            <AngleDownIcon height={1.2} width={1.2} min-width="1.2" min-height="1.2" fill="black" />
                                        </StyledIconWrapper>
                                    </StyledFormSelectWrapper>
                                );
                            } else if (field.slug === 'is-caregiver') {
                                return (
                                    <StyledCheckboxGroup>
                                        <Checkbox
                                            isChecked={formStore.fields[ 'is-caregiver' ]}
                                            whenClicked={() => {
                                                setError('');
                                                setFormStoreField({ 'is-caregiver': !formStore.fields[ 'is-caregiver' ] });
                                            }}
                                            value='is-caregiver'
                                            name={localize('is-caregiver-registration-question', 'Are you a caregiver?')}
                                        />
                                    </StyledCheckboxGroup>
                                );
                            } else {
                                if (field.slug.includes('patient')) return null;

                                const value = formStore.fields[field.slug as FormStoreKey];
                                if (typeof value !== 'string') return null;

                                return (
                                    <StyledFormInput
                                        value={value}
                                        placeholder={getPlaceholder(field, localize)}
                                        onChange={(e) => setFormStoreField({ [field.slug]: e.currentTarget.value })}
                                        onFocus={() => setError('')}
                                        type={field.type ? field.type : 'text'}
                                        name={field.slug}
                                        autocomplete={field.autocomplete}
                                    />
                                );
                            }
                        }}
                    </For>
                    <Show when={isBusinessLead()}>
                        <StyledFormInput
                            value={formStore.fields['company']}
                            placeholder={getPlaceholder({name: 'Company', slug: 'company', required: true}, localize)}
                            onChange={(e) => setFormStoreField({ ['company']: e.currentTarget.value })}
                            onFocus={() => setError('')}
                            type='text'
                            name={'company'}
                            required={true}
                        />
                    </Show>

                    <Show when={formStore.fields[ 'is-caregiver' ]}>
                        <StyledVerticalSpace size={0.5} />
                        <Heading tag="h4">
                            {localize('patient-information', 'Patient information')}
                        </Heading>
                        <StyledPatientInfo>
                            <For each={props.fields}>
                                {(field: FormField) => {
                                    const value = formStore.fields[ field.slug as FormStoreKey ];
                                    if (typeof value !== 'string') return null;

                                    if (field.slug.includes('patient')) {
                                        return (
                                            <StyledFormInput
                                                value={value}
                                                placeholder={getPlaceholder(field, localize)}
                                                onChange={(e) => setFormStoreField({ [field.slug]: e.currentTarget.value })}
                                                onFocus={() => setError('')}
                                                type={field.type ? field.type : 'text'}
                                                name={field.slug}
                                                autoWidth={field.autoWidth}
                                            />
                                        );
                                    }
                                }}
                            </For>
                        </StyledPatientInfo>
                    </Show>
                    <StyledVerticalSpace size={0.5} />
                    <Heading tag="h4">{localize('password', 'Password')}</Heading>
                    <StyledFormInput
                        value={formStore.fields.password}
                        name='password'
                        autocomplete='new-password'
                        placeholder={localize('password', 'Password')}
                        type="password"
                        onChange={(e) => setFormStoreField({ password: e.currentTarget.value })}
                        onFocus={() => setError('')}
                    />
                    <StyledFormInput
                        value={formStore.fields.verifyPassword}
                        placeholder={localize('verify-password', 'Verify Password')}
                        name='verify-password'
                        autocomplete='new-password'
                        type="password"
                        onChange={(e) => setFormStoreField({ verifyPassword: e.currentTarget.value })}
                        onFocus={() => setError('')}
                    />
                    <Text noBlockSpacing={true} fontSize='small' fontStyle='italic' color='darkGray'>{`*${localize('required-fields', 'required fields')}`}</Text>
                    <StyledVerticalSpace size={0.5} />
                    <StyledCheckboxGroup>
                        <Checkbox
                            isChecked={formStore.fields.consent}
                            whenClicked={() => {
                                setError('');
                                setFormStoreField({ consent: !formStore.fields['consent'] });
                            }}
                            value='privacy-policy-consent'
                            name={localize('agree-to-terms', 'I agree to the terms and conditions')}
                        />
                        <Link noBlockSpacing={true} label={localize('read-more-here', 'Read more here')} url={privacyPolicyPage || '/'} />
                            
                    </StyledCheckboxGroup>
                </Show>

                <Show when={formStore.error} fallback={<div style={{ height: '22px' }}></div>}>
                    <StyledErrorMessage>{formStore.error}</StyledErrorMessage>
                </Show>
                <Show when={success()}>
                    <StyledSuccessMessage>

                        {props.successMessageComponents}

                        <StyledVerticalSpace size={5} />

                        <div>
                            <Show when={arrivedFromUrl()}>
                                <StyledFlexRow justifyContent='center'>
                                    <Link
                                        url={arrivedFromUrl()}
                                        label={localize('continue-where-you-left-off', 'Continue where you left off')}
                                        plainStyle={true}
                                        arrowDirection={'left'}
                                        noBlockSpacing
                                    />
                                </StyledFlexRow>
                            </Show>

                            <StyledVerticalSpace size={2} />

                            {props.navigationComponents}
                        </div>
                    </StyledSuccessMessage>
                </Show>
                <Show when={loading()}>
                    <StyledLoadingSpinner />
                </Show>
                <Show when={!success()}>
                    <Button label={localize('submit-registration-form-button-text', 'Submit')} onClick={() => submit()} />
                    <Text fontSize='small' fontStyle='italic' color='darkGray'>
                        {'This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.'}
                    </Text>
                </Show>
            </Show>
        </StyledRegistrationForm>
    );
};
