import { User } from '../types/shared';
import { validateCaptcha } from './validate-captcha';

type UserArgs = {
    email: string;
    password: string;
    siteType?: string;
    siteSlug?: string;
};

export const logIn = async (userArgs: UserArgs, updateUser: (user: User) => void, recaptchaSiteKey: string) => {    
    try {
        const captchaToken = await validateCaptcha('login_user', recaptchaSiteKey);
    
        if (!captchaToken) {
            throw new Error('Captcha validation failed - probably a bot.');
        }
    
        const response = await fetch('/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...userArgs,
                captchaToken,
            }),
        });
    
        if (response.ok) {
            const res = await response.json();
            updateUser(res.user);
            
            return { success: true };
        } else {
            throw new Error('Invalid credentials');
        }
    } catch (error) {
        console.error('Error logging in', error);
        return { success: false, message: 'invalid-credentials' };
    }
};
