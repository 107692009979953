/* eslint-disable indent */
import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

const { spacing, palette, colors } = theme;

export const StyledQuoteOuter = styled.div``;

export const StyledFeedContent = styled.div`
    /* container-type: inline-size; */ //@TODO: what is this?
    background-color: ${palette.beige};
    padding: ${spacing.m} ${spacing.m} ${spacing.m} ${spacing.m};

    ${theme.mediaQueries.mobileMax} {
        padding: ${spacing.s} ${spacing.xxs};
    }
`;

export const StyledLoadMoreButtonContainer = styled.div`
    display: grid;
    &>button{
        margin: 0 auto;
    }
`;
