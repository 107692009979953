import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../../../../style/theme';

export const StyledPdfEventHeader = styled.div`
    position: relative;
    background-color: white;
    border-radius: 1.43rem;
`;

export const StyledPdfEventMetaInfo = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${fontFamilyAtosBold}; 
    font-weight: 800;
    font-size: 1.29rem;
    flex-shrink: 1;
    gap: 0.5rem;
    color: ${theme.palette.black};
    flex-basis: 25%;
`;

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const StyledHeadingAndRegistrationContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledHeadingContainer = styled.div`
    padding-right: 1.5rem;
    flex-basis: 65%;
    flex-grow: 1;
`;

export const StyledHeadContainer = styled.div`
    width: 100%;
`;

export const StyledPdfEventDate = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

export const StyledPdfEventDateText = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledPdfAuthor = styled.div`
`;

export const StyledRegistrationContainer = styled.div`
    border: 1px solid ${theme.palette.lightGray};
    border-radius: 0.73rem;
    padding: 0.73rem;
`;

export const StyledDateLocationHeader = styled.div`
    margin-bottom: 0.75rem;
`;
export const StyledQrCodeForEvent = styled.div`
    height: 7.40rem;
    padding: 0.73rem;
    display: flex;
    justify-content: center;
    & > svg {
        height: 100%;
        width: auto;
    }
`;
