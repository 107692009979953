import { Show, createEffect, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Grid } from '../../../grid-system/grid/grid';
import { Section } from '../../../grid-system/section/section';
import { User } from '../../../types/shared';
import { Button } from '../../../ui-components/button/button';
import { StyledVerticalSpace } from '../../../ui-components/utility-style-components/spacing';
import { AtosLogo } from '../../icons-library/atos-logo-desktop';
import { CrossIcon } from '../../icons-library/cross';
import { LoginForm } from '../../login-form/login-form';
import { StyledCloseButton, StyledCloseButtonText } from '../../menu/menu-desktop/menu-header/menu-header.styles';
import { StyledLogo, StyledLogoContent } from '../../menu/menu-desktop/shortcuts/shortcuts.styles';
import { StyledLoginMenuLeftArea, StyledLoginMenuRightArea, StyledLoginMenuHeader, StyledLoginMenuLeftBackgroundPanel, StyledSpacer, StyledWelcomeText, StyledDivider, StyledLoginMenuSignedInAs, StyledLoginMenuSignedInAsBold, StyledLoginMenuSignedInAsContent, StyledLoginInstruction, StyledEmptyColumn, StyledBottomFade } from '../login-menu.styles';
import { UserOrderHistory } from '../user-order-history';
import { Heading } from '../../../ui-components/heading/heading';
import theme from '../../../style/theme';
import { TabHandler } from '../../menu/menu-desktop/tab-handling';

export const LoginMenuDesktop = (props: LoginMenuProps) => {
    const { localize, siteInfo, viewport, userState } = useContext(AppContext);
    const [ welcomeMessage, setWelcomeMessage ] = createSignal<string>('');

    // Accessibility/keyboard-controls related
    const [ firstTabbableElement, setFirstTabbableElement ] = createSignal<HTMLElement | undefined>();
    const [ lastTabbableElement, setLastTabbableElement ] = createSignal<HTMLElement | undefined>();

    const isLargeDesktop = () => {
        return viewport.width > theme.breakpoints.DESKTOP_LARGE;
    };

    createEffect(() => {
        if (siteInfo.siteType === 'default') {
            setWelcomeMessage(localize('welcome-to-healthcare-professionals', 'Welcome Healthcare Professionals!'));
        } else {
            setWelcomeMessage(localize('welcome-to-atos-care', 'Welcome to Atos Care'));
        }
    });

    return (
        <Section
            templateShorthand={[12]}
            widthType={'bgFull'}
            removeSpacingBlock={true}
            heightType="fill"
            customCss="padding-top: 0 !important; padding-bottom: 0 !important;"
        >
            <TabHandler
                refs={{
                    firstTabbableElement,
                    lastTabbableElement,
                }}
            >
                <Grid
                    templateShorthand={[4, 1, 7]}
                    customCss={'height: 100%;'}
                    responsive={{
                        largeDesktop: [4, 1, 7],
                        smallDesktop: [4, 8],
                        tablet: [12],
                        mobile: [12],
                    }}
                >
                    <StyledLoginMenuLeftArea>
                        <StyledLogoContent>
                            <StyledLogo>
                                <AtosLogo />
                            </StyledLogo>
                        </StyledLogoContent>
                        
                        <StyledWelcomeText>
                            <Show when={viewport.width < theme.breakpoints.ORIGINAL_TABLET}>
                                <Heading tag='h1' variant='smallGray' color={{ name: 'white', color: 'white'}}>
                                    {welcomeMessage()}
                                </Heading>
                            </Show>
                            <Show when={viewport.width >= theme.breakpoints.ORIGINAL_TABLET && viewport.width < theme.breakpoints.DESKTOP_LARGE}>
                                <Heading tag='h1' variant='large' color={{ name: 'white', color: 'white'}}>
                                    {welcomeMessage()}
                                </Heading>
                            </Show>
                            <Show when={viewport.width >= theme.breakpoints.DESKTOP_LARGE}>
                                <Heading tag='h1' variant='xxlarge' color={{ name: 'white', color: 'white'}}>
                                    {welcomeMessage()}
                                </Heading>
                            </Show>
                        </StyledWelcomeText>


                        <Show when={!userState.user}>
                            <StyledDivider  />
                            <StyledLoginInstruction>
                                <Show
                                    fallback={localize('log-in-to-access-healthcare-professionals', 'Log in to access the healthcare professionals site')}
                                    when={siteInfo.siteType === 'atos-care'}
                                >
                                    {localize('log-in-to-access-atos-care', 'Log in to access Atos Care')}
                                </Show>
                            </StyledLoginInstruction>
                        </Show>

                        <StyledLoginMenuLeftBackgroundPanel />
                    </StyledLoginMenuLeftArea>

                    <Show when={isLargeDesktop()}>
                        <StyledEmptyColumn/>
                    </Show>

                    <StyledLoginMenuRightArea>
                        <StyledLoginMenuHeader>
                            <StyledSpacer />
                            <StyledCloseButton
                                onClick={() => props.handleCloseMenuClick()}
                                ref={(el: HTMLElement) => setFirstTabbableElement(el)}
                            >
                                <CrossIcon fill="white" width={1.79} height={1.79} />
                                <StyledCloseButtonText>{localize('close', 'Close')}</StyledCloseButtonText>
                            </StyledCloseButton>
                        </StyledLoginMenuHeader>
                        <Show 
                            when={props.user}
                            fallback={ 
                                <LoginForm 
                                    firstElement={(el: HTMLElement) => {
                                        setFirstTabbableElement(el);
                                    }}
                                    lastElement={(el: HTMLElement) => {
                                        setLastTabbableElement(el);
                                    }}
                                /> 
                            }
                        >
                            <StyledLoginMenuSignedInAsContent>
                                <StyledLoginMenuSignedInAs>
                                    <StyledLoginMenuSignedInAsBold>
                                        {localize('signed-in-as', 'Signed in as')}
                                    </StyledLoginMenuSignedInAsBold>
                                    <div>{props.user?.email}</div>
                                </StyledLoginMenuSignedInAs>
                                <StyledVerticalSpace size={2} />
                                <Button
                                    label={localize('sign-out', 'Sign out')}
                                    onClick={() => props.handleSignOutClick()}
                                    element={(el: HTMLElement) => setLastTabbableElement(el)}
                                />
                            </StyledLoginMenuSignedInAsContent>

                            <Show when={siteInfo.siteType === 'atos-care'}>
                                <UserOrderHistory />
                            </Show>
                        </Show>

                        <StyledBottomFade />
                        
                    </StyledLoginMenuRightArea>
                </Grid>
            </TabHandler>
        </Section>
    );
};
export type LoginMenuProps = {
    handleCloseMenuClick: () => void;
    handleSignOutClick: () => void;
    user: User | null;
};
