import { createSignal, mergeProps, onMount, useContext } from 'solid-js';
import {
    StyledAuthorContainer,
    StyledAuthorName,
    StyledAuthorTextContainer,
    StyledClosingQuoteSvgContainer,
    StyledHeadingWrapper,
    StyledImage,
    StyledOpeningQuoteSvgContainer,
    StyledQuoteContainer,
    StyledQuoteContent,
    StyledQuoteWrapper,
    StyledRoundImageWrapper,
    StyledSignature,
} from './testimony.styles';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Heading } from '../heading/heading';
import { Grid } from '../../grid-system/grid/grid';
import { QuoteOpen } from '../../components/icons-library/quote-open';
import { QuoteClose } from '../../components/icons-library/quote-close';
import { getBackgroundImageSrcData, ImageSrcData } from '../../components/image-component/image-component';
import { Section } from '../../grid-system/section/section';
import { Column } from '../../grid-system/column/column';
import theme from '../../style/theme';

const defaultProps = {};

export const Testimony = (componentProps: any) => {
    const props = mergeProps(defaultProps, componentProps);
    const { imagesServiceUrl, supportedImageFormats, viewport } = useContext(AppContext);
    const [backgroundImageSrcData, setBackgroundImageSrcData] = createSignal<ImageSrcData>();

    const isTablet = () => viewport.width <= theme.breakpoints.TABLET;
    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    onMount(() => {
        if (props.src) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.src,
                width: 500,
                height: 500,
            });
            setBackgroundImageSrcData(backgroundImage);
        }
    });

    return (
        <>
            <Section widthType='bgFull' combineWithBelowSection={true}>
                <StyledHeadingWrapper>
                    <Heading tag="h3" variant="small">
                        {props.eyebrowHeading}
                    </Heading>
                    <Heading tag="h2" variant="xlarge">
                        {props.testimonyHeading}
                    </Heading>
                </StyledHeadingWrapper>
            </Section>
            <Section widthType='bgFull' autoResponsiveness={true}>
                <Column // Forgive me father, for I have sinned...
                    roundCorners={true}
                    backgroundValue={'white'}
                    customCss={`
                        margin-top: ${isTablet() ? isMobile() ? '0rem;' : '-1.5rem;' : '-2.5rem;'}
                        margin-bottom: ${isTablet() ? isMobile() ? '0rem;' : '-1.5rem;' : '-2.5rem;'}
                `}>
                    <StyledQuoteContent>
                        <Grid templateShorthand={[5, 7]} responsive={{ mobile: [12], tablet: [5, 7], smallDesktop: [5, 7] }}>
                            <StyledAuthorContainer>
                                <StyledRoundImageWrapper>
                                    <StyledImage src={backgroundImageSrcData()?.original} alt={props.altText} title={props.title} />
                                </StyledRoundImageWrapper>

                                <StyledAuthorTextContainer>
                                    <StyledSignature>
                                        <StyledAuthorName>{props.authorName},</StyledAuthorName>
                                        {props.authorShortDescription}
                                    </StyledSignature>
                                </StyledAuthorTextContainer>
                            </StyledAuthorContainer>

                            <StyledQuoteWrapper>
                                <StyledOpeningQuoteSvgContainer>
                                    <QuoteOpen />
                                </StyledOpeningQuoteSvgContainer>

                                <StyledQuoteContainer>
                                    <Heading tag='h2' variant='large'>
                                        {props.quote.children}
                                    </Heading>
                                </StyledQuoteContainer>

                                <StyledClosingQuoteSvgContainer>
                                    <QuoteClose />
                                </StyledClosingQuoteSvgContainer>
                            </StyledQuoteWrapper>
                        </Grid>
                    </StyledQuoteContent>
                </Column>
            </Section>
        </>
    );
};

Testimony.parseProps = (atts: any) => {
    const { image, quote } = atts;

    const commonAtts = {
        eyebrowHeading: atts.eyebrowHeading,
        testimonyHeading: atts.testimonyHeading,
        authorName: atts.authorName,
        authorShortDescription: atts.authorShortDescription,
        quote: quote[0],
    };

    if (image) {
        return {
            src: image.url,
            width: image.width,
            focus: image.focus,
            altText: image?.altText,
            title: image?.title,
            ...commonAtts,
        };
    } else return commonAtts;
};
