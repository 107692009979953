import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';

export const StyledMobileFiltersModal = styled.div`
    container-type: inline-size;
    background: ${theme.palette.white};
    height: 100vh;
    position: fixed;
    top: ${theme.header.mobile.height.value + theme.header.mobile.height.unit};
    left: 0;
    right: 0;
    z-index: 3;
    padding-inline: 1.5rem;
`;

export const StyledMobileFilterModalContentWrapper = styled.div`
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const StyledList = styled.ul`
    margin-bottom: 2rem;
`;

export const StyledHeadingContainer = styled.div`
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${theme.palette.gray};
    margin-bottom: 1.5rem;
`;
