import { SwirlPdf } from '../../../../icons-library/swirl-pdf';
import { Text } from '../../../../../ui-components/text/text';
import { Show, useContext } from 'solid-js';
import { AppContext } from '../../../../../app-context-provider/app-context-provider';
import { gql } from 'graphql-request';
import { AtosLogoPdfFooter } from '../../../../icons-library/atos-logo-pdf-footer';
import { StyledBlankPageFooter, StyledFooterItems, StyledFooterItem, StyledFooterPostTitle, StyledCopyrightItems, StyledPdfDivider, StyledFooterLogo, StyledFooterLogoInner, StyledFooterSwirl, StyledFooterTexts } from './pdf-footer.style';
import { AtosCareLogoPdfFooter } from '../../../../icons-library/atos-care-logo-pdf-footer';

const GET_FOOTER_QUERY = gql`
    query GetFooter($key: String!) {
        footer: resource(key: $key) {
            content
            key
        }
    }
`;

type FooterPdfProps = {
    postTitle: string;
    pageNumber: number;
    finalPage: boolean;
    url: string;
    totalPageCount: number;
}


export const FooterPDF = (props: FooterPdfProps) => {
    const { createCachedResource, localize, siteInfo } = useContext(AppContext);
    const [ footerContent ] = createCachedResource(GET_FOOTER_QUERY, () => ({ key: '/wp-json/rawb/v1/footer' }), true);
    const copyrightText = footerContent()?.footer?.content?.copyrighttext;

    return (
        <StyledBlankPageFooter>
            <StyledFooterItems>
                <StyledFooterTexts>
                    <StyledFooterItem>
                        <StyledFooterPostTitle>
                            <Text fontSize="smallerPDF" displayRedVerticalLine={false} noBlockSpacing={false} color='black'>
                                {props.postTitle}
                            </Text>
                        </StyledFooterPostTitle>
                    </StyledFooterItem>
                    <StyledFooterItem>
                        <Text fontSize="smallerPDF" displayRedVerticalLine={false} noBlockSpacing={false} color='black'>
                            { `www.${ siteInfo?.currentDomain }${ props.url }` }
                        </Text>
                    </StyledFooterItem>
                    <StyledCopyrightItems>
                        <Text fontSize="tinyPDF" displayRedVerticalLine={false} noBlockSpacing={true} color='black'>
                            {copyrightText}
                        </Text>

                        <StyledPdfDivider />

                        <Text fontSize="tinyPDF" displayRedVerticalLine={false} noBlockSpacing={true} color='black'>
                            {`${localize('page', 'Page')} ${props.pageNumber} ${localize('of', 'of')} ${props.totalPageCount}`}
                        </Text>
                    </StyledCopyrightItems>
                </StyledFooterTexts>

                <StyledFooterLogo>
                    <StyledFooterLogoInner>
                        <Show 
                            when={siteInfo?.siteType === 'atos-care'}
                            fallback={(
                                <AtosLogoPdfFooter width={props.finalPage ? 9.35 : 5.6} height={props.finalPage ? 4.55 : 2.72} />
                            )}
                        >
                            <AtosCareLogoPdfFooter width={props.finalPage ? 9.35 : 5.6} height={props.finalPage ? 4.55 : 2.72}/>
                        </Show>
                    </StyledFooterLogoInner>
                </StyledFooterLogo>
            </StyledFooterItems>

            <Show when={siteInfo?.siteType !== 'atos-care' && props.finalPage}>
                <StyledFooterSwirl>
                    <SwirlPdf />
                </StyledFooterSwirl>
            </Show>
        </StyledBlankPageFooter>
    );
};