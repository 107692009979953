import { ProductIconTabProps } from './product-icon-tab-types';
import { StyledIconTab } from './product-icon-tab.styles';
import { ProductIcons } from './product-icons';

export const ProductIconTab = (props: ProductIconTabProps) => {
    if (!props.iconName) {
        return <></>;
    }
    
    return (
        <StyledIconTab
            iconName={props.iconName}
            {...props.top && { top: props.top }}
            {...props.width && { width: props.width }}
            {...props.type && { type: props.type }}
            {...props.height && { height: props.height }}
        >
            <ProductIcons name={props.iconName} size={props.size} />
        </StyledIconTab>
    );
};
