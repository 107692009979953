import { Shortcuts } from './shortcuts/shortcuts';
import { MenuHeader } from './menu-header/menu-header';
import { PrimaryMenu } from './primary-menu/primary-menu';
import { StyledMenuRightArea } from './menu-desktop.styles';
import { Section } from '../../../grid-system/section/section';
import type { MenuProps } from '../menu-types';
import { Grid } from '../../../grid-system/grid/grid';
import { Accessor, createSignal } from 'solid-js';
import { TabHandler } from './tab-handling';

export const MenuDesktop = (props: MenuProps) => {
    const [ lastElementIsAMenuAndIsOpen, setLastElementIsAMenuAndIsOpen ] = createSignal(false);
    const [ firstTabbableElement, setFirstTabbableElement ] = createSignal<HTMLElement | undefined>();
    const [ lastTabbableElement, setLastTabbableElement ] = createSignal<HTMLElement | undefined>();
    const [ lastChildOfLastElement, setLastChildOfLastElement ] = createSignal<HTMLElement | undefined>();

    return (
        <TabHandler
            lastElementIsAMenuAndIsOpen={lastElementIsAMenuAndIsOpen}
            refs={{
                firstTabbableElement,
                lastTabbableElement,
                lastChildOfLastElement,
            }}
        >
            <Section
                templateShorthand={[ 12 ]}
                widthType={'bgFull'}
                removeSpacingBlock={true}
                heightType="fill"
                customCss='padding-top: 0 !important; padding-bottom: 0 !important; overflow-y: auto;'
            >
                <Grid
                    templateShorthand={[ 3, 9 ]}
                    customCss={'height: 100%;'}
                    responsive={{
                        largeDesktop: [ 4, 8 ],
                        smallDesktop: [ 4, 8 ],
                        tablet: [ 12 ],
                        mobile: [ 12 ],
                    }}>
                    <Shortcuts
                        shortcutsHeading={props.shortcutsHeading}
                        contactHeading={props.contactHeading}
                        letUsContactYouLink={props.letUsContactYouLink}
                        phoneNumber={props.phoneNumber}
                        servicesHeading={props.servicesHeading}
                        servicesLinks={props.servicesLinks}
                        firstElement={(el: HTMLElement) => {
                            setFirstTabbableElement(el);
                        }}
                    />
                    <StyledMenuRightArea>
                        <MenuHeader links={props.headerLinks} handleCloseMenuClick={props.handleCloseMenuClick} />
                        <PrimaryMenu
                            menuTitle={props.primaryMenuName}
                            menu={props.primaryMenuLinks}
                            lastElement={(el: HTMLElement, elementIsAMenuAndIsOpen?: Accessor<boolean>) => {
                                setLastTabbableElement(el);
                                if (!elementIsAMenuAndIsOpen) return;
                                setLastElementIsAMenuAndIsOpen(elementIsAMenuAndIsOpen());
                            }}
                            lastChildOfLastElement={(el: HTMLElement) => {
                                setLastChildOfLastElement(el);
                            }}
                        />
                    </StyledMenuRightArea>
                </Grid>
            </Section>
        </TabHandler>
    );
};
