import { styled } from 'solid-styled-components';

export const StyledAppRoot = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    img {
        max-width: 100%;
    }
`;

export const StyledAppInner = styled.div`
    flex: 1;
    z-index: 1;
    @media screen {
        padding-bottom: 8rem;
    }
`;

export const StyledPdfFactoryRoot = styled.div`
    overflow: hidden;
    height: 0;
    @media print {
        height: auto;
    }
`;