import { For } from 'solid-js';
import { ImageComponent } from '../image-component/image-component';
import { Heading } from '../../ui-components/heading/heading';
import { StyledImageHolder, StyledProfilesWrapper, StyledProfileWrapper } from './profile-images-styles';


type ProfileImage = {
    url: string;
    alt: string;
    title: string;
}
type Profile = {
    image: ProfileImage;
    text: string;
}
type ProfileImagesProps = {
    profiles: Profile[];
}

const getProfileCard = (profile: Profile) => {
    if (!profile.image || !profile.text) {
        return null;
    }
    return (
        <StyledProfileWrapper>
            <StyledImageHolder>
                <ImageComponent
                    src={profile.image.url}
                    width={'260px'}
                    height={'260px'}
                    altText={profile.image.alt || ''}
                    title={profile.image.title || ''}
                    roundCorners={true}
                    aspectRatio={'original'}
                />
            </StyledImageHolder>
            <Heading tag='h4' variant='tinyGray'>{profile.text}</Heading>
        </StyledProfileWrapper>
    );
};


export const ProfileImages = (props: ProfileImagesProps) => {
    if (!props.profiles || props.profiles.length === 0) {
        return null;
    }

    return (
        <StyledProfilesWrapper>
            <For each={ props.profiles }>
                {(profile) => {
                    return getProfileCard(profile);
                }}
            </For>
        </StyledProfilesWrapper>
    );

};

ProfileImages.parseProps = (atts: any) => {
    const { profiles } = atts;
    return {
        profiles,
    };
};

