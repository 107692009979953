import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular, palette } from '../../style/theme';
import { Link } from '@solidjs/router';

const { spacing } = theme;

const common = `
    letter-spacing: 0.45px;
    font-family: ${fontFamilyAtosRegular};
    font-size: clamp(1.14rem, 1.14rem + 1.14vw,  1.29rem);
    font-weight: 400;
    line-height: 1.57rem;

    ${theme.mediaQueries.mobileMax} {
        font-size: 1.4rem;
        letter-spacing: 0.4px;
        line-height: 1.36rem;
    }
`;

export const StyledFlexContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledEventDate = styled.span<{ isInThePast?: boolean; }>`
    ${common};
    color: ${p => p.isInThePast ? palette.lightGray : palette.darkGray};
    margin-block-start: 1em;
    margin-block-end: 1em;
`;

export const StyledFlexContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledReadMore = styled(Link)`
    color: ${theme.palette.red};
    font-size: 1.8rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    margin-right: 1rem;
`;
