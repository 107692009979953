import { styled } from 'solid-styled-components';
import { pageWidth } from '../components/shared-components.style';
import isVisregTest from '../../../../tools/is-visual-regression-test';

export const StyledBlankPageWrapper = styled.div`
    overflow: hidden;
    height: 0;
    @media ${isVisregTest ? 'screen' : 'print'} {
        height: auto;
        -webkit-print-color-adjust: exact; 
    }
`;

export const StyledA4 = styled.div`
    width: ${pageWidth}px;
    position: relative;
`;

export const StyledDebugHeight = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
`;

export const HeightIndicator = styled.div<{ debugging?: boolean }>`
    ${p => p.debugging ? 'border: 1px solid black;' : ''}
`;