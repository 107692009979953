import { gql } from 'graphql-request';
import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';

export const GET_BLOG_NEWS_QUERY = gql`
    query GetBlogNews($key: String!) {
        blogNews: resource(key: $key) {
            content
            key
        }
    }
`;

export const GET_RELATED_BLOGS_AND_NEWS = gql`
    query GetRelatedBlogsAndNews(
        $tags: [String]
    ) {
        relatedBlogNews: resources(
            type: "blog-and-news"
            tags: $tags
        ) {
            content
        }
    }
`;

export const fetchBlogAndNews = (slug: string) => {
    const { createCachedResource } = useContext(AppContext);
    const [blogNewsContent] = createCachedResource(
        GET_BLOG_NEWS_QUERY,
        () => ({ key: `/blog-and-news/${slug}` }),
        true
    );

    const post = blogNewsContent()?.blogNews?.content;
    return post?.hideOnWeb ? null : post;
};

export const fetchRelatedBlogAndNews = (tags: string[], currentPostId: number) => {    
    const { createCachedResource } = useContext(AppContext);
    const [relatedBlogNewsContent] = createCachedResource(
        GET_RELATED_BLOGS_AND_NEWS,
        () => ({ tags }),
        true
    );
    
    return relatedBlogNewsContent()?.relatedBlogNews
        ?.filter((post: any) => {
            return post.content.id !== currentPostId;
        })
        ?.filter((post: any) => {
            return !post.content?.hideOnWeb;
        })
        .sort((a: any, b: any) => {
            const dateA = a.content.date ? new Date(a.content.date).getTime() : 0; // Use 0 as default if a.content.date is undefined
            const dateB = b.content.date ? new Date(b.content.date).getTime() : 0; // Use 0 as default if b.content.date is undefined
            return dateB - dateA; // Reverse order to show newest first
        });
};