import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';
import { palette } from '../../style/theme';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 29.288 30.541',
    // width: 29.992
};

export const RemoveStarIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <g id="Group_3572" data-name="Group 3572" transform="translate(-1223.712 -1525.729)">
                <path
                    id="Path_10826"
                    data-name="Path 10826"
                    d="M26.2,25.777l3.4-4.521-5.479-5.337,7.609-1.106,3.4-6.9,1.79,3.624L38.7,9.191,36.713,5.162a1.753,1.753,0,0,0-3.142,0L30,12.41l-8,1.166a1.753,1.753,0,0,0-.969,2.989L26.816,22.2Z"
                    transform="translate(1203.212 1521.774)"
                    fill={palette.lightGray}
                />
                <path
                    id="Path_10827"
                    data-name="Path 10827"
                    d="M239.323,166.058h0l-3.832-.558-1.8,2.392,3.509.509-5.507,5.364,1.3,7.576-6.755-3.547-3.635,4.828,3.591-1.888,7.154,3.761a1.752,1.752,0,0,0,2.54-1.845l-1.368-7.964,5.786-5.638A1.758,1.758,0,0,0,239.323,166.058Z"
                    transform="translate(1012.175 1369.291)"
                    fill={palette.lightGray}

                />
                <path
                    id="Path_10828"
                    data-name="Path 10828"
                    d="M101.859.167a1.066,1.066,0,0,0-1.494.208l-21.4,28.409a1.065,1.065,0,1,0,1.7,1.281l21.4-28.4A1.067,1.067,0,0,0,101.859.167Z"
                    transform="translate(1148.15 1525.779)"
                    fill={palette.gray}

                />
            </g>
        </SvgIcon>
    );
};
