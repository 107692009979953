import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../../style/theme';

export const StyledPreTextLink = styled.div`

    span {
        font-family: ${fontFamilyAtosRegular};
        font-size: 1.43rem;
        color: ${theme.palette.darkGray};
    }

    a {
        position: relative;
        top: -1px;
        
        ${theme.mediaQueries.mobileMax} {
            display: block;
        }
    }
`;
