import { createSignal, Show, For, Switch, Match } from 'solid-js';
import theme from '../../../../../style/theme';
import { ArrowDownIcon } from '../../../../icons-library/arrow-down';
import { ArrowUpIcon } from '../../../../icons-library/arrow-up';
import { StyledFirstLevelMenu, StyledFirstLevelMenuItem, StyledSecondaryMenuItem, StyledSecondaryMenuItemParentLink, StyledUnorderedList } from './first-level-menu.styles';
import { StyledRelativeContainer } from '../../../../../ui-components/utility-style-components/positioning';
import type { FirstLevelMenuProps } from './first-level-menu-types';
import type { MenuItem } from '../../../types/primary-menu-types';
import { removeTrailingSlash } from '../../../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../../../tools/remove-ampersand';

export const FirstLevelMenu = (props: FirstLevelMenuProps) => {
    const [open, setOpen] = createSignal(false);

    const firstLevelItemColor = () => (open() ? theme.palette.red : theme.palette.darkGray);

    return (
        <StyledFirstLevelMenu>
            <StyledFirstLevelMenuItem
                color={firstLevelItemColor()}
                onClick={() => setOpen(!open())}
                ref={(el: HTMLButtonElement) => props.lastElement(el, open)}
            >
                <Switch>
                    <Match when={!open()}>
                        <StyledRelativeContainer top={2}>
                            <ArrowDownIcon fill={theme.palette.darkGray} width={1.43} />
                        </StyledRelativeContainer>
                    </Match>
                    <Match when={open()}>
                        <StyledRelativeContainer top={2}>
                            <ArrowUpIcon fill={firstLevelItemColor()} width={1.43} />
                        </StyledRelativeContainer>
                    </Match>
                </Switch>
                {removeAmpersand(props.parent.title)}
            </StyledFirstLevelMenuItem>
            <Show when={open()}>
                <StyledUnorderedList>
                    <li>
                        <StyledSecondaryMenuItemParentLink
                            href={removeTrailingSlash(props.parent.url)}
                            onClick={() => props.onLinkClick()}
                            ref={(el: HTMLElement) => {
                                if (props.parent.children?.length === 1) {
                                    props.lastChildOfLastElement(el);
                                }
                            }}
                        >
                            {removeAmpersand(props.parent.title)}
                        </StyledSecondaryMenuItemParentLink>
                    </li>
                    <For each={props.parent.children}>
                        {(link: MenuItem, index) => (
                            <li>
                                <StyledSecondaryMenuItem 
                                    href={removeTrailingSlash(link.url)}
                                    onClick={() => props.onLinkClick()}
                                    ref={(el: HTMLElement) => {
                                        if (!props.parent.children) return;

                                        if (index() === props.parent.children.length - 1) {
                                            props.lastChildOfLastElement(el);
                                        }
                                    }}
                                >
                                    {removeAmpersand(link.title)}
                                </StyledSecondaryMenuItem>
                            </li>
                        )}
                    </For>
                </StyledUnorderedList>
            </Show>
        </StyledFirstLevelMenu>
    );
};
