import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../style/theme';

export const StyledTextInput = styled.input<{ width?: string }>`
    border: 1px solid ${theme.palette.gray};
    padding: 1rem;
    border-radius: 1.1rem;
    width: ${(p) => p.width || '100%'};
    height: 5.29;
    font-size: 1.43rem;
    font-family: ${fontFamilyAtosRegular};
    font-weight: 400;
    color: ${theme.palette.darkGray};
`;
