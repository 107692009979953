import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../../../style/theme';

export const StyledPrimaryMenuContainer = styled.div`
    padding: 2rem 0;
    margin-top: 9rem;
`;

export const StyledPrimaryMenuTitle = styled.h2`
    font-family: ${fontFamilyAtosRegular};
    color: ${theme.palette.darkGray};
    font-size: 2.86rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 6rem;

    strong {
        font-weight: 600;
    }
`;

export const StyledPrimaryMenuList = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(17.1rem, 1fr));
    gap: 4rem 11rem;
    align-items: start;
`;
