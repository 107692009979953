import { Template } from './shared-types';

type ChildSpanProps = {
    template?: Template,
    templateShorthand?: number[],
}

export const getChildSpans = (props: ChildSpanProps) => {
    let spans: number[] = [];
    if (props.templateShorthand && props.templateShorthand.length > 0) {
        spans = props.templateShorthand;
    } else {

        spans = calculateChildSpans(props.template);
    }

    return spans;
};

const calculateChildSpans = (template: Template = [{ width: '1/1' }]) => {
    if (!template.length || template.length === 1) return [12];

    const spans = template.map(({ width }) => {
        const fraction = width.split('/');
        const numerator = isNaN(parseInt(fraction[0])) ? 1 : parseInt(fraction[0]);
        const denominator = isNaN(parseInt(fraction[1])) ? 1 : parseInt(fraction[1]);
        const divisor = 12 / denominator;
        const span = numerator * divisor;

        return span;
    });

    return spans;
};

