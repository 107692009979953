import { format } from 'date-fns';
import { sv, enGB, enUS, de, fr, es, nl, it, da, pt, ptBR, ja, enAU, deAT, nlBE, fi, pl, frCH, enCA, frCA, zhCN, ko, enNZ } from 'date-fns/locale/index.js';
import { LocaleKey } from '../types/app-state';

type LocaleMap = {
    [K in LocaleKey]: Locale;
};

const locales: LocaleMap = {
    'sv': sv,
    'en-gb': enGB,
    'en-us': enUS,
    'no': da,
    'de': de,
    'fr': fr,
    'es': es,
    'nl': nl,
    'it': it,
    'da': da,
    'pt-br': ptBR,
    'pt-pt': pt,
    'ja': ja,
    'en-au': enAU,
    'de-at': deAT,
    'nl-be': nlBE,
    'fr-be': fr,
    'fi': fi,
    'sv-fi': sv,
    'pl-pl': pl,
    'de-ch': de,
    'fr-ch': frCH,
    'en-ca': enCA,
    'fr-ca': frCA,
    'zh-cn': zhCN,
    'ko-kr': ko,
    'en-nz': enNZ
};


export const formattedDayMonth = (date: string, locale: LocaleKey) => {
    // Mar. 14, or Mars 14, etc.
    const dateObject = new Date(date);

    const formattedDayMonth = format(dateObject, 'MMM dd', { locale: locales[ locale ] });

    return formattedDayMonth;
};

export const formatDateDigits = (date: string, locale: LocaleKey) => {
    // 2023-09-26, 09/26/2023, 26/09/2023, etc.
    const dateObject = new Date(date);

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = dateObject.toLocaleDateString(locale, options);

    return formattedDate;
};

export const formatLongDate = (date: string, locale: LocaleKey) => {
    const dateObject = new Date(date);
    const formattedDate = format(dateObject, 'LLLL dd', { locale: locales[ locale ] });
    
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

export const formatTextDate = (date: string, locale: LocaleKey) => {
    // March 14, 2029
    const dateObject = new Date(date);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    return dateObject.toLocaleString(locale, options);
};
