import theme from '../../style/theme';
import { styled } from 'solid-styled-components';

export const StyledSpotlightSidebarContainer = styled.div`
    height: 100%;

    ${theme.mediaQueries.tabletMax} {
        padding-right: unset;
        padding-top: 3rem;
    }
`;

export const StyledCollectionHeadingWrapper = styled.div`
    padding-top: 6rem;
    ${theme.mediaQueries.tabletMax} {
        padding-top: 1rem;
        padding-inline: 1.5rem;
    }
`;

export const StyledProductsCollectionHeadingWrapper = styled.div`
    ${theme.mediaQueries.tabletMax} {
        padding-inline: 1.5rem;
    }
`;

export const StyledButtonWrapper = styled.div<{ isTablet?: boolean }>`
    margin-top: ${p => p.isTablet ? '-1.5rem' : 'auto'};
    margin-bottom: ${p => p.isTablet ? '3rem' : '11rem'};
`;

export const StyledCollectionSidebar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-right: 3rem;

    ${theme.mediaQueries.tabletMax} {
        position: unset;
        padding-inline: unset;
        margin-right: unset;
        padding-bottom: 0;
        z-index: 2;
    }
`;

export const StyledProductsSpotlightContainer = styled.div`
    padding-block: 5rem;
    padding-bottom: 2rem;

    ${theme.mediaQueries.tabletMax} {
        padding-block: 0;
        padding-bottom: 0;
    }
`;