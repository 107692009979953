import { FormStoreKey, UserTypes, validUserTypes } from './form-types';
import { formStore, setError } from './registration-form-store';


type ValidationArgs = {
    fields: any[];
    localize: (slug: string, defaultValue: string) => string;
}


export const validate = (args: ValidationArgs) => {
    const { localize } = args;

    const { validFields } = validateFields(args);

    if (!validFields) {
        return false;
    }

    const { validPassword, passwordsMatch } = validatePassword();

    if (!validPassword) {
        setError(
            `${localize('password-does-not-meet-security-requirements', 'Password does not meet security requirements')}, ${localize(
                'password-requirements-phrase',
                'Password must be minimum 8 characters and contain both numbers and letters'
            )}`
        );
        return false;
    }
    
    if (!passwordsMatch) {
        setError(localize('passwords-do-not-match', 'Passwords do not match'));
        return false;
    }

    return true;
};


const validateFields = (args: ValidationArgs) => {
    const { fields, localize } = args;

    let validFields = true;
    for (const field of fields) {
        const value = formStore.fields[field.slug as FormStoreKey];

        if (formStore.fields['consent'] === false) {
            setError(localize('please-agree-to-terms', 'Please accept the terms and conditions'));
            validFields = false;
            break;
        }

        if (!field.required) {
            continue;
        }

        if (field.required && !value) {
            setError(`${localize(field.slug, field.name)} ${localize('is-required', 'is required')}`);
            validFields = false;
        } else {
            switch (field.slug) {
                case 'email':    
                    // Validate email using a regular expression
                    if (!/^[\p{L}\p{N}\p{M}\p{P}\p{S}]+@([\p{L}\p{N}\p{M}\p{P}\p{S}]+\.)+[\p{L}\p{M}]{2,}$/u.test(value as string)) {
                        setError(localize('please-enter-a-valid-email-address', 'Please enter a valid email address'));
                        validFields = false;
                    }
                    break;
                case 'country':
                    // Assuming country code is a two-letter ISO country code
                    if (!/^[A-Za-z]{2}$/.test(value as string)) {
                        setError('Please enter a valid two-letter country code');
                        validFields = false;
                    }
                    break;
                case 'user-type':
                    // Assuming userType is one of a pre-defined list of values
                    if (!validUserTypes.includes(value as UserTypes)) {
                        setError('Please select a valid user type');
                        validFields = false;
                    }
                    break;
                default:
                    // For all other fields, just check that the value is not empty
                    if (!value) {
                        setError(`${field.name} cannot be empty`);
                        validFields = false;
                    }
            }
        }
    }
  
    return { validFields };
};


const validatePassword = () => {
    const password = formStore.fields.password || '';

    if (password.length < 8) {
        return {
            validPassword: false,
            passwordsMatch: false,
        };
    }

    let hasNumber = false;
    let hasLetter = false;

    for (let i = 0; i < password.length; i++) {
        if (/[0-9]/.test(password[i])) {
            hasNumber = true;
        }
        if (/[a-zA-Z]/.test(password[i])) {
            hasLetter = true;
        }
    }

    return {
        validPassword: hasNumber && hasLetter,
        passwordsMatch: formStore.fields.password === formStore.fields.verifyPassword,
    };
};
