import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const AngleRightIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M80.69,77.16a2.5,2.5,0,0,1,0,3.538l-12.5,12.5a2.5,2.5,0,0,1-3.538-3.538l10.73-10.73L64.665,68.195A2.5,2.5,0,0,1,68.2,64.657l12.5,12.5Z"
                transform="translate(-53.925 -63.925)"
            />
        </SvgIcon>
    );
};
