import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../../../style/theme';

export const StyledMenuHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledMenuHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.36rem;
    ${theme.mediaQueries.mobileMax}{
        margin-top: -1rem;
        margin-left: -1rem;
    }
`;

export const StyledMenuHeaderLinks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1.5rem;
    border-bottom: 1px solid ${theme.palette.lightGray};
    padding-bottom: 1.5rem;
`;

export const StyledMenuHeaderLinkPatientOrHcpContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 0.5rem;
`;

const sharedMenuHeaderStyle = `
    display: flex;
    align-items: center;
    column-gap: 0.65rem;
    font-size: 1.29rem;
    letter-spacing: 0;
    line-height: 1.93rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    align-self: flex-start;
    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const StyledMenuHeaderLink = styled.a<StyledMenuHeaderLinkProps>`
    ${sharedMenuHeaderStyle}
    color: ${(p) => (p.active ? theme.palette.red : theme.palette.gray)};
    text-decoration: ${(p) => (p.active ? 'underline' : 'none')};
`;

export const StyledMenuHeaderChangeLanguage = styled.button`
    ${sharedMenuHeaderStyle}
    color: ${theme.palette.gray};
    text-decoration: none;
`;

export const StyledMenuHeaderLinkPatientOrHcp = styled(StyledMenuHeaderLink)`
    text-transform: uppercase;
`;

type StyledMenuHeaderLinkProps = {
    active: boolean;
};

export const StyledMenuHeaderIconLinks = styled.div`
    display: flex;
    align-items: center;
    height: 3.86rem;
    gap: 0.5rem;

    justify-content: flex-end;
    margin-left: auto;
`;

export const StyledDivider = styled.div`
    border-left: 1px solid ${theme.palette.gray};
    height: 2.86rem;
`;

export const StyledCloseButton = styled.div`
    background-color: ${theme.palette.gray};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 0.71rem 0.71rem;
    gap: 1rem;
    align-items: flex-end;
    padding: 1rem;
    position: relative;
    top: -3rem;
`;

export const StyledCloseButtonText = styled.div`
    color: ${theme.palette.white};
    font-family: ${fontFamilyAtosRegular};
    font-size: 2.14rem;
    letter-spacing: -0.05rem;
    position: relative;
    top: 0.5rem;
`;

export const StyledMenuHeaderRight = styled.div`
    display: flex;
    gap: 1rem;
`;
