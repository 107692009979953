import { ExpandableContainer } from '../../../ui-components/expandable-container/expandable-container';
import { ProductData, ProductImage, Specification } from '../product-types';
import { For, Show, createSignal, useContext } from 'solid-js';
import { Heading } from '../../../ui-components/heading/heading';
import { Text } from '../../../ui-components/text/text';
import { Button } from '../../../ui-components/button/button';
import { Link } from '../../../ui-components/link/link';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { AmountControls } from '../../basket/basket-components';
import { StyledToastContainer, StyledList, StyledOrderline, StyledDetailsContainer, StyledItem, StyledProductShopToolbar, StyledAddToOrderButton, StyledOrderTableMobileContainer, StyledNoWrap, StyledToastLink, StyledText } from './product-shop-toolbar.styles';
import { StyledVerticalSpace } from '../../../ui-components/utility-style-components/spacing';
import { StyledOrderTableMobile } from '../../basket/basket.styles';
import { specificationComponentId } from '../product';
import { navigateToAnchor } from '../../../tools/scroll-to-element';
import { StyledFlexRow } from '../../../ui-components/utility-style-components/flex';
import theme from '../../../style/theme';
import { BasketContext } from '../../basket/basket-context-provider';

type ProductShopToolbarProps = {
    productData: ProductData;
};

export const getImageUrl = (images?: ProductImage[]) => {
    if (!images?.length) {
        return;
    }

    const featuredImage = images?.find((image: { isFeatured: boolean; }) => image.isFeatured);
    const image = featuredImage || images[ 0 ];
    const imageUrl = image.file.url;
    const imageRatio = image.file.width / image.file.height;

    return { imageUrl, imageRatio };
};

export const ProductShopToolbar = (props: ProductShopToolbarProps) => {
    const { shop, userState, localize, viewport, productsListingPage } = useContext(AppContext);
    const { setEmail, addNewOrderLine } = useContext(BasketContext);
    const [ selectedSku, setSelectedSku ] = createSignal<Specification>(props.productData.specifications[ 0 ]);
    const [ amount, setAmount ] = createSignal<number>(1);
    const [ toastMessage, setToastMessage ] = createSignal<string>('');

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    const changeAmount = (newAmount: number) => {
        if (amount() + newAmount < 1) return;
        setAmount(amount() + newAmount);
    };

    const addToBasket = () => {
        setToastMessage('');
        setToastMessage(`<strong>${amount() ? `${amount()} x ` : ''}“${selectedSku()?.name}”</strong> ${localize('added-to-basket', 'added to basket')}`);
        setEmail(userState.user?.email || '');

        const image = getImageUrl(props.productData.images);

        addNewOrderLine({
            id: props.productData.id,
            externalId: props.productData.externalId,
            postTitle: props.productData.post_title,
            slug: props.productData.slug,
            type: props.productData.type,
            skuRefNumber: selectedSku().refNumber,
            amount: amount(),
            skuDescription: selectedSku().description || '',
            skuName: selectedSku().name,
            skuQuantity: parseInt(selectedSku().quantity || '1'),
            imageUrl: image?.imageUrl,
            imageRatio: image?.imageRatio,
        });
    };

    let skuDropdownRef: HTMLDivElement | undefined;
    const selectSku = (sku: Specification) => {
        setAmount(1);
        setSelectedSku(sku);
        skuDropdownRef?.click();
    };


    return (
        <StyledProductShopToolbar>
            <Show when={toastMessage()}>
                <StyledToastContainer>
                    <Text fontSize='small' noBlockSpacing>{toastMessage()}</Text>
                    <StyledToastLink>
                        <Link 
                            url={shop.basketPage.url} 
                            size='small' 
                            label={localize('view-basket', 'View basket')}
                            noBlockSpacing={true}
                        />
                    </StyledToastLink>
                </StyledToastContainer>
            </Show>
            
            <Heading tag="h3" variant='bigRed'>
                {localize('purchase', 'Purchase')}
            </Heading>

            <StyledVerticalSpace size={1} />

            <StyledFlexRow flexWrap={'wrap'}>
                <StyledText>
                    <Text
                        fontSize='normal'
                        color='gray'
                        noBlockSpacing
                    >
                        {localize('select-specification', 'Select a specification from the list')}
                    </Text>
                </StyledText>
                <Button
                    noCaps
                    variant='tertiary'
                    label={localize('view-specifications', 'View specifications')}
                    onClick={(e: any) => {
                        navigateToAnchor({
                            event: e,
                            id: specificationComponentId,
                            isCareSite: true,
                            smallScreen: isMobile(),
                        });
                    }}
                />
            </StyledFlexRow>

            <StyledVerticalSpace size={0.8} />

            <ExpandableContainer
                initialOpen={false}
                isDropdown={true}
                chevronColor='red'
                enableOutsideClickToClose={true}
                withBorder={true}
                withPadding={true}
                ref={skuDropdownRef}
                headingProps={{
                    noBlockSpacing: true,
                    tag: 'h4',
                    variant: 'tinyGray',
                    children: selectedSku()?.name,
                    lineLimit: 1,
                }}
            >
                <StyledList>
                    <For each={props.productData.specifications}>{(sku: Specification) => {
                        return (
                            <li>
                                <StyledItem onClick={() => selectSku(sku)}>
                                    <Heading tag="h3" variant="tinyGray" noBlockSpacing={true}>
                                        {sku.name}
                                    </Heading>
                                    <StyledNoWrap>
                                        <Text fontSize='small' color='gray' noBlockSpacing={true}>
                                            {sku.refNumber}
                                        </Text>
                                    </StyledNoWrap>
                                </StyledItem>
                            </li>
                        );
                    }}</For>
                </StyledList>
            </ExpandableContainer>

            <StyledVerticalSpace size={3} />

            <Show when={selectedSku()}>
                <StyledOrderline>
                    <div>
                        <Text fontSize={isMobile() ? 'normal' : 'large'} noBlockSpacing>
                            {selectedSku()?.name}
                        </Text>

                        <StyledDetailsContainer>
                            <Text fontSize='small' color='gray' noBlockSpacing={true}>{
                                selectedSku()?.refNumber
                            }</Text>
                            <Text fontSize='small' color='gray' noBlockSpacing={true}>
                                {`${localize('units-per-pack', 'Units per pack')}: ${selectedSku()?.quantity || 1}`}
                            </Text>
                            <Text fontSize='small' color='gray' noBlockSpacing={true}>{
                                selectedSku()?.description
                            }</Text>
                        </StyledDetailsContainer>
                    </div>
                    <StyledOrderTableMobileContainer>
                        <StyledOrderTableMobile>
                            <AmountControls
                                color='#fff'
                                amount={amount()}
                                onDecreaseClick={() => changeAmount(-1)}
                                onIncreaseClick={() => changeAmount(1)}
                            />
                        </StyledOrderTableMobile>
                    </StyledOrderTableMobileContainer>
                </StyledOrderline>
            </Show>

            <StyledAddToOrderButton>
                <Show when={productsListingPage.url}>
                    <Link
                        url={productsListingPage.url}
                        label={localize('back-to-products', 'Back to products')}
                        plainStyle={true}
                        arrowDirection={'left'}
                    />
                </Show>

                <Button
                    onClick={addToBasket}
                    disabled={!selectedSku()}
                    label={localize('add-to-basket', 'Add to basket')}
                />
            </StyledAddToOrderButton>
        </StyledProductShopToolbar>
    );
};

export default ProductShopToolbar;
