import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const CompassIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M13.594,15A1.406,1.406,0,1,1,15,16.406,1.4,1.4,0,0,1,13.594,15Zm6.832-7.8A1.878,1.878,0,0,1,22.8,9.574l-2.865,8.584a2.791,2.791,0,0,1-1.775,1.775L9.574,22.8A1.878,1.878,0,0,1,7.2,20.426l2.865-8.584a2.791,2.791,0,0,1,1.775-1.775Zm.592,1.781-8.584,2.859a.957.957,0,0,0-.592.592L8.982,21.018l8.584-2.859a.957.957,0,0,0,.592-.592ZM0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15ZM15,28.125A13.125,13.125,0,1,0,1.875,15,13.126,13.126,0,0,0,15,28.125Z"
                {...props}
            />
        </SvgIcon>
    );
};
