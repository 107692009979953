import { Link } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../style/theme';
import { StyledImageProps } from '../post-card/post-card.styles';

const { spacing } = theme;

export const StyledPostCard = styled(Link)<any>`
    display: block;
    border-radius: 1.43rem;
    height: 100%;
    min-height: 27.6rem;
    ${(p) =>
        p.noBorder
            ? ''
            : `
        border: 1px solid ${theme.palette.lightGray};
    `}
    width: ${(p) => (p.orientation === 'vertical' ? 'auto' : '100%')};
    background-color: white;
    list-style: none;
    position: relative;
    text-decoration: none;

    ${theme.mediaQueries.tabletMax} {
        margin-block: 2rem;
    }
    ${theme.mediaQueries.mobileMax} {
        margin-block: 1rem !important;
    }
`;

export const StyledImage = styled.img<StyledImageProps>`
    height: ${(p) => (p.large ? ' 28.6rem' : '19.3rem')};
    background-color: ${theme.palette.lightGray};
    border-radius: 1.43rem 1.43rem 0 0;

    width: 100%;
    object-fit: cover;
    object-position: center;
`;

export const StyledBottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing.xs};
    padding: ${spacing.xs} ${spacing.s} ${spacing.xs} ${spacing.s};
`;

export const StyledCardTitle = styled.div`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 2.07rem;
    color: ${theme.palette.darkGray};
    text-decoration: none;
    margin-bottom: ${spacing.xs};
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 1rem;
    overflow: hidden;
    &:first-letter {
        text-transform: uppercase;
    }
`;

export const StyledIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 19.3rem;
    border-bottom: 1px solid ${theme.palette.lightGray};
`;