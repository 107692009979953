import { styled } from 'solid-styled-components';
import theme from '../../../style/theme';

const {spacing} = theme;

export const StyledDocumentRow = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    gap: ${spacing.xxs};
    text-transform: none;
`;
