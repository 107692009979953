import { gql } from 'graphql-request';
import { createMemo, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ProductData } from './product-types';

export const GET_PRODUCT_QUERY = gql`
    query GetProduct($key: String!) {
        product: resource(key: $key) {
            content
            key
        }
    }
`;

// TODO: we are loosing reactivity if using this. Is there a way to fix it?
// Are we? 
export const fetchProduct = (slug: string) => {
    const {createCachedResource} = useContext(AppContext);
    const [productContent] = createCachedResource(GET_PRODUCT_QUERY, () => ({ key: `/product/${slug}` }), true);
    
    return createMemo((): ProductData => productContent()?.product?.content);
};

