// import { ArrowAndPlus } from '../../../ui-components/arrow-and-plus/arrow-and-plus';
import { StyledBigText, StyledProductVideoCard, StyledProductVideo, StyledVideoDescription } from './product-video.styles';
import { ProductVideoCardProps } from './product-video.d';
import { Match, Switch } from 'solid-js';
import { getYoutubeVideoId, getVimeoUrl } from '../../video-embed/helpers';
import removeAmpersand from '../../../tools/remove-ampersand';

export const ProductVideoCard = (componentProps: ProductVideoCardProps) => {
    return (
        <StyledProductVideoCard>
            <StyledProductVideo>
                <Switch>
                    <Match when={componentProps?.video?.url.includes('youtu')}>
                        <iframe
                            src={'https://www.youtube-nocookie.com/embed/' + getYoutubeVideoId(componentProps?.video?.url)}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Match>
                    <Match when={componentProps?.video?.url.includes('vimeo')}>
                        <iframe
                            src={getVimeoUrl(componentProps.video.url)}
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Match>
                </Switch>
            </StyledProductVideo>
            <StyledVideoDescription>
                <StyledBigText>{removeAmpersand(componentProps?.video?.title?.trim())}</StyledBigText>
                {/* <ArrowAndPlus icon="star" iconColor='gray' onClick={handleAddToFavorites}></ArrowAndPlus> */}
            </StyledVideoDescription>
        </StyledProductVideoCard>
    );
};
