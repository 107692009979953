import theme from '../../style/theme';
import { styled } from 'solid-styled-components';

export const StyledHeadingWrapper = styled.div`
    margin-bottom: 4.5rem;
    ${theme.mediaQueries.mobileMax} {
        margin-bottom: unset;
        padding-top: 1rem;
        padding-inline: 1.5rem;
    }
`;

export const StyledFilterHeadingContainer = styled.div`
    border-bottom: ${theme.redLineThickness} solid ${theme.palette.red};
    margin-bottom: 1.3rem;
    padding-bottom: 0.5rem;
`;

export const StyledProductsList = styled.div`
    margin-top: 4rem;
    ${theme.mediaQueries.mobileMax} {
        margin-top: 1rem;
    }
`;

export const StyledClearAllContainer = styled.div`
    margin-top: 3rem;
    ${theme.mediaQueries.mobileMax} {
        margin-top: 0;
        display: flex;
        justify-content: center;
    }
`;

export const StyledProductsContainer = styled.div`
    padding-block: ${theme.header.desktop.height.floating.value + theme.header.desktop.height.floating.unit};

    ${theme.mediaQueries.mobileMax} {
        padding-top: 0;
    }
`;