import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const mobilePadding = '0.5rem';

type StyledLabelProps = {
    hasErrors?: boolean;
};

export const StyledLabel = styled.label<StyledLabelProps>`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    & > input {
        margin-right: 0.9rem;
    }
    ${theme.mediaQueries.mobileMax} {
        padding-block: ${mobilePadding};
    }

    border: ${p => p.hasErrors ? '1px solid red' : 'none'};
`;

export const inputStyle = `
    margin: 0;
    appearance: none;
    border: 1px solid ${theme.palette.darkGray};
    background-clip: content-box;
    padding: 0;
    cursor: pointer;
`;
