import { keyframes, css, styled } from 'solid-styled-components';
import { containerQueries } from '../../tools/check-container-support';

export const ImageWrapper = styled.div<{ blockSpacing?: boolean, requestingViaPdfBuilder?: boolean }>`
    height: fit-content;
    width: 100%;

    ${p => p.blockSpacing && !p.requestingViaPdfBuilder
        ? `
            &:not(:last-child) {
                ${containerQueries('mobileMax')} {
                    margin-bottom: 2rem;
                }
                margin-bottom: 3rem;
            }
            
            &:not(:first-child) {
                margin-top: 1rem;
            }
        ` : ''}
`;

export const StyledFigure = styled.figure`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;

export const imageFitCss = css`
    display: block;
`;

export const imagePictureCss = css`
    width: 100%;
`;

export const imageTextCss = css`
    p-small(); // what is this supposed to be?
    text-align: center;
`;

export const imageLowresCss = css`
    width: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
    opacity: 0;
    animation: image--animate 0.5s ease-out forwards;
`;

export const imageKeyframes = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const imageLowresTransitionEndedCss = css`
    display: none;
    opacity: 0;
    animation: none;
`;

export const imageFullresCss = css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0;
`;

export const imageFullresTransitionEndedCss = css`
    position: static;
    height: auto;
`;

export const imageFullresLoadedCss = css`
    animation: ${imageKeyframes} 0.8s ease-out forwards;
    display: block;
`;
