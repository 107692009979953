import { StyledVerticalSpace } from '../../../../ui-components/utility-style-components/spacing';
import type { ShorcutsMobileProps, ShortCutMenuLinkItem, ShortcutsProps } from '../../types/shortcuts-types';
import { ShortcutsMenuList } from './shortcuts-menu-list/shortcuts-menu-list';
import { StyledShortcuts, StyledShortcutsContent, StyledShortcutsHeading, StyledShortcutsHeadingRow } from './shortcuts.styles';

export const Shortcuts = (props: ShortcutsProps & ShorcutsMobileProps) => {
    const contactLinks = () => [
        {
            ...props.letUsContactYouLink,
            ...{ icon: 'envelope' },
        },
        {
            title: props.phoneNumber,
            url: `tel:${props.phoneNumber}`,
            icon: 'phone',
        },
    ] as ShortCutMenuLinkItem[];

    return (
        <>
            <StyledShortcutsHeadingRow>
                <StyledShortcutsHeading>{props.shortcutsHeading}</StyledShortcutsHeading>
            </StyledShortcutsHeadingRow>

            <StyledShortcuts>
                <StyledShortcutsContent id="shortcutsContent">
                    <div>
                        <ShortcutsMenuList
                            menuHeading={props.contactHeading}
                            links={contactLinks()}
                        />
                        <StyledVerticalSpace size={1} />
                        <ShortcutsMenuList
                            menuHeading={props.servicesHeading}
                            links={props.servicesLinks}
                            lastElement={(el: HTMLElement) => {
                                props.lastElement(el);
                            }}
                        />
                    </div>
                </StyledShortcutsContent>
            </StyledShortcuts>
        </>
    );
};
