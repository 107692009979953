import theme from '../../style/theme';
import { styled } from 'solid-styled-components';

export const StyledCollectionList = styled.div`
    margin-top: 2rem;
    ${theme.mediaQueries.mobileMax} {
        margin-top: 1rem;
    }
`;

export const StyledCategoryGroup = styled.div`
    margin-bottom: 4rem;
    ${theme.mediaQueries.mobileMax} {
        margin-bottom: 0;
        margin-top: 4rem;
    }
`;

export const StyledProductsCollectionHeadingWrapper = styled.div`
    ${theme.mediaQueries.mobileMax} {
        padding-inline: 1.5rem;
    }
`;

export const StyledButtonWrapper = styled.div`
    margin-top: 12rem;
`;

export const StyledMobileBackContainer = styled.div`
    padding-inline: 1.5rem;
    padding-block: 0.5rem;
`;