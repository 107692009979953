import { Show, useContext } from 'solid-js';
import { Text } from '../../ui-components/text/text';
import { StyledFlexColumn } from '../../ui-components/utility-style-components/flex';
import { Link } from '../../ui-components/link/link';
import { ImageComponent } from '../image-component/image-component';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import {
    StyledTableItem,
    StyledOrderLine,
    StyledImageHolder,
} from './basket.styles';
import { MobilePriceControls, RemoveButton, TableBody } from './basket-components';
import { MappedOrderDetails } from './basket';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';

type BasketOrderLineProps = {
    orderDetails: MappedOrderDetails;
};

const BasketOrderLine = ({ orderDetails }: BasketOrderLineProps) => {
    const { viewport, localize } = useContext(AppContext);

    const isDesktop = () => viewport.width > theme.breakpoints.ORIGINAL_TABLET;

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    return (
        <>
            <StyledOrderLine>
                <StyledImageHolder>
                    <Show when={orderDetails.imageUrl}>
                        <ImageComponent
                            src={orderDetails.imageUrl!}
                            height={'AUTO'}
                            width={300}
                            altText={'Product image for ' + orderDetails.sku.name}
                            title={'Product image for ' + orderDetails.sku.name}
                            aspectRatio={'original'}
                            ratio={orderDetails.imageRatio}
                        />
                    </Show>
                </StyledImageHolder>
                <StyledFlexColumn>
                    <Link
                        label={orderDetails.sku.name}
                        url={orderDetails.product.url}
                        size={isMobile() ? 'medium' : 'large'}
                        noBlockSpacing
                        lineLimit={isMobile() ? 1 : 2}
                    />
                    <StyledVerticalSpace size={1.3} />
                    <Text fontSize={isMobile() ? 'small' : 'normal'} lineLimit={isDesktop() ? 2 : 3} noBlockSpacing>{
                        orderDetails.product.shortDescription
                    }</Text>
                    <StyledVerticalSpace size={1.3} />
                    <Text fontSize='small' noBlockSpacing color='gray' lineLimit={isDesktop() ? 2 : 3}>{
                        orderDetails.sku.description
                    }</Text>
                    <Text fontSize='small' color='gray' noBlockSpacing>{
                        `${orderDetails.sku?.quantity ? `${localize('units-per-pack', 'Units per pack')}: ${orderDetails.sku?.quantity}` : ''}`
                    }</Text>

                    <Show when={!isDesktop()}>
                        <MobilePriceControls orderDetails={orderDetails} />
                    </Show>

                    <StyledVerticalSpace size={isDesktop() ? 3 : 7} />
                </StyledFlexColumn>
            </StyledOrderLine>

            <Show when={isDesktop()}>
                <TableBody orderDetails={orderDetails} />

                <StyledTableItem align='flex-end'>
                    <RemoveButton orderDetails={orderDetails} />
                </StyledTableItem>
            </Show>
        </>
    );
};

export default BasketOrderLine;
