import { createSignal, Show, For } from 'solid-js';
import { StyledFirstLevelMenu, StyledFirstLevelMenuItem, StyledListItem, StyledSecondaryMenuItem, StyledSecondaryMenuItemParentLink, StyledUnorderedList } from './first-level-menu.styles';
import type { FirstLevelMenuProps } from './first-level-menu-types';
import type { MenuItem } from '../../../types/primary-menu-types';
import { removeTrailingSlash } from '../../../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../../../tools/remove-ampersand';
import clsx from 'clsx';
import { ChevronUpBoldIcon } from '../../../../icons-library/chevron-up-bold';

export const FirstLevelMenu = (props: FirstLevelMenuProps) => {
    const [open, setOpen] = createSignal(false);

    return (
        <StyledListItem isOpen={open()}>
            <StyledFirstLevelMenu>
                <StyledFirstLevelMenuItem onClick={() => setOpen(!open())}>
                    {removeAmpersand(props.parent.title)}

                    <span
                        class={clsx('ml-[0.5rem]', 'transform', 'translate-y-[1px] transform transition-transform duration-200 origin-center', {
                            'rotate-180': open(),
                        })}
                    >
                        <ChevronUpBoldIcon width={1.5} height={1.5} />
                    </span>
                </StyledFirstLevelMenuItem>
                <Show when={open()}>
                    <StyledUnorderedList>
                        <li>
                            <StyledSecondaryMenuItemParentLink
                                href={removeTrailingSlash(props.parent.url)}
                                onClick={() => props.onLinkClick()}
                            >
                                {removeAmpersand(props.parent.title)}
                            </StyledSecondaryMenuItemParentLink>
                        </li>
                        <For each={props.parent.children}>
                            {(link: MenuItem) => (
                                <li>
                                    <StyledSecondaryMenuItem
                                        href={removeTrailingSlash(link.url)}
                                        onClick={() => props.onLinkClick()}
                                    >
                                        {removeAmpersand(link.title)}
                                    </StyledSecondaryMenuItem>
                                </li>
                            )}
                        </For>
                    </StyledUnorderedList>
                </Show>
            </StyledFirstLevelMenu>
        </StyledListItem>
    );
};
