import { Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { MedicalRegistrationWrapper } from './medical-registration-wrapper';
import { CareRegistrationWrapper } from './care-registration-wrapper';

export const RegistrationFormEntry = () => {
    const { siteInfo } = useContext(AppContext);
    
    return (
        <Show
            fallback={<MedicalRegistrationWrapper />}
            when={siteInfo.siteType === 'atos-care'}
        >
            <CareRegistrationWrapper />
        </Show>
    );
};

