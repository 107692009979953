import { For, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { getBackgroundImageSrcData, ImageSrcData } from '../image-component/image-component';
import theme, { breakpoints, fontFamilyAtosBold } from '../../style/theme';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { Text } from '../../ui-components/text/text';
import {
    StyledContentWrapper,
    StyledHeroWrapper,
    StyledMobileImageContainer,
    StyledScrollDownContainer,
    StyledInnerWrapper,
    StyledLinkWrapper,
    StyledEmptyColumn,
    StyledHeadings,
    StyledSwirlMobile,
    StyledIconWrapper,
    StyledSwirlDesktop,
    StyledHeroAndSwirlContainer,
    StyledScrollDownButton,
    StyledMenusContainer,
    StyledMenuItemsList,
    StyledMenuItem,
    ExpandableContainerWrapper,
    StyledTextWrapper,
    StyledHeadingWrapper,
} from './hero.styles';
import { HeroComponentProps } from './hero-types';
import { Grid } from '../../grid-system/grid/grid';
import { AngleDownIcon } from '../icons-library/angle-down';
import { focusPosition } from '../../tools/get-image-focus';
import { ErrorCatcher } from '../../tools/error-catcher';
import { SwirlMobile } from '../icons-library/swirl-mobile';
import { SwirlDesktop } from '../icons-library/swirl-desktop';
import { ExpandableContainer } from '../../ui-components/expandable-container/expandable-container';
import { Link } from '../../ui-components/link/link';

export const Hero = (props: HeroComponentProps) => {
    const { imagesServiceUrl, supportedImageFormats, localize, viewport, siteInfo } = useContext(AppContext);

    const isMobile = () => viewport.width <= breakpoints.MOBILE;
    const isUnderDesktopLarge = () => viewport.width <= breakpoints.DESKTOP_LARGE;

    const imageWidth = () => {
        // Because this is a background image which sizes itself to the container, we need to set the width larger than the container, so it has room to grow/shrink.
        if (viewport.width <= breakpoints.TABLET_SMALL) {
            return 750; // Mobile
        }

        if (viewport.width <= breakpoints.DESKTOP && viewport.width > breakpoints.TABLET_SMALL) {
            return 1500; // Tablet
        }

        return 1920; // Desktop
    };

    const imageHeight = () => {
        // Because this is a background image which sizes itself to the container, we need to set the width larger than the container, so it has room to grow/shrink.
        if (viewport.width <= breakpoints.TABLET_SMALL) {
            return 325; // Mobile
        }

        if (viewport.width <= breakpoints.DESKTOP && viewport.width > breakpoints.TABLET_SMALL) {
            return 750; // Tablet
        }

        return 960; // Desktop
    };

    const getBackgroundImage = () => {
        if (props.src) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.src,
                focus: props.focus,
                sign: props.sign,
                width: imageWidth() || 750, // Assume mobile from the start
                height: imageHeight() || 325,
            });
            return backgroundImage;
        }
    };

    const customCssRow = `
        height: 100%;
        ${theme.mediaQueries.mobileMax} {
            height: auto;
        }
    `;

    const customCssSecondaryButton = `
        font-size: 1.29rem;
        letter-spacing: 0.3.2rem;
        line-height: 1.57rem;
        font-family: ${fontFamilyAtosBold};
        font-weight: 800;
        margin-left: 2rem;
    `;

    const handleScrollDown = (e: any) => {
        e.preventDefault();
        if (!window) {
            return;
        }
        
        window.scrollTo({
            top: window.innerHeight - (isMobile() ? 20 : 100),
            behavior: 'smooth'
        });
    };

    const withHeroMenu = () => props.showMenu && Boolean(props.menu);


    return (
        <ErrorCatcher componentName="Hero">
            <StyledHeroAndSwirlContainer>
                <StyledHeroWrapper 
                    backgroundImageSrcData={getBackgroundImage()} 
                    focusPosition={focusPosition(props.focus)} 
                    overlayType={props.overlayType}
                    withHeroMenu={withHeroMenu()}
                >
                    <Grid
                        templateShorthand={[12]}
                        isLayoutGrid={true}
                        customCss={`
                            height: 100%; ${isMobile() ? 'grid-template-rows: max-content;' : ''}
                        `}
                    >
                        <Grid templateShorthand={[1, 11]} responsive={{ tablet: [1, 11], mobile: [12] }} customCss={customCssRow}>
                            <StyledEmptyColumn />
                            <StyledInnerWrapper>
                                <StyledContentWrapper isAtosCare={siteInfo?.siteType === 'atos-care'} withHeroMenu={withHeroMenu()}>
                                    <StyledHeadings>
                                        <Heading tag="h1" variant="heroEyebrow">
                                            {props.eyebrowHeading}
                                        </Heading>
                                        <StyledHeadingWrapper>
                                            <Heading tag="h2" variant="hero" noBlockSpacing={true}>
                                                {props.largerHeading}
                                            </Heading>
                                        </StyledHeadingWrapper>
                                    </StyledHeadings>
                                    <StyledTextWrapper>
                                        <Text color={'darkGray'} fontSize={isMobile() ? 'normal' : 'large'} displayRedVerticalLine={true}>
                                            {props.description}
                                        </Text>
                                    </StyledTextWrapper>
                                    <Show when={!withHeroMenu() && !isMobile()}>
                                        <StyledLinkWrapper>
                                            <Button label={localize('learn-more', 'Learn more')} onClick={handleScrollDown} />
                                        </StyledLinkWrapper>
                                    </Show>
                                    <Show when={props?.customLink?.url}>
                                        <Button
                                            label={props.customLink.label ? props.customLink.label : ''}
                                            url={props.customLink.url}
                                            variant="tertiary"
                                            customCss={customCssSecondaryButton}
                                            isLink={true}
                                        />
                                    </Show>

                                    <Show when={withHeroMenu()}>
                                        <StyledMenusContainer>
                                            <For each={props.menu}>{(menuItem, index) => (
                                                <Show when={menuItem.children && index() <= 2}>
                                                    <ExpandableContainerWrapper layerPriority={2 - index()}>
                                                        <ExpandableContainer
                                                            enableOutsideClickToClose={true}
                                                            isDropdown={true}
                                                            shrinkOnTablet={true}
                                                            backgroundColor={theme.palette.white}
                                                            chevronColor={theme.palette.darkGray}
                                                            chevronSize={isMobile() ? 1.8 : 2.2}
                                                            headingProps={{
                                                                children: menuItem.title,
                                                                tag: 'h4',
                                                                noBlockSpacing: true,
                                                                variant: isUnderDesktopLarge() ? 'tinyGray' : 'smallGray'
                                                            }}
                                                            customHeadingRowCss={`
                                                                padding-block: 2rem;
                                                                padding-inline: 0.5rem;
                                                                ${theme.mediaQueries.tabletMaxOriginal} {
                                                                    padding-block: 1rem;
                                                                }
                                                            `}
                                                        >
                                                            <StyledMenuItemsList>
                                                                <For each={menuItem.children}>{(child, index) => (
                                                                    <Show when={index() <= 3}>
                                                                        <StyledMenuItem>
                                                                            <Link
                                                                                label={child.title}
                                                                                url={child.url}
                                                                                plainStyle={true}
                                                                                size={isMobile() ? 'small' : 'medium'}
                                                                                labelColor={theme.palette.darkGray}
                                                                                bold={true}
                                                                            />
                                                                        </StyledMenuItem>
                                                                    </Show>
                                                                )}</For>
                                                            </StyledMenuItemsList>
                                                        </ExpandableContainer>
                                                    </ExpandableContainerWrapper>
                                                </Show>

                                            )}</For>
                                        </StyledMenusContainer>
                                    </Show>

                                </StyledContentWrapper>
                            </StyledInnerWrapper>
                        </Grid>
                        <StyledMobileImageContainer 
                            backgroundImageSrcData={getBackgroundImage()}
                            focusPosition={focusPosition(props.focus)}
                            withHeroMenu={withHeroMenu()}
                        >
                            <Show when={!withHeroMenu()}>
                                <div id="learnMoreMobile" onClick={handleScrollDown}>
                                    <Button label={localize('learn-more', 'Learn more')} variant="tertiary" darkMode={true} />
                                    <StyledIconWrapper>
                                        <AngleDownIcon fill="white" />
                                    </StyledIconWrapper>
                                </div>
                            </Show>
                        </StyledMobileImageContainer>
                    </Grid>
                    <Show when={siteInfo?.siteType !== 'atos-care' && isMobile()}>
                        <StyledSwirlMobile>
                            <SwirlMobile />
                        </StyledSwirlMobile>
                    </Show>
                    <StyledScrollDownContainer>
                        <StyledScrollDownButton aria-label={localize('learn-more', 'Learn more')} onClick={handleScrollDown}>
                            <AngleDownIcon />
                        </StyledScrollDownButton>
                    </StyledScrollDownContainer>
                </StyledHeroWrapper>
                <Show when={siteInfo?.siteType !== 'atos-care' && !isMobile()}>
                    <StyledSwirlDesktop>
                        <SwirlDesktop />
                    </StyledSwirlDesktop>
                </Show>
            </StyledHeroAndSwirlContainer>
        </ErrorCatcher>
    );
};

Hero.parseProps = (atts: any) => {
    // This is the raw image object coming from gutenberg. We dont want all details from it.
    const { image, customLink } = atts;
    const commonAtts = {
        altText: atts.altText,
        imageText: atts.imageText,
        eyebrowHeading: atts.eyebrowHeading,
        largerHeading: atts.largerHeading,
        description: atts.description,
        labels: atts.labels,
        customLink: customLink ? customLink[0] : null,
        showMenu: atts.showMenu,
        menu: atts.menu,
    };

    if (image) {
        return {
            src: image.url,
            width: image.width,
            focus: image.focus,
            sign: image.sign,
            overlayType: atts.overlayType,
            ...commonAtts,
        };
    } else return commonAtts;
};
