import { EventData } from '../../event/event-types';

export const languageCodes: { [key: string]: string } = {
    en: 'English',
    nl: 'Nederlands',
    fr: 'Français',
    de: 'Deutsch',
    es: 'Español',
    it: 'Italiano',
    pt: 'Português',
    fi: 'Suomi',
    sv: 'Svenska',
    da: 'Dansk',
    no: 'Norsk',
    pl: 'Polski',
    cn: 'Chinese',
    kr: 'Korean',
    ja: 'Japanese',
};

export const getUniqueLanguages = (events: EventData[]) => {
    const uniqueLanguages: Set<string> = new Set('');

    events?.forEach((event) => {
        event?.eventLanguage && uniqueLanguages.add(languageCodes[event?.eventLanguage]);
    });

    return Array.from(uniqueLanguages);
};
