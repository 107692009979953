import { pdfStore } from '../../../pdf-store';
import { StyledCommonA4Body, StyledCommonFittingTemplate } from '../shared-components.style';

export const ProductA4Body = (props: any) => {
    return (
        <StyledCommonA4Body debugging={pdfStore.debugging}>
            {props.children}
        </StyledCommonA4Body>
    );
};


export const ProductFittingTemplate = (props: any) => {
    return (
        <StyledCommonFittingTemplate debugging={pdfStore.debugging}>
            {props.children}
        </StyledCommonFittingTemplate>
    );
};
