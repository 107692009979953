import { createEffect, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../../../app-context-provider/app-context-provider';
import { Button } from '../../../../ui-components/button/button';
import { StyledTextInput } from '../../../../ui-components/inputs/text-input';
import { Modal } from '../../../../ui-components/modal/modal';
import { pdfStore, setPdfStore } from '../../pdf-store';
import { Text } from '../../../../ui-components/text/text';
import { StyledModalInner, StyledErrorMessage, StyledModalButtonsContainer } from './title-modal.style';

export const TitleModal = () => {
    const [ filenameError, setFilenameError ] = createSignal('');
    const { localize } = useContext(AppContext);
    let input: any;
    
    createEffect(() => {
        if (pdfStore.status === 'naming') {
            input?.focus();
        }
    });

    const updateTitle = (e: Event) => {
        if (filenameError()) {
            setFilenameError('');
        }

        const target = e.target as HTMLInputElement;
        if (!target.value) {
            return;
        }

        const ok = validateTitle(target.value);
        if (!ok) {
            return;
        }

        setPdfStore('title',target.value);
    };

    const validateTitle = (title: string) => {
        const regex = /^[-a-zA-Z0-9_ ]*$/;
        const ok = regex.test(title);

        if (!ok) {
            setFilenameError(localize('pdf-title-allowed-characters', 'Please only use alphanumeric characters, dashes, underscores and spaces'));
            return false;
        }

        return true;
    };

    const saveBuild = async () => {
        if (duplicateTitle()) {
            return;
        };

        const newCollection = {
            title: pdfStore.title,
            orderedPosts: pdfStore.workingCollectionPosts,
            downloaded: false
        };

        setPdfStore('collections', [ ...pdfStore.collections, newCollection ]);
        closeModal();
    };

    const duplicateTitle = () => {
        const existingTitles = pdfStore.collections.map((collection) => collection.title);
        const isDuplicate = existingTitles.includes(pdfStore.title);
        if (isDuplicate) {
            setFilenameError('A collection with this title already exists');
            return true;
        }

        return false;
    };

    const closeModal = () => {
        setPdfStore('status', '');
    };

    return (
        <Modal visible={ pdfStore.status === 'naming' } onClose={ closeModal } size='small' darkenBackground={ true }>
            <StyledModalInner>
                <Text fontSize='large' fontStyle='bold'>
                    {localize('pdf-name-of-file', 'Name your file')}
                </Text>
                <StyledTextInput
                    ref={input}
                    onInput={ ((e) => updateTitle(e)) }
                    placeholder={ localize('name', 'Name') }
                />
                <StyledErrorMessage>{filenameError()}</StyledErrorMessage>
                <StyledModalButtonsContainer>
                    <Button
                        label={ localize('cancel', 'Cancel') }
                        onClick={ closeModal }
                        customCss='margin-right: 1.5rem;'
                    />
                    <Button
                        disabled={ !pdfStore.title }
                        label='Ok'
                        onClick={ saveBuild }
                    />
                </StyledModalButtonsContainer>
            </StyledModalInner>
        </Modal>
    );
};
