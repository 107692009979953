import { For, Show, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Specification } from '../product-types';
import { StyledSpecificationsMobile, StyledSpecificationsMobileCell, StyledSpecificationsMobileColumn, StyledSpecificationsMobileHeader, StyledSpecificationsMobileTable, StyledSpecificationsMobileTableCare } from './product-specifications-mobile.styles';
import { ProductSpecificationProps } from './product-specifications-types';

import { AddToBasketButton } from '../../basket/basket-components';
import { StyledFlexRow } from '../../../ui-components/utility-style-components/flex';

export const ProductSpecificationsMobile = (props: ProductSpecificationProps) => {
    const { localize, siteInfo } = useContext(AppContext);

    const isCareSite = () => siteInfo.siteType === 'atos-care';

    return (
        <StyledSpecificationsMobile>
            <For each={props.product.specifications}>
                {(spec: Specification) => (
                    <>
                        <Show when={!isCareSite()}>
                            <StyledSpecificationsMobileTable>
                                <StyledSpecificationsMobileColumn>
                                    <StyledSpecificationsMobileHeader>{localize('name', 'Name')}</StyledSpecificationsMobileHeader>
                                    <StyledSpecificationsMobileCell>{spec?.name}</StyledSpecificationsMobileCell>
                                </StyledSpecificationsMobileColumn>
                                <StyledSpecificationsMobileColumn>
                                    <StyledSpecificationsMobileHeader>{localize('ref-nr', 'Reference number')}</StyledSpecificationsMobileHeader>
                                    <StyledSpecificationsMobileCell>{spec?.refNumber}</StyledSpecificationsMobileCell>
                                </StyledSpecificationsMobileColumn>
                                <StyledSpecificationsMobileColumn>
                                    <StyledSpecificationsMobileHeader>{localize('quantity', 'Quantity')}</StyledSpecificationsMobileHeader>
                                    <StyledSpecificationsMobileCell>{spec?.quantity}</StyledSpecificationsMobileCell>
                                </StyledSpecificationsMobileColumn>
                                <StyledSpecificationsMobileColumn>
                                    <StyledSpecificationsMobileHeader>{localize('description', 'Description')}</StyledSpecificationsMobileHeader>
                                    <StyledSpecificationsMobileCell>{spec?.description}</StyledSpecificationsMobileCell>
                                </StyledSpecificationsMobileColumn>
                            </StyledSpecificationsMobileTable>
                        </Show>

                        <Show when={isCareSite()}>
                            <StyledSpecificationsMobileTableCare>
                                <StyledSpecificationsMobileColumn>
                                    <StyledSpecificationsMobileHeader>{localize('name', 'Name')}</StyledSpecificationsMobileHeader>
                                    <StyledSpecificationsMobileCell>{spec?.name}</StyledSpecificationsMobileCell>
                                </StyledSpecificationsMobileColumn>
                                <StyledSpecificationsMobileColumn>
                                    <StyledSpecificationsMobileHeader>{localize('ref-nr', 'Reference number')}</StyledSpecificationsMobileHeader>
                                    <StyledSpecificationsMobileCell>{spec?.refNumber}</StyledSpecificationsMobileCell>
                                </StyledSpecificationsMobileColumn>
                                <StyledSpecificationsMobileColumn>
                                    <StyledSpecificationsMobileHeader>{localize('quantity', 'Quantity')}</StyledSpecificationsMobileHeader>
                                    <StyledSpecificationsMobileCell>{spec?.quantity}</StyledSpecificationsMobileCell>
                                </StyledSpecificationsMobileColumn>
                                <StyledSpecificationsMobileColumn>
                                    <StyledSpecificationsMobileHeader>{localize('description', 'Description')}</StyledSpecificationsMobileHeader>
                                    <StyledSpecificationsMobileCell>{spec?.description}</StyledSpecificationsMobileCell>
                                </StyledSpecificationsMobileColumn>
                                <StyledSpecificationsMobileColumn>
                                    <StyledFlexRow gap='1rem'>
                                        <AddToBasketButton product={props.product} specification={spec} />
                                    </StyledFlexRow>
                                </StyledSpecificationsMobileColumn>
                            </StyledSpecificationsMobileTableCare>
                        </Show>
                    </>
                )}
            </For>
        </StyledSpecificationsMobile>
    );
};
