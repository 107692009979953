import { styled } from 'solid-styled-components';

export const StyledProductIntroWrapperPDF = styled.div`
    display: flex;
    padding-top: 3rem;
    position: relative;
    justify-content: space-between;
`;

export const StyledProductIntroLeftPDF = styled.div`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
`;

export const StyledProductIntroRightPDF = styled.div`
    width: 300px;
    text-align: center;
`;

export const StyledProductImagePDF = styled.img`
    object-fit: contain;
    object-position: center;
    height: auto;
`;
