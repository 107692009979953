/* eslint-disable indent */
import { Link } from '@solidjs/router';
import { Match, Show, Switch, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Grid } from '../../../grid-system/grid/grid';
import theme from '../../../style/theme';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { AtosLogo } from '../../icons-library/atos-logo-desktop';
import {
    InnerHeader,
    StyledHeader,
    StyledLogo,
} from './campaign-header.style';

export const HeaderCampaignContainer = () => {
    const { viewport, localize } = useContext(AppContext);

    let headerRef: any;

    return (
        <ErrorCatcher componentName="Header">
            <StyledHeader>

                <Grid templateShorthand={[ 12 ]} customCss={'height: inherit;'} isLayoutGrid={true}>
                    <InnerHeader>
                        <div>
                            <Link href="/">
                                <Switch>
                                    <Match when={viewport.width >= theme.breakpoints.TABLET}>
                                        <StyledLogo>
                                            <Show when={false} fallback={<AtosLogo width={11} altText="Atos Medical Logo" aria-label="Atos Medical Logo" />}>
                                                <AtosLogo width={7.5} aria-label={localize('atos_medical_logotype', 'Atos Medical Logo')} />
                                            </Show>
                                        </StyledLogo>
                                    </Match>
                                    <Match when={viewport.width <= theme.breakpoints.TABLET}>
                                        <StyledLogo>
                                            <AtosLogo width={9.36} aria-label={localize('atos_medical_logotype', 'Atos Medical Logo')} />
                                        </StyledLogo>
                                    </Match>
                                </Switch>
                            </Link>
                        </div>
                    </InnerHeader>
                </Grid>

            </StyledHeader>
            <div ref={headerRef} />
        </ErrorCatcher>
    );
};
