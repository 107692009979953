import { gql } from 'graphql-request';
import { supportedTypes, OutputObject, setPdfStore } from './pdf-store';

export const GET_POSTS_QUERY = gql`
    query GetPosts(
        $type: [String]
    ) {
        posts: resources(
            type: $type
        ) {
            key
            content
            type
        }
    }
`;

type GeneratePageProps = {
    graphQlClient: (graphQlQuery: string, variables: any, useCache?: boolean) => Promise<any>;
    data: string;
};


export const fetchPosts = async (vars: GeneratePageProps) => {
    const keysList = JSON.parse(vars?.data);
    
    const variables = {
        type: supportedTypes,
    };

    const response = await vars.graphQlClient(GET_POSTS_QUERY, variables, true); // TODO: use get resources by key instead

    const posts = response?.posts
        .filter((resource: any) => {
            return keysList.includes(resource.key);
        })
        .map((resource: any): OutputObject => {
            return {
                key: resource.key,
                type: resource.type,
                data: resource.content,
            };
        })
        .sort((a: any, b: any) => {
            // Sort the posts in the order they appear in the keysList
            return keysList.indexOf(a.key) - keysList.indexOf(b.key);
        });

    setPdfStore('postsData', posts);
};
