import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../../style/theme';

const {spacing, palette, mediaQueries} = theme;

export const StyledBigText = styled.p`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 2rem;
    color: ${theme.palette.darkGray};

    ${theme.mediaQueries.mobileMax} {
        text-align: center;
        font-size: 1.64rem;
        margin-top: ${spacing.xxxs};
        margin-bottom: ${spacing.ms};
    }
`;

export const StyledFurtherLinksBottom = styled.div`
    padding: ${spacing.xl} 0 ${spacing.xxxl} ${spacing.xl};
    border-bottom: 2px solid ${palette.gray};

    ${mediaQueries.mobileMax} {
        padding: ${spacing.s};
    }
`;

export const StyledDocumentationLink = styled.div`
    padding: ${spacing.m} 0 ${spacing.xl} ${spacing.xl};
    border-bottom: 2px solid ${palette.red};

    ${mediaQueries.mobileMax} {
        padding: ${spacing.m} ${spacing.s} ${spacing.s} ${spacing.s};
    }
`;