import { GridProps } from './grid.d';
import { getChildSpans } from '../helpers';
import { StyledGrid } from './grid.style';
import { children, createSignal, createRenderEffect } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';

export const Grid = (props: GridProps) => { 
    const spans = getChildSpans({ templateShorthand: props.templateShorthand });
    const [childCount, setChildCount] = createSignal(spans.length);

    const resolved = children(() => props.children);
    createRenderEffect(() => {        
        const list = resolved.toArray();
        setChildCount(list.length);
    });
    
    return (
        <ErrorCatcher componentName='Grid'>
            <StyledGrid
                gridColumns={props.gridColumns}
                isLayoutGrid={props.isLayoutGrid}
                widthType='bgFull'
                customCss={props.customCss}
                childSpans={spans}
                inheritParentGrid={props.inheritParentGrid}
                responsive={props.responsive}
                autoResponsiveness={props.autoResponsiveness}
                childCount={childCount()}
            >
                {resolved()}
            </StyledGrid>
        </ErrorCatcher>
    );
};
