import { Accessor, createMemo, Match, Switch } from 'solid-js';
import { VideoData } from '../videos/videos-types';
import { createGroupedVideoCard } from '../videos/videos-component';
import { createGroupedProductCard } from '../products/products';
import { BlogNewsData } from '../blog-news/blog-news';
import { createBlogAndNewsCard, createGroupedBlogAndNewsCard } from '../blogs-news/blogs-news-component';
import { fetchProducts } from '../products/fetch-products';
import { fetchVideos } from '../videos/fetch-videos';
import { fetchBlogsAndNews } from '../blogs-news/fetch-blogs-and-news';
import { fetchProduct } from '../product/fetch-product';
import { fetchVideo } from '../video/fetch-video';
import { fetchBlogAndNews } from '../blog-news/fetch-blog-and-news';
import { ProductData } from '../product/product-types';
import { ErrorCatcher } from '../../tools/error-catcher';

type RequestParamsType = {
    tag?: {
        slug: string;
        name: string;
    };
    key?: string;
    limit?: number;
}

// export type PostTypeSlug = 'custom' | 'document' | 'page' | 'product' | 'video-item' | 'video-item-hcp' | 'blog-and-news-hcp' | 'blog-and-news';
export type PostTypeSlug = 'product' | 'video-item' | 'blog-and-news';

export type PostTypeType = {
    label: string;
    value: PostTypeSlug;
    tagSlug?: string;
    categorySlug?: string;
}

type CardTypeFactoryProps = {
    dynamicCards: boolean;
    tag?: {
        slug: string;
        name: string;
    };
    posttype: PostTypeType;
    postSlug?: string;
    insideSlider?: boolean;
}

export const CardTypeFactory = (props: CardTypeFactoryProps) => {

    const createCards = () => {
        const dataFetchers = {
            'product': (params: RequestParamsType) => fetchProducts(params),
            'video-item': (params: RequestParamsType) => fetchVideos(params),
            'blog-and-news': (params: RequestParamsType) => fetchBlogsAndNews(params),
            // 'event': (params: RequestParamsType) => fetchEvents(params),
            // 'document': (params: RequestParamsType) => Videos(params),
        };

        const cardCreators = {
            'product': (product: ProductData) => createGroupedProductCard(product),
            'video-item': (video: VideoData) => createGroupedVideoCard(video, props.insideSlider),
            'blog-and-news': (post: BlogNewsData) => createGroupedBlogAndNewsCard({post}),
            // 'event': (event: EventData) => createGroupedEventCard({event}),
            // 'document': (post: BlogNewsData) => createBlogAndNewsCard(post),
        };

        const requestParams: RequestParamsType = {
            limit: 5,
        };

        if (props.tag?.slug) {
            requestParams.tag = props.tag;
        }
        
        const data: Accessor<ProductData[]> | Accessor<VideoData[]> | Accessor<BlogNewsData[]> = dataFetchers[props.posttype.value](requestParams);

        return data()?.map(item => {
            return cardCreators[props.posttype.value](item as any);
        });
    };

    const createSingleCard = () => {
        //TODO: these methods tend to lose reactivity, check if this still works.
        const dataFetchers = {
            'product': (slug: string) => fetchProduct(slug),
            'video-item': (slug: string) => createMemo(() => fetchVideo(slug)),
            'blog-and-news': (slug: string) => createMemo(() => fetchBlogAndNews(slug)),
            // 'event': (slug: string) => createMemo(() => fetchEvent(slug)),
            // 'document': (slug: string) => Videos(slug),
            // 'page': (slug: string) => Videos(slug),
        };

        const cardCreators = {
            'product': (product: ProductData) => createGroupedProductCard(product),
            'video-item': (video: VideoData) => createGroupedVideoCard(video),
            'blog-and-news': (post: BlogNewsData) => createBlogAndNewsCard({post}),
            // 'event': (event: EventData) => createGroupedEventCard({event}),
            // 'document': (post: BlogNewsData) => createBlogAndNewsCard(post),
            // 'page': (post: BlogNewsData) => createBlogAndNewsCard(post),
        }; 

        if (!props.postSlug) {
            return;
        }
        
        const data: Accessor<ProductData> | Accessor<VideoData> | Accessor<BlogNewsData> = dataFetchers[props.posttype.value](props.postSlug);        
        
        if (!data) {
            return null;
        }

        const post = data();
        return cardCreators[props.posttype.value](post as any);
    };

    return (
        <ErrorCatcher componentName='Card factory'>
            <Switch>
                <Match when={props.dynamicCards}>
                    { createCards() }
                </Match>
                <Match when={!props.dynamicCards}>
                    { createSingleCard() }
                </Match>
            </Switch>
        </ErrorCatcher>
    );
};
