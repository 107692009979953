import { createSignal, lazy, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { SiteDomain } from '../../types/app-state';
import { ErrorCatcher } from '../../tools/error-catcher';
import { closeCountrySelectionMenu, localeMenuIsOpen, openCountrySelectionMenu } from '../../stores/locale-store';
import { Portal } from 'solid-js/web';


// type LocaleControllerProps = {
//     showCountrySelection: boolean;
//     setShowCountrySelection: (value: boolean) => void;
// }

type SiteLanguages = {
    current?: SiteDomain;
    countrySites?: SiteDomain[];
} | undefined;

type SiteLanguagesDefined = {
    current: SiteDomain;
    countrySites: SiteDomain[];
};

const dualLanguageCountries = [ /*'belgium',*/ 'finland', 'switzerland', 'canada' ]; // TODO: Move someplace better, not hard coded (Atos globals countries?)

export const LocaleController = () => {

    const { siteInfo, siteDomains, language } = useContext(AppContext);
    const [siteLanguages, setSiteLanguages] = createSignal<SiteLanguages>();
    const [internationalSite, setInternationalSite] = createSignal<SiteDomain | undefined>();

    // This loads the language selector and country selector components dynamically
    // We do this since the icons are HUGE. We need to wrap them in our own function because our components
    // dont have a default export.

    const LanguageSelector = lazy(async () => {
        const imported = await import('./language-selector/language-selector');
        return { default: imported.LanguageSelector };
    });
    const CountrySelector = lazy(async () => {
        const imported = await import('./country-selector/country-selector');
        return { default: imported.CountrySelector };
    });

    const site = () => siteDomains.find((domain) => domain.siteId === siteInfo.siteId );

    /**
     * @returns true if a cookie is found and it belongs to the same country as the current site
     */
    const matchingLanguageCookieFound = () => {
        if (!language.key || language.key === '') {
            return false;
        }

        const currentCountry = site()?.country;
        const cookieCountry = siteDomains.find(domain => domain.key === language.key)?.country;
        
        const countryMatch = currentCountry === cookieCountry;
        return countryMatch; // Cookie belongs to same country, so do not prompt user to change language
    };

    /**
     * @returns true if the current site is a dual language site
     * Also sets the international site and the country sites
     */
    const isMultiLanguage = () => {
        const country = dualLanguageCountries.find(dualSites => dualSites === site()?.country);

        if (!country) {
            return false;
        }

        const { internationalSite, countrySites } = siteDomains.reduce((agg, domain) => {
            if (domain.country === 'international') {
                if (!agg.internationalSite) {
                    agg.internationalSite = domain;
                    return agg;
                }
            }

            const isCurrent = domain.siteId === siteInfo.siteId; // Already grabbed it above
            if (!isCurrent && domain.country === country) {
                if (!agg.countrySites) {
                    agg.countrySites = [];
                }

                agg.countrySites = [...agg.countrySites, domain];
                return agg;
            }

            return agg;

        }, { internationalSite: undefined as undefined | SiteDomain, countrySites: undefined as undefined | SiteDomain[]});

        setInternationalSite(internationalSite);
        setSiteLanguages({
            current: site() as undefined | SiteDomain,
            countrySites: countrySites
        });

        return true;
    };

    return (
        <ErrorCatcher componentName='Language selector'>
            <Portal mount={document.getElementById('root')!}>
                <Show when={isMultiLanguage() && !localeMenuIsOpen() && !matchingLanguageCookieFound()}>
                    <Show when={siteLanguages()?.current !== null && siteLanguages()?.countrySites !== null}>
                        <LanguageSelector
                            openCountrySelection={() => openCountrySelectionMenu()}
                            siteLanguages={siteLanguages() as SiteLanguagesDefined}
                            internationalSite={internationalSite()}
                        />
                    </Show>
                </Show>
                <Show when={localeMenuIsOpen()}>
                    <CountrySelector
                        closeCountrySelection={() => closeCountrySelectionMenu()}
                    />
                </Show>
            </Portal>
        </ErrorCatcher>
    );
};
