import { For, useContext, Show } from 'solid-js';
import {
    StyledProductSpecificationsTable,
    StyledProductSpecificationsTableColumn,
    StyledProductSpecificationsTableHeader,
} from './product-specifications.styles';
import type { ProductSpecificationProps } from './product-specifications-types';
import { Specification } from '../product-types';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import theme from '../../../style/theme';
import { ProductSpecificationsMobile } from './product-specifications-mobile';
import { AddToBasketButton } from '../../basket/basket-components';

export const ProductSpecifications = (props: ProductSpecificationProps) => {
    const { localize, viewport, siteInfo } = useContext(AppContext);

    const isCareSite = () => siteInfo.siteType === 'atos-care';

    const mobileBreakpoint = () => {
        if (isCareSite()) return viewport.width <= theme.breakpoints.DESKTOP;

        return viewport.width <= theme.breakpoints.ORIGINAL_TABLET;
    };

    return (
        <Show when={mobileBreakpoint()} fallback={
            <StyledProductSpecificationsTable isCare={isCareSite()}>
                {/* DESKTOP */}
                <StyledProductSpecificationsTableHeader>{localize('name', 'Name')}</StyledProductSpecificationsTableHeader>
                <StyledProductSpecificationsTableHeader>{localize('description', 'Description')}</StyledProductSpecificationsTableHeader>
                <StyledProductSpecificationsTableHeader>{localize('ref-nr', 'Reference number')}</StyledProductSpecificationsTableHeader>
                <StyledProductSpecificationsTableHeader>{localize('quantity', 'Quantity')}</StyledProductSpecificationsTableHeader>

                <Show when={isCareSite()}>
                    <StyledProductSpecificationsTableHeader />
                </Show>

                <For each={props.product.specifications}>
                    {(spec: Specification) => (
                        <>
                            <StyledProductSpecificationsTableColumn isCare={isCareSite()}>
                                {spec?.name}
                            </StyledProductSpecificationsTableColumn>
                            <StyledProductSpecificationsTableColumn isCare={isCareSite()}>
                                {spec?.description}
                            </StyledProductSpecificationsTableColumn>
                            <StyledProductSpecificationsTableColumn isCare={isCareSite()}>
                                {spec?.refNumber}
                            </StyledProductSpecificationsTableColumn>
                            <StyledProductSpecificationsTableColumn isCare={isCareSite()}>
                                {spec?.quantity}
                            </StyledProductSpecificationsTableColumn>

                            <Show when={isCareSite()}>
                                <StyledProductSpecificationsTableColumn isCare={isCareSite()}>{
                                    <AddToBasketButton product={props.product} specification={spec} />
                                }</StyledProductSpecificationsTableColumn>
                            </Show>
                        </>
                    )}
                </For>
            </StyledProductSpecificationsTable>
        }>
            {/* MOBILE */}
            <ProductSpecificationsMobile product={props.product} />
        </Show>
    );
};
