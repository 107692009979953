import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

const customCss = `
    fill: white;

    box-shadow: 0px 0.21rem 0.42rem #00000029, inset 0px 0px 1.1rem #0000005e;
    border-radius: 0.36rem;
`;

export const LinkedinIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props} customCss={customCss}>
            <path
                d="M27.857,32H2.136A2.152,2.152,0,0,0,0,34.163V59.837A2.152,2.152,0,0,0,2.136,62H27.857A2.158,2.158,0,0,0,30,59.837V34.163A2.158,2.158,0,0,0,27.857,32ZM9.067,57.714H4.621V43.4H9.074V57.714ZM6.844,41.442a2.578,2.578,0,1,1,2.578-2.578A2.579,2.579,0,0,1,6.844,41.442ZM25.734,57.714H21.288V50.75c0-1.661-.033-3.8-2.31-3.8-2.317,0-2.672,1.808-2.672,3.676v7.085H11.859V43.4h4.266v1.955h.06a4.683,4.683,0,0,1,4.212-2.31c4.5,0,5.337,2.967,5.337,6.824Z"
                transform="translate(0 -32)"
            />
        </SvgIcon>
    );
};
