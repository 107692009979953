import { For, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import {
    gridSettings,
    StyledSidebar,
    StyledSidebarContainer,
    StyledSidebarContainerLine,
    StyledSidebarInner
} from '../../ui-components/layouts/sidebar.style';
import { Link } from '../../ui-components/link/link';
import { ProductData } from '../product/product-types';
import { fetchProducts } from '../products/fetch-products';
import {
    StyledHeadingWrapper,
    StyledProductsContainer
} from '../products/products.style';
import { ProductsCollectionProps } from './products-collection.d';
import { 
    StyledButtonWrapper,
    StyledCollectionList,
    StyledMobileBackContainer,
} from './products-collection.style';
import { createGroupedProductCard } from '../products/products';

export const ProductsCollection = (props: ProductsCollectionProps) => {
    const {viewport, localize} = useContext(AppContext);

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    const productsData = fetchProducts({ tag: props?.tag });
    const loaded = () => productsData();

    return (
        <ErrorCatcher componentName='Products collection'>
            <Show when={loaded()}>
                <Show when={isMobile() && props?.button?.url}>
                    <Section 
                        templateShorthand={[12]}
                        widthType={'bgFull'}
                        backgroundValue={'lightBeige'}
                        backgroundType={'color'}
                        removeSpacingBlock={true}
                    >
                        <StyledMobileBackContainer>
                            <Link 
                                label={localize('back', 'Back')}
                                url={props?.button?.url}
                                plainStyle={true}
                                arrowColor={'gray'}
                                arrowPosition={'left'}
                                arrowDirection={'left'}
                                labelColor={'gray'}
                            />
                        </StyledMobileBackContainer>
                    </Section>
                </Show>
                <Section 
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    backgroundValue={'white'}
                    backgroundType={'color'}
                    removeSpacingBlock={true}
                    customCss={'padding-top: 0 !important; padding-bottom: 0 !important;'}
                >
                    <Grid {...gridSettings.container}>
                        <StyledSidebarContainer>
                            <StyledSidebarContainerLine>
                                <StyledSidebar>
                                    <StyledSidebarInner>
                                        <StyledHeadingWrapper>
                                            <DynamicModuleGenerator skipSectionConversion={true} content={[props?.mainHeading]} />
                                            <DynamicModuleGenerator skipSectionConversion={true} content={[props?.subHeading]} />
                                        </StyledHeadingWrapper>

                                        <Show when={!isMobile() && props?.button?.url}>
                                            <StyledButtonWrapper>
                                                <DynamicModuleGenerator skipSectionConversion={true} content={[props?.button]} />
                                            </StyledButtonWrapper>
                                        </Show>
                                    </StyledSidebarInner>
                                </StyledSidebar>
                            </StyledSidebarContainerLine>
                        </StyledSidebarContainer>

                        <StyledProductsContainer>
                            <StyledCollectionList>
                                <Grid {...gridSettings.listing}>
                                    <For each={productsData()}>{(product: ProductData) => {
                                        return createGroupedProductCard(product);
                                    }}</For>
                                </Grid>
                            </StyledCollectionList>
                        </StyledProductsContainer>
                    </Grid>
                </Section>
            </Show>
        </ErrorCatcher>
    );
};

ProductsCollection.parseProps = (atts: any) => {    
    return {
        ...atts,
        mainHeading: atts.innerBlocks[0],
        subHeading: atts.innerBlocks[1],
        button: atts.innerBlocks[2],
        tag: atts.tag,
        labels: atts.labels,
    };
};
