/* eslint-disable indent */

import { theme } from '../../style/theme';
import { containerQueries } from '../../tools/check-container-support';
import { gridItems } from '../grid-helpers';
import { ResponsiveTemplate } from './grid.d';

const {grid} = theme;

const SIDE_GUTTERS = 3;
const CONTENT_COL_START = SIDE_GUTTERS; // Ends on 15 - the first/last two cols are "margins"

type ResponsiveProps = {
    childSpans: number[];
    isLayoutGrid: boolean | undefined;
    inheritParentGrid?: boolean | undefined;
    responsive?: ResponsiveTemplate;
    childCount: number;
    autoResponsiveness: boolean | undefined;
    withSidebarMenu?: boolean | undefined;
}

const totalCols = (spans: number[]) => {
    if (spans.length === 0) return grid.columns;

    return spans.reduce((total, count) => {
        return total + count;
    }, 0);
};

export const desktopCss = (p: ResponsiveProps) => {
    const spans = p.responsive?.smallDesktop || p.responsive?.largeDesktop || p.childSpans || [];

    const style = `
        position: relative;
        column-gap: ${grid.gapSize}rem;
        row-gap: ${p.childSpans.length > 1 ? grid.gapSize : 0}rem;
        ${p.isLayoutGrid
            ? `
                grid-template-columns:
                    auto
                    repeat(${totalCols(spans)}, 1fr)
                    auto;
            `
            : `
                grid-template-columns: repeat(${totalCols(spans)}, 1fr);
            `
        }

        ${gridItems({
            childSpans: spans,
            start: p.isLayoutGrid ? CONTENT_COL_START - 1 : 1,
            inheritParentGrid: p.inheritParentGrid,
            childCount: p.childCount,
            autoResponsiveness: p.autoResponsiveness,
        })}
    `;

    const containerQuery = theme.containerQueries[
        p.responsive?.largeDesktop ? 'desktopLargeMax' : 'desktopMax'
    ];

    return `
        ${containerQuery} {
            ${style}
        }
    `;
};

export const tabletCss = (p: ResponsiveProps) => {
    const spans = p.responsive?.tablet || p.responsive?.smallDesktop || p.responsive?.largeDesktop || p.childSpans || [];
    const style = `
        column-gap: ${grid.gapSizeMobile}rem;

        ${p.isLayoutGrid
            ? `
                grid-template-columns:
                    auto
                    repeat(${totalCols(spans)}, 1fr)
                    auto;
            `
            : `
                grid-template-columns: repeat(${totalCols(spans)}, 1fr);
            `
        }

        ${gridItems({
            childSpans: p.isLayoutGrid || p.autoResponsiveness ? [12] : spans,
            start: p.isLayoutGrid ? CONTENT_COL_START - 1 : 1,
            inheritParentGrid: p.inheritParentGrid,
            childCount: p.childCount,
            autoResponsiveness: p.autoResponsiveness,
        })}

        ${p.isLayoutGrid ? `
            & > * {
                grid-column-start: ${CONTENT_COL_START - 1};
            }
        ` : ''}

        ${!p.isLayoutGrid && p.autoResponsiveness ? `
            & > * {
                grid-column-start: ${1};
            }
        ` : ''}
    `;

    const breakpointSize = p.withSidebarMenu ? 'tabletMax' : 'tabletMax';

    return `
        ${containerQueries(breakpointSize)} {
            ${style}
        }
    `;
};

export const mobileCss = (p: ResponsiveProps) => {
    const spans = p.responsive?.mobile || p.responsive?.tablet || p.responsive?.smallDesktop || p.responsive?.largeDesktop || p.childSpans || [];
    const colsToUse = p.inheritParentGrid ? totalCols(spans) : grid.columns;
    const style = `
        column-gap: 1.14rem;
        row-gap: ${theme.grid.gapSizeTabletSmall}rem;

        ${p.isLayoutGrid || p.autoResponsiveness ? '' : `
            grid-template-columns: repeat(${colsToUse}, 1fr) !important;
            
            ${gridItems({
                childSpans: spans,
                start: p.isLayoutGrid ? CONTENT_COL_START - 1 : 1,
                inheritParentGrid: p.inheritParentGrid,
                childCount: p.childCount,
                autoResponsiveness: p.autoResponsiveness,
            })}
        `}
    `;

    return `
        ${theme.mediaQueries.mobileMax} {
            ${style}
        }
    `;
};