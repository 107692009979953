import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 29.992 26',
    // width: 29.992
};

export const ArrowRightIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M29.372,29.939,18.657,40.654a2.138,2.138,0,0,1-3.028,0,2.142,2.142,0,0,1,0-3.03l7.06-7.057H2.143a2.143,2.143,0,0,1,0-4.286H22.689L15.63,19.224a2.143,2.143,0,0,1,3.03-3.03L29.375,26.909A2.145,2.145,0,0,1,29.372,29.939Z"
                transform="translate(0-15.566)"
            />
        </SvgIcon>
    );
};
