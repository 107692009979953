import { styled } from 'solid-styled-components';
import { fontFamilyAtosBold, palette, theme } from '../../style/theme';
import { StyledHeaderProps, FrostedBackgroundProps } from './header.d';
import { frostedBackgroundBlurStyle } from './shared';

const { header, grid } = theme;
const { desktop, mobile } = header;

const desktopHeight = {
    staticHeight: desktop.height.fixed.value + desktop.height.fixed.unit,
    floatingHeight: desktop.height.floating.value + desktop.height.floating.unit,
};

const mobileHeight = mobile.height.value + mobile.height.unit;

export const StyledHeader = styled.header<StyledHeaderProps>`
    container-type: inline-size;
    height: ${(p) => (p.floatHeader ? desktopHeight.floatingHeight : desktopHeight.staticHeight)};
    position: sticky;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    ${theme.mediaQueries.tabletMax} {
        height: ${mobileHeight};
    }

    @media print {
        display: none;
    }
`;

export const StyledLogo = styled.div`
    width: auto;
`;

export const StyledLink = styled.div<{ active: boolean | null }>`
    margin-left: 4rem;
    text-transform: uppercase;
    color: ${theme.palette.darkGray};
    text-decoration: none;
    font-size: 1.43rem;
    letter-spacing: 0;
    line-height: 1.71rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;

    a,
    a:visited {
        font-size: 1.29rem;
        letter-spacing: 0;
        line-height: 1.93rem;
        font-family: ${fontFamilyAtosBold};
        font-weight: 800;
        margin: 1.43rem 0;
        transition: 0.2s color;
        color: inherit;
        text-decoration: none;
    }
    a:hover {
        color: ${theme.palette.pink};
        text-decoration: underline;
    }

    ${(p) =>
        p.active
            ? `
        color: ${theme.palette.red} !important;
        text-decoration: underline;
    `
            : ''}
`;

export const InnerHeader = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: inherit;
    z-index: 2;
    padding-top: 0.36rem;
`;

export const FrostedBackground = styled.div<FrostedBackgroundProps>`
    position: absolute;
    top: 0;
    left: 0;
    margin-inline: ${grid.standardUnit * 2 * -1}rem;
    right: 0;
    height: inherit;
    z-index: -1;
    background: transparent;
    border-radius: 0 0 ${grid.standardUnit}rem ${grid.standardUnit}rem;

    ${theme.mediaQueries.desktopMax} {
        margin-inline: ${grid.gapSizeTablet * -1}rem;
    }

    ${theme.mediaQueries.tabletMax} {
        background: ${theme.palette.beige};
        margin-inline: ${grid.gapSizeMobile * -1}rem;
        border-radius: 0 0 ${grid.gapSizeMobile}rem ${grid.gapSizeMobile}rem;
    }

    ${(p) => p.floatHeader ? frostedBackgroundBlurStyle : ''};
`;

export const StyledMiddleColumn = styled.div`
    display: flex;
    ${theme.mediaQueries.desktopMax} {
        display: none;
    }
`;

export const StyledRightColumn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
`;

export const StyledIcon = styled.div<{ isActive?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 1.43rem;
    cursor: pointer;
    transition: ease-in-out 0.2s all;

    &:not(:last-child) {
        border-right: 1px solid ${theme.palette.darkGray};
    }
    &:last-child {
        padding-right: 0.36rem;
    }
    ${theme.mediaQueries.tabletMax} {
        padding-inline: 1.25rem;
        &:not(:last-child) {
            border-right: none;
        }
    }

    ${theme.mediaQueries.mobileMax} {
        padding-inline: 0.9rem;
    }

    ${(p) =>
        p.isActive
            ? `
        svg {
            fill: ${palette.pink};
        }
    `
            : ''}

    &:hover {
        svg {
            fill: ${palette.pink};
        }
        h3 {
            color: ${palette.pink};
        }
    }
`;

export const StyledMenuText = styled.div`
    margin-left: 1.14rem;
    ${theme.mediaQueries.desktopMax} {
        display: none;
    }
`;

export const StyledMenuButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 1.43rem;
    cursor: pointer;
`;
