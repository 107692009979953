import { useLocation } from '@solidjs/router';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import { Grid } from '../../grid-system/grid/grid';
import { Section } from '../../grid-system/section/section';
import theme, { VerticalAlignment } from '../../style/theme';
import {
    gridSettings,
    StyledSidebarContainer,
    StyledSidebarContainerLine,
} from '../../ui-components/layouts/sidebar.style';
import { Text } from '../../ui-components/text/text';
import { Link } from '../../ui-components/link/link';
import { ImageComponent } from '../image-component/image-component';
import {
    StyledAnchorLinksContainer,
    StyledMainContent,
    StyledProductHeroSidebar,
    StyledProductImageWrapper,
    StyledProductsHeroAndSwirlContainer,
    StyledSwirlDesktop,
    StyledSwirlMobile,
} from './products-hero.style';
import { For, Show, useContext } from 'solid-js';
import { ProductsHeroProps } from './products-hero.d';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Column } from '../../grid-system/column/column';
import { StyledFlexColumn } from '../../ui-components/utility-style-components/flex';
import { ErrorCatcher } from '../../tools/error-catcher';
import { SwirlDesktop } from '../icons-library/swirl-desktop';
import { SwirlMobile } from '../icons-library/swirl-mobile';


export const ProductsHero = (props: ProductsHeroProps) => {
    const location = useLocation();
    const { viewport, siteInfo, appStore, headerSettings, } = useContext(AppContext);

    const isTablet = () => viewport.width <= theme.breakpoints.TABLET;
    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    const adjustForHcpHeader = () => headerSettings.activateHcpHeader && appStore.isHcp();

    const headerHeight = () => {
        let headerHeightValue = theme.header.desktop.height.fixed.value;
        let headerHeightUnit = theme.header.desktop.height.fixed.unit;
        
        if (adjustForHcpHeader()) {
            headerHeightValue = theme.header.desktop.height.fixedHcp.value;
            headerHeightUnit = theme.header.desktop.height.fixedHcp.unit;
        }

        return `${headerHeightValue}${headerHeightUnit}`;
    };

    return (
        <ErrorCatcher componentName='Products hero'>
            <Section 
                templateShorthand={[12]}
                widthType={'bgFull'}
                backgroundValue={'beige'}
                backgroundType={'color'}
                customCss={`
                    min-height: calc(100vh - ${headerHeight()});
                    padding-bottom: 0;
                `}
            >
                <Grid {...gridSettings.container}>
                    <StyledSidebarContainer>
                        <StyledSidebarContainerLine>
                            <StyledProductHeroSidebar>
                                <Column verticalAlignment={VerticalAlignment.center}>
                                    <Show when={isTablet()}>
                                        <DynamicModuleGenerator skipSectionConversion={true} content={[props?.mainSectionEyebrow, props?.mainSectionHeading]} />
                                    </Show>
                                    <StyledFlexColumn alignItems='center'>
                                        <Show when={props?.image}>
                                            <StyledProductImageWrapper>
                                                <ImageComponent
                                                    altText={props?.image?.altText || ''}
                                                    title={props?.image?.title || ''}
                                                    height={'auto'}
                                                    width={368}
                                                    spacer={false}
                                                    src={props?.image?.url}
                                                    aspectRatio={'original'}
                                                    ratio={props?.image.width / props?.image.height}
                                                />
                                            </StyledProductImageWrapper>
                                        </Show>
                                        <Text fontSize='small' displayRedVerticalLine={false} color='gray'>{props?.sidebarText}</Text>
                                    </StyledFlexColumn>
                                </Column>

                                <Show when={!isTablet()}>
                                    <Link 
                                        label={props.productsLink?.title}
                                        url={props.productsLink?.url || '#'}
                                        plainStyle={true}
                                        arrowColor={theme.palette.red}
                                        arrowPosition={'left'}
                                        arrowDirection={'right'}
                                        labelColor={theme.palette.red}
                                        size={'medium'}
                                        bold={true}
                                    />
                                </Show>
                            </StyledProductHeroSidebar>
                        </StyledSidebarContainerLine>
                    </StyledSidebarContainer>

                    <StyledMainContent>
                        <Show when={!isTablet()}>
                            <div style={{ 'margin-bottom': '7rem'}}>
                                <DynamicModuleGenerator skipSectionConversion={true} content={[props.mainSectionEyebrow, props.mainSectionHeading]} />
                            </div>
                        </Show>
                        <Grid 
                            templateShorthand={[6, 3]}
                            inheritParentGrid={true}
                            responsive={{
                                largeDesktop: [12],
                            }}
                        >
                            <Column>
                                <DynamicModuleGenerator skipSectionConversion={true} content={[props.mainSectionTextHeading]} />
                                <DynamicModuleGenerator skipSectionConversion={true} content={[props.mainSectionText]} />
                            </Column>
                            <Column customCss={'flex-direction: row;'}>
                                <StyledAnchorLinksContainer>
                                    <For each={props.anchorLinks}>{link => (
                                        <div style={{ 'padding-bottom': '2rem'}}>
                                            <Link 
                                                label={link?.title}
                                                anchorLink={link.url}
                                                url={location.pathname + (link?.url || '#')}
                                                plainStyle={true}
                                                arrowColor={theme.palette.red}
                                                arrowPosition={'left'}
                                                arrowDirection={'down'}
                                                labelColor={theme.palette.darkGray}
                                                size={isTablet() ? 'medium' : 'large'}
                                                bold={true}
                                            />
                                        </div>
                                    )}</For>
                                </StyledAnchorLinksContainer>
                            </Column>
                        </Grid>
                    </StyledMainContent>
                </Grid>
                <Show when={siteInfo?.siteType !== 'atos-care' && !isMobile()}>
                    <StyledSwirlDesktop>
                        <SwirlDesktop />
                    </StyledSwirlDesktop>
                </Show>
            </Section>
            <Show when={siteInfo?.siteType !== 'atos-care' && isMobile()}>
                <StyledProductsHeroAndSwirlContainer>
                    <StyledSwirlMobile>
                        <SwirlMobile />
                    </StyledSwirlMobile>
                </StyledProductsHeroAndSwirlContainer>
            </Show>
        </ErrorCatcher>
    );
};

ProductsHero.parseProps = (atts: any) => {
    const [
        mainSectionEyebrow,
        mainSectionHeading,
        textHeading,
        textBody,
    ] = atts.innerBlocks;
    
    return {
        image: atts.image,
        sidebarText: atts.sidebarText,
        productsLink: atts.productsLink,
        mainSectionEyebrow: mainSectionEyebrow,
        mainSectionHeading: mainSectionHeading,
        mainSectionTextHeading: textHeading,
        mainSectionText: textBody,
        anchorLinks: atts.anchorLinks,
    };
};
