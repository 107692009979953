import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 50 50',
    // width: 29.992
};

export const ShowerIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                id="Path_21810"
                data-name="Path 21810"
                d="M25,0A25,25,0,1,0,50,25,25.028,25.028,0,0,0,25,0Zm0,48.85A23.85,23.85,0,1,1,48.85,25,23.878,23.878,0,0,1,25,48.85Z"
            />
            <path
                id="Path_21811"
                data-name="Path 21811"
                d="M39.44,7.58H33.42a.605.605,0,0,0-.5.28l-2.81,4.82L19.49,13.75a3.941,3.941,0,0,0-3.02,1.95l-1.49,2.57a.549.549,0,0,0-.05.45.6.6,0,0,0,.29.35l19.35,9.7a.583.583,0,0,0,.26.06.693.693,0,0,0,.2-.03.561.561,0,0,0,.33-.31l1.2-2.77a3.957,3.957,0,0,0-.21-3.56l-5.21-8.92,2.62-4.5h5.69a.57.57,0,0,0,.57-.57.576.576,0,0,0-.57-.57Zm-4.9,19.89L16.28,18.32l.94-1.62a.727.727,0,0,0,.14.11L35.3,25.73l-.75,1.74Zm.81-4.74a2.841,2.841,0,0,1,.34,1.91L17.87,15.77h-.02a2.836,2.836,0,0,1,1.77-.88l10.54-1.06,5.19,8.89Z"
            />
            <path
                id="Path_21812"
                data-name="Path 21812"
                d="M15.22,21.61a.479.479,0,0,0,.28-.08l.25-.14a.571.571,0,1,0-.57-.99l-.25.14a.565.565,0,0,0-.21.78.571.571,0,0,0,.5.29Z"
            />
            <path
                id="Path_21813"
                data-name="Path 21813"
                d="M12.49,23.25l.5-.29a.571.571,0,1,0-.57-.99l-.5.29a.565.565,0,0,0-.21.78.571.571,0,0,0,.5.29.479.479,0,0,0,.28-.08Z"
            />
            <path
                id="Path_21814"
                data-name="Path 21814"
                d="M9.97,24.69a.573.573,0,1,0-.56-1l-.5.29a.577.577,0,0,0-.22.78.571.571,0,0,0,.5.29.479.479,0,0,0,.28-.08l.5-.29Z"
            />
            <path
                id="Path_21815"
                data-name="Path 21815"
                d="M7.17,25.62a.565.565,0,0,0-.78-.21l-.5.29a.565.565,0,0,0-.21.78.571.571,0,0,0,.5.29.479.479,0,0,0,.28-.08l.5-.29a.565.565,0,0,0,.21-.78Z"
            />
            <path
                id="Path_21816"
                data-name="Path 21816"
                d="M3.36,27.13l-.25.14a.577.577,0,0,0-.22.78.571.571,0,0,0,.5.29.479.479,0,0,0,.28-.08l.25-.14a.573.573,0,1,0-.56-1Z"
            />
            <path
                id="Path_21817"
                data-name="Path 21817"
                d="M32.85,29.45h0a.57.57,0,0,0-.57.57v.57a.572.572,0,0,0,.57.58h0a.57.57,0,0,0,.57-.57v-.57A.572.572,0,0,0,32.85,29.45Z"
            />
            <path
                id="Path_21818"
                data-name="Path 21818"
                d="M32.83,32.9a.565.565,0,0,0-.58.57v.57a.572.572,0,0,0,.57.58h0a.57.57,0,0,0,.57-.57v-.57a.572.572,0,0,0-.57-.58Z"
            />
            <path
                id="Path_21819"
                data-name="Path 21819"
                d="M32.78,43.24h0a.57.57,0,0,0-.57.57v.57a.572.572,0,0,0,.57.58h0a.57.57,0,0,0,.57-.57v-.57A.572.572,0,0,0,32.78,43.24Z"
            />
            <path
                id="Path_21820"
                data-name="Path 21820"
                d="M32.8,39.79h0a.57.57,0,0,0-.57.57v.57a.572.572,0,0,0,.57.58h0a.57.57,0,0,0,.57-.57v-.57A.572.572,0,0,0,32.8,39.79Z"
            />
            <path
                id="Path_21821"
                data-name="Path 21821"
                d="M32.81,36.34h0a.57.57,0,0,0-.57.57v.57a.572.572,0,0,0,.57.58h0a.57.57,0,0,0,.57-.57v-.57A.572.572,0,0,0,32.81,36.34Z"
            />
            <path
                id="Path_21822"
                data-name="Path 21822"
                d="M17.73,37.31a.575.575,0,0,0-.78.23l-.27.5a.575.575,0,0,0,.23.78.544.544,0,0,0,.27.07.6.6,0,0,0,.51-.3l.27-.5A.575.575,0,0,0,17.73,37.31Z"
            />
            <path
                id="Path_21823"
                data-name="Path 21823"
                d="M19.38,34.29a.575.575,0,0,0-.78.23l-.27.5a.575.575,0,0,0,.23.78.544.544,0,0,0,.27.07.569.569,0,0,0,.5-.3l.27-.5a.575.575,0,0,0-.23-.78Z"
            />
            <path
                id="Path_21824"
                data-name="Path 21824"
                d="M14.44,43.37a.575.575,0,0,0-.78.23l-.17.32a.575.575,0,0,0,.23.78.544.544,0,0,0,.27.07.569.569,0,0,0,.5-.3l.17-.32a.575.575,0,0,0-.23-.78Z"
            />
            <path
                id="Path_21825"
                data-name="Path 21825"
                d="M16.09,40.34a.575.575,0,0,0-.78.23l-.27.5a.575.575,0,0,0,.23.78.544.544,0,0,0,.27.07.569.569,0,0,0,.5-.3l.27-.5a.575.575,0,0,0-.23-.78Z"
            />
            <path
                id="Path_21826"
                data-name="Path 21826"
                d="M22.68,28.23a.575.575,0,0,0-.78.23l-.27.5a.575.575,0,0,0,.23.78.544.544,0,0,0,.27.07.569.569,0,0,0,.5-.3l.27-.5a.575.575,0,0,0-.23-.78Z"
            />
            <path
                id="Path_21827"
                data-name="Path 21827"
                d="M24.28,26.49l.27-.5a.575.575,0,0,0-1.01-.55l-.27.5a.575.575,0,0,0,.23.78.544.544,0,0,0,.27.07.569.569,0,0,0,.5-.3Z"
            />
            <path
                id="Path_21828"
                data-name="Path 21828"
                d="M21.03,31.26a.575.575,0,0,0-.78.23l-.27.5a.575.575,0,0,0,.23.78.544.544,0,0,0,.27.07.569.569,0,0,0,.5-.3l.27-.5a.575.575,0,0,0-.23-.78Z"
            />
            <path
                id="Path_21829"
                data-name="Path 21829"
                d="M13.27,30.21a.57.57,0,0,0-.81,0l-.41.41a.57.57,0,0,0,0,.81.583.583,0,0,0,.41.17.6.6,0,0,0,.41-.17l.41-.41a.57.57,0,0,0,0-.81Z"
            />
            <path
                id="Path_21830"
                data-name="Path 21830"
                d="M10.01,32.64l-.41.41a.57.57,0,0,0,0,.81.583.583,0,0,0,.41.17.6.6,0,0,0,.41-.17l.41-.41a.573.573,0,0,0-.81-.81Z"
            />
            <path
                id="Path_21831"
                data-name="Path 21831"
                d="M7.57,35.07l-.41.41a.57.57,0,0,0,0,.81.583.583,0,0,0,.41.17.6.6,0,0,0,.41-.17l.41-.41a.573.573,0,0,0-.81-.81Z"
            />
            <path
                id="Path_21832"
                data-name="Path 21832"
                d="M15.71,28.59a.573.573,0,1,0-.81-.81l-.41.41a.57.57,0,0,0,0,.81.583.583,0,0,0,.41.17.6.6,0,0,0,.41-.17l.41-.41Z"
            />
            <path
                id="Path_21833"
                data-name="Path 21833"
                d="M20.6,23.73a.573.573,0,0,0-.81-.81l-.41.41a.57.57,0,0,0,0,.81.583.583,0,0,0,.41.17.6.6,0,0,0,.41-.17l.41-.41Z"
            />
            <path
                id="Path_21834"
                data-name="Path 21834"
                d="M18.16,25.35a.57.57,0,0,0-.81,0l-.41.41a.57.57,0,0,0,0,.81.583.583,0,0,0,.41.17.6.6,0,0,0,.41-.17l.41-.41a.57.57,0,0,0,0-.81Z"
            />
            <path
                id="Path_21835"
                data-name="Path 21835"
                d="M24.65,44.17a.566.566,0,0,0-.68.44l-.12.56a.566.566,0,0,0,.44.68c.04,0,.08.01.12.01a.575.575,0,0,0,.56-.45l.12-.56a.566.566,0,0,0-.44-.68Z"
            />
            <path
                id="Path_21836"
                data-name="Path 21836"
                d="M27.58,30.7a.566.566,0,0,0-.68.44l-.12.56a.566.566,0,0,0,.44.68c.04,0,.08.01.12.01a.575.575,0,0,0,.56-.45l.12-.56a.566.566,0,0,0-.44-.68Z"
            />
            <path
                id="Path_21837"
                data-name="Path 21837"
                d="M28.31,27.33a.566.566,0,0,0-.68.44l-.12.56a.566.566,0,0,0,.44.68c.04,0,.08.01.12.01a.575.575,0,0,0,.56-.45l.12-.56A.566.566,0,0,0,28.31,27.33Z"
            />
            <path
                id="Path_21838"
                data-name="Path 21838"
                d="M26.85,34.07a.566.566,0,0,0-.68.44l-.12.56a.566.566,0,0,0,.44.68c.04,0,.08.01.12.01a.575.575,0,0,0,.56-.45l.12-.56a.566.566,0,0,0-.44-.68Z"
            />
            <path
                id="Path_21839"
                data-name="Path 21839"
                d="M25.38,40.8a.566.566,0,0,0-.68.44l-.12.56a.566.566,0,0,0,.44.68c.04,0,.08.01.12.01a.575.575,0,0,0,.56-.45l.12-.56a.566.566,0,0,0-.44-.68Z"
            />
            <path
                id="Path_21840"
                data-name="Path 21840"
                d="M26.12,37.44a.566.566,0,0,0-.68.44l-.12.56a.566.566,0,0,0,.44.68c.04,0,.08.01.12.01a.575.575,0,0,0,.56-.45l.12-.56a.566.566,0,0,0-.44-.68Z"
            />
        </SvgIcon>
    );
};
