import { Show, useContext } from 'solid-js';
import { AppContext } from '../../../../../app-context-provider/app-context-provider';
import { Heading } from '../../../../../ui-components/heading/heading';
import { StyledRelativeContainer } from '../../../../../ui-components/utility-style-components/positioning';
import { CalendarIcon } from '../../../../icons-library/calendar';
import { StyledDateLocationHeader, StyledHeadContainer, StyledHeader, StyledHeadingAndRegistrationContainer, StyledHeadingContainer, StyledPdfEventDate, StyledPdfEventDateText, StyledPdfEventHeader, StyledPdfEventMetaInfo, StyledQrCodeForEvent, StyledRegistrationContainer } from './event-components.style';
import { ComponentPiece } from '../../pdf-renderer/pdf-renderer';
import { PdfIconTab } from '../post-icon-tab/icon-tab';
import { extractBlocks } from '../extract-blocks';
import { EventData } from '../../../../event/event-types';
import { CompassIcon } from '../../../../icons-library/compass';
import { generateQRCode } from '../generate-qr';
import { formatDateDigits, formatLongDate } from '../../../../../tools/date-format';
import { StyledFlexRow } from '../../../../../ui-components/utility-style-components/flex';
import { SpacerPdf } from '../blocks/spacer/spacer-pdf';


const header: ComponentPiece = {
    elements: (data: EventData) => {
        const { localize, siteInfo } = useContext(AppContext);

        const isActiveEvent = () => {
            /**
             * eventExpiryDate takes precedence over eventDate - one is for on-demand events, 
             * the other for live events, but nothing prevents editors from setting both
             */
            const eventDate = data.eventExpireDate || data.eventDate;
            if (!eventDate) return true;
    
            const today = new Date();
            today.setHours(0, 0, 0, 0);
    
            const eventDateObj = new Date(eventDate);
            eventDateObj.setHours(0, 0, 0, 0);
    
            return eventDateObj >= today;
        };
    
        const showRegistrationLink = () => {
            if (data?.isFullyBooked) return false;
            if (!isActiveEvent()) return false;
    
            return data?.eventRegistrationLink?.url;
        };    
        
        return (
            <>
                <PdfIconTab type={'event'} label={data?.eventDate ? formatLongDate(data.eventDate, siteInfo.key) : localize('event', 'Event')} />
                <StyledPdfEventHeader>
                    <SpacerPdf height={4} />
                    <StyledHeader>
                        <StyledHeadContainer>
                            <StyledDateLocationHeader>
                                <StyledFlexRow gap='1.5rem'>

                                    <StyledPdfEventDate>
                                        <StyledRelativeContainer top={-3}>
                                            <CalendarIcon height={2.21} width={1.79} fill='black' />
                                        </StyledRelativeContainer>
                                        <StyledPdfEventDateText>{
                                            data.eventDate
                                                ? formatDateDigits(data.eventDate, siteInfo.key)
                                                : ''
                                        }</StyledPdfEventDateText>
                                    </StyledPdfEventDate>

                                    <StyledPdfEventDate>
                                        <StyledRelativeContainer>
                                            <CompassIcon height={2.21} width={1.79} fill='black' />
                                        </StyledRelativeContainer>
                                        <StyledPdfEventDateText>{data?.eventLocation}</StyledPdfEventDateText>
                                    </StyledPdfEventDate>

                                </StyledFlexRow>
                            </StyledDateLocationHeader>
                            <StyledHeadingAndRegistrationContainer>

                                <StyledHeadingContainer>
                                    <Heading tag={'h1'} variant='xlargeBoldPDF'>{data.post_title}</Heading>
                                </StyledHeadingContainer>

                                <StyledPdfEventMetaInfo>
                                    <Show when={showRegistrationLink()}>
                                        <StyledRegistrationContainer>
                                            <StyledFlexRow>
                                                <Heading tag={'h2'} variant='bigRedPDF'>{localize('register-now', 'Register now')}</Heading>
                                            </StyledFlexRow>
                                            <StyledQrCodeForEvent innerHTML={generateQRCode( data.eventRegistrationLink?.url )} />
                                        </StyledRegistrationContainer>
                                    </Show>
                                </StyledPdfEventMetaInfo>

                            </StyledHeadingAndRegistrationContainer>
                        </StyledHeadContainer>
                    </StyledHeader>
                </StyledPdfEventHeader>
            </>
        );
    }
};

const body: ComponentPiece = {
    canBeBrokenUp: true,
    elements: (data: EventData) => {
        const context = useContext(AppContext);

        return extractBlocks(context, {
            title: data.post_title,
            ...data,
        });
    }
};

export const components = [
    header,
    body
];