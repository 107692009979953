/* eslint-disable indent */
import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosRegular } from '../../style/theme';

const { palette, colors } = theme;

export const StyledQuoteOuter = styled.div``;

export const StyledHeadingWrapper = styled.div`
    margin-bottom: 2rem;
    ${theme.mediaQueries.mobileMax} {
        padding-inline: 2rem;
    }
`;

export const StyledQuoteContent = styled.div`
    container-type: inline-size;
    background-color: white;
    border-radius: 1.43rem;

    ${theme.mediaQueries.mobileMax} {
        padding: 0;
    }
`;

export const StyledRoundImageWrapper = styled.div`
    position: relative;
    width: 45%;
    padding-bottom: 45%;
    border-radius: 50%;
    background: lightblue;
    align-self: center;
    overflow: hidden;

    ${theme.mediaQueries.desktopSmallMax} {
        width: 45%;
        padding-bottom: 45%;
    }

    @media only screen and (max-width: ${theme.breakpoints.ORIGINAL_TABLET}px) {
        width: 60%;
        padding-bottom: 60%;
    }

    @media only screen and (max-width: 555px) {
        width: 85%;
        padding-bottom: 85%;
    }

    ${theme.mediaQueries.mobileMax} {
        width: 70%;
        padding-bottom: 70%;
    }
`;

export const StyledImage = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
`;

export const StyledAuthorContainer = styled.div`
    display: flex;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.ORIGINAL_TABLET}px) {
        flex-direction: column;
    }
`;


export const StyledAuthorName = styled.div`
    font-weight: bold;
`;

export const StyledAuthorTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    max-width: 15rem;
    padding-left: 2.5rem;

    @media only screen and (max-width: ${theme.breakpoints.ORIGINAL_TABLET}px) {
        max-width: unset;
        padding-left: unset;
        padding-top: 1rem;
    }
    
    h4 {
        ${theme.mediaQueries.mobileMin} {
            margin-bottom: 0;
        }
        ${theme.mediaQueries.mobileMax} {
            margin-bottom: 0.5rem;
        }
    }
`;

export const StyledSignature = styled.p`
    font-weight: 400;
    font-family: ${fontFamilyAtosRegular};
    font-size: 1.35rem;
    line-height: 2rem;
    letter-spacing: 0;
    color: ${palette.darkGray};
    margin: 0;
`;

export const StyledQuoteWrapper = styled.div`
    margin: auto;
    display: flex;
    align-self: flex-start;
    gap: 0 2rem;

    @media only screen and (max-width: ${theme.breakpoints.ORIGINAL_TABLET}px) {
        flex-direction: column;
    }

    @media only screen and (max-width: 555px) {
        margin-left: -1.5rem;
        max-width: 17rem;
    }

    ${theme.mediaQueries.mobileMax} {
        flex-direction: column;
        margin-top: 1.5rem;
        max-width: unset;
        margin-left: unset;
    }
`;

export const StyledOpeningQuoteSvgContainer = styled.div``;

export const StyledQuoteContainer = styled.div`
    padding-block: 1rem;
`;

export const StyledClosingQuoteSvgContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: end;
`;

export const StyledQuote = styled.p<any>`
    font-weight: 400;
    font-family: ${fontFamilyAtosRegular};
    font-size: 2.71rem;
    line-height: 3.93rem;
    letter-spacing: 0;
    margin: 0;
    margin-bottom: 1rem;
    ${theme.mediaQueries.mobileMax} {
        margin-bottom: 0;
    }
    color: ${(p) => (p.color ? p.color : colors.text.quote)};
`;
