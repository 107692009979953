import { Show, useContext } from 'solid-js';
import theme from '../../../../../style/theme';
import { Heading } from '../../../../../ui-components/heading/heading';
import { ArrowRightIcon } from '../../../../icons-library/arrow-right';
import { StyledQrCode } from '../blocks/video/video-pdf.style';
import { generateQRCode } from '../generate-qr';
import { prettifyUrl } from '../pretty-url';
import { StyledDocumentPdfCardWrapper, StyledDocumentPdfCardInner, StyledDocumentPdfTextContainer, StyledDocumentPdfTextInnerContainer, StyledArrowRighContainer, StyledDocumentDownloadTextPdf } from './document-component.style';
import { Text } from '../../../../../ui-components/text/text';
import { DocumentType } from '../../../../product/product-types';
import { AppContext } from '../../../../../app-context-provider/app-context-provider';
import { StyledVerticalSpace } from '../../../../../ui-components/utility-style-components/spacing';
import { formatTextDate } from '../../../../../tools/date-format';
import { StyledFlexRow } from '../../../../../ui-components/utility-style-components/flex';

export const DocumentPDF = (props: { doc: DocumentType }) => {
    const { localize, siteInfo } = useContext(AppContext);

    const { doc } = props;
    
    return (
        <Show when={doc?.file?.url}>
            <StyledDocumentPdfCardWrapper>
                <StyledDocumentPdfCardInner>
                    <StyledDocumentPdfTextContainer>
                        <StyledDocumentPdfTextInnerContainer>
                            <Heading tag="h5" variant="mediumPDF">
                                {doc?.file?.title}
                            </Heading>
                            <Text fontSize="normalPDF" lineLimit={4} noBlockSpacing>
                                {doc?.file?.filename}
                            </Text>
                            <StyledVerticalSpace size={0.5} />
                            <StyledFlexRow justifyContent='space-between'>
                                <Text fontSize="normalPDF" lineLimit={4}>
                                    {formatTextDate(doc?.file?.date, siteInfo.key)}
                                </Text>
                                <StyledDocumentDownloadTextPdf>
                                    <Heading tag="h5" variant="tinyRedPDF" noBlockSpacing={true}>
                                        {localize('pdf-download-document', 'Download document')}
                                    </Heading>
                                    <StyledArrowRighContainer>
                                        <ArrowRightIcon fill={theme.palette.red} width={1.2} />
                                    </StyledArrowRighContainer>
                                </StyledDocumentDownloadTextPdf>
                            </StyledFlexRow>
                        </StyledDocumentPdfTextInnerContainer>
                    </StyledDocumentPdfTextContainer>
                    <StyledQrCode innerHTML={generateQRCode(`${siteInfo.protocol}://${prettifyUrl(doc?.file?.url, siteInfo)}`) || ''} />
                </StyledDocumentPdfCardInner>
            </StyledDocumentPdfCardWrapper>
        </Show>
    );
};
