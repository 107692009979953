import { theme } from '../style/theme';
import { convertRemToPixel } from './html-rem-tools';
import headerStore from '../stores/header-store';

type NavigationProps = {
    event?: any;
    id: string;
    smallScreen?: boolean;
    isCareSite: boolean;
}

export const navigateToAnchor = (props: NavigationProps ) => {
    const { floatHeader } = headerStore;
    
    if (!props.id) {
        return;
    }

    const parsedId = props.id.includes('#') ? props.id.replace('#', '') : props.id;
    
    if (props.event) {
        props.event.preventDefault();
    }

    const scrollToElement = (element: HTMLElement) => {
        const { 
            fixed,
            floating,
            fixedAtosCare,
            floatingAtosCare,
        } = theme.header.desktop.height;

        const { value: mobileHeaderHeight } = theme.header.mobile.height;

        const desktopFloatHeight = props.isCareSite ? floatingAtosCare.value : floating.value;
        const desktopFixedHeight = props.isCareSite ? fixedAtosCare.value : fixed.value;

        let headerAdjustment;
        if (props.smallScreen) {
            headerAdjustment = mobileHeaderHeight;
        } else {
            headerAdjustment = floatHeader() ? desktopFloatHeight : desktopFixedHeight;
        }
        
        const offsetTop = element.getBoundingClientRect().top - document.documentElement.getBoundingClientRect().top;

        window.scrollTo({
            top: offsetTop - convertRemToPixel(headerAdjustment),
            behavior: 'smooth'
        });
    };

    const element = document.getElementById(parsedId);
    
    if (element) {
        scrollToElement(element);
    } else {
        const observer = new MutationObserver((mutations, obs) => {
            const element = document.getElementById(parsedId);
            if (element) {
                scrollToElement(element);
                obs.disconnect();
                clearTimeout(timeoutId); // Clear the timeout if element is found
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });

        // Set a timeout to disconnect the observer after 5 seconds
        const timeoutId = setTimeout(() => {
            observer.disconnect();
        }, 5000);
    }

    // Get the current URL and append the anchor id
    const url = new URL(window.location.href);
    url.hash = parsedId;
    const newUrl = url.toString();
    
    // Update the URL without reloading the page or scrolling
    history.replaceState({}, '', newUrl);
};
