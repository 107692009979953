import { createSignal, onMount, onCleanup, Accessor } from 'solid-js';

type Refs = {
    firstTabbableElement: Accessor<HTMLElement | undefined>;
    lastTabbableElement: Accessor<HTMLElement | undefined>;
    lastChildOfLastElement?: Accessor<HTMLElement | undefined>;
};

type TabHandlerProps = { 
    refs: Refs;
    lastElementIsAMenuAndIsOpen?: Accessor<boolean>;
    children: any
};

export const TabHandler = (props: TabHandlerProps) => {
    const [ tabbedCount, setTabbedCount ] = createSignal(0);

    const { 
        firstTabbableElement,
        lastTabbableElement,
        lastChildOfLastElement
    } = props.refs;

    onMount(() => document.addEventListener('keydown', keyDownHandler));
    onCleanup(() => document.removeEventListener('keydown', keyDownHandler));

    const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key !== 'Tab') return;

        tabHandler(event);
    };

    /**
     * Ensure tab navigation stays within the menu context
     */
    const tabHandler = (event: KeyboardEvent) => {        
        if (tabbedCount() === 0) {
            handleInitialTabbedEntry(event);
            setTabbedCount(1);
        }
    
        if (event.target === firstTabbableElement()) {
            handleFirstGroupNavigationOut(event);
        }
    
        const isTargetLastElement = event.target === lastTabbableElement();
        const isTargetLastChildOfLastElement = event.target === (lastChildOfLastElement && lastChildOfLastElement());
        const isLastElementMenuOpen = props.lastElementIsAMenuAndIsOpen && props.lastElementIsAMenuAndIsOpen();
    
        const isLastElementNavigationOut = 
            (isTargetLastElement && !isLastElementMenuOpen) ||
            (isTargetLastChildOfLastElement && isLastElementMenuOpen);

        if (isLastElementNavigationOut) {
            handleLastGroupNavigationOut(event);
        }
    };

    const handleInitialTabbedEntry = (event: KeyboardEvent) => {        
        event.preventDefault();
    
        if (!firstTabbableElement()) return;
    
        if (firstTabbableElement()) {
            firstTabbableElement()!.tabIndex = -1;
            firstTabbableElement()!.focus();
            firstTabbableElement()!.tabIndex = 0;
        }
    };
    
    /**
     * Handle moving focus from first tabbable element to the last tabbable element.
     */
    const handleFirstGroupNavigationOut = (event: KeyboardEvent) => {
        if (!event.shiftKey) return;
    
        const elementToFocus = props.lastElementIsAMenuAndIsOpen && props.lastElementIsAMenuAndIsOpen() 
            ? lastChildOfLastElement
            : lastTabbableElement;
    
        if (!elementToFocus || !elementToFocus()) return;
    
        event.preventDefault();
    
        elementToFocus()!.tabIndex = -1;
        elementToFocus()!.focus();
        elementToFocus()!.tabIndex = 0;
    };
    
    /**
     * Handle moving focus from last tabbable element to the first tabbable element.
     */
    const handleLastGroupNavigationOut = (event: KeyboardEvent) => {
        if (event.shiftKey) return;
    
        event.preventDefault();
    
        if (firstTabbableElement()) {
            firstTabbableElement()!.tabIndex = -1;
            firstTabbableElement()!.focus();
            firstTabbableElement()!.tabIndex = 0;
        }
    };

    return (
        <>
            {props.children}
        </>
    );
};
