import { For, useContext } from 'solid-js';
import { CrossIcon } from '../../../icons-library/cross';
import {
    StyledMenuHeader,
    StyledMenuHeaderLink,
    StyledMenuHeaderLinks,
    StyledMenuHeaderIconLinks,
    StyledCloseButton,
    StyledCloseButtonText,
    StyledMenuHeaderRight,
} from './menu-header.styles';
import type { LinkType, MenuHeaderProps } from '../../menu-header-types';
import urlMatches from '../../../../tools/url-match';
import { removeTrailingSlash } from '../../../../tools/remove-trailing-slash';
import { AppContext } from '../../../../app-context-provider/app-context-provider';
import { HeaderNavigationShortcuts } from '../../../header/header-navigation-shortcuts';
import removeAmpersand from '../../../../tools/remove-ampersand';

export const MenuHeader = (props: MenuHeaderProps) => {
    const { appStore, localize } = useContext(AppContext);
    const { close } = appStore;

    return (
        <StyledMenuHeader>
            <StyledMenuHeaderLinks>
                <For each={props.links}>
                    {(link: LinkType) => (
                        <StyledMenuHeaderLink onClick={close} href={removeTrailingSlash(link.url)} active={urlMatches(link.url)}>
                            {removeAmpersand(link.title)}
                        </StyledMenuHeaderLink>
                    )}
                </For>
            </StyledMenuHeaderLinks>
            <StyledMenuHeaderRight>
                <StyledMenuHeaderIconLinks>
                    <HeaderNavigationShortcuts />
                </StyledMenuHeaderIconLinks>
                <StyledCloseButton onClick={() => props.handleCloseMenuClick()}>
                    <CrossIcon fill="white" width={1.79} height={1.79} />
                    <StyledCloseButtonText>{localize('close', 'Close')}</StyledCloseButtonText>
                </StyledCloseButton>
            </StyledMenuHeaderRight>
        </StyledMenuHeader>
    );
};
