import { styled } from 'solid-styled-components';
import { ProductIconName } from './product-icon-tab-types';
import theme from '../../../style/theme';

const {spacing, containerQueries, palette} = theme;

export const backgroundColorsByIconName = {
    'home': palette.darkGray,
    'go': palette.lightRed,
    'energy': palette.lightBlue,
    'free-hands': palette.darkGreen,
    'night-hme': palette.darkBlue,
    'protect': palette.lightColdGray,
    'shower': palette.lightPurple,
    'night-adhesive': palette.darkBlue,
    'standard-adhesive': palette.darkColdGray,
    'stability-adhesive': palette.orange,
    'sensitive-adhesive': palette.green,
    'larytube': palette.darkPurple,
    'larybutton': palette.lightColdGray,
    'tracoe-neonates': palette.brightGreen,
    'tracoe-pediatric': palette.brightGreen,
};

export const StyledIconTab = styled.div<StyledIconTabProps>`
    ${p => p.type === 'product-intro' ? `
        top: ${p.top ? p.top : spacing.xxxl};
        right: 0;

        ${containerQueries.desktopMax} {

            right: ${Math.floor(theme.grid.standardUnit * 1.5 * -1)}rem;
            top: ${p.top ? p.top : '0'};
        }

        ${containerQueries.mobileMax} {
            right: 0px;
            top: 0px;
        }
    ` : `
        top: ${p.top ? p.top : spacing.xxxl};
        right: 0;

        ${containerQueries.mobileMax} {
            top: ${p.top ? p.top : '20rem'};
        }
    `}

    position: absolute;
    border-radius: 1.43rem 0 0 1.43rem;
    background-color: ${p => backgroundColorsByIconName[p.iconName]};
    height: ${p => p.height ? p.height : '5rem'};
    width: ${p => p.width ? p.width : '6.43rem'};
    box-sizing: border-box;
    padding: 0.71rem;
    z-index: 1;
    display: flex;
    align-items: center;

    ${containerQueries.mobileMax} {
        height: 3.93rem;
        width: 4.29rem;
        padding: 0 0.71rem;
        border-radius: 0.71rem 0 0 0.71rem;
    }
`;

type StyledIconTabProps = {
    iconName: ProductIconName;
    width?: string;
    top?: string;
    type?: 'product-intro';
    height?: string;
};
