import { For, Show, useContext } from 'solid-js';
import { FirstLevelMenuItemLink } from './first-level-menu-item-link/first-level-menu-item-link';
import { FirstLevelMenu } from './first-level-menu/first-level-menu';
import { StyledCalendarIconContainer, StyledPrimaryMenu, StyledPrimaryMenuList } from './primary-menu.styles';
import { AppContext } from '../../../../app-context-provider/app-context-provider';

import type { MenuItem, PrimaryMenuProps } from '../../types/primary-menu-types';
import { gql } from 'graphql-request';
import { CalendarIcon } from '../../../icons-library/calendar';

const GET_HEADER_QUERY = gql`
    query GetHeader($key: String!) {
        header: resource(key: $key) {
            content
            key
        }
    }
`;

export const PrimaryMenu = (props: PrimaryMenuProps) => {
    const { appStore, createCachedResource, localize, } = useContext(AppContext);
    const { close } = appStore;

    const [headerContent] = createCachedResource(GET_HEADER_QUERY, () => ({ key: '/wp-json/rawb/v1/header' }), true);

    return (
        <StyledPrimaryMenu>
            <StyledPrimaryMenuList>
                <For each={props.menu}>
                    {(menuItem: MenuItem) => {
                        if (!menuItem.children) {
                            return (
                                <li>
                                    <FirstLevelMenuItemLink link={menuItem} onLinkClick={close} />
                                </li>
                            );
                        } else {
                            return (
                                <FirstLevelMenu parent={menuItem} onLinkClick={close} />
                            );
                        }
                    }}
                </For>

                <Show when={appStore.isHcp()}>
                    <Show when={headerContent()?.header?.content?.headerHcpContactLink?.url}>
                        <FirstLevelMenuItemLink
                            link={headerContent()?.header?.content?.headerHcpContactLink}
                            onLinkClick={close}
                        />
                    </Show>

                    <Show when={headerContent()?.header?.content?.headerHcpEventsLink?.url}>
                        <FirstLevelMenuItemLink
                            link={headerContent()?.header?.content?.headerHcpEventsLink}
                            onLinkClick={close}
                            icon={(
                                <StyledCalendarIconContainer>
                                    <CalendarIcon
                                        height={1.8}
                                        width={1.35}
                                        altText={localize('header_calendar_icon', 'Header Calendar Icon')}
                                        aria-label={localize('header_calendar_icon', 'Header Calendar Icon')}
                                    />
                                </StyledCalendarIconContainer>
                            )}
                        />
                    </Show>
                </Show>
            </StyledPrimaryMenuList>
        </StyledPrimaryMenu>
    );
};
