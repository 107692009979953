import { styled } from 'solid-styled-components';
import theme from '../../../../../style/theme';

export const StyledDocumentPdfCardWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 100%;
    border: 1px solid ${theme.palette.lightGray};
    border-radius: ${theme.borderRadius.medium};
    background-color: ${theme.palette.white};
    color: unset;
    text-decoration: unset;
    padding: 1.5rem;
    padding-right: 0.6rem;
    margin-bottom: 1.5rem;
`;

export const StyledDocumentPdfCardInner = styled.div`
    display: flex;
    height: 100%;
    gap: 1.1rem;
`;

export const StyledDocumentPdfTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 0.5rem;
`;

export const StyledArrowRighContainer = styled.div`
    margin-top: 0.2rem;
`;

export const StyledDocumentPdfTextInnerContainer = styled.div``;

export const StyledDocumentDownloadTextPdf = styled.div`
    display: flex;
    gap: 0.5rem;
    align-self: flex-end;
    padding-left: 0.73rem;
    transform: translate(0, -10px);
    align-items: center;
`;

export const StyledDocumentArrowRighContainer = styled.div`
    margin-top: 0.2rem;
`;