/* eslint-disable indent */
import { Link } from '@solidjs/router';
import { gql } from 'graphql-request';
import { For, Show, onCleanup, onMount, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Grid } from '../../../grid-system/grid/grid';
import headerStore from '../../../stores/header-store';
import theme from '../../../style/theme';
import { ErrorCatcher } from '../../../tools/error-catcher';
import urlMatches from '../../../tools/url-match';
import { Heading } from '../../../ui-components/heading/heading';
import { AtosLogo } from '../../icons-library/atos-logo-desktop';
import { MenuBarsIcon } from '../../icons-library/menu-bars';
import {
    StyledTopRow,
    InnerHeaderWrapper,
    StyledHeader,
    StyledIconContainer,
    StyledLink,
    StyledMenuButton,
    StyledMenuText,
    StyledTopRowMiddleColumn,
    StyledSideNav,
    StyledTopRowRightColumn,
    StyledAngleDownContainer,
    InnerUpperBackground,
    InnerLowerBackground,
    StyledBottomRow,
    FrostedBackground,
    StyledInnerGrid,
    StyledIconContainerInner,
    StyledTest,
} from './hcp-header.style';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../tools/remove-ampersand';
import { GET_PRIMARY_MENU_QUERY } from '../../menu/menu';
import { PrimaryMenuData } from '../../menu/types/primary-menu-types';
import { HeaderHcpDropdownMenu } from './hcp-header-dropdown-menu';
import { CalendarIcon } from '../../icons-library/calendar';
import { StyledHorizontalSpace } from '../../../ui-components/utility-style-components/spacing';
import { AngleDownIcon } from '../../icons-library/angle-down';
import clsx from 'clsx';
import { HeaderNavigationShortcuts } from '../header-navigation-shortcuts';

const GET_HEADER_QUERY = gql`
    query GetHeader($key: String!) {
        header: resource(key: $key) {
            content
            key
        }
    }
`;

type HeaderLink = {
    title: string;
    url: string;
};

export const HeaderMedicalHcpContainer = () => {
    const { appStore, createCachedResource, viewport, localize } = useContext(AppContext);

    const [headerContent] = createCachedResource(GET_HEADER_QUERY, () => ({ key: '/wp-json/rawb/v1/header' }), true);
    const [primaryMenuContent] = createCachedResource(GET_PRIMARY_MENU_QUERY, () => ({ key: '/wp-json/content/v1/menus/primary_menu_hcp' }), true);

    const primaryMenuData = (): PrimaryMenuData => primaryMenuContent()?.menu?.content;

    const isOriginalTablet = () => viewport.width <= theme.breakpoints.ORIGINAL_TABLET;

    const { open } = appStore;
    const { floatHeader, setFloatHeader } = headerStore;

    let headerRef: any;

    onMount(() => {
        const pageAtTopObserver = new IntersectionObserver(
            (payload) => {
                const pageAtTop = payload[0].isIntersecting;
                setFloatHeader(!pageAtTop);
            },
            {
                threshold: 1,
                rootMargin: '-1px',
            }
        );

        if (headerRef) {
            pageAtTopObserver.observe(headerRef);
        }

        // TODO: shall be unobserve I think?
        onCleanup(() => pageAtTopObserver.unobserve(headerRef));
    });

    return (
        <ErrorCatcher componentName="Header">
            <StyledHeader floatHeader={floatHeader()} >
                <Show when={headerContent()?.header?.content?.headerLinks}>

                    <Grid templateShorthand={[ 12 ]} customCss={'height: inherit; position: relative;'} isLayoutGrid={true}>
                        <StyledInnerGrid>
                            <FrostedBackground
                                floatHeader={floatHeader()}
                                hasHero={appStore.hasHero()}
                                id='frosted-background'
                            />

                            <InnerHeaderWrapper id='inner-header-wrapper'>
                                <StyledTopRow floatHeader={floatHeader()} id='header-top-row'>
                                    <InnerUpperBackground floatHeader={floatHeader()}/>
                                    <div>
                                        <div class="left-column">
                                            <div
                                                class={clsx('w-[12.182rem] h-[6.1rem] ease-in-out duration-200', {
                                                    'w-[8rem] h-[4rem]': floatHeader() || isOriginalTablet(),
                                                })}
                                            >
                                                <Link href="/">
                                                    <AtosLogo width="100%" height="100%" aria-label={localize('atos_medical_logotype', 'Atos Medical Logo')} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <StyledTopRowMiddleColumn floatHeader={floatHeader()}>
                                        <For each={headerContent().header.content.headerLinks}>
                                            {(link: HeaderLink, index) => {
                                                if (!link || !link.title || !link.url) {
                                                    return null;
                                                }

                                                return (
                                                    <StyledLink 
                                                        class="uppercase"
                                                        isHcpHomeLink={index() === 1}
                                                        floatHeader={floatHeader()}
                                                    >
                                                        <Link href={removeTrailingSlash(link.url)}>
                                                            {link?.title ? removeAmpersand(link.title) : null}
                                                        </Link>
                                                        <Show when={index() === 1}>
                                                            <StyledAngleDownContainer floatHeader={floatHeader()}>
                                                                <AngleDownIcon fill={theme.palette.red} />
                                                            </StyledAngleDownContainer>
                                                        </Show>
                                                    </StyledLink>
                                                );
                                            }}
                                        </For>
                                    </StyledTopRowMiddleColumn>
                                    <StyledTopRowRightColumn>
                                        <HeaderNavigationShortcuts />

                                        <Show when={isOriginalTablet()}>
                                            <StyledMenuButton onClick={open} tabIndex="0">
                                                <MenuBarsIcon
                                                    altText="Open menu"
                                                    height={1.9}
                                                />
                                                <StyledMenuText>
                                                    <Heading tag="span" variant="medium" noBlockSpacing={true}>
                                                        {localize('menu', 'Menu')}
                                                    </Heading>
                                                </StyledMenuText>
                                            </StyledMenuButton>
                                        </Show>
                                    </StyledTopRowRightColumn>
                                </StyledTopRow>

                                <StyledBottomRow floatHeader={floatHeader()} id='header-bottom-row'>
                                    <InnerLowerBackground 
                                        floatHeader={floatHeader()}
                                        hasHero={appStore.hasHero()}
                                    />

                                    <Show when={!appStore.hasHero()}>
                                        <StyledTest id="styled-test" floatHeader={floatHeader()} />
                                    </Show>

                                    <HeaderHcpDropdownMenu primaryMenuData={primaryMenuData()} floatHeader={floatHeader()} />

                                    <StyledSideNav floatHeader={floatHeader()}>
                                        <Show when={headerContent()?.header?.content?.headerHcpContactLink?.url}>
                                            <Link href={removeTrailingSlash(headerContent()?.header?.content?.headerHcpContactLink.url)}>
                                                <StyledIconContainer
                                                    active={urlMatches(headerContent()?.header?.content?.headerHcpContactLink.url)}
                                                    floatHeader={floatHeader()}
                                                >
                                                    <span>{removeAmpersand(
                                                        headerContent()?.header?.content?.headerHcpContactLink.title
                                                    )}</span>
                                                </StyledIconContainer>
                                            </Link>
                                        </Show>

                                        <Show when={headerContent()?.header?.content?.headerHcpEventsLink?.url}>
                                            <Link href={removeTrailingSlash(headerContent()?.header?.content?.headerHcpEventsLink?.url)}>
                                                <StyledIconContainer
                                                    active={urlMatches(headerContent()?.header?.content?.headerHcpEventsLink?.url)}
                                                    floatHeader={floatHeader()}
                                                >
                                                    <StyledIconContainerInner floatHeader={floatHeader()}>
                                                        <CalendarIcon 
                                                            height={2}
                                                            width={1.5}
                                                            altText={localize('header_calendar_icon', 'Header Calendar Icon')}
                                                            aria-label={localize('header_calendar_icon', 'Header Calendar Icon')}
                                                        />
                                                    </StyledIconContainerInner>
                                                    <StyledHorizontalSpace size={0.6} />
                                                    <span>{removeAmpersand(
                                                        headerContent()?.header?.content?.headerHcpEventsLink?.title
                                                    )}</span>
                                                </StyledIconContainer>
                                            </Link>
                                        </Show>
                                    </StyledSideNav>
                                </StyledBottomRow>

                            </InnerHeaderWrapper>
                        </StyledInnerGrid>
                    </Grid>
                </Show>
            </StyledHeader>
            <div ref={headerRef} />
        </ErrorCatcher>
    );
};
