import { createRoot, createSignal, onMount } from 'solid-js';
import { getCookie } from '../tools/cookie-helpers';

function createMyContentStore() {
    const [myContentHasItems, setMyContentHasItems] = createSignal(false);

    onMount(() => {
        const cookieValue = getCookie('myContent');

        if (cookieValue === '[]' || !cookieValue) {
            setMyContentHasItems(false);
        } else {
            setMyContentHasItems(true);
        }
    });

    return {
        myContentHasItems,
        setMyContentHasItems,
    };
}

export default createRoot(createMyContentStore);
