import { gql } from 'graphql-request';
import { createMemo, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { BlogNewsData } from '../blog-news/blog-news';
import { BlogsNewsProps, PostContainer } from './blogs-news';
import { PostCardData } from '../post-card/post-card-types';

const GET_POSTS_QUERY = gql`
    query GetPosts(
        $tags: [String]
        $limit: Int
    ) {
        posts: resources(
            type: "blog-and-news"
            tags: $tags
            limit: $limit
            order: DESC
        ) {
            content
        }
    }
`;

export const fetchBlogsAndNews = (props: BlogsNewsProps) => {
    const { createCachedResource } = useContext(AppContext);

    const POST_QUERY_VARS = {
        ...props?.tag?.slug
            ? { tags: [props?.tag?.slug] }
            : null,
        ...props?.limit
            ? { limit: props?.limit }
            : null,
    };

    try {
        const [postsContent] = createCachedResource(GET_POSTS_QUERY, POST_QUERY_VARS, true);
    
        return createMemo((): BlogNewsData[] => postsContent()?.posts
            ?.map((post: PostContainer) => {
                return post.content;
            })
            .filter((content: BlogNewsData) => {
                return !content?.hideOnWeb;
            })
            .sort((a: PostCardData, b: PostCardData) => {
                // Newest show first TODO: does this work?
                // AS: yes it does work, why not?
                const dateA = a.date ? new Date(a.date).getTime() : 0; // Use 0 as default if a.date is undefined
                const dateB = b.date ? new Date(b.date).getTime() : 0; // Use 0 as default if b.date is undefined
                return dateB - dateA; // Reverse order to show newest first
            }));
    } catch (err) {
        console.log('Error fetching blogs and news: ', err);
        throw err;        
    }
    
};
