import { ErrorCatcher } from '../../tools/error-catcher';
import { StyledViewStyleButton } from './view-style-button.styles';
import { ListViewStyleIcon } from '../../components/icons-library/list-view-style';
import { GalleryViewStyleIcon } from '../../components/icons-library/gallery-view-style';

type ButtonProps = {
    type: 'list' | 'gallery';
    text: string;
    onClick: (args?: any) => void;
    isActive: boolean;
};

export const ViewStyleButton = (props: ButtonProps) => {
    const icon = props.type === 'list' ? <ListViewStyleIcon /> : <GalleryViewStyleIcon />;

    return (
        <ErrorCatcher componentName="ListStyleButton">
            <StyledViewStyleButton onClick={props.onClick} isActive={props.isActive}>
                {icon}
                <span>{props.text}</span>
            </StyledViewStyleButton>
        </ErrorCatcher>
    );
};
