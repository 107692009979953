import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 135 44.85',
    width: 11.25,
    height: 3.74,
};

export const AtosLogoPdfFooter = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <g>
                <g>
                    <path fill="#F0414B" d="M48.25,10.47V0.62h9.32v9.85h8.69v7.82h-8.69v13.93c0,2.94,2.01,3.95,4.59,3.95c1.79,0,4.24-1.01,4.24-1.01
                        v8.61c0,0-3.66,1.08-7.32,1.01c-7.9-0.14-11.06-3.88-11.06-11.99v-14.5h-6.25v-7.82H48.25z"/>
                    <path fill="#F0414B" d="M69.62,27.34c0-10.55,8.04-17.44,18.16-17.44c10.12,0,18.23,6.89,18.23,17.44c0,10.55-8.11,17.44-18.23,17.44
                        C77.66,44.78,69.62,37.89,69.62,27.34z M96.4,27.26c0-5.24-3.8-8.47-8.47-8.47c-4.74,0-8.69,3.23-8.69,8.47
                        c0,5.1,3.8,8.9,8.54,8.9C92.45,36.17,96.4,32.36,96.4,27.26z"/>
                    <path fill="#F0414B" d="M121.14,37.1c2.3,0,3.8-1.08,3.8-2.73c0-2.01-1.29-2.51-5.6-3.95c-5.89-1.94-9.76-3.23-9.76-10.41
                        c0-5.67,4.74-10.27,12.92-10.27c7.68,0,10.62,2.23,10.62,2.23v8.61c0,0-4.16-3.02-9.69-3.02c-2.73,0-4.16,1.01-4.16,2.23
                        c0,1.44,0.93,2.44,5.1,3.66C130.55,25.33,135,27.7,135,34.23c0,5.17-4.09,10.62-14.07,10.62c-6.68,0-11.2-2.58-11.2-2.58v-9.62
                        C109.73,32.65,114.4,37.1,121.14,37.1z"/>
                    <path fill="#F0414B" d="M9.51,44.05l2.15-10.12h18.31l2.15,10.12h9.51l-6.47-30.84C33.17,3.65,27.4,0,20.82,0
                        C14.41,0,8.07,3.61,6.47,13.22L0,44.05H9.51z M15.64,13.98c0.82-3.47,2.89-4.69,5.18-4.69c2.15,0,4.29,0.94,5.18,4.69l2.11,11.2
                        H13.53L15.64,13.98z"/>
                </g>
            </g>
        </SvgIcon>
    );
};
