import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
    fill: 'white',
};

const customCss = `
    border-radius: 0.36rem;
`;

export const InstagramIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props} customCss={customCss}>
            <path
                d="M14.932,39.133a7.692,7.692,0,1,0,7.692,7.692A7.679,7.679,0,0,0,14.932,39.133Zm0,12.692a5,5,0,1,1,5-5A5.01,5.01,0,0,1,14.932,51.826Zm9.8-13.007a1.794,1.794,0,1,1-1.794-1.794A1.79,1.79,0,0,1,24.732,38.819Zm5.094,1.821A8.878,8.878,0,0,0,27.4,34.354a8.937,8.937,0,0,0-6.286-2.423c-2.477-.141-9.9-.141-12.378,0a8.924,8.924,0,0,0-6.286,2.417A8.907,8.907,0,0,0,.03,40.633c-.141,2.477-.141,9.9,0,12.378A8.878,8.878,0,0,0,2.454,59.3,8.948,8.948,0,0,0,8.74,61.72c2.477.141,9.9.141,12.378,0A8.878,8.878,0,0,0,27.4,59.3a8.937,8.937,0,0,0,2.423-6.286C29.967,50.534,29.967,43.116,29.826,40.64Zm-3.2,15.028a5.063,5.063,0,0,1-2.852,2.852c-1.975.783-6.661.6-8.843.6s-6.875.174-8.843-.6a5.063,5.063,0,0,1-2.852-2.852c-.783-1.975-.6-6.661-.6-8.843s-.174-6.875.6-8.843A5.063,5.063,0,0,1,6.089,35.13c1.975-.783,6.661-.6,8.843-.6s6.875-.174,8.843.6a5.063,5.063,0,0,1,2.852,2.852c.783,1.975.6,6.661.6,8.843S27.41,53.7,26.626,55.668Z"
                transform="translate(0.075 -31.825)"
            />
        </SvgIcon>
    );
};
