import { useLocation } from '@solidjs/router';
import { createEffect, createSignal, Match, mergeProps, Show, Switch, useContext } from 'solid-js';
import { StyledDeleteFromMyContentButton } from './my-content-handler.style';
import { Text } from '../../ui-components/text/text';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { addMyContent, deleteMyContentItemByPermalink, getMyContent, MyContentItem } from '../my-content/my-content-helpers';
import { RemoveStarIcon } from '../icons-library/remove-star';
import { StyledShareContainer } from '../share-and-save/share-and-save.styles';
import { StarIcon } from '../icons-library/star';
import { StarFullIcon } from '../icons-library/star-full';
import theme from '../../style/theme';

type MyContentHandlerProps = 
| {
    action: 'add';
    title?: string;
    type: string;
    permalink?: string;
    onlyIcon?: boolean;
}
| {
    action: 'remove';
    permalink: string;
};

const defaultProps = {
    title: '',
    type: '',
    onlyIcon: false,
};

export const MyContentHandler = (componentProps: MyContentHandlerProps) => {
    const props = mergeProps(defaultProps, componentProps);
    const { localize, myContentPage } = useContext(AppContext);
    const [existsInMyContent, setExistsInMyContent] = createSignal(false);
    const location = useLocation();

    createEffect(() => { 
        checkIfItemExistsInMyContent();
    });

    const myContentFeatureEnabled = (myContentUrl: string) => {
        // The editors have created a page which can host the my content feature
        const enabled = myContentUrl && typeof myContentUrl === 'string' && myContentUrl !== '';
        return enabled;
    };
    
    const handleOnClickSaveToMyContent = (e: any) => {
        e.preventDefault();
        if (props.action === 'remove') {
            return;
        }

        const permalink = props.permalink === '' ? '/' : props.permalink;

        if (existsInMyContent()) {
            deleteMyContentItemByPermalink(permalink);
            setExistsInMyContent(false);
            return;
        }

        const myContentObjectToSave: MyContentItem = {
            title: props.title,
            permalink,
            type: props.type,
        };

        addMyContent(myContentObjectToSave);
        setExistsInMyContent(true);
    };

    const checkIfItemExistsInMyContent = () => {
        const myContent: MyContentItem[] | [] = getMyContent();
        
        const exists = myContent.find((item: any) => {
            return item.permalink === props.permalink;
        });        

        if (exists) {
            setExistsInMyContent(true);
        } else {
            setExistsInMyContent(false);
        }
    };
    
    return (
        <div>
            <Switch>
                <Match when={props.action === 'add'}>
                    <Show when={myContentFeatureEnabled(myContentPage?.url) && (location.pathname !== myContentPage.url)}>
                        <StyledShareContainer onClick={handleOnClickSaveToMyContent}>
                            <Show when={props.type === 'page' || !props.onlyIcon}>
                                <Text color={'darkGray'} fontSize="small" noBlockSpacing={true}>
                                    {localize('save-to-my-content', 'Save to my content')}
                                </Text>
                            </Show>
                            <Show
                                when={existsInMyContent()}
                                fallback={
                                    <StarIcon fill={theme.palette.gray} aria-label={localize('my_content_star_icon', 'Star')} />
                                }
                            >
                                <StarFullIcon fill={theme.palette.red} aria-label={localize('my_content_star_icon', 'Star')} />
                            </Show>
                        </StyledShareContainer>
                    </Show>
                </Match>
                <Match when={props.action === 'remove'}>
                    <Show when={myContentFeatureEnabled(myContentPage?.url) && (location.pathname === myContentPage.url)}>
                        <StyledDeleteFromMyContentButton
                            onClick={() => {
                                deleteMyContentItemByPermalink(props.permalink);
                                setExistsInMyContent(false);
                            }}
                        >
                            <RemoveStarIcon aria-label={localize('my_content_remove_star', 'Remove Star')}/>
                        </StyledDeleteFromMyContentButton>
                    </Show>
                </Match>
            </Switch>
        </div>
    );
};
