

import { StyledSvg } from './svg-icon.style';
import { mergeProps } from 'solid-js';
import { theme } from '../../style/theme';
import { SvgIconProps, SvgIconStyledProps } from './svg-icon.d';

const defaultProps: SvgIconStyledProps = {
    xmlns: 'http://www.w3.org/2000/svg',
    backgroundOpacity: 0,
    version: '1.1',
    x: '0px',
    y: '0px',
    viewBox: '0 0 30 30',
    height: 2.14,
    width: 2.14,
    padding: '',
    fill: theme.colors.svg.default,
    opacity: 1,
};

export const SvgIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);
    
    return (
        <StyledSvg {...props}>
            { componentProps.children }
        </StyledSvg>
    );
};
