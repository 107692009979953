import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { Show, mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 30 30',
};

export const GlobeIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <Show when={props.altText}>
                <title>{props.altText}</title>
            </Show>
            <path
                d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,27.188c.434,0,1.529-.422,2.789-2.836a20.24,20.24,0,0,0,1.289-3.727H10.922a16.962,16.962,0,0,0,1.289,3.727C13.418,26.766,14.566,27.188,15,27.188Zm-4.541-9.375h9.082A27.1,27.1,0,0,0,19.688,15a27.1,27.1,0,0,0-.146-2.812H10.459a27.073,27.073,0,0,0,0,5.625Zm8.619-8.437a20.129,20.129,0,0,0-1.289-3.724C16.529,3.236,15.434,2.813,15,2.813s-1.582.423-2.789,2.839a16.881,16.881,0,0,0-1.289,3.724Zm3.293,2.813c.082.908.129,1.852.129,2.813s-.047,1.9-.129,2.812h4.488a12.218,12.218,0,0,0,0-5.625ZM20.045,3.9a19.55,19.55,0,0,1,1.91,5.472h3.861A12.239,12.239,0,0,0,20.045,3.9Zm-10.09,0a12.238,12.238,0,0,0-5.77,5.472h3.86A19.55,19.55,0,0,1,9.955,3.9ZM2.813,15a12.226,12.226,0,0,0,.326,2.812h4.49C7.547,16.9,7.5,15.961,7.5,15s.047-1.9.129-2.812H3.139A12.226,12.226,0,0,0,2.813,15Zm23,5.625H21.955a19.588,19.588,0,0,1-1.91,5.473A12.248,12.248,0,0,0,25.816,20.625Zm-17.771,0H4.185A12.247,12.247,0,0,0,9.955,26.1a19.588,19.588,0,0,1-1.91-5.473Z"
            />
        </SvgIcon>
    );
};