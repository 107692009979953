import type { FirstLevelMenuItemLinkProps } from './first-level-menu-item-link-types';
import { StyledFirstLevelMenuItemLink } from './first-level-menu-item-link.styles';
import { removeTrailingSlash } from '../../../../../tools/remove-trailing-slash';
import removeAmpersand from '../../../../../tools/remove-ampersand';

export const FirstLevelMenuItemLink = (props: FirstLevelMenuItemLinkProps) => {
    return (
        <StyledFirstLevelMenuItemLink
            href={removeTrailingSlash(props.link.url)}
            onClick={() => props.onLinkClick()}
            withIcon={Boolean(props.icon)}
        >
            {props?.icon}
            {removeAmpersand(props.link.title)}
        </StyledFirstLevelMenuItemLink>
    );
};
