import { Show } from 'solid-js';
import { Forms } from '../forms/forms';

export const FormSelector = (props: any) => {

    return (
        <Show when={props?.blocks?.length} fallback={<div />}>
            <Forms 
                { ...props?.blocks?.[0] }
            />
        </Show>
    );
};

