import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../../../../style/theme';

const {palette} = theme;

export const cardPadding = 25;

export const StyledExpandableContainerRowPDF = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.1rem;
`;

export const StyledPdfSpecificationTop = styled.div`
    background-color: ${palette.beige};
    padding-inline: 1.85rem;
    padding-top: 2.3rem;
    border-radius: 1rem 1rem 0 0;
`;

export const StyledPdfSpecificationBottom = styled.div`
    background-color: ${palette.beige};
    padding-inline: 1.85rem;
    padding-bottom: 2.3rem;
    border-radius: 0 0 1rem 1rem;
`;

export const StyledDividerLinePDF = styled.div`
    border-top: 2px solid ${palette.darkGray};
    background-color: ${palette.beige};
    padding-bottom: 0.93rem;
`;

export const StyledGrayEmptySpacePDF = styled.div`
    background-color: ${palette.beige};
    padding-bottom: 0.93rem;
`;

export const StyledProductSpecificationsTablePDF = styled.div`
    display: grid;
    grid-template-columns: 5fr 4fr 3fr 2fr;
    background-color: ${palette.beige};
    padding: 0.7rem 0;
`;

export const StyledPdfProductSpecificationsBody = styled.div<{ last?: boolean }>`
    display: grid;
    grid-template-columns: 5fr 4fr 3fr 2fr;
    background-color: ${palette.beige};
    padding-inline: 1.85rem;
    padding-bottom: 0.93rem;
    ${p => p.last ? 'padding-bottom: 0;' : ''}
`;

export const StyledTableHeaderPDF = styled.div<{ align?: string; }>`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    color: black;
    font-size: 0.9rem;
    line-height: normal;
    text-align: ${p => p.align || 'left'};
`;

export const StyledTableColumnPDF = styled.div<{ align?: string; }>`
    text-align: ${p => p.align || 'left'};
`;

export const CardTopPdf = styled.div`
    height: ${cardPadding}px;
    border-radius: 1rem 1rem 0 0;
    background-color: ${theme.palette.beige};
`;

export const CardBottomPdf = styled.div`
    height: ${cardPadding}px;
    border-radius: 0 0 1rem 1rem;
    background-color: ${theme.palette.beige};
`;

export const StyledKeyFeaturesPDF = styled.div`
    background-color: ${theme.palette.beige};
    padding-inline: ${cardPadding}px;
`;

export const StyledKeyFeaturesListPDF = styled.div`
    margin-left: 1.36rem;
`;

export const StyledKeyFeaturesHeadingPDF = styled.h5`
    margin-bottom: 1rem;
    margin-top: 0;
`;

export const StyledPdfProductSupportingMaterialBody = styled.div<{ last?: boolean }>`
    display: grid;
    grid-template-columns: 1fr;
    background-color: ${palette.beige};
    padding-inline: 1.85rem;
    padding-bottom: 0.93rem;
    ${p => p.last ? 'padding-bottom: 0;' : ''}
`;
