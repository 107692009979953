import { createStore } from 'solid-js/store';
// import { isServer } from 'solid-js/web';
import { Tag } from '../components/event/event-types';

export type EventStatus = {
    name: string;
    slug: string;
};

export type AreaOfCare = {
    name: string;
    slug: string;
};

export type TopCategory = EventStatus & {
    children?: TopCategory[];
};

type EventsStoreMethods = {
    status: EventStatus;
    areaOfCare: AreaOfCare;
    availableStatuses: EventStatus[];
    availableAreaOfCares: AreaOfCare[];
    availableCategories: TopCategory[];
    selectedCategories: TopCategory[];
    availableTags: Tag[];
    selectedTags: Tag[];
    availableLanguages: string[];
    selectedLanguages: string[];
    landedWithSearchParams: boolean;
};

const availableStatuses: EventStatus[] = [
    {
        name: 'All',
        slug: 'all',
    },
    {
        name: 'Past',
        slug: 'past',
    },
    {
        name: 'Upcoming',
        slug: 'upcoming',
    },
    {
        name: 'On Demand',
        slug: 'on-demand',
    },
];

const availableAreaOfCares: AreaOfCare[] = [
    {
        name: 'All',
        slug: 'all',
    },
    {
        name: 'Laryngectomy',
        slug: 'laryngectomy',
    },
    {
        name: 'Tracheostomy',
        slug: 'tracheostomy',
    },
];

export const [eventsStore, setEventsStore] = createStore<EventsStoreMethods>({
    status: {
        name: 'Upcoming',
        slug: 'upcoming',
    },
    areaOfCare: {
        name: '',
        slug: '',
    },
    availableStatuses,
    availableAreaOfCares,
    availableCategories: [],
    selectedCategories: [],
    availableTags: [],
    selectedTags: [],
    availableLanguages: [],
    selectedLanguages: [],
    landedWithSearchParams: false,
});

// if (isServer) {
//     setInterval(() => {
//         console.log('Events areas:', eventsStore.availableAreaOfCares);
//         console.log('Events categories:', eventsStore.availableCategories);
//         console.log('Events tags:', eventsStore.availableTags);
//         console.log('Events languages:', eventsStore.availableLanguages);
//     }, 600000);
// }


/**
 * Statuses
 */
export const setStatus = (status: EventStatus) => setEventsStore(() => ({ status }));
export const setLandedWithSearchParams = (withSearchParams: boolean) => setEventsStore(() => ({ landedWithSearchParams: withSearchParams }));

/**
 * Event Categories
 */
export const setAvailableCategories = (categories: TopCategory[]) => setEventsStore(() => ({ availableCategories: categories }));
export const setSelectedCategories = (categories: TopCategory[]) => setEventsStore(() => ({ selectedCategories: categories }));
export const addSelectedEventCategory = (category: TopCategory) => setEventsStore('selectedCategories', (prev: TopCategory[]) => [...prev, category]);
export const removeSelectedEventCategory = (category: TopCategory) => {
    return setEventsStore('selectedCategories', (prev: TopCategory[]) => removeItemFromArray<TopCategory>(prev, category));
};

export const changeSelectedEventCategory = (category: TopCategory) => {
    const alreadyAdded = eventsStore.selectedCategories?.find((cat: TopCategory) => {
        return cat.slug === category.slug;
    });

    if (alreadyAdded) {
        removeSelectedEventCategory(alreadyAdded);
    } else {
        addSelectedEventCategory(category);
    }
};

/**
 * Event Tags
 */
export const setAvailableTags = (tags: Tag[]) => setEventsStore(() => ({ availableTags: tags }));
export const setSelectedTags = (tags: Tag[]) => setEventsStore(() => ({ selectedTags: tags }));
export const addSelectedEventTag = (tag: Tag) => setEventsStore('selectedTags', (prev: Tag[]) => [...prev, tag]);
export const removeSelectedEventTag = (tag: Tag) => {
    return setEventsStore('selectedTags', (prev: Tag[]) => removeItemFromArray<Tag>(prev, tag));
};

export const changeSelectedEventTag = (tag: Tag) => {
    const alreadyAdded = eventsStore.selectedTags?.find((top: Tag) => {
        return top.slug === tag.slug;
    });

    if (alreadyAdded) {
        removeSelectedEventTag(alreadyAdded);
    } else {
        addSelectedEventTag(tag);
    }
};

/**
 * Event Languages
 */
export const setAvailableLanguages = (languages: string[]) => setEventsStore(() => ({ availableLanguages: languages }));
export const setSelectedLanguages = (languages: string[]) => setEventsStore(() => ({ selectedLanguages: languages }));
export const addSelectedEventLanguage = (language: string) => setEventsStore('selectedLanguages', (prev: string[]) => [...prev, language]);
export const removeSelectedEventLanguage = (language: string) => {
    return setEventsStore('selectedLanguages', (prev: string[]) => removeItemFromArray<any>(prev, language));
};

export const changeSelectedEventLanguage = (language: string) => {
    const alreadyAdded = eventsStore.selectedLanguages?.find((lan: string) => {
        return lan === language;
    });

    if (alreadyAdded) {
        removeSelectedEventLanguage(alreadyAdded);
    } else {
        addSelectedEventLanguage(language);
    }
};

/**
 * Area Of Care
 */
export const setAreaOfCare = (areaOfCare: AreaOfCare) => setEventsStore(() => ({ areaOfCare }));

/**
 * Helpers
 */
export const clearFilters = () => {
    // setStatus(availableStatuses[0]);
    setSelectedLanguages([]);
    setSelectedCategories([]);
    setSelectedTags([]);
};

export const resetCategoriesAndTags = () => {
    setSelectedCategories([]);
    setSelectedTags([]);
};

function removeItemFromArray<T extends { slug?: string }>(array: T[], item: T): T[] {
    const index = array.findIndex((element) => (element.slug ? element.slug === item.slug : element === item));

    if (index !== -1) {
        const updatedArray = [...array];
        updatedArray.splice(index, 1);
        return updatedArray;
    }

    return array;
}
