import { Link, useLocation } from '@solidjs/router';
import { gql } from 'graphql-request';
import { useContext, Show, For } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Text } from '../../../ui-components/text/text';
import { Column } from '../../../grid-system/column/column';
import { Grid } from '../../../grid-system/grid/grid';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';
import { AtosLogoWhite } from '../../icons-library/atos-logo-desktop-white';
import {
    StyledFooter,
    StyledLogo,
    CopyrightContainer,
    StyledCopyright,
    rowBlockSpacing,
    StyledFooterColumn,
    StyledSocialMediaContainer,
    StyledOpeningHoursContainer,
    StyledNewsletterText,
    StyledPageLink
} from './footer-atos-care.styles';
import { Button } from '../../../ui-components/button/button';
import { TwitterIcon } from '../../icons-library/social-media/twitter';
import { FacebookIcon } from '../../icons-library/social-media/facebook';
import { LinkedinIcon } from '../../icons-library/social-media/linkedin';
import { InstagramIcon } from '../../icons-library/social-media/instagram';
import { YoutubeIconPlain } from '../../icons-library/youtube-icon-plain';


const GET_FOOTER_QUERY = gql`
    query GetFooter($key: String!) {
        footer: resource(key: $key) {
            content
            key
        }
    }
`;

export const FooterAtosCareContainer = () => {

    const { createCachedResource, localize } = useContext(AppContext);
    const [ footerContent ] = createCachedResource(GET_FOOTER_QUERY, () => ({ key: '/wp-json/rawb/v1/footer' }), true);
    const location = useLocation();

    const footer = () => footerContent()?.footer?.content;

    const copyrightText = footer()?.copyrighttext;

    const handleOnClickCookieConsent = (e: any) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.CookieConsent.renew();
    };

    return (
        <ErrorCatcher componentName="Footer">
            <StyledFooter>
                <Grid templateShorthand={[3, 3, 3, 3]} isLayoutGrid={true} customCss={rowBlockSpacing}>
                    <StyledLogo>
                        <AtosLogoWhite aria-label="Atos Care Logo" />
                    </StyledLogo>

                    <StyledFooterColumn>
                        <Text fontSize="footerHeading" color={'white'} displayRedVerticalLine={false} fontStyle="bold">
                            {footer()?.column?.header}
                        </Text>
                        <For each={footer()?.column?.columnItems}>
                            {(columnItem) => {
                                if (!columnItem || !columnItem.title || !columnItem.url) {
                                    return null;
                                }

                                return (
                                    <StyledPageLink>
                                        <Link href={removeTrailingSlash(columnItem.url)} target={columnItem.target}>
                                            <Text
                                                fontSize={'footerSmall'}
                                                color={'white'}
                                                noBlockSpacing={true}
                                                displayRedVerticalLine={false}
                                                backgroundColor="transparent"
                                            >
                                                {columnItem.title}
                                            </Text>
                                        </Link>
                                    </StyledPageLink>
                                );
                            }}
                        </For>

                        <Link href={location.pathname} onClick={handleOnClickCookieConsent}>
                            <Text fontSize={'footerSmall'} color={'white'} noBlockSpacing={true} displayRedVerticalLine={false} backgroundColor="transparent">
                                {localize('cookie-consent', 'Cookie consent')}
                            </Text>
                        </Link>
                    </StyledFooterColumn>

                    <StyledFooterColumn socialMediaColumn={true}>
                        <Show when={!footer()?.openingHours?.hide && footer()?.openingHours?.text}>
                            <StyledOpeningHoursContainer>
                                <Text fontSize="footerHeading" color={'white'} displayRedVerticalLine={false} fontStyle="bold">
                                    {footer()?.openingHours.heading}
                                </Text>
                                <Text fontSize="footerSmall" color={'white'} displayRedVerticalLine={false}>
                                    {footer()?.openingHours.text}
                                </Text>
                            </StyledOpeningHoursContainer>
                        </Show>

                        <Show when={!footer()?.socialMedia?.hide}>
                            <div>
                                <Text fontSize="footerHeading" color={'white'} displayRedVerticalLine={false} fontStyle="bold">
                                    {footer()?.socialMedia.heading}
                                </Text>
                                <StyledSocialMediaContainer>
                                    <Show when={footer()?.socialMedia?.linkedin}>
                                        <Link target="_blank" href={footer()?.socialMedia?.linkedin}>
                                            <LinkedinIcon width={1.8} height={1.8} />
                                        </Link>
                                    </Show>
                                    <Show when={footer()?.socialMedia?.facebook}>
                                        <Link target="_blank" href={footer()?.socialMedia?.facebook}>
                                            <FacebookIcon width={1.8} height={1.8} />
                                        </Link>
                                    </Show>
                                    <Show when={footer()?.socialMedia?.instagram}>
                                        <Link target="_blank" href={footer()?.socialMedia?.instagram}>
                                            <InstagramIcon width={1.8} height={1.8} />
                                        </Link>
                                    </Show>
                                    <Show when={footer()?.socialMedia?.twitter}>
                                        <Link target="_blank" href={footer()?.socialMedia?.twitter}>
                                            <TwitterIcon width={1.8} height={1.8} />
                                        </Link>
                                    </Show>
                                    <Show when={footer()?.socialMedia?.youtube}>
                                        <Link target="_blank" href={footer()?.socialMedia?.youtube}>
                                            <YoutubeIconPlain width={1.8} height={1.8} />
                                        </Link>
                                    </Show>
                                </StyledSocialMediaContainer>
                            </div>
                        </Show>
                    </StyledFooterColumn>

                    <StyledFooterColumn>
                        <Show when={!footer()?.newsletter?.hide && footer()?.newsletter?.link}>
                            <Text fontSize="footerHeading" color={'white'} displayRedVerticalLine={false} fontStyle="bold">
                                {footer()?.newsletter.heading}
                            </Text>
                            <StyledNewsletterText>
                                <Text fontSize="footerSmall" color={'white'} displayRedVerticalLine={false} noBlockSpacing={true}>
                                    {footer()?.newsletter.text}
                                </Text>
                            </StyledNewsletterText>
                            <Button isLink={true} target={footer()?.newsletter?.link?.url} variant="primary" label={footer()?.newsletter?.link?.title} small={true} />
                        </Show>
                    </StyledFooterColumn>
                </Grid>

                <Grid templateShorthand={[12]} customCss={'height: 20%'} isLayoutGrid={true}>
                    <CopyrightContainer>
                        <Column>
                            {copyrightText && (
                                <StyledCopyright>
                                    <Text align="center" fontSize="small" color="white" displayRedVerticalLine={false}>
                                        {`${localize('copyright', 'Copyright')} ${copyrightText}`}
                                    </Text>
                                </StyledCopyright>
                            )}
                        </Column>
                    </CopyrightContainer>
                </Grid>
            </StyledFooter>
        </ErrorCatcher>
    );
};
