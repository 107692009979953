import { useLocation, useParams } from '@solidjs/router';
import { createEffect, createMemo, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Section } from '../../grid-system/section/section';
import theme from '../../style/theme';
import { ErrorCatcher } from '../../tools/error-catcher';
import { PageCacheType } from '../../types/shared';
import { LockedContent } from '../../ui-components/layouts/locked-content';
import { Text } from '../../ui-components/text/text';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import { NotFound } from '../404/404';
import { SeoSettings } from '../seo-settings/seo-settings';
import { ShareAndSave } from '../share-and-save/share-and-save';
import { ExpandableProductContainer } from './expandable-product-container/expandable-product-container';
import { GET_PRODUCT_QUERY } from './fetch-product';
import { ProductDescription } from './product-description/product-description';
import { ProductFurtherLinks } from './product-further-links/product-further-links';
import { ProductIntro } from './product-intro/product-intro';
import { ProductSpecifications } from './product-specifications/product-specifications';
import { ProductSupplements } from './product-supplements/product-supplements';
import { ProductData, DocumentType } from './product-types';
import { ProductVideo } from './product-video/product-video';
import { StyledMcRefContainer, StyledProduct, StyledProductOuter, StyledProductWrapper } from './product.styles';
import ProductShopToolbar from './product-shop-toolbar/product-shop-toolbar';
import { HCPWarning } from '../hcp-warning/hcp-warning';
import { LoadingPlaceHolder } from '../loading-place-holder/loading-place-holder';
import { legacyDocuments } from './legacy-documents';
// import { ExpandableContainer } from '../../ui-components/expandable-container/expandable-container';
// import { StyledDividerLine } from './expandable-container/expandable-container.styles';

export const specificationComponentId = 'specification-component';

export const Product = () => {
    const params = useParams<{ productSlug: string }>();
    const location = useLocation();
    
    const {
        setCacheType,
        createCachedResource,
        localize,
        setStatusCode,
        viewport,
        userState,
        pageRegistration,
        siteInfo,
        appStore,
    } = useContext(AppContext);

    const [productContent] = createCachedResource(GET_PRODUCT_QUERY, () => ({ key: `/product/${params.productSlug}` }), true);
    const productData = createMemo((): ProductData => productContent()?.product?.content);

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;
    const productSectionTemplateShort = () => (isMobile() ? [12] : [10, 2]);
    const isLayoutSection = () => (isMobile() ? false : true);

    const isHcp = () => {
        if (checkHcp()) {
            setCacheType(PageCacheType.none);
            return true;
        }
        setCacheType(PageCacheType.defaultCache);
        return false;
    };

    const checkHcp = () => {
        /**
         * "appStore.isHcp" behaves like a ratchet, once set to true, it can't be set to false,
         * unless the homepage is visited (which is a "patients" page, not a HCP page)
         */
        if (location.pathname === '/') {
            return false;
        }

        if (appStore.isHcp()) {
            return true;
        }
        
        return Boolean(productData()?.isHealthcareProfessional);
    };

    const setHcp = () => {
        appStore.setHcp(checkHcp());
    };

    setHcp();

    createEffect(() => {
        setHcp();
    });

    const isHiddenForPublic = () => {
        return checkHcp() 
            && !productData().isVisibleForPublic 
            && !userState.user 
            && pageRegistration.url !== '';
    };

    const checkNotFound = () => {
        if (!productData()) {
            setStatusCode(404);
            return true;
        } else {
            setStatusCode(200);
            return false;
        }
    };

    const filterDocuments = (documents: DocumentType[]) => {
        try {
            if (!documents || !Array.isArray(documents)){
                return [];
            }
            
            return documents.filter((document) => {
                if (!document?.file?.url) {
                    return false;
                }

                if (legacyDocuments.includes(document.file.name)) {
                    return false;
                }

                return true;
            });
        } catch (error) {
            return [];
        }
    };

    return (
        <ErrorCatcher componentName="Product">
            <StyledProductWrapper>
                <Show when={checkNotFound() && !productContent.loading}>
                    <NotFound />
                </Show>

                <Show when={productData()?.post_title} fallback={<LoadingPlaceHolder />}>
                    <HCPWarning isHcp={isHcp()} />
                    <SeoSettings seo={productData().seo} title={productData().post_title}></SeoSettings>
                    <Section widthType="bgFull" templateShorthand={productSectionTemplateShort()} isLayoutSection={isLayoutSection()}>
                        <StyledProductOuter>
                            <StyledProduct>
                                <ProductIntro
                                    productTitle={productData().post_title}
                                    shortDescription={productData().shortDescription}
                                    images={productData().images}
                                    videoData={productData().videoData}
                                    purchaseButton={productData().purchaseButton}
                                    iconName={productData().icon}
                                />
                                <Show when={siteInfo.siteType === 'atos-care'}>
                                    <StyledVerticalSpace size={2} />
                                    <ProductShopToolbar productData={productData()} />
                                </Show>
                                <StyledVerticalSpace size={4} />
                                <ProductDescription
                                    subTitle={productData().subTitle}
                                    longDescription={productData().longDescription}
                                    features={productData().features}
                                    productFamilyImage={productData().productFamilyImage}
                                    productFamilyLink={productData().productFamilyGuideLink}
                                    productLearnMoreLink={productData().productFamilyLearnMore}
                                    hideOverviewLink={productData().hideOverviewLink}
                                />
                                <StyledVerticalSpace size={4} />
                                <Show when={productData().videoData.videos.length}>
                                    <ProductVideo videoData={productData().videoData} />
                                </Show>

                                <Show when={productData().specifications.length}>
                                    <ExpandableProductContainer 
                                        heading={localize('specifications', 'Specifications')}
                                        initialOpen={siteInfo.siteType === 'atos-care'}
                                    >
                                        <ProductSpecifications product={productData()} />
                                    </ExpandableProductContainer>
                                    <StyledVerticalSpace size={2} />
                                </Show>
                                <Show when={filterDocuments(productData()?.documents).length}>
                                    <ExpandableProductContainer heading={localize('supporting-material', 'Supporting Material')}>
                                        <ProductSupplements documents={filterDocuments(productData().documents as [DocumentType])} />
                                    </ExpandableProductContainer>
                                </Show>

                                <ProductFurtherLinks productTitle={productData().post_title} />

                                <ShareAndSave type="product" title={productData().post_title} permalink={productData().permalink} data={productData()} />

                                <Show when={isHiddenForPublic()}>
                                    <LockedContent />
                                </Show>
                            </StyledProduct>
                            
                            <Show when={productData()?.mcRef}>
                                <StyledMcRefContainer>
                                    <Text fontSize='smaller'>
                                        {productData()?.mcRef}
                                    </Text>
                                </StyledMcRefContainer>
                            </Show>
                        </StyledProductOuter>
                    </Section>
                </Show>
            </StyledProductWrapper>
        </ErrorCatcher>
    );
};
