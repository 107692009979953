import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';
import { palette } from '../../style/theme';

const defaultProps: SvgIconProps = {
    width: 4,
    height: 2.76,
    viewBox: '0 0 50 34.549',
    fill: palette.pink

};

export const QuoteClose = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                id="Path_10784"
                data-name="Path 10784"
                d="M143.652,4.387a14.78,14.78,0,0,1,2.742,9.048,20.05,20.05,0,0,1-4.113,12.339q-4.112,5.484-12.5,8.774l-1.451-2.468a21.747,21.747,0,0,0,8.629-5.621q3.629-3.837,3.629-7.815c0-.106,0-.21,0-.314a1.718,1.718,0,0,0-2.366-1.544,10.722,10.722,0,0,1-4.082.761,11.331,11.331,0,0,1-7.338-2.4,7.763,7.763,0,0,1-2.984-6.375A7.881,7.881,0,0,1,126.8,2.536,10.943,10.943,0,0,1,134.136,0q6.29,0,9.516,4.387m27.419,0a14.787,14.787,0,0,1,2.742,9.048A20.047,20.047,0,0,1,169.7,25.775q-4.112,5.484-12.5,8.774l-1.451-2.468a21.745,21.745,0,0,0,8.628-5.621q3.63-3.837,3.629-7.815c0-.106,0-.211,0-.314a1.717,1.717,0,0,0-2.365-1.544,10.722,10.722,0,0,1-4.082.761,11.332,11.332,0,0,1-7.339-2.4,7.761,7.761,0,0,1-2.984-6.375,7.878,7.878,0,0,1,2.984-6.238A10.943,10.943,0,0,1,161.555,0q6.29,0,9.516,4.387"
                transform="translate(-123.813)"
            />
        </SvgIcon>
    );
};
