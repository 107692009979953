import theme from '../../style/theme';

export const SwirlPdf = () => {

    return (
        <svg width="792" height="174" viewBox="0 0 597 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M596 50.7959C587.919 54.2627 577.885 56.5121 564.144 56.5121C514.882 56.5121 486.241 12.9273 442.022 11.3103C411.142 10.2108 378.659 34.6348 343.889 51.6855C327.623 59.6571 312.494 65.8338 297.217 65.9955C249.786 66.5372 268.503 17.1152 222.494 18.15C176.966 19.2091 171.13 63.4246 123.118 63.4246C79.2645 63.4246 43.2101 5.70841 1 1.26929" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M595.962 55C585.944 60.9294 573.427 65.2943 553.38 65.2943C504.019 65.2943 475.877 22.5507 432.851 20.5521C402.551 19.1128 370.535 42.3968 335.437 59.0106C319.172 66.7336 304.279 72.7869 289.183 72.9925C242.241 73.6834 258.891 26.0873 213.633 27.4032C168.989 28.6945 162.131 71.8657 114.479 71.8657C73.5469 71.8657 39.6165 20.7258 1 12.1974" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 58.3371C583.705 66.6932 569.778 73.8631 542.623 73.8631C493.173 73.8631 465.521 32.8954 423.697 30.511C394.026 28.8232 362.443 50.405 327.076 66.2595C310.827 73.5435 296.155 79.3527 281.23 79.6067C234.738 80.4261 249.361 35.6812 204.863 37.2462C161.142 38.7947 153.255 80.0001 105.962 80.0001C67.9624 80.0001 36.186 36.1435 1 24.7021" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 61.3951C580.392 71.707 566.374 82.2077 531.981 82.2077C482.416 82.2077 455.271 42.4163 414.648 39.6155C385.583 37.6102 354.482 57.829 318.788 73.1506C302.555 80.1112 288.063 85.7626 273.35 86.0609C227.333 87.0221 239.912 44.4713 196.167 46.3275C153.304 48.1505 144.386 88 97.4532 88C62.4434 88 32.8713 50.4334 1 36.8364" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 65.6283C575.6 76.8803 562.599 90.6267 521.208 90.6267C471.545 90.6267 444.883 52.4924 405.469 49.3132C377.009 47.0198 346.406 65.6151 310.369 80.2114C294.168 86.7272 279.914 92.1832 265.34 92.5227C219.796 93.6238 230.332 53.8088 187.347 55.9283C145.367 58.0064 135.436 96 88.8462 96C56.9115 96 29.6427 64.8776 1 50.2004" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 71.391C569.597 82.6322 558.276 99.0879 510.542 99.0879C460.78 99.0879 434.592 62.8631 396.397 59.3392C368.533 56.7599 338.47 73.632 302.056 87.399C285.905 93.5022 271.838 98.6525 257.444 99.0304C212.367 100.263 220.859 63.4299 178.626 65.8038C137.561 68.1202 126.552 104 80.3459 104C51.4938 104 26.573 78.9321 1 64.2133" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 77.7595C562.617 88.5453 553.328 107.492 499.785 107.492C449.918 107.492 424.22 72.6903 387.242 68.7684C359.967 65.8703 330.435 81.2359 293.654 94.3639C277.56 100.102 263.673 105.048 249.451 105.469C204.847 106.848 211.288 72.5086 169.815 75.1673C129.592 77.7433 117.577 112 71.7308 112C46.0148 112 23.5311 92.1308 0.938721 78.0001" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 85.216C555.013 95.0982 547.899 115.967 489.037 115.967C439.071 115.967 413.847 82.7798 378.104 78.4665C351.417 75.2748 322.515 89.0643 285.276 101.435C269.272 106.771 255.532 111.481 241.481 111.942C197.352 113.46 201.749 81.8396 161.028 84.7674C121.721 87.5962 108.676 120 63.1893 120C40.6637 120 20.7031 104.912 1 92.1816" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 93.5465C547.037 102.234 542.124 124.381 478.337 124.381C428.274 124.381 403.532 93.1838 368.982 88.6025C342.892 85.1462 314.611 97.1863 276.914 108.737C260.984 113.628 247.432 118.022 233.536 118.519C189.865 120.15 192.227 91.5535 152.266 94.7163C113.841 97.7569 99.7663 128 54.6314 128C35.3005 128 17.9188 117.149 1 106.476" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 101.538C550.703 107.513 539.542 123.555 502.985 130M442.167 130C408.132 122.486 386.996 101.824 359.836 97.7935C334.334 93.977 306.707 104.586 268.52 115.549C252.695 120.091 239.241 124.336 225.551 124.88C182.361 126.669 182.696 100.423 143.463 103.902C116.878 106.254 101.545 121.377 78.9549 130M19.9554 130C13.4886 127.207 7.19478 123.684 1 119.973" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 110.774C595.615 110.815 595.23 110.856 594.845 110.898C559.99 114.312 543.661 122.856 524.641 130M404.627 130C384.976 121.595 369.228 111.028 350.706 108.002C325.801 103.945 298.86 112.87 260.15 122.938C249.606 125.682 240.134 128.361 230.958 130M195.886 130C172.635 125.072 165.288 111.003 134.684 113.94C116.579 115.676 103.478 123.036 89.5 130.037" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 119.41C593.666 119.584 591.375 119.779 589.115 119.978C567.121 121.919 552.203 125.744 539.5 130.018M375 130.023C363.713 124.507 353.184 119.43 341.576 117.313C317.276 112.876 291.014 120.347 251.78 129.802C251.78 129.802 250.925 130.008 250.5 130.111M166.83 130.111C155.606 125.556 145.197 121.116 125.906 123.168C116.119 124.207 107.725 126.838 99.7903 130.111" stroke={theme.palette.lightGray} stroke-width="0.25" />
            <path d="M596 128.28C591.651 128.499 587.464 128.808 583.377 129.131C580.132 129.386 577.035 129.679 574.071 130.006M343.986 130C340.208 128.63 336.388 127.494 332.446 126.696C315.563 123.283 297.763 125.347 274.5 129.989" stroke={theme.palette.lightGray} stroke-width="0.25" />
        </svg>
    );
};