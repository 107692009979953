import { Text } from '../../../../../../ui-components/text/text';
import { Show, createSignal, onMount } from 'solid-js';
import { Heading } from '../../../../../../ui-components/heading/heading';
import { ProductData } from '../../../../../product/product-types';
import { StyledProductIntroWrapperPDF, StyledProductIntroLeftPDF, StyledProductIntroRightPDF } from './intro.style';
import { PdfIconTab } from '../../post-icon-tab/icon-tab';
import { ImageComponent } from '../../../../../image-component/image-component';

type ProductIntroPropsPDF = {
    data: ProductData
}

export const ProductIntroPDF = (props: ProductIntroPropsPDF) => {
    const [featuredImageSrc, setFeaturedImageSrc] = createSignal<any>();

    onMount(() => {
        if (!props?.data?.images?.length) return;
        const featuredImage = props.data.images?.find((image: { isFeatured: boolean }) => image.isFeatured);
        const image = featuredImage || props?.data.images[0];
        setFeaturedImageSrc(image);
    });


    return (
        <Show when={props?.data} fallback={<div></div>}>

            <Show when={props?.data?.icon}>
                <PdfIconTab type='product' productIconName={props.data.icon} />
            </Show>
            
            <StyledProductIntroWrapperPDF>
                <StyledProductIntroLeftPDF>
                    <div style={{ 'margin-bottom': '3rem'}}>
                        <Heading tag={'h1'} variant='xxlargeBoldPDF'>{props?.data?.post_title || ''}</Heading>
                    </div>
                    <div>
                        <Text displayRedVerticalLine={false} fontSize="largerPDF" splitParagraph={true}>
                            {props?.data?.shortDescription || ''}
                        </Text>
                    </div>
                </StyledProductIntroLeftPDF>
                <StyledProductIntroRightPDF>
                    <Show when={featuredImageSrc()}>
                        <ImageComponent
                            src={featuredImageSrc().file?.url}
                            height={'AUTO'}
                            width={300}
                            altText={featuredImageSrc().file?.alt}
                            title={featuredImageSrc().file?.title}
                            forPdfBuilder={true}
                            aspectRatio={'standard'}
                        />
                    </Show>
                </StyledProductIntroRightPDF>
            </StyledProductIntroWrapperPDF>
        </Show>
    );
};