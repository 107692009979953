import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    // viewBox: '0 0 29.992 30',
    // width: 29.992
};

export const CalendarIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path
                d="M7.5,0A1.073,1.073,0,0,1,8.571,1.071V4.286H21.429V1.071a1.071,1.071,0,1,1,2.143,0V4.286h2.143A4.288,4.288,0,0,1,30,8.571V30a4.29,4.29,0,0,1-4.286,4.286H4.286A4.288,4.288,0,0,1,0,30V8.571A4.285,4.285,0,0,1,4.286,4.286H6.429V1.071A1.073,1.073,0,0,1,7.5,0ZM27.857,12.857H2.143V30a2.142,2.142,0,0,0,2.143,2.143H25.714A2.141,2.141,0,0,0,27.857,30ZM25.714,6.429H4.286A2.142,2.142,0,0,0,2.143,8.571v2.143H27.857V8.571A2.141,2.141,0,0,0,25.714,6.429Z"
            />
        </SvgIcon>
    );
};
