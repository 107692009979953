import { styled } from 'solid-styled-components';

const StyledSpacerPdf = styled.div<{ height?: number }>`
    height: ${(props) => props.height ? `${props.height}rem` : '1.7rem'};
`;

export const SpacerPdf = (props: { height?: number }) => {
    return (
        <StyledSpacerPdf height={props.height} />
    );
};

SpacerPdf.parseProps = (atts: any) => {
    return {
        height: atts.height,
    };
};
