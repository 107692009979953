import { gql } from 'graphql-request';
import { onMount, createSignal, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Button } from '../../ui-components/button/button';
import { Modal } from '../../ui-components/modal/modal';
import { Text } from '../../ui-components/text/text';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import { StyledButtonRow, StyledHCPWarning, StyledDivider } from './hcp-warning.styles';
import { Heading } from '../../ui-components/heading/heading';
import { createStore } from 'solid-js/store';
import { useNavigate } from '@solidjs/router';

export const GET_HCP_WARNING_QUERY = gql`
    query {
        settings: resource(externalId: "hcp-warning-settings") {
            content
        }
    }
`;
type HCPWarningProps = {
    isHcp: boolean;
};
export const HCPWarning = (props: HCPWarningProps) => {
    const { createCachedResource, hcpWarning, registerHcpWarningSeen, userState } = useContext(AppContext);
    const [showModal, setShowModal] = createSignal(false);
    const [message, setMessage] = createStore({
        heading: '',
        body: '',
        prompt: '',
        rejectButtonText: '',
        acceptButtonText: '',
        hcpWarningRejectPageUrl: '',
    });

    const navigate = useNavigate();

    const [ hcpWarningSettings ] = createCachedResource(GET_HCP_WARNING_QUERY, {}, true);

    onMount(() => {
        const settings = hcpWarningSettings()?.settings?.content;
        
        if (!settings) {
            return;
        }

        const { enabled, heading, body, prompt, rejectButtonText, acceptButtonText } = settings;

        if (!enabled) {
            return;
        }

        if(!props.isHcp) {
            return;
        }

        if(userState.user?.email) {
            return;
        }
        if (hcpWarning.seen) {
            return;
        }

        setMessage({
            heading,
            body,
            prompt,
            rejectButtonText,
            acceptButtonText,
            hcpWarningRejectPageUrl: settings.hcpWarningRejectPage?.url
        });

        setTimeout(() => {
            setShowModal(true);
        }, 2000);
    });

    const accept = () => {
        saveCookie();
        registerHcpWarningSeen();
        setShowModal(false);
    };

    const reject = () => {
        setShowModal(false);
        // navigate to reject HCP warning page
        let rejectUrl = message.hcpWarningRejectPageUrl ? message.hcpWarningRejectPageUrl : '/';

        // clear eventual trailing slash from rejectUrl
        if (rejectUrl.endsWith('/')) {
            rejectUrl = rejectUrl.slice(0, -1);
        }

        navigate(rejectUrl);
    };

    const saveCookie = async () => {
        await fetch('/set-hcp-warning-seen', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ seenHcpWarning: true }),
        });        
    };

    return (
        <Modal visible={showModal()} onClose={() => reject()} size='normal' darkenBackground={true}>
            <StyledVerticalSpace size={3} />
            <StyledHCPWarning>
                <Heading tag="h3">
                    {message.heading ? message.heading : 'You are now entering healthcare professional content only'}
                </Heading>

                <StyledDivider />

                <Text fontSize="small" color="gray">
                    {message.body ? message.body : `Please note that you are about to enter a section of the website that is intended for healthcare professionals and
                        purchasing agents only.`}
                </Text>

                <Text fontSize="normal" fontStyle="bold" color="gray">
                    {message.prompt ? message.prompt : 'Are you a healtchcare professional or a hospital purchasing agent?'}
                </Text>

                <StyledButtonRow>
                    <Button variant="secondary" label={message.rejectButtonText ? message.rejectButtonText : 'No I am not'} onClick={reject} />
                    <Button variant="primary" label={message.acceptButtonText ? message.acceptButtonText : 'Yes I am'} onClick={accept} />
                </StyledButtonRow>
            </StyledHCPWarning>
        </Modal>
    );
};
