import { createSignal, For, Match, Switch, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme, { palette } from '../../style/theme';
import { Heading } from '../../ui-components/heading/heading';
import { Grid } from '../../grid-system/grid/grid';
import { StyledCategoriesContainer, StyledCategoriesContainerMobile, StyledCategoriesHeadingDesktop, StyledCategoriesList, StyledCategoryItem } from './videos.styles';
import { ExpandableContainer } from '../../ui-components/expandable-container/expandable-container';
import { Section } from '../../grid-system/section/section';
import { PageContext } from '../page/page-context-provider';
import { VideoData } from './videos-types';
import { VideoPostCard } from './video-post-card/video-post-card';
import { useLocation, useNavigate, useSearchParams } from '@solidjs/router';

type TopCategory = {
    slug: string;
    name: string;
    children?: TopCategory[];
};

export const gridSettings = {
    container: {
        templateShorthand: [4, 4, 4],
    },
};

const extractCategories = (accCategories: TopCategory[], categories: TopCategory[]) => {
    let uniqueCategories = [...accCategories];

    categories.forEach((category) => {            
        if (!uniqueCategories.find((uc) => uc.slug === category.slug)) {
            uniqueCategories.push({
                slug: category.slug,
                name: category.name,
            });
        }

        if (category?.children && category?.children.length > 0) {
            uniqueCategories = [...extractCategories(uniqueCategories, category.children)];
        }
    });

    return uniqueCategories;
};

export const extractTopCategories = (videos: VideoData[]) => {
    const allCategories = videos.reduce((accCategories: TopCategory[], video) => {
        accCategories = [...extractCategories(accCategories, video.categories)];

        return accCategories;
    }, []);
    
    return allCategories;
};

export const createGroupedVideoCard = (video: VideoData, insideSlider?: boolean) => {
    return createVideoCard(video, true, insideSlider);
};

const createVideoCard = (video: VideoData, inGrouping?: boolean, insideSlider?: boolean) => {
    if (!video) {
        return <></>;
    }

    return (
        <VideoPostCard
            title={video?.video_title?.trim()}
            videoUrl={video?.video_link}
            inGrouping={inGrouping}
            isHealthcareProfessional={video?.isHealthcareProfessional}
            isVisibleForPublic={video?.isVisibleForPublic}
            permalink={video?.permalink}
            image={video?.image}
            ableToPlay={false}
            insideSlider={insideSlider}
        />
    );
};

export const VideosComponent = (props: VideosComponentProps) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { viewport, localize } = useContext(AppContext);
    const { withSidebarMenu } = useContext(PageContext);
    const [topCategories, setTopCategories] = createSignal<TopCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = createSignal<TopCategory>({
        slug: searchParams.category || 'all',
        name: ''
    });

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    const categoryName = () => {
        const category = topCategories().find((category) => {
            return category.slug === selectedCategory().slug;
        });
        
        if (!category) {
            return '';
        }

        return category.name;
    };

    const filteredVideos = () => {
        if (selectedCategory().slug === 'all') {
            return props.videos;
        }

        const matchCategory = (categories: TopCategory[], targetSlug: string) => {
            const foundMatch = categories.find((category) => {
                if (category.slug === targetSlug) {
                    return true;
                }

                if (category.children && category.children.length > 0) {
                    return matchCategory(category.children, targetSlug);
                }

                return false;
            });

            if (!foundMatch) {
                return false;
            }

            return true;
        };

        const matches = props.videos.filter((video) => {
            return matchCategory(video.categories, selectedCategory().slug);
        });
        
        return matches;
    };

    let expandableContainer: HTMLDivElement | undefined;
    const handleCategoryClick = (category: TopCategory | null) => {
        const categoryToUse = category || { slug: 'all', name: 'All' };
        setSelectedCategory(categoryToUse);
        expandableContainer?.click();
        navigate(`${location.pathname}?category=${categoryToUse.slug}`, { scroll: false });
    };

    const extractedTopCategories = extractTopCategories(props.videos);
    setTopCategories(extractedTopCategories);

    const renderCategoriesList = () => {
        return (
            <StyledCategoriesList>
                <StyledCategoryItem underlined={selectedCategory() === null} onClick={() => handleCategoryClick(null)}>
                    <Heading tag="h3" variant="tinyRed" noBlockSpacing={true}>
                        {localize('all', 'All')}
                    </Heading>
                </StyledCategoryItem>

                <For each={topCategories()}>
                    {(topCategory: TopCategory) => (
                        <StyledCategoryItem underlined={selectedCategory()?.slug === topCategory.slug} onClick={() => handleCategoryClick(topCategory)}>
                            <Heading tag="h3" variant="tinyRed" noBlockSpacing={true}>
                                {topCategory.name}
                            </Heading>
                        </StyledCategoryItem>
                    )}
                </For>
            </StyledCategoriesList>
        );
    };

    return (
        <>
            <Section
                templateShorthand={[12]}
                widthType='bgFull'
                backgroundType='color'
                backgroundValue='white'
                customCss='z-index: 1;'
            >
                <Switch>
                    <Match when={!isMobile()}>
                        <StyledCategoriesContainer>
                            <StyledCategoriesHeadingDesktop>
                                <Heading tag="h3" variant="medium" noBlockSpacing={true}>
                                    {localize('categories', 'Categories')}
                                </Heading>
                            </StyledCategoriesHeadingDesktop>
                            {renderCategoriesList()}
                        </StyledCategoriesContainer>
                    </Match>

                    <Match when={isMobile()}>
                        <StyledCategoriesContainerMobile>
                            <ExpandableContainer
                                ref={expandableContainer}
                                initialOpen={false}
                                chevronColor={palette.red}
                                isDropdown={true}
                                withBorder={true}
                                headingProps={{
                                    noBlockSpacing: true,
                                    tag: 'h3',
                                    variant: 'medium',
                                    children: categoryName() || localize('categories', 'Categories'),
                                }}
                            >
                                {renderCategoriesList()}
                            </ExpandableContainer>
                        </StyledCategoriesContainerMobile>
                    </Match>
                </Switch>
            </Section>


            <Section templateShorthand={[12]} widthType='bgFull' isLayoutSection={withSidebarMenu() ? false : true}>
                <Grid 
                    templateShorthand={withSidebarMenu() ? [6,6] : [4,4,4]}
                    responsive={{mobile: [12], tablet: [12], smallDesktop: [6,6]}}
                >
                    <For each={filteredVideos()}>{(video: VideoData) => {
                        return createGroupedVideoCard(video);
                    }}
                    </For>
                </Grid>
            </Section>
        </>
    );
};
export type VideosComponentProps = {
    videos: VideoData[];
}
