import { styled } from 'solid-styled-components';
import { fontFamilyAtosRegular, theme } from '../../../style/theme';

const { header } = theme;
const { mobile } = header;

const mobileHeight = mobile.height.value + mobile.height.unit;

const BACKGROUND_COLOR = theme.palette.darkGray;

export const StyledSidebarNav = styled.nav<any>`
    container-type: inline-size;
    position: sticky;
    z-index: 3;
    top: ${mobileHeight};
    left: 0;
    right: 0;
    ${theme.mediaQueries.tabletMin} {
        display: none;
    }

    display: ${(p) => (p.hideMenu ? 'none' : 'block')};
`;

export const StyledSidebarNavHeadingRow = styled.div<{ isOpen: boolean }>`
    display: flex;
    background-color: ${BACKGROUND_COLOR};
    padding: 1rem 1.5rem;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: ${(p) => (!p.isOpen ? '1rem' : '1rem 1rem 0 0')};
    * {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
`;

export const StyledSidebarHeading = styled.div`
    font-weight: 400;
    font-size: 1.6rem;
    color: ${theme.palette.white};
    letter-spacing: 0;
    font-family: ${fontFamilyAtosRegular};

    margin-top: 0;
    margin-bottom: 0;
`;

export const StyledSidebarDropdown = styled.ul`
    background-color: ${BACKGROUND_COLOR};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    position: absolute;
    top: 46px;
    width: 100%;

    padding: 1rem 1.5rem;
    border-radius: 0 0 1rem 1rem;

    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    & > *:first-child {
        margin-top: 1rem;
    }

    & > *:not(:first-child) {
        margin-top: 2rem;
    }

    a,
    span {
        opacity: 0.71;
    }

    span {
        padding: 0;
    }
`;

export const StyledSecondLevelItem = styled.li`
    a,
    span {
        font-size: 1.5rem;
        padding-block: 1rem;
        padding-left: 2rem;
    }
`;

export const StyledThirdLevelItem = styled.li`
    a,
    span {
        font-size: 1.5rem;
        padding-block: 1rem;
        padding-left: 2rem;
    }
`;
