import { SvgIcon } from '../svg-icon';
import { SvgIconProps } from '../svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 51 51',
    // width: 29.992
};

export const TracoeNeonatesIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path d="M25.9 1.39997C39.3 1.39997 50.1 12.3 50.1 25.6C50.1 38.9 39.2 49.9 25.9 49.9C12.6 49.9 1.59999 39 1.59999 25.6C1.59999 12.2 12.5 1.39997 25.9 1.39997ZM25.9 0.599976C12.1 0.599976 0.899994 11.8 0.899994 25.6C0.899994 39.4 12.1 50.6 25.9 50.6C39.7 50.6 50.9 39.4 50.9 25.6C50.9 11.8 39.7 0.599976 25.9 0.599976Z"/>
            <path d="M25.9 18.9C28.33 18.9 30.3 16.93 30.3 14.5C30.3 12.0699 28.33 10.1 25.9 10.1C23.4699 10.1 21.5 12.0699 21.5 14.5C21.5 16.93 23.4699 18.9 25.9 18.9Z"/>
            <path d="M37.1 24.1C36.9 24 32.2 20.3 25.8 20.3C19.4 20.3 14.8 23.9 14.6 24.1C14.1 24.5 14 25.2 14.4 25.7C14.8 26.2 15.5 26.3 16 25.9C16 25.9 18.1 24.2 21.4 23.3V27.6C21.4 28.1 21.8 28.5 22.3 28.5H29.4C29.9 28.5 30.3 28.1 30.3 27.6V23.4C33.5 24.4 35.7 26 35.7 26C35.9 26.2 36.2 26.2 36.4 26.2C36.7 26.2 37.1 26 37.3 25.7C37.7 25.3 37.6 24.5 37.1 24.1Z"/>
            <path d="M21.1 40.6C20.5 40.6 20 40.3 19.7 39.8L18 37C17.1 35.4 17.5 33.4 19 32.3L21.7 30.5C22.5 30 23.5 30.1 24 30.9C24.5 31.7 24.3 32.7 23.6 33.2L20.9 35C20.8 35.1 20.8 35.2 20.8 35.3L22.5 38.1C23 38.9 22.7 39.9 21.9 40.3C21.7 40.5 21.4 40.6 21.1 40.6Z"/>
            <path d="M30.6 40.6C30.3 40.6 30 40.5 29.7 40.4C28.9 39.9 28.7 38.9 29.1 38.2L30.8 35.4C30.8 35.3 30.8 35.2 30.7 35.1L28.1 33.3C27.3 32.8 27.2 31.8 27.7 31C28.2 30.2 29.2 30.1 30 30.6L32.7 32.4C34.2 33.4 34.7 35.5 33.7 37.1L32 39.8C31.7 40.3 31.2 40.6 30.6 40.6Z"/>
        </SvgIcon>
    );
};



