import { createEffect, createSignal, For, Match, onCleanup, Show, Switch } from 'solid-js';
import { Section } from '../../../grid-system/section/section';
import { TopCategory, eventsStore } from '../../../stores/events-store';
import { Button } from '../../../ui-components/button/button';
import { Heading } from '../../../ui-components/heading/heading';
import { Checkbox } from '../../../ui-components/inputs/checkbox/checkbox';
import { StyledFlexRow } from '../../../ui-components/utility-style-components/flex';
import { Tag } from '../../event/event-types';
import { CrossIcon } from '../../icons-library/cross';
import {
    StyledHeadingContainer,
    StyledList,
    StyledMobileFilterModalContentWrapper,
    StyledMobileFiltersModal,
} from '../../products/mobile/mobile-filters-modal.style';
import { Labels } from '../events-types';
import { Text } from '../../../ui-components/text/text';

type MobileFilterModalProps = {
    closeModal: () => void;
    labels?: Labels;
    eventsCategoriesHeading: string;
    eventsTagsHeading: string;
    eventsLanguagesHeading: string;

    handleOnClickCategory: (category: TopCategory) => void;
    handleOnClickTag: (tag: Tag) => void;
    handleOnClickLanguage: (language: string) => void;
};

export const MobileFilterModal = (props: MobileFilterModalProps) => {
    const [ inputRef, setInputRef ] = createSignal<HTMLInputElement | undefined>(undefined);

    createEffect(() => {
        if (!inputRef()) return;

        inputRef()!.tabIndex = -1;
        inputRef()!.focus();
        inputRef()!.tabIndex = 0;
    });

    const onKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.closeModal();
        }
    };

    document.addEventListener('keydown', onKeyPress);

    onCleanup(() => {
        document.removeEventListener('keydown', onKeyPress);
    });

    return (
        <StyledMobileFiltersModal onScroll={(e) => e.preventDefault()}>
            <Section templateShorthand={[12]} widthType={'bgFull'} backgroundValue={'white'} backgroundType={'color'} heightType={'fill'}>
                <StyledMobileFilterModalContentWrapper>
                    <StyledFlexRow justifyContent="end">
                        <CrossIcon size="small" onClick={props.closeModal} />
                    </StyledFlexRow>

                    <StyledHeadingContainer>
                        <Heading noBlockSpacing={true} tag="h3" variant="small">
                            {props.eventsLanguagesHeading}
                        </Heading>
                    </StyledHeadingContainer>
                    <Show when={eventsStore.availableLanguages.length > 0}>
                        <StyledList>
                            <For each={eventsStore.availableLanguages}>
                                {(language: any, index) => (
                                    <li>
                                        <Checkbox
                                            value={language}
                                            inputRef={(el: HTMLInputElement) => index() === 0 && setInputRef(el)}
                                            whenClicked={() => props.handleOnClickLanguage(language)}
                                            name={language}
                                            isChecked={!!eventsStore?.selectedLanguages?.find((existing) => existing === language)}
                                        />
                                    </li>
                                )}
                            </For>
                        </StyledList>
                    </Show>

                    <StyledHeadingContainer>
                        <Heading noBlockSpacing={true} tag="h3" variant="small">
                            {props.eventsCategoriesHeading}
                        </Heading>
                    </StyledHeadingContainer>
                    <Switch>
                        <Match when={eventsStore.availableCategories.length === 0}>
                            <Text fontSize="small" displayRedVerticalLine={false}>
                                {props?.labels?.noCategoriesFound || 'No categories found'}
                            </Text>
                        </Match>
                        <Match when={eventsStore.selectedLanguages.length === 0 && eventsStore.availableCategories.length}>
                            <Text fontSize="small" displayRedVerticalLine={false}>
                                {props?.labels?.noLanguageSelected || 'No language selected'}
                            </Text>
                        </Match>
                        <Match when={eventsStore.selectedLanguages.length > 0 && eventsStore.availableCategories.length > 0}>
                            <StyledList>
                                <For each={eventsStore.availableCategories}>
                                    {(category) => (
                                        <li>
                                            <Checkbox
                                                value={category?.slug}
                                                whenClicked={() => props.handleOnClickCategory(category)}
                                                name={category?.name}
                                                isChecked={!!eventsStore?.selectedCategories?.find((existing) => existing?.slug === category?.slug)}
                                            />
                                        </li>
                                    )}
                                </For>
                            </StyledList>
                        </Match>
                    </Switch>

                    <StyledHeadingContainer>
                        <Heading noBlockSpacing={true} tag="h3" variant="small">
                            {props.eventsTagsHeading}
                        </Heading>
                    </StyledHeadingContainer>
                    <Switch>
                        <Match when={eventsStore.availableTags.length === 0}>
                            <Text fontSize="small" displayRedVerticalLine={false}>
                                {props?.labels?.noTagsFound || 'No tags found'}
                            </Text>
                        </Match>
                        <Match when={eventsStore.selectedLanguages.length === 0 && eventsStore.availableTags.length}>
                            <Text fontSize="small" displayRedVerticalLine={false}>
                                {props?.labels?.noLanguageSelected || 'No language selected'}
                            </Text>
                        </Match>
                        <Match when={eventsStore.selectedLanguages.length > 0 && eventsStore.availableTags.length > 0}>
                            <StyledList>
                                <For each={eventsStore.availableTags}>
                                    {(tag) => (
                                        <li>
                                            <Checkbox
                                                value={tag?.slug}
                                                whenClicked={() => props.handleOnClickTag(tag)}
                                                name={tag?.name}
                                                isChecked={!!eventsStore?.selectedTags?.find((existing) => existing?.slug === tag?.slug)}
                                            />
                                        </li>
                                    )}
                                </For>
                            </StyledList>
                        </Match>
                    </Switch>

                    <StyledFlexRow justifyContent="center">
                        <Button label={props.labels?.showEventsButtonText || 'Show events'} onClick={props.closeModal} />
                    </StyledFlexRow>
                </StyledMobileFilterModalContentWrapper>
            </Section>
        </StyledMobileFiltersModal>
    );
};
