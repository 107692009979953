import { Match, Show, Switch, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import theme from '../../style/theme';
import { User } from '../../types/shared';
import { LoginMenuDesktop } from './login-menu-desktop/login-menu-desktop';
import { LoginMenuMobile } from './login-menu-mobile/login-menu-mobile';
import { StyledLoginMenu } from './login-menu.styles';

export const LoginMenu = (props: LoginMenuProps) => {

    const { viewport, updateUser, siteInfo, appStore } = useContext(AppContext);

    const { loginMenuOpen, closeLoginMenu } = appStore;

    const logOut = async () => {
        const response = await fetch('/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                siteType: siteInfo.siteType,
            }),
        });
        
        if (response.ok) {
            closeLoginMenu();
            updateUser();
        }
    };

    // if user hits esc key, close the login menu
    onMount(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                closeLoginMenu();
            }
        });
    });

    return (
        <Show when={loginMenuOpen()}>
            <StyledLoginMenu>
                <Switch>
                    <Match when={viewport.width > theme.breakpoints.TABLET}>
                        <LoginMenuDesktop
                            user={props.user}
                            handleCloseMenuClick={() => closeLoginMenu()}
                            handleSignOutClick={() => logOut()}
                        />
                    </Match>
                    <Match when={viewport.width <= theme.breakpoints.TABLET}>
                        <LoginMenuMobile
                            user={props.user}
                            handleCloseMenuClick={() => closeLoginMenu()}
                            handleSignOutClick={() => logOut()}
                        />
                    </Match>
                </Switch>
            </StyledLoginMenu>
        </Show>
    );
};
export type LoginMenuProps = {
    user: User | null;
};
