import { For, Match, Show, Switch } from 'solid-js';
import theme from '../../../style/theme';
import { ErrorCatcher } from '../../../tools/error-catcher';
import urlMatches from '../../../tools/url-match';
import { CustomSidebarMenuLink } from './custom-link';
import { StyledSecondLevelItem, StyledSidebarDropdown, StyledThirdLevelItem } from './sidebar-menu-mobile.style';
import { MenuItem } from '../desktop/sidebar-menu-desktop';

export const SidebarMenuMobileDropdown = (props: any) => {
    const openMenuItems = (item: MenuItem) => {
        let childItemMatches = false;
        if (!item.children) {
            return false;
        } else {
            // We go deeper
            item.children.map((childItem: MenuItem) => {
                if (openMenuItems(childItem)) {
                    childItemMatches = true;
                }
            });
        }

        if (childItemMatches) {
            return true;
        }

        if (urlMatches(item.url)) {
            return true;
        }

        return item.children.some((child) => urlMatches(child.url));
    };
    
    return (
        <ErrorCatcher componentName="CustomSidebarMenuLink">
            <StyledSidebarDropdown>
                <For each={props.menu}>
                    {(item: MenuItem) => (
                        <li>
                            <CustomSidebarMenuLink
                                size={'medium'}
                                plainStyle={true}
                                bold={true}
                                labelColor={theme.palette.white}
                                url={item.url}
                                label={item.title}
                                breakWords={true}
                            />
                            <Show when={openMenuItems(item)}>
                                <ul>
                                    <For each={item.children}>
                                        {(childLink) => (
                                            <StyledSecondLevelItem>
                                                <Switch>
                                                    <Match when={childLink?.url?.includes('#')}>
                                                        <CustomSidebarMenuLink
                                                            label={childLink?.title}
                                                            anchorLink={childLink?.url}
                                                            url={location.pathname + (childLink?.url || '#')}
                                                            plainStyle={true}
                                                            labelColor={theme.palette.white}
                                                            bold={false}
                                                            size={'medium'}
                                                            breakWords={true}
                                                        />
                                                    </Match>
                                                    <Match when={!childLink?.url?.includes('#')}>
                                                        <CustomSidebarMenuLink
                                                            plainStyle={true}
                                                            bold={false}
                                                            labelColor={theme.palette.white}
                                                            url={childLink?.url}
                                                            label={childLink?.title}
                                                            size={'medium'}
                                                            breakWords={true}
                                                        />
                                                    </Match>
                                                </Switch>

                                                <Show when={openMenuItems(childLink)}>
                                                    <ul>
                                                        <For each={childLink.children}>
                                                            {(secondChildLink) => (
                                                                <StyledThirdLevelItem>
                                                                    <Switch>
                                                                        <Match when={secondChildLink?.url?.includes('#')}>
                                                                            <CustomSidebarMenuLink
                                                                                label={secondChildLink?.title}
                                                                                anchorLink={secondChildLink?.url}
                                                                                url={location.pathname + (secondChildLink?.url || '#')}
                                                                                plainStyle={true}
                                                                                labelColor={theme.palette.white}
                                                                                bold={false}
                                                                                size={'medium'}
                                                                                breakWords={true}
                                                                            />
                                                                        </Match>
                                                                        <Match when={!secondChildLink?.url?.includes('#')}>
                                                                            <CustomSidebarMenuLink
                                                                                plainStyle={true}
                                                                                bold={false}
                                                                                labelColor={theme.palette.white}
                                                                                url={secondChildLink?.url}
                                                                                label={secondChildLink?.title}
                                                                                size={'medium'}
                                                                                breakWords={true}
                                                                            />
                                                                        </Match>
                                                                    </Switch>
                                                                </StyledThirdLevelItem>
                                                            )}
                                                        </For>
                                                    </ul>
                                                </Show>
                                            </StyledSecondLevelItem>
                                        )}
                                    </For>
                                </ul>
                            </Show>
                        </li>
                    )}
                </For>
            </StyledSidebarDropdown>
        </ErrorCatcher>
    );
};
