/* eslint-disable indent */

import { theme, FlexAlignment, HorizontalAlignment } from '../style/theme';
import { HeightType, WidthType } from './shared-types';
import { ResponsiveTemplate } from './grid/grid.d';
import { containerQueries } from '../tools/check-container-support';

const {grid} = theme;

type GridBaseProps = {
    childSpans: number[];
    widthType: WidthType;
    gridColumns: number | undefined;
    isLayoutGrid: boolean | undefined;
    inheritParentGrid?: boolean | undefined;
}

type ItemStylingProps = {
    hAlign: HorizontalAlignment | undefined;
    equalHeight: boolean | undefined;
    spans: number[];
    backgroundValue?: string | undefined;
}

export type GridItemsProps = {
    childSpans: number[];
    autoColWidth?: number | undefined;
    start: number;
    responsive?: ResponsiveTemplate;
    inheritParentGrid?: boolean;
    childCount: number;
    autoResponsiveness: boolean | undefined;
}

type BlockBufferType = {
    customHeight: string | undefined,
    heightType: HeightType | undefined,
    removeSpacingBlock: boolean | undefined,
    backgroundValue?: string | undefined,
}

export const gridBase = (p: GridBaseProps) => {
    const colWidthValue = p.widthType === 'full' ? '1fr' : `${grid.colSize()}rem`;

    const inheritedColCount = p.childSpans?.reduce((total, count) => {
        return total + count;
    }, 0);

    const columns = p.gridColumns || inheritedColCount || grid.columns;

    return `
        display: grid;
        grid-template-rows: auto;
        height: fit-content;
        column-gap: ${grid.gapSize}rem;
        row-gap: ${p.childSpans.length > 1 ? grid.standardUnit : 0}rem;
        ${p.isLayoutGrid ? `
            grid-template-columns:
                auto
                ${grid.standardUnit}rem
                repeat(${columns}, minmax(0, ${colWidthValue}))
                ${grid.standardUnit}rem
                auto;
        ` : `
            grid-template-columns:
                repeat(${columns}, 1fr);
        `}
    `;
};

export const gridItems = (p: GridItemsProps) => {
    const desktopQuery = [];

    for (let i = 0; i < p.childCount; i++) {            
        const span = p.childSpans[i % p.childSpans.length];
    
        desktopQuery.push(`
            & > *:nth-child(${i + 1}) {
                ${i === 0 ? `grid-column-start: ${p.start};` : ''}
                grid-column-end: span ${span};
            }
        `);
    }

    return desktopQuery.join(' ');
};

export const blockBuffer = (p: BlockBufferType) => {
    const defaultSpace = {
        topPadding: `padding-top: ${Math.floor(grid.standardUnit * 1.5)}rem;`,
        bottomPadding: `padding-bottom: ${Math.floor(grid.standardUnit * 1.5)}rem;`,
        blockSpacing: `margin-block: ${grid.standardUnit}rem;`,
    };
    
    if (!p.customHeight) {
        return p.heightType !== 'fill' ? `
            ${defaultSpace.topPadding}
            ${defaultSpace.bottomPadding}
        ` : '';
    }

    if (p.removeSpacingBlock) return '';
    return defaultSpace.blockSpacing;
};

export const itemStyling = (p: ItemStylingProps) => {
    const base = `
        position: relative;
        height: auto;
        ${p.hAlign ? `justify-self: ${FlexAlignment[p.hAlign]};` : ''}
        ${p.equalHeight ? 'height: 100%;' : 'height: auto;'}
        
        ${containerQueries('tabletMax')} {
            height: auto;
        }
    `;

    return p.spans.map((_, index: number) => {
        return `
            & > *:nth-child(${index + 1}) {
                ${base}
            }
        `;
    }).join(' ');
};
