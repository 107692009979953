import { useLocation } from '@solidjs/router';
import { For, Match, Show, Switch, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import theme from '../../../style/theme';
import { ErrorCatcher } from '../../../tools/error-catcher';
import urlMatches from '../../../tools/url-match';
import { Link } from '../../../ui-components/link/link';
import { PageContext } from '../../page/page-context-provider';
import { StyledSecondLevelItem, StyledSecondLevelItems, StyledSecondaryMenu, StyledThirdLevelItem, StyledThirdLevelItems } from './sidebar-menu-desktop.style';

export type MenuContent = {
    menu: MenuItem[];
    menuName: string;
};

export type MenuItem = {
    url: string;
    title: string;
    children?: MenuItem[];
};

export const SidebarMenuDesktop = () => {
    const { viewport } = useContext(AppContext);
    const { sidebarMenu } = useContext(PageContext);

    const location = useLocation();

    const isTablet = () => viewport.width <= 750;

    const openMenuItems = (item: MenuItem) => {
        let childItemMatches = false;
        if (!item.children) {
            return false;
        } else {

            // We go deeper
            item.children.map((childItem: MenuItem) => {
                if (openMenuItems(childItem)) {
                    childItemMatches = true;
                }
            });
        }

        if (childItemMatches) {
            return true;
        }

        if (urlMatches(item.url)) {
            return true;
        }

        return item.children.some((child) => urlMatches(child.url));
    };

    return (
        <ErrorCatcher componentName="Secondary menu">
            <StyledSecondaryMenu>
                <For each={sidebarMenu()?.menu}>
                    {(item: MenuItem) => (
                        <li>
                            <Link
                                size={isTablet() ? 'small' : 'medium'}
                                plainStyle={true}
                                bold={true}
                                labelColor={theme.palette.darkGray}
                                url={item.url}
                                label={item.title}
                                breakWords={true}
                            />
                            <Show when={openMenuItems(item)}>
                                <StyledSecondLevelItems>
                                    <For each={item.children}>
                                        {(childLink) => (
                                            <StyledSecondLevelItem>
                                                <Switch>
                                                    <Match when={childLink?.url?.includes('#')}>
                                                        <Link
                                                            label={childLink?.title}
                                                            anchorLink={childLink?.url}
                                                            url={location.pathname + (childLink?.url || '#')}
                                                            plainStyle={true}
                                                            labelColor={theme.palette.gray}
                                                            bold={false}
                                                            size={isTablet() ? 'small' : 'medium'}
                                                            breakWords={true}
                                                        />
                                                    </Match>
                                                    <Match when={!childLink?.url?.includes('#')}>
                                                        <Link
                                                            plainStyle={true}
                                                            bold={false}
                                                            labelColor={theme.palette.gray}
                                                            url={childLink?.url}
                                                            label={childLink?.title}
                                                            size={isTablet() ? 'small' : 'medium'}
                                                            breakWords={true}
                                                        />
                                                    </Match>
                                                </Switch>

                                                <Show when={openMenuItems(childLink)}>
                                                    <StyledThirdLevelItems>
                                                        <For each={childLink.children}>
                                                            {(secondChildLink) => (
                                                                <StyledThirdLevelItem>
                                                                    <Switch>
                                                                        <Match when={secondChildLink?.url?.includes('#')}>
                                                                            <Link
                                                                                label={secondChildLink?.title}
                                                                                anchorLink={secondChildLink?.url}
                                                                                url={location.pathname + (secondChildLink?.url || '#')}
                                                                                plainStyle={true}
                                                                                labelColor={theme.palette.gray}
                                                                                bold={false}
                                                                                size={isTablet() ? 'small' : 'medium'}
                                                                                breakWords={true}
                                                                            />
                                                                        </Match>
                                                                        <Match when={!secondChildLink?.url?.includes('#')}>
                                                                            <Link
                                                                                plainStyle={true}
                                                                                bold={false}
                                                                                labelColor={theme.palette.gray}
                                                                                url={secondChildLink?.url}
                                                                                label={secondChildLink?.title}
                                                                                size={isTablet() ? 'small' : 'medium'}
                                                                                breakWords={true}
                                                                            />
                                                                        </Match>
                                                                    </Switch>
                                                                </StyledThirdLevelItem>
                                                            )}
                                                        </For>
                                                    </StyledThirdLevelItems>
                                                </Show>

                                            </StyledSecondLevelItem>
                                        )}
                                    </For>
                                </StyledSecondLevelItems>
                            </Show>
                        </li>
                    )}
                </For>
            </StyledSecondaryMenu>
        </ErrorCatcher>
    );
};
