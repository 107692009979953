import { styled } from 'solid-styled-components';

// TODO: Make sizes here controlled maybe?

export const StyledVerticalSpace = styled.div<SpaceElementProps>`
    height: ${(p) => (p.size ? p.size : 1)}rem;
`;

export const StyledVerticalPixelSpace = styled.div<SpaceElementProps>`
    height: ${(p) => (p.size ? p.size : 11)}px;
`;

export const StyledHorizontalSpace = styled.div<SpaceElementProps>`
    width: ${(p) => (p.size ? p.size : 1)}rem;
`;

type SpaceElementProps = {
    size?: number;
};
