/* eslint-disable indent */

import { Accessor } from 'solid-js';
import { ImageSrcData } from '../../components/image-component/image-component';
import { ColorName, theme } from '../../style/theme';
import { containerQueries } from '../../tools/check-container-support';
import { overlayStyle } from '../../ui-components/layouts/background-image-overlay';
import { OverlayType } from '../section/section-types';

const {grid} = theme;

type GridBaseProps = {
    bgValue: ColorName;
    extendBg: boolean | undefined;
    extendBgColorSide?: 'auto' | 'left' | 'right';
    withSidebarMenu?: Accessor<boolean>,
}

export const gridBase = (p: GridBaseProps) => {
    return `
        &.atos-column {
            display: grid;
            row-gap: 0px;
            grid-template-rows: auto;
            grid-template-columns:
                0px
                auto
                0px;
            &:first-of-type {
                &.extend-bg-color-left, &:not(.with-bg-color) {
                    ${containerQueries('tabletMin')} {
                        column-gap: 0;
                    }
                }
                &.combine-with-right:not(.with-bg-color), &.extend-bg-color-left {
                    ${containerQueries('tabletMin')} {
                        padding-right: ${grid.gapSizeTablet}rem;
                    }
                }
            }
    
            &:last-of-type {
                ${p.bgValue !== 'transparent'
                    ?  p.extendBg && p.extendBgColorSide === 'right'
                        ? `;
                            ${containerQueries('tabletMin')} {
                                column-gap: 0;
                                padding-left: ${grid.gapSizeTablet}rem;
                            }
                        `
                        : `
                            ${containerQueries('tabletMin')} {
                                ${p.extendBg && p.extendBgColorSide !== 'right'} {
                                    column-gap: ${grid.gapSizeTablet}rem;
                                }
                            }`
                    : `
                        ${containerQueries('tabletMin')} {
                            column-gap: 0;
                        }
                    `
                }
            }
    
            &:not(:first-of-type):not(:last-of-type) {
                &.with-bg-color {
                    ${containerQueries('tabletMin')} {
                        column-gap: ${grid.gapSizeTablet}rem;
                    }
                }
                &:not(.with-bg-color) {
                    ${containerQueries('tabletMin')} {
                        column-gap: 0px;
                    }
                }
                &.combine-with-right:not(.with-bg-color) {
                    ${containerQueries('tabletMin')} {
                        padding-right: ${grid.gapSizeTablet}rem;
                    }
                }
            }
        }
    `;
};

export const gridItems = () => {
    return `
        & > * {
            grid-column-start: 2;
            grid-column-end: span 1;
            grid-row-end: span 1;
            grid-row-start: auto;
        }
    `;
};

type ExtendBgColorProps = {
    extendBg: boolean | undefined,
    bgValue: ColorName,
    withSidebarMenu: Accessor<boolean>,
    extendBgColorSide: 'auto' | 'left' | 'right' | undefined,
    combineWithRightColumn: boolean | undefined
    bgImage: string | undefined,
}
export const extendBgColor = (props: ExtendBgColorProps) => {    
    const leftPartialStyle = `
        ${containerQueries('mobileMin')} {
            ${props.withSidebarMenu()
                ? `
                    &.with-image {
                        margin-left: calc((${grid.standardUnit * 2}rem + 3px) * -1);
                    }
                `
                : `
                    ${containerQueries('desktopMax')} {
                        &.with-image {
                            margin-left: ${theme.grid.standardUnit * 1.5 * -1}rem;
                        }
                    }
                `
            }
        }

        ${ props.withSidebarMenu() 
            ? `
                &.with-image {
                    @media (max-width: ${theme.breakpoints.MOBILE}px) {
                        &::after {
                            content: ' ';
                            position: absolute;
                            background-color: inherit;
                            left: calc((${grid.standardUnit * 2}rem + 3px) * -1);
                            height: 100%;
                            right: 100%;
                    
                            ${containerQueries('tabletMax')} {
                                left: calc((${grid.standardUnit * 2}rem) * -1);
                            }
                        }
                    }
                }

                &:not(.with-image) {
                    &::after {
                        content: ' ';
                        position: absolute;
                        background-color: inherit;
                        left: calc((${grid.standardUnit * 2}rem + 3px) * -1);
                        height: 100%;
                        right: 100%;
                        z-index: -1;
                
                        ${containerQueries('tabletMax')} {
                            left: calc((${grid.standardUnit * 2}rem) * -1);
                        }
                    }
                }

                @media (max-width: ${theme.breakpoints.TABLET}px) {
                    &.with-image {
                        margin-left: ${theme.grid.standardUnit * 1.5 * -1}rem;
                    }
                }
            `
            : `
                &::after {
                    content: ' ';
                    position: absolute;
                    background-color: inherit;
                    left: -1000%;
                    height: 100%;
                    right: calc(100% - 1px);
                }
            `
        }
    `;

    const leftStyle = `
        ${props.combineWithRightColumn ? `
            & + .atos-column.no-extend.with-bg-color:not(.combine-with-right) {
                border-bottom-left-radius: 0px;
            }
            & + .atos-column.no-extend.with-bg-color {
                ${containerQueries('tabletMax')} {
                    ${leftPartialStyle}
                }
            }
            & + .atos-column.combine-with-right.with-bg-color + .atos-column.no-extend.with-bg-color {
                ${containerQueries('tabletMax')} {
                    border-bottom-left-radius: 0;
                    ${leftPartialStyle}
                }
            }
            & + .atos-column.combine-with-right.with-bg-color + .atos-column.no-extend.with-bg-color + .atos-column.no-extend.with-bg-color {
                ${containerQueries('tabletMax')} {
                    border-bottom-left-radius: 0;
                    ${leftPartialStyle}
                }
            }
        ` : ''}

        ${leftPartialStyle}
    `;

    const rightStyle = `
        &.with-image {
            ${containerQueries('desktopMax')} {
                margin-right: ${theme.grid.standardUnit * 1.5 * -1}rem;
            }
        }

        &::after {
            content: ' ';
            position: absolute;
            background-color: ${theme.palette[props.bgValue]};
            right: -1000%;
            height: 100%;
            left: calc(100% - 1px);
        }
    `;

    if (!props.extendBg) {
        return `
            &.combine-with-right:has(+ .atos-column.extend-bg-color-right) {
                border-top-right-radius: 0px;
                ${containerQueries('tabletMax')} {
                    ${rightStyle}
                }
            }
            &.combine-with-right:has(+ .atos-column.combine-with-right + .atos-column.extend-bg-color-right) {
                border-top-right-radius: 0px;
                ${containerQueries('tabletMax')} {
                    ${rightStyle}
                }                
            }
            &.combine-with-right:has(+ .atos-column.combine-with-right + .atos-column.combine-with-right + .atos-column.extend-bg-color-right) {
                border-top-right-radius: 0px;
                ${containerQueries('tabletMax')} {
                    ${rightStyle}
                }                
            }
        `;
    }

    const left = !props.extendBgColorSide || props.extendBgColorSide !== 'right';
    return left ? leftStyle : rightStyle;
};

export const bgImage = (backgroundImageSrcData: ImageSrcData | undefined, focusPosition: string, overlayType?: OverlayType,) => {
    if (!backgroundImageSrcData || backgroundImageSrcData.original === '') return '';
    
    return `
        background-image: url(${backgroundImageSrcData.original});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: ${focusPosition || 'center'};
        ${overlayStyle(overlayType)}
    `;
};

export const redDividerStyleDesktop = (redDividerEnabled: boolean | undefined, backgroundImage: string | undefined) => {
    if (!redDividerEnabled || backgroundImage) {
        return;
    }

    return `
        & + .atos-column {
            ${containerQueries('tabletMax')} {
                padding-top: 3.5rem;
            }
        }
        &:not(:has(+ .with-image)) {
            &::before {
                content: ' ';
                width: ${theme.redLineThickness};
                background-color: ${theme.palette.red};
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                height: 70%;
                margin: auto;
                ${containerQueries('tabletMax')} {
                    width: auto;
                    top: unset;
                    left: ${grid.gapSizeTablet}rem;
                    right: ${grid.gapSizeTablet}rem;
                    z-index: 1;
                    height: ${theme.redLineThickness};
                    ${theme.mediaQueries.mobileMax} {
                        left: ${grid.gapSizeMobile * 1.5}rem;
                        right: ${grid.gapSizeMobile * 1.5}rem;
                    }
                }
            }
        }
    `;
};
