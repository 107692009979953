export const SwirlDesktop = (props: {colorProp?: string}) => {
    const color = props.colorProp || '#fff';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1932.643 577.533">
            <defs>
                <clipPath id="clip-path-swirl">
                    <rect id="Rectangle_1286" data-name="Rectangle 1286" width="1932.643" height="577.533" fill="none" stroke={color} stroke-width="1" />
                </clipPath>
            </defs>
            <g id="Group_1450" data-name="Group 1450" transform="translate(-12.644 2)" opacity="0.8" style="mix-blend-mode: normal;isolation: isolate">
                <g id="Group_1468" data-name="Group 1468" transform="translate(12.644 -2)" clip-path="url(#clip-path-swirl)">
                    <path id="Path_171" data-name="Path 171" d="M1932.644,233.266c-69.147,0-68.951,97.407-138.046,97.407-68.837,0-69.342-114.686-138.626-114.686-68.442,0-68.928,188.753-137.466,188.753-69.2,0-69.022-127.83-138.046-127.83-69.087,0-69.324,124.634-138.045,124.634s-59.618-101.123-128.9-101.123S1044.847,442.7,975.464,442.7s-77.9-221.14-147.188-221.14S758.952,370.129,690.229,370.129s-68.959-92.318-138.045-92.318c-69.022,0-68.847,69.551-138.046,69.551-68.538,0-69.024-31.285-137.466-31.285-69.285,0-69.788,42.088-138.627,42.088-69.095,0-68.9-124.9-138.045-124.9" transform="translate(0 134.629)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_172" data-name="Path 172" d="M0,232.529c70.837,0,71.431,119.241,140.916,118.929,25.624-.07,42.029-5.86,56.336-13.024,23.585-11.844,40.512-28.023,82.932-28.686,67.421-1.177,68.918,27.108,136.7,26.2,27.189-.346,43.947-11.146,58.738-23.894,22.562-19.295,39.575-43.132,80.52-42.585,68.389,1.094,69.806,91.5,139.4,91.5,71.4,0,71.424-149.966,143.355-149.966,68.421,0,77.807,213.5,145.516,214.642,67.46.859,67.5-134.932,134.818-134.007,67.214.782,59.094,98.735,125.962,99.238,55.159.446,65.71-80.674,105.647-109.3a50.321,50.321,0,0,1,30.189-9.826c67.762-.054,68.593,122.613,135.979,122.286,67.2-.5,68.615-182.669,135.89-183.089,67.439-.235,68.905,109.886,136.393,109.886,71.767,0,71.53-91.037,143.355-91.037" transform="translate(0 131.49)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_173" data-name="Path 173" d="M0,233.889c72.527,0,73.911,113.582,143.786,112.959,25.306-.138,41.877-5.742,56.62-12.618,24.236-11.37,41.62-27.389,83.289-28.713,66.4-2.355,68.908,22.929,135.932,21.123,26.851-.691,43.77-11.337,59.1-23.52,23.421-18.608,41.044-41.083,81.371-39.885,67.7,2.015,70.286,90.682,140.748,90.682,74.082,0,74.088-151.363,148.665-151.363,67.556,0,77.806,205.858,143.842,208.145,65.538,1.717,66.233-127.447,131.591-125.736,65.136,1.706,58.008,96.349,123.018,97.354,53.682.9,64.663-76.692,104.056-104.177a50.225,50.225,0,0,1,29.57-9.443c66.5-.106,68.342,117.564,133.912,116.743,65.87-.825,68.2-176.767,134.313-177.424,65.592-.653,68.022,105.082,134.159,105.082,74.441,0,74.163-84.667,148.665-84.667" transform="translate(0 126.255)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_174" data-name="Path 174" d="M0,235.271c74.217,0,76.389,107.749,146.656,106.99,24.986-.271,41.726-5.625,56.9-12.216,24.887-10.9,42.737-26.642,83.646-28.741,65.389-3.355,68.9,18.754,135.167,16.043,26.51-1.037,43.62-11.5,59.469-23.148,24.321-17.874,42.513-38.978,82.218-37.182,67.006,3.027,70.769,89.862,142.1,89.862,76.762,0,76.754-152.761,153.973-152.761,66.692,0,77.807,198.217,142.169,201.649,63.62,2.576,64.968-120.033,128.366-117.463,63.058,2.555,56.922,93.959,120.075,95.469,52.2,1.342,63.616-72.713,102.466-99.052a50.145,50.145,0,0,1,28.952-9.061c65.243-.159,68.09,112.425,131.848,111.2,64.535-1.24,67.79-170.768,132.736-171.759,63.745-.972,67.139,100.281,131.926,100.281,77.113,0,76.794-78.3,153.975-78.3" transform="translate(0 120.998)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_175" data-name="Path 175" d="M0,236.653c75.906,0,78.867,102.04,149.524,101.019,24.668-.357,41.575-5.506,57.188-11.81,25.538-10.423,43.851-25.975,84-28.768,64.376-4.48,68.889,14.576,134.4,10.961,26.17-1.381,43.474-11.66,59.832-22.775,25.228-17.141,43.985-36.867,83.069-34.481,66.314,4.047,71.251,89.044,143.449,89.044,79.442,0,79.419-154.159,159.283-154.159,65.829,0,77.807,190.576,140.5,195.152,61.7,3.435,63.7-112.621,125.139-109.191,60.978,3.4,55.834,91.572,117.132,93.583,50.727,1.791,62.626-68.645,100.875-93.925a50.312,50.312,0,0,1,28.333-8.68c63.983-.213,67.84,107.282,129.781,105.655,63.2-1.659,67.377-164.769,131.159-166.095,61.9-1.287,66.256,95.48,129.694,95.48,79.783,0,79.426-71.928,159.283-71.928" transform="translate(0 115.74)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_176" data-name="Path 176" d="M0,238.034c77.6,0,81.346,96.334,152.394,95.049,24.348-.44,41.437-5.354,57.472-11.405,26.21-9.893,44.964-25.308,84.359-28.8,63.365-5.609,68.881,10.4,133.637,5.881,25.83-1.727,43.333-11.819,60.2-22.4,26.14-16.4,45.458-34.754,83.918-31.781,65.629,5.071,71.733,88.226,144.8,88.226,82.122,0,82.085-155.556,164.593-155.556,64.962,0,77.807,182.937,138.823,188.656,59.776,4.294,62.43-105.211,121.911-100.92,58.9,4.25,54.748,89.185,114.189,91.7,49.248,2.239,61.59-64.644,99.284-88.8a50.391,50.391,0,0,1,27.717-8.3c62.722-.266,67.59,102.133,127.713,100.11,61.875-2.081,66.964-158.764,129.583-160.429,60.048-1.6,65.373,90.679,127.46,90.679,82.457,0,82.059-65.558,164.593-65.558" transform="translate(0 110.483)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_177" data-name="Path 177" d="M0,239.416c79.286,0,83.823,90.633,155.264,89.078,24.03-.523,41.286-5.23,57.754-11,26.863-9.412,46.081-24.647,84.718-28.824,62.353-6.742,68.869,6.224,132.869.8,25.491-2.073,43.195-11.977,60.561-22.03,27.057-15.663,46.932-32.635,84.768-29.08,64.943,6.1,72.213,87.408,146.152,87.408,84.8,0,84.749-156.954,169.9-156.954,64.1,0,77.807,175.3,137.151,182.158,57.854,5.152,61.157-97.8,118.684-92.646,56.813,5.091,53.662,86.8,111.248,89.813,47.769,2.685,60.551-60.644,97.691-83.674a50.564,50.564,0,0,1,27.1-7.915c61.461-.32,67.342,96.98,125.646,94.567,60.547-2.506,66.55-152.758,128.007-154.764,58.2-1.9,64.49,85.875,125.226,85.875,85.129,0,84.69-59.188,169.9-59.188" transform="translate(0 105.226)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_178" data-name="Path 178" d="M0,240.8c80.976,0,86.3,84.935,158.134,83.109,23.71-.6,41.135-5.11,58.039-10.6,27.514-8.931,47.2-23.986,85.075-28.85,61.344-7.88,68.86,2.045,132.1-4.281,25.15-2.419,43.059-12.131,60.925-21.657,27.978-14.918,48.406-30.514,85.617-26.379,64.261,7.139,72.7,86.588,147.5,86.588,87.481,0,87.412-158.352,175.211-158.352,63.233,0,77.807,167.655,135.476,175.661,55.935,6.011,59.883-90.4,115.459-84.374,54.727,5.93,52.583,84.283,108.3,87.927,46.3,3.029,59.513-56.644,96.1-78.548a50.851,50.851,0,0,1,26.481-7.534c60.2-.372,67.1,91.823,123.58,89.023,59.219-2.935,66.132-146.745,126.431-149.1,56.347-2.2,63.606,81.075,122.992,81.075,87.8,0,87.323-52.818,175.213-52.818" transform="translate(0 99.968)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_179" data-name="Path 179" d="M0,242.18c82.666,0,88.779,79.242,161,77.138,23.39-.682,40.982-4.988,58.323-10.193,28.163-8.451,48.315-23.329,85.43-28.877,60.337-9.019,68.835-2.261,131.338-9.36,24.8-2.818,42.925-12.282,61.288-21.285,28.9-14.17,49.881-28.387,86.468-23.678,63.577,8.183,73.178,85.768,148.854,85.768,90.162,0,90.08-159.748,180.521-159.748,62.37,0,77.807,160.014,133.8,169.165,54.013,6.87,58.6-83,112.231-76.1,52.638,6.766,51.5,81.88,105.362,86.042,44.825,3.464,58.474-52.646,94.508-73.424a51.221,51.221,0,0,1,25.863-7.151c58.941-.425,66.85,86.661,121.514,83.479,57.894-3.368,65.717-140.73,124.854-143.435,54.5-2.492,62.724,76.272,120.76,76.272,90.473,0,89.953-46.446,180.521-46.446" transform="translate(0 94.711)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_180" data-name="Path 180" d="M0,243.561c84.356,0,91.257,73.554,163.873,71.168,23.072-.758,40.831-4.867,58.607-9.787,28.814-7.975,49.435-22.676,85.789-28.9C367.6,265.87,377.091,269.588,438.84,261.6c24.462-3.167,42.794-12.431,61.654-20.913,29.83-13.417,51.355-26.257,87.315-20.977,62.9,9.235,73.66,84.95,150.206,84.95,92.841,0,92.744-161.145,185.831-161.145,61.5,0,77.807,152.375,132.13,162.668,52.091,7.729,57.323-75.6,109-67.83,50.549,7.6,50.417,79.479,102.42,84.158,43.349,3.9,57.432-48.648,92.917-68.3a51.737,51.737,0,0,1,25.244-6.769c57.682-.479,66.6,81.492,119.449,77.936,56.568-3.807,65.3-134.71,123.277-137.77,52.644-2.779,61.84,71.47,118.527,71.47,93.144,0,92.586-40.076,185.831-40.076" transform="translate(0 89.453)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_181" data-name="Path 181" d="M0,244.943c86.047,0,93.735,67.869,166.743,65.2,22.752-.833,40.679-4.748,58.891-9.383,29.463-7.5,50.552-22.025,86.146-28.932,58.324-11.318,68.808-10.633,129.806-19.52,24.121-3.516,42.664-12.576,62.017-20.54,30.762-12.659,52.831-24.123,88.166-18.277,62.215,10.293,74.14,84.132,151.556,84.132,95.521,0,95.407-162.543,191.139-162.543,60.639,0,77.807,144.734,130.458,156.172,50.17,8.586,56.04-68.205,105.776-59.558,48.456,8.423,49.334,77.078,99.477,82.273,41.874,4.337,56.388-44.653,91.326-63.173a52.358,52.358,0,0,1,24.627-6.386c56.42-.532,66.358,76.315,117.381,72.39,55.243-4.25,64.878-128.685,121.7-132.106,50.792-3.057,60.957,66.67,116.293,66.67,95.818,0,95.217-33.707,191.141-33.707" transform="translate(0 84.196)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_182" data-name="Path 182" d="M0,246.326c87.737,0,96.213,62.191,169.613,59.227,22.433-.906,40.526-4.628,59.175-8.977,30.113-7.024,51.67-21.379,86.5-28.96,57.319-12.475,68.8-14.816,129.039-24.6,23.78-3.862,42.537-12.717,62.383-20.166,31.694-11.9,54.307-21.981,89.015-15.576,61.537,11.36,74.624,83.314,152.907,83.314,98.2,0,98.075-163.941,196.451-163.941,59.774,0,77.807,137.093,128.784,149.673,48.248,9.446,54.755-60.816,102.548-51.284,46.36,9.245,48.252,74.679,96.536,80.387,40.4,4.776,55.342-40.658,89.734-58.047a53.193,53.193,0,0,1,24.009-6c55.16-.586,66.115,71.134,115.314,66.847,53.92-4.7,64.459-122.653,120.124-126.44,48.94-3.329,60.074,61.866,114.061,61.866,98.488,0,97.849-27.337,196.449-27.337" transform="translate(0 78.939)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_183" data-name="Path 183" d="M0,247.707c89.427,0,98.69,56.518,172.483,53.256,22.115-.976,40.374-4.51,59.459-8.573,30.76-6.548,52.789-20.735,86.859-28.986,56.315-13.639,68.782-19,128.273-29.684,23.439-4.209,42.409-12.857,62.746-19.795,32.629-11.13,55.784-19.837,89.865-12.873,60.855,12.433,75.1,82.494,154.258,82.494,100.881,0,100.738-165.338,201.759-165.338,58.91,0,77.807,129.452,127.111,143.177,46.328,10.3,53.467-53.433,99.323-43.013,44.263,10.06,47.169,72.283,93.592,78.5,38.924,5.214,54.3-36.666,88.143-52.922a54.244,54.244,0,0,1,23.391-5.623c53.9-.638,65.871,65.946,113.248,61.3,52.6-5.154,64.038-116.616,118.548-120.775,47.086-3.594,59.191,57.065,111.827,57.065,101.162,0,100.482-20.967,201.759-20.967" transform="translate(0 73.681)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_184" data-name="Path 184" d="M0,249.089c91.117,0,101.169,50.849,175.353,47.287,21.795-1.047,40.221-4.393,59.741-8.169,31.411-6.078,53.907-20.1,87.216-29.015,55.308-14.808,68.77-23.176,127.509-34.763,23.1-4.557,42.283-12.995,63.11-19.421,33.565-10.358,57.258-17.688,90.714-10.173,60.175,13.516,75.587,81.676,155.61,81.676,103.561,0,103.4-166.736,207.069-166.736,58.045,0,77.807,121.811,125.437,136.68,44.408,11.164,52.178-46.058,96.1-34.741,42.164,10.867,46.086,69.889,90.649,76.617,37.45,5.656,53.247-32.674,86.552-47.8a55.6,55.6,0,0,1,22.772-5.242c52.641-.692,65.627,60.748,111.181,55.759,51.279-5.617,63.616-110.571,116.972-115.111,45.232-3.847,58.308,52.264,109.594,52.264,103.834,0,103.113-14.6,207.069-14.6" transform="translate(0 68.424)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_185" data-name="Path 185" d="M0,250.47c92.807,0,103.646,45.187,178.221,41.317,21.477-1.114,40.07-4.276,60.027-7.764,32.059-5.6,55.026-19.46,87.573-29.041,54.3-15.983,68.757-27.355,126.742-39.844,22.756-4.9,42.156-13.128,63.475-19.05,34.5-9.582,58.732-15.534,91.563-7.472,59.491,14.607,76.069,80.858,156.96,80.858,106.24,0,106.068-168.134,212.377-168.134,57.18,0,77.807,114.171,123.765,130.184,42.486,12.022,50.886-38.692,92.869-26.468,40.065,11.665,45.005,67.494,87.706,74.733,35.976,6.1,52.2-28.687,84.961-42.672a57.309,57.309,0,0,1,22.153-4.859c51.38-.745,65.386,55.543,109.115,50.216,49.958-6.086,63.2-104.517,115.4-109.446,43.379-4.1,57.425,47.463,107.36,47.463,106.506,0,105.746-8.227,212.379-8.227" transform="translate(0 63.167)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_186" data-name="Path 186" d="M0,251.852c94.5,0,106.125,39.529,181.091,35.346,21.157-1.18,39.917-4.159,60.311-7.36,32.708-5.133,56.141-18.829,87.93-29.069,53.292-17.165,68.744-31.528,125.976-44.924,22.415-5.247,42.029-13.259,63.839-18.676,35.437-8.8,60.2-13.375,92.412-4.773,58.808,15.707,76.551,80.039,158.311,80.039,108.922,0,108.733-169.531,217.689-169.531,56.315,0,77.807,106.53,122.092,123.686,40.564,12.883,49.594-31.333,89.641-18.2,37.964,12.456,43.922,65.1,84.765,72.848,34.5,6.542,51.143-24.7,83.369-37.546a59.444,59.444,0,0,1,21.535-4.475c50.12-.8,65.142,50.328,107.05,44.671,48.636-6.565,62.772-98.454,113.817-103.78,41.526-4.333,56.542,42.659,105.128,42.659,109.178,0,108.376-1.857,217.687-1.857" transform="translate(0 57.91)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_187" data-name="Path 187" d="M0,253.235c96.187,0,108.6,33.879,183.961,29.376,20.839-1.245,39.765-4.042,60.594-6.954,33.358-4.664,57.258-18.2,88.287-29.1,52.282-18.355,68.733-35.7,125.21-50.006,22.076-5.591,41.9-13.388,64.2-18.3,36.374-8.018,61.676-11.209,93.263-2.071C673.639,193,692.55,255.4,775.181,255.4c111.6,0,111.4-170.927,223-170.927,55.451,0,77.807,98.889,120.419,117.189,38.641,13.74,48.3-23.988,86.414-9.923,35.864,13.235,42.841,62.715,81.822,70.963,33.028,6.988,50.089-20.718,81.778-32.421a62.168,62.168,0,0,1,20.918-4.094c48.859-.852,64.9,45.1,104.982,39.127,47.315-7.055,62.35-92.381,112.241-98.117,39.672-4.56,55.659,37.859,102.894,37.859,111.85,0,111.009,4.513,223,4.513" transform="translate(0 52.652)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_188" data-name="Path 188" d="M0,254.616c97.876,0,111.082,28.234,186.831,23.407,20.519-1.308,39.612-3.928,60.878-6.552,34.007-4.193,58.371-17.579,88.645-29.122,51.269-19.551,68.722-39.874,124.444-55.087,21.735-5.933,41.778-13.514,64.568-17.931,37.31-7.229,63.147-9.04,94.112.631,57.43,17.934,77.515,78.4,161.014,78.4,114.28,0,114.063-172.325,228.307-172.325,54.586,0,77.807,91.248,118.744,110.693,36.723,14.6,47.013-16.652,83.189-1.651,33.765,14,41.758,60.329,78.879,69.077,31.554,7.438,49.034-16.738,80.187-27.294a65.825,65.825,0,0,1,20.3-3.713c47.6-.9,64.654,39.869,102.915,33.583,45.994-7.557,61.93-86.3,110.665-92.451,37.82-4.776,54.774,33.057,100.66,33.057,114.524,0,113.64,10.883,228.307,10.883" transform="translate(0 47.395)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_189" data-name="Path 189" d="M0,256c99.566,0,113.563,22.6,189.7,17.436,20.2-1.37,39.455-3.854,61.162-6.146,34.648-3.79,59.482-16.96,89-29.152,50.252-20.753,68.71-44.041,123.678-60.167,21.394-6.276,41.651-13.639,64.931-17.558,38.245-6.438,64.615-6.867,94.961,3.331,56.737,19.064,78,77.582,162.366,77.582,116.96,0,116.726-173.724,233.615-173.724,53.722,0,77.807,83.611,117.072,104.2,34.8,15.457,45.726-9.331,79.962,6.62,31.671,14.756,40.677,57.946,75.936,67.194,30.08,7.888,47.979-12.764,78.6-22.17a70.546,70.546,0,0,1,19.681-3.331c46.339-.958,64.408,34.622,100.849,28.04,44.67-8.07,61.511-80.2,109.089-86.786,35.969-4.982,53.891,28.254,98.427,28.254,117.2,0,116.274,17.254,233.617,17.254" transform="translate(0 42.137)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_190" data-name="Path 190" d="M0,257.38c101.256,0,116.043,16.967,192.57,11.465,19.882-1.428,39.3-3.735,61.448-5.742,35.3-3.316,60.59-16.344,89.357-29.178,49.23-21.964,68.7-48.208,122.913-65.248,21.053-6.618,41.523-13.763,65.295-17.186,39.18-5.643,66.081-4.687,95.812,6.032,56.035,20.2,78.478,76.764,163.715,76.764,119.64,0,119.392-175.122,238.927-175.122,52.855,0,77.806,75.97,115.4,97.7,32.879,16.318,44.443-2.021,76.734,14.894,29.582,15.5,39.593,55.566,72.992,65.308,28.606,8.342,46.921-8.792,77.005-17.043a77.1,77.1,0,0,1,19.063-2.95c45.08-1.011,64.16,29.364,98.782,22.5,43.346-8.6,61.094-74.082,107.512-81.122,34.119-5.174,53.008,23.454,96.195,23.454,119.866,0,118.9,23.623,238.925,23.623" transform="translate(0 36.879)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_191" data-name="Path 191" d="M0,258.762c102.946,0,118.525,11.342,195.44,5.495,19.564-1.487,39.153-3.617,61.73-5.337,35.95-2.842,61.7-15.732,89.716-29.205,48.2-23.179,68.694-52.372,122.147-70.327,20.714-6.959,41.395-13.883,65.66-16.813,40.112-4.846,67.543-2.5,96.659,8.732,55.333,21.352,78.96,75.945,165.067,75.945,122.32,0,122.057-176.52,244.235-176.52,51.992,0,77.807,68.329,113.726,91.2,30.958,17.175,43.171,5.269,73.507,23.166,27.5,16.223,38.508,53.191,70.051,63.423,27.129,8.8,45.86-4.828,75.411-11.918a86.413,86.413,0,0,1,18.447-2.568c43.818-1.063,63.909,24.092,96.716,16.952,42.013-9.144,60.678-67.947,105.935-75.457,32.272-5.354,52.125,18.65,93.961,18.65,122.54,0,121.536,29.994,244.235,29.994" transform="translate(0 31.622)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_192" data-name="Path 192" d="M0,260.144c104.636,0,121.007,5.727,198.31-.474,19.246-1.544,39-3.5,62.014-4.933,36.6-2.368,62.8-15.124,90.072-29.233,47.174-24.4,68.725-56.43,121.38-75.408,20.391-7.258,41.266-14,66.025-16.441,41.046-4.044,69-.313,97.51,11.433,54.62,22.509,79.442,75.127,166.418,75.127,125,0,124.721-177.917,249.545-177.917,51.126,0,77.806,60.688,112.051,84.706,29.038,18.033,41.914,12.545,70.28,31.439,25.433,16.936,37.424,50.82,67.11,61.537,25.652,9.261,44.8-.867,73.82-6.794A100.237,100.237,0,0,1,1392.364,211c42.559-1.118,63.654,18.8,94.649,11.407,40.679-9.711,60.269-61.793,104.358-69.791,30.427-5.521,51.242,13.849,91.727,13.849,125.212,0,124.169,36.364,249.545,36.364" transform="translate(0 26.365)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_193" data-name="Path 193" d="M0,261.526c106.326,0,123.491.118,201.18-6.445,18.928-1.6,38.849-3.383,62.3-4.529,37.25-1.894,63.9-14.521,90.429-29.259,46.135-25.634,68.715-60.607,120.614-80.491,20.05-7.6,41.137-14.118,66.389-16.068,41.977-3.239,70.457,1.88,98.359,14.136,53.9,23.674,79.924,74.307,167.77,74.307,127.679,0,127.385-179.315,254.853-179.315,50.263,0,77.807,53.047,110.379,78.21,27.116,18.892,40.671,19.806,67.053,39.71,23.378,17.637,36.336,48.454,64.167,59.652,24.173,9.729,43.742,3.089,72.23-1.667a123.383,123.383,0,0,1,17.21-1.8c41.3-1.17,63.394,13.5,92.583,5.863,39.336-10.3,59.866-55.612,102.782-64.128,28.588-5.672,50.359,9.048,89.493,9.048,127.885,0,126.8,42.734,254.855,42.734" transform="translate(0 21.107)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_194" data-name="Path 194" d="M0,262.908c108.016,0,125.974-5.48,204.05-12.415,18.61-1.653,38.7-3.265,62.581-4.123,37.9-1.422,65-13.92,90.787-29.288,45.089-26.868,68.705-64.782,119.848-85.57,19.709-7.948,41.007-14.235,66.753-15.694,42.908-2.433,71.91,4.076,99.209,16.835,53.175,24.851,80.4,73.489,169.119,73.489,130.361,0,130.052-180.713,260.165-180.713,49.4,0,77.806,45.408,108.706,71.713,25.194,19.751,39.452,27.051,63.826,47.982,21.342,18.326,35.244,46.093,61.224,57.767,22.691,10.2,42.683,7.035,70.639,3.458,5.51-.7,11-1.261,16.592-1.42,40.037-1.224,63.126,8.188,90.516.32,37.982-10.912,59.473-49.4,101.208-58.462,26.752-5.808,49.474,4.247,87.26,4.247,130.555,0,129.432,49.1,260.163,49.1" transform="translate(0 15.85)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_195" data-name="Path 195" d="M0,264.289c109.706,0,128.46-11.071,206.918-18.386,18.293-1.7,38.547-3.146,62.866-3.719,38.552-.948,66.089-13.321,91.143-29.314,44.037-28.109,68.7-68.96,119.083-90.651A184.321,184.321,0,0,1,547.127,106.9c43.839-1.544,73.358,6.279,100.058,19.537,52.44,26.038,80.888,72.67,170.471,72.67,133.039,0,132.716-182.109,265.473-182.109,48.532,0,77.807,37.767,107.033,65.215,23.274,20.611,38.258,34.288,60.6,56.256,19.329,19.006,34.148,43.735,58.281,55.881,21.205,10.673,41.628,10.977,69.048,8.584,5.371-.469,10.67-.872,15.973-1.039,38.778-1.278,62.849,2.855,88.45-5.224,36.616-11.555,59.092-43.154,99.631-52.8,24.924-5.928,48.591-.557,85.026-.557,133.228,0,132.064,55.474,265.473,55.474" transform="translate(0 10.593)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_196" data-name="Path 196" d="M0,265.671c111.4,0,130.956-16.566,209.788-24.355,17.978-1.737,38.4-3.029,63.15-3.315,39.2-.474,67.145-12.767,91.5-29.343,42.925-29.429,68.686-73.135,118.316-95.73,19.03-8.639,40.745-14.439,67.483-14.951,44.763-.771,74.8,8.485,100.907,22.238,51.695,27.233,81.369,71.851,171.822,71.851,135.719,0,135.382-183.507,270.783-183.507,47.667,0,77.806,30.126,105.36,58.719,21.352,21.468,37.1,41.515,57.371,64.527,17.342,19.684,33.022,41.429,55.339,54,19.691,11.188,40.573,14.909,67.455,13.709,5.234-.234,10.337-.484,15.356-.657,37.517-1.33,62.56-2.5,86.382-10.768,35.229-12.233,58.727-36.867,98.055-47.133,23.1-6.031,47.708-5.357,82.793-5.357,135.9,0,134.7,61.844,270.783,61.844" transform="translate(0 5.336)" fill="none" stroke={color} stroke-width="1" />
                    <path id="Path_197" data-name="Path 197" d="M0,267.052c138.294,0,137.9-33.236,276.091-33.236,137.675,0,138.682-144.758,277.255-144.758,136.883,0,137.856,95.971,274.93,95.971,138.4,0,138.046-184.9,276.091-184.9,138.172,0,138.646,195.969,276.093,195.969s137.52-68.215,276.091-68.215,137.328,68.215,276.093,68.215" transform="translate(0 0.078)" fill="none" stroke={color} stroke-width="1" />
                </g>
            </g>
        </svg>
    );
};