import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledAtag = styled.a`
    width: 26rem;
    margin-bottom: 2rem;
    padding-inline: 1.5rem;
    align-self: center;

    ${theme.mediaQueries.mobileMax} {
        margin-bottom: 2rem;
    };
`;

export const StyledBusinessCard = styled.div<{bordered: boolean}>`
    display: flex;
    flex-direction: column;
    background-color: white;
    max-height: 20rem;
    height: 100%;
    min-height: 12rem;
    font-size: 1.43rem;
    background: white no-repeat padding-box;
    border-radius: 1.5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-block: 2rem;

    ${p => p.bordered ? `border: 1px solid ${theme.palette.gray};` : ''}
    ${theme.mediaQueries.mobileMax} {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        height: 8.6rem;
        width: auto;
        padding: 0 1.1rem;
    };
`;



export const BusinessInner = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 0;
    ${theme.mediaQueries.desktopMin} {
        padding-top: 0.6rem;
        padding-bottom: 0;
    };
    ${theme.mediaQueries.mobileMax} {
        align-items: center;
        padding-bottom: 0;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
    };
`;

export const SvgWrapper = styled.div`
    ${theme.mediaQueries.mobileMax} {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 1.1rem;

    };
    
`;
