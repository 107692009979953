import { /* createEffect, */ Match, mergeProps, Show, Switch, useContext } from 'solid-js';
import { ArrowRightIcon } from '../../components/icons-library/arrow-right';
import theme from '../../style/theme';
import { StyledFlexRow } from '../utility-style-components/flex';
import { ButtonProps, ButtonWpAttrs } from './button-types';
import { StyledButton, StyledLink } from './button.styles';
import { ErrorCatcher } from '../../tools/error-catcher';
import { navigateToAnchor } from '../../tools/scroll-to-element';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { removeTrailingSlash } from '../../tools/remove-trailing-slash';
import removeAmpersand from '../../tools/remove-ampersand';
import { isExternalLink } from '../../tools/check-is-external-url';
import { Link } from '../link/link';

const defaultProps = {
    variant: 'primary',
    darkMode: false,
};

export const Button = (componentProps: ButtonProps) => {
    const { viewport, siteInfo, } = useContext(AppContext);

    const props = mergeProps(defaultProps, componentProps) as ButtonProps;
    
    const isSmallScreen = () => viewport.width <= theme.breakpoints.TABLET;

    const buttonClass = () => {
        // Because we are using tailwind and styled components simultaneously we have to do a little bit of magic here
        let buttonBgClass: string;
        if (props.variant === 'primary' && !props.darkMode) {
            buttonBgClass = 'bg-red';
        } else {
            buttonBgClass = 'bg-transparent';
        }

        if (props.disabled) {
            buttonBgClass = 'bg-gray-light';
        }

        return buttonBgClass;
    };


    let url = removeTrailingSlash(props.url);
    url = removeAmpersand(url);

    const anchorButton = () => {
        return (
            <ErrorCatcher componentName='Button'>
                <StyledButton
                    class={buttonClass()}
                    type="button"
                    onClick={(e: any) => navigateToAnchor({
                        event: e, 
                        id: url, 
                        smallScreen: isSmallScreen(),
                        isCareSite: siteInfo.siteType === 'atos-care',
                    })}
                    variant={props.variant}
                    noCaps={props.variant === 'tertiary' && props.noCaps}
                    darkMode={props.darkMode} customCss={props.customCss}
                    isDisabled={props.disabled}
                    small={props.small}
                    noBlockSpacing={props.noBlockSpacing}
                    horizontalAlignment={props.horizontalAlignment}
                    positionToBottom={props.positionToBottom}
                >
                    {props.label ? buttonRemoveAmpersand(props.label) : 'Click'}
                </StyledButton>
            </ErrorCatcher>
        );
    };

    const buttonRemoveAmpersand = (text?: string) => {
        if (!text) {
            return '';
        }
        /**
         * If you type "&amp;" in the CMS, it will be converted to "&amp;amp;" by the time it gets here.
         * We normally handle removing "&amp;" in removeAmpersand function, but we need to handle "&amp;amp;" here.
         */
        return removeAmpersand(text.replace(/&amp;amp;/g, '&'));
    };

    if (props.variant === 'arrow') {
        // Primarily from gutenberg button block
        const alignmentMap = {
            'left': 'flex-start',
            'center': 'center',
            'right': 'flex-end',
        };        

        const style = {
            ...props.positionToBottom ? { 'margin-top': 'auto' } : {},
            ...props.horizontalAlignment ? { 'align-self': alignmentMap[ props.horizontalAlignment ] } : {}
        };

        return (
            <div style={style}>
                <Link
                    label={props.label}
                    url={props.url || ''}
                    arrowPosition='right'
                    arrowDirection='right'
                    plainStyle={true}
                    arrowColor={theme.palette.red}
                    labelColor={theme.palette.red}
                    bold={true}
                    size='large'
                />
            </div>
        );
    } else if (props.isLink) {

        if (url?.slice(0,1) === '#' && !isExternalLink(url) && !url?.match(/\/(wp-content|download)/i)) {
            return anchorButton();
        }

        let target = props.target;
        if (url?.match(/\/(wp-content|download)/i)) {
            target = '_blank';
        }

        return (
            <ErrorCatcher componentName='Button'>
                <Switch>
                    <Match when={target}>
                        <StyledLink
                            class={buttonClass()}
                            href={url ? url : '#'}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant={props.variant}
                            noCaps={props.variant === 'tertiary' && props.noCaps}
                            darkMode={props.darkMode}
                            customCss={props.customCss}
                            small={props.small}
                            noBlockSpacing={props.noBlockSpacing}
                            ref={(el: HTMLElement) => props.element && props.element(el)}
                            horizontalAlignment={props.horizontalAlignment}
                            positionToBottom={props.positionToBottom}
                        >
                            {props.label ? buttonRemoveAmpersand(props.label) : 'Click'}
                        </StyledLink>
                    </Match>
                    <Match when={!target}>
                        <StyledLink
                            class={buttonClass()}
                            href={url ? url : '#'}
                            variant={props.variant}
                            darkMode={props.darkMode}
                            customCss={props.customCss}
                            small={props.small}
                            noBlockSpacing={props.noBlockSpacing}
                            ref={(el: HTMLElement) => props.element && props.element(el)}
                            horizontalAlignment={props.horizontalAlignment}
                            positionToBottom={props.positionToBottom}
                        >
                            <Show when={props.arrow}>
                                <StyledFlexRow>
                                    <ArrowRightIcon
                                        height={ 1.29 }
                                        fill={ props.disabled
                                            ? theme.palette.lightGray
                                            : props.darkMode ? theme.palette.white : theme.palette.red
                                        }
                                    />
                                    {props.label ? buttonRemoveAmpersand(props.label) : 'Click'}
                                </StyledFlexRow>
                            </Show>
                            <Show when={!props.arrow}>
                                {props.label ? buttonRemoveAmpersand(props.label) : 'Click'}
                            </Show>
                        </StyledLink>
                    </Match>
                </Switch>
            </ErrorCatcher>
        );
    } else if (props.arrow && props.variant === 'tertiary') {
        return (
            <ErrorCatcher componentName="Button">
                <StyledButton
                    class={buttonClass()}
                    type="button"
                    onClick={props.onClick}
                    variant="tertiary"
                    darkMode={props.darkMode}
                    customCss={props.customCss}
                    noCaps={props.noCaps}
                    isDisabled={props.disabled}
                    small={props.small}
                    noBlockSpacing={props.noBlockSpacing}
                    ref={(el: HTMLElement) => props.element && props.element(el)}
                    horizontalAlignment={props.horizontalAlignment}
                    positionToBottom={props.positionToBottom}
                >
                    <StyledFlexRow>
                        <ArrowRightIcon
                            height={1.29}
                            fill={props.disabled ? theme.palette.lightGray : props.darkMode ? theme.palette.white : theme.palette.red}
                        />
                        <div innerHTML={buttonRemoveAmpersand(props.label)} />
                    </StyledFlexRow>
                </StyledButton>
            </ErrorCatcher>
        );
    } else {
        return (
            <ErrorCatcher componentName="Button">
                <StyledButton
                    class={buttonClass()}
                    id={props.id}
                    type="button"
                    onClick={props.onClick}
                    variant={props.variant}
                    noCaps={props.variant === 'tertiary' && props.noCaps}
                    darkMode={props.darkMode}
                    customCss={props.customCss}
                    isDisabled={props.disabled}
                    innerHTML={props.label}
                    small={props.small}
                    noBlockSpacing={props.noBlockSpacing}
                    ref={(el: HTMLElement) => props.element && props.element(el)}
                    horizontalAlignment={props.horizontalAlignment}
                    positionToBottom={props.positionToBottom}
                >
                    <div innerHTML={buttonRemoveAmpersand(props.label)} />
                </StyledButton>
            </ErrorCatcher>
        );
    }
};

Button.parseProps = (atts: ButtonWpAttrs) => {
    return atts;
};
