import { Owner, getOwner, runWithOwner, useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { Heading } from '../../../ui-components/heading/heading';
import { Text } from '../../../ui-components/text/text';
import { StyledAdmin, StyledPdfDashboard } from './pdf-dashboard.style';
import { ContentItem, Searchbar } from '../../search/searchbar-standalone';
import { Section } from '../../../grid-system/section/section';
import { Grid } from '../../../grid-system/grid/grid';
import { EnhancedContentItem, EntitySlug, supportedTypes, pdfStore, setPdfStore } from '../pdf-store';
import { Arrangement } from './arrangement/arrangement';
import { TitleModal } from './title-modal/title-modal';
import { PdfFactory } from '../pdf-factory/pdf-factory';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { CollectionsList } from './collections/collections-list';
import { SeoSettings } from '../../seo-settings/seo-settings';
import { Portal } from 'solid-js/web';


export const PdfBuilderDashboard = () => {
    const {
        productsEntityName, 
        blogAndNewsEntityName, 
        eventsEntityName, 
        localize,
        ...rest
    } = useContext(AppContext);

    const owner = getOwner();

    setPdfStore('entitySlugMap', {
        product: productsEntityName,
        'blog-and-news': blogAndNewsEntityName,
        event: eventsEntityName,
    });

    setPdfStore('entityNameMap', {
        product: localize('product', 'Product'),
        'blog-and-news': `${localize('blog', 'Blog')} & ${localize('news', 'News')}`,
        event: localize('event', 'Event'),
        page: localize('page', 'Page'),
    });

    const formatPostItem = (post: ContentItem): EnhancedContentItem => {
        const type = post.type as EntitySlug;

        const entityName = pdfStore.entitySlugMap[ type ];
        const key = entityName ? post.url.replace(entityName, type) : post.url;

        return {
            ...post,
            key
        };
    };

    const addItem = (originalPost: ContentItem) => {
        setPdfStore('undo', []);
        const post = formatPostItem(originalPost);

        const externalId = post?.objectID;
        const type = post?.type as EntitySlug;

        if (!externalId || !type) {
            return;
        }

        if (!supportedTypes.includes(type)) {
            return;
        }

        if (type === 'product') {
            const products = [ ...pdfStore.products, externalId ];
            setPdfStore('products', products);
        }

        if (type === 'blog-and-news') {
            const articles = [ ...pdfStore.articles, externalId ];
            setPdfStore('articles', articles);
        }

        const posts = [ ...pdfStore.workingCollectionPosts ];
        posts.push(post);
        setPdfStore('workingCollectionPosts', posts);
    };

    const excludedEndpointsForBuilderList = () => {
        const endpoints = [
            rest.ifuPage.url,
            rest.productsListingPage?.url,        
            rest.eventsListingPage?.url,
            rest.hcpEventsListingPage?.url,
            rest.blogsNewsListingPage?.url,
            rest.hcpBlogsNewsListingPage?.url,
            rest.videosListingPage?.url,
            rest.pdfBuilderPage,
            rest.privacyPolicyPage,
            rest.newsletterSignupPage,
            rest.pageRequestPasswordResetUrl,
            rest.pageResetPasswordUrl,
            rest.pageVerifyAccountUrl,
            rest.shopPage?.url,
            rest.myContentPage?.url,
            rest.page404?.url,
            rest.pageRegistration?.url,
            rest.searchPage?.url,
            rest.productContactFormUrl,
        ].filter(endpoint => endpoint !== undefined && endpoint !== '');
    
        return endpoints as string[];
    };

    return (
        <ErrorCatcher componentName="Pdf Builder">
            <StyledPdfDashboard>
                <SeoSettings seo={{ noIndex: true }} title={localize('pdf-builder-title', 'PDF Builder')} />
                <StyledAdmin>
                    <Section widthType="bgFull">
                        <Grid templateShorthand={ [ 6, 6 ] } responsive={{ smallDesktop: [ 6, 6 ], tablet: [ 12 ] }}>
                            <div>
                                <Heading tag="h1" variant="large">
                                    {localize('pdf-builder-title', 'PDF Builder')}
                                </Heading>

                                <Text fontSize="normal">
                                    {localize('pdf-builder-description', 'Create a PDF with the products and posts you would like to combine.')}
                                </Text>

                                <Searchbar
                                    enabledPostTypes={ supportedTypes }
                                    excludedEndpoints={ excludedEndpointsForBuilderList() }
                                    onPostSelect={ addItem }
                                />

                                <Arrangement />

                                <TitleModal />
                            </div>

                            <CollectionsList />
                        </Grid>
                    </Section>
                </StyledAdmin>
            </StyledPdfDashboard>
            
            <Portal mount={document.getElementById('pdf-factory-root')!}>
                {runWithOwner(owner as Owner, () => (
                    <PdfFactory />
                ))}
            </Portal>
        </ErrorCatcher>
    );
};