import { Show, createSignal, onMount } from 'solid-js';
import {
    StyledProductInfo,
    StyledProductActions,
    StyledProductIntroLeft,
    StyledProductIntroRight,
    StyledProductIconTabRow,
    StyledProductImage
} from './product-intro.styles';
import type { ProductIntroProps } from './product-intro-types';
import { Heading } from '../../../ui-components/heading/heading';
import { Text } from '../../../ui-components/text/text';
import { Button } from '../../../ui-components/button/button';
import { StyledVerticalSpace } from '../../../ui-components/utility-style-components/spacing';
import { For, useContext } from 'solid-js';
import { SliderWithThumbnails } from '../../../ui-components/slider/slider-with-thumbnails';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import { getBackgroundImageSrcData, ImageSrcData } from '../../image-component/image-component';
import { Grid } from '../../../grid-system/grid/grid';
import theme from '../../../style/theme';
import { ProductIconTab } from '../product-icon-tab/product-icon-tab';
import { ImageWordpress } from '../../../types/shared';

export const ProductIntro = (props: ProductIntroProps) => {
    const { imagesServiceUrl, supportedImageFormats, viewport, siteInfo } = useContext(AppContext);
    const [mounted, setMounted] = createSignal(false);

    onMount(() => {
        setMounted(true);
    });

    const createSliderCards = () => {
        const featuredImage = props.images?.find((image: { isFeatured: boolean, file: { url: string} }) => image.isFeatured && image.file?.url);
        const featuredImageSrc = getBackgroundImageSrcData({
            imagesServiceUrl,
            supportedImageFormats,
            src: featuredImage?.file?.url || '',
            width: 500, // Assume mobile from the start
            height: 'AUTO',
        });

        return (
            <>
                <Show when={featuredImage}>
                    <div>
                        <StyledProductImage 
                            src={featuredImageSrc.original}
                            alt={featuredImage.file?.alt || ''}
                            title={featuredImage.file?.title || ''}
                        />
                    </div>
                </Show>

                <For each={props.images}>
                    {(image: { file: ImageWordpress, isFeatured: boolean }) => {
                        if (image?.file?.url && !image?.isFeatured) {
                            const backgroundImageSrcData: ImageSrcData = getBackgroundImageSrcData({
                                imagesServiceUrl,
                                supportedImageFormats,
                                src: image.file.url,
                                width: 500, // Assume mobile from the start
                                height: 'AUTO',
                            });
                            return (
                                <StyledProductImage
                                    src={backgroundImageSrcData.original}
                                    alt={image.file?.alt || ''}
                                    title={image.file?.title || ''}
                                />
                            );
                        }
                    }}
                </For>
            </>
        );
    };

    return (
        <Grid templateShorthand={[5, 5]} responsive={{ mobile: [12], tablet: [12] }}>
            <StyledProductIntroLeft>
                <Heading tag={'h1'}>{props.productTitle}</Heading>
                <StyledProductInfo>
                    <Text displayRedVerticalLine={true} fontSize="normal" splitParagraph={true}>
                        {props.shortDescription}
                    </Text>
                </StyledProductInfo>
                <StyledProductActions>
                    <Show when={viewport.width > theme.breakpoints.MOBILE && props.purchaseButton && (siteInfo.siteType === 'default')}>
                        <Button label={props.purchaseButton.title} isLink={true} url={props.purchaseButton.url} />
                        <StyledVerticalSpace />
                    </Show>
                </StyledProductActions>
            </StyledProductIntroLeft>
            <StyledProductIntroRight>
                <StyledProductIconTabRow>
                    <ProductIconTab iconName={props.iconName} type={'product-intro'} />
                </StyledProductIconTabRow>
                <Show when={props.images?.length && mounted()}>
                    <SliderWithThumbnails thumbnailsPerView={3}>
                        {createSliderCards()}
                    </SliderWithThumbnails>
                </Show>
            </StyledProductIntroRight>
        </Grid>
    );
};
