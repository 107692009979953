import { Show } from 'solid-js';
import { NestedCategory } from '../../../../products/products-types';
import { DocumentType } from '../../../../product/product-types';
import { 
    StyledIfuPdfHeadingWrapper, 
    StyledIfuPdfHeadingWrapperAreaOfCare,
} from './ifu-components.style';
import { Heading } from '../../../../../ui-components/heading/heading';
import { Text } from '../../../../../ui-components/text/text';
import { ComponentPiece } from '../../pdf-renderer/pdf-renderer';
import { hasBlock } from '../page/page-components';
import { IfuProductsStore } from '../../../../../stores/instructions-for-use-products-store';
import { DocumentPDF } from '../document/document';


const ifuHeader: ComponentPiece = {
    elements: (data) => {
        if (!hasBlock(['atos/manuals'], data.blocks)) {
            return [];
        }

        let categoriesString = '';

        IfuProductsStore?.selectedProductCategories?.forEach((category: NestedCategory, index: number) => {
            const isLast = IfuProductsStore?.selectedProductCategories?.length - 1 === index;
            const suffix = isLast ? '' : ', ';
            categoriesString += `${category.data.name}${suffix}`;
        });

        const header = (
            <div>
                <Heading tag="h1" variant={'giantRedPDF'}>
                    {IfuProductsStore?.labels?.heading}
                </Heading>

                <StyledIfuPdfHeadingWrapper>
                    <Show when={IfuProductsStore?.areaOfCare?.name}>
                        <StyledIfuPdfHeadingWrapperAreaOfCare>
                            <Heading tag="h2" variant={'tinyGrayPDF'}>
                                {IfuProductsStore?.labels?.topCategoryHeading}
                            </Heading>
                            <Text fontSize="normalPDF" lineLimit={6}>
                                {IfuProductsStore?.areaOfCare?.name}
                            </Text>
                        </StyledIfuPdfHeadingWrapperAreaOfCare>
                    </Show>

                    <Show when={IfuProductsStore?.selectedProductCategories?.length}>
                        <div>
                            <Heading tag="h2" variant={'tinyGrayPDF'}>
                                {IfuProductsStore?.labels?.firstNestedCategoryHeading}
                            </Heading>
                            <Text fontSize="normalPDF" lineLimit={6}>
                                {categoriesString}
                            </Text>
                        </div>
                    </Show>

                    <Show when={IfuProductsStore?.selectedTypes?.length}>
                        <StyledIfuPdfHeadingWrapperAreaOfCare>
                            <Heading tag="h2" variant={'tinyGrayPDF'}>
                                {IfuProductsStore?.labels?.secondNestedCategoryHeading}
                            </Heading>
                            <Text fontSize="normalPDF" lineLimit={6}>
                                {IfuProductsStore?.selectedTypes?.map((type: NestedCategory) => type.data.slug)}
                            </Text>
                        </StyledIfuPdfHeadingWrapperAreaOfCare>
                    </Show>
                </StyledIfuPdfHeadingWrapper>
            </div>
        );

        return [
            header,
        ];
    }
};

const ifuCards: ComponentPiece = {
    canBeBrokenUp: true,
    elements: (data) => {
        if (!hasBlock(['atos/manuals'], data.blocks)) {
            return [];
        }

        if (IfuProductsStore?.results?.length <= 0) {
            return [];
        }
        
        const cards = IfuProductsStore.results.map((doc: DocumentType) => {
            return (
                <DocumentPDF doc={doc} />
            );
        });

        return [
            ...cards,
        ];
    },
};


export const components = [
    ifuHeader,
    ifuCards,
];