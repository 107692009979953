import { styled } from 'solid-styled-components';
import { ProductIconName } from '../../../../product/product-icon-tab/product-icon-tab-types';
import { backgroundColorsByIconName } from '../../../../product/product-icon-tab/product-icon-tab.styles';
import theme from '../../../../../style/theme';

export const StyledPdfIconTabRow = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

const sharedIconTabStyles = `
    top: 3rem;
    right: 0;
    position: absolute;
    border-radius: 2.2rem 0 0 2.2rem;
    height: 4rem;
    min-width: 9.04rem;
    box-sizing: border-box;
    z-index: 1;
    display: flex;
    align-items: center;
`;

export const StyledPdfProductIconTab = styled.div<{ productIconName: ProductIconName }>`
    ${sharedIconTabStyles}
    width: 9.04rem;
    background-color: ${p => backgroundColorsByIconName[p.productIconName!] };
    padding: 0.71rem;
`;

export const StyledPdfArticleIconTab = styled.div`
    ${sharedIconTabStyles}
    width: max-content;
    background-color: ${theme.palette.darkGray};
    padding-block: 0.7rem;
    padding-left: 1.8rem;
    padding-right: 3rem;
`;

export const StyledPdfEventIconTab = styled.div`
    ${sharedIconTabStyles}
    width: max-content;
    background-color: ${theme.palette.pink};
    padding-block: 0.7rem;
    padding-left: 1.8rem;
    padding-right: 3rem;
`;