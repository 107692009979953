import theme from '../../style/theme';
import { styled } from 'solid-styled-components';


export const StyledAnchorLinksContainer = styled.div`
    border-left: 2px solid ${theme.palette.red};
    padding-left: 3rem;

    ${theme.mediaQueries.desktopLargeMax} {
        padding-left: 0;
        margin-top: 2rem;
        border-left: unset;
    }

    ${theme.mediaQueries.tabletMax} {
        padding-left: 0;
        margin-top: 2rem;
        border-left: unset;
    }
`;

export const StyledMainContent = styled.div`
    padding-block: 9rem;

    ${theme.mediaQueries.desktopLargeMax} {
        padding-top: 5rem;
    }

    ${theme.mediaQueries.tabletMax} {
        padding-block: 0;
        padding-bottom: 2rem;
    }
`;

export const StyledProductImageWrapper = styled.div`
    width: 90%;
    max-width: 32.14rem;
    margin-inline: auto;
    margin-bottom: 2rem;
`;

export const StyledProductHeroSidebar = styled.div`
    height: 100%;
    position: sticky;
    top: ${theme.header.desktop.height.floating.value + theme.header.desktop.height.floating.unit};
    padding-bottom: 22rem;
    padding-top: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 3rem;

    ${theme.mediaQueries.desktopLargeMax} {
        padding-top: 5rem;
        padding-right: 0;
        padding-bottom: 11rem;
        height: auto;
    }

    ${theme.mediaQueries.tabletMax} {
        position: unset;
        padding-inline: unset;
        padding-top: 4rem;
        margin-right: unset;
        padding-bottom: 0;
        z-index: 2;
    }
`;

export const StyledSwirlDesktop = styled.div`
    position: absolute;
    width: 100%;
    bottom: calc(34vh - 0.3 * 100vw / 2);
    z-index: -1;
`;

export const StyledProductsHeroAndSwirlContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const StyledSwirlMobile = styled.div`
    position: absolute;
    width: 100%;
    bottom: -8rem;
    left: 0;
    & svg {
        width: 100%;
    }
`;
