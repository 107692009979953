export const getRootFontSize = () => {
    try {        
        if (!window) {
            return 1;
        }
    
        const pixelString = window.getComputedStyle(document.body).getPropertyValue('font-size');
        const parsedString = pixelString.replace('px', '');
        const pixelValue = parseInt(parsedString);
        return pixelValue;
    } catch(err) {
        return 1;
    }
};

export const convertRemToPixel = (rem: number) => {    
    return rem * getRootFontSize();
};

export const convertPixelToRem = (px: number) => {
    const pixels = px / getRootFontSize();
    const rounded = Math.round(pixels * 100) / 100;
    
    return rounded;
};