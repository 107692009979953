import { Show } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { fetchVideos } from './fetch-videos';
import { VideosComponent } from './videos-component';
import { VideoData } from './videos-types';

export type VideoContainer = {
    content: VideoData;
};

export const Videos = (props: VideosProps) => {
    const videosData = fetchVideos(props);

    return (
        <ErrorCatcher componentName='Videos list'>
            <Show when={videosData()} fallback={<div>Loading...</div>}>
                <VideosComponent videos={videosData()} />
            </Show>
        </ErrorCatcher>
    );
};

export type VideosProps = {
    tag?: {
        slug: string
    };
    key?: string,
    limit?: number;
}

Videos.parseProps = (atts: any) => {
    return {
        tag: atts.tag,
    };
};
