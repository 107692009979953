import { ContextType } from '../../../../app-context-provider/app-context-provider';
import DynamicModuleGenerator from '../../../../dynamic-module-generator';
import { Block } from '../../../../types/shared';
import { VariantTagMap } from '../../../../ui-components/heading/heading';
import { HeadingWordpressBlock } from '../../../../ui-components/heading/heading.d';
import { TextWordpressBlock } from '../../../../ui-components/text/text.d';

type ExtractBlocksProps = {
    blocks: Block[];
    permalink: string;
    title: string;
};


export const extractBlocks = (context: ContextType, data: ExtractBlocksProps) => {
    const unpackedBlocks = extractBlocksFromSections(data.blocks);
    const preparedBlocks = modifyBlocksForPdf(context, unpackedBlocks);

    const components = preparedBlocks.map((block) => {
        return (
            <DynamicModuleGenerator
                content={[ block ]}
                permalink={data.permalink}
                pageTitle={data.title}
                skipSectionConversion={true}
            />
        );
    });

    return [ ...components ];
};

const extractBlocksFromSections = (blocks: Block[]) => {
    const unpackedBlocks = [];

    for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];

        if (block.blockName !== 'next24hr/section') {
            unpackedBlocks.push(block);
            continue;
        }

        for (const column of block.blocks) {
            if (column.image) {
                unpackedBlocks.push({
                    ...column,
                    'blockName': 'atos/bg-image-pdf',
                });
            }
            
            unpackedBlocks.push(...column.blocks);
        }

        if (i !== blocks.length - 1 && !block.combineWithBelow) {
            // Add some space before each section, except for the first one
            const spacer = {
                blockName: 'atos/spacer-pdf',
                height: 2,
            };

            unpackedBlocks.push(spacer);
        }
    }

    return unpackedBlocks;
};

const modifyBlocksForPdf = (context: ContextType, blocks: any[]) => {
    const modifiedBlocks: any[] = [];

    blocks.forEach((block, index) => {
        if (block.blockName === 'atos/heading') {
            modifiedBlocks.push(modifydHeading(block));
            return;
        }

        if (block.blockName === 'atos/paragraph') {            
            const nextBlock = blocks[index + 1];
            const isLastParagraphPdf = !nextBlock || nextBlock.blockName !== 'atos/paragraph';

            modifiedBlocks.push(modifyParagraph(context, {
                ...block,
                isLastParagraphPdf,
            }));
            return;
        }

        if (block.blockName === 'atos/divider') {
            modifiedBlocks.push({
                ...block,
                forPdfBuilder: true,
            });
            return;
        }

        if (block.blockName === 'atos/video-embed') {
            modifiedBlocks.push({
                ...block,
                blockName: 'atos/video-embed-pdf',
            });
            return;
        }

        if (block.blockName === 'atos/button') {
            modifiedBlocks.push(linkLike(block, context));
            return;
        }

        if (block.blockName === 'atos/hero') {
            modifiedBlocks.push({
                ...block,
                blockName: 'atos/hero-pdf',
            });
            return;
        }

        if (block.blockName === 'atos/hero-campaign') {
            modifiedBlocks.push({
                ...block,
                blockName: 'atos/hero-pdf',
            });
            return;
        }

        if (block.blockName === 'atos/slider') {
            if (block.dynamicCards) {
                modifiedBlocks.push({
                    ...block,
                    blockName: 'atos/slider-pdf',
                });
                return;
            }

            if (!block.cards || block.cards.length === 0) {
                return;
            }

            block.cards.forEach((card: any) => {
                modifiedBlocks.push({
                    ...block,
                    card,
                    blockName: 'atos/slider-pdf',
                });
            });

            return;
        }

        if (block.blockName === 'atos/quote') {
            modifiedBlocks.push({
                ...block,
                forPdfBuilder: true,
            });
            return;
        }

        if (block.blockName === 'atos/image') {
            modifiedBlocks.push({
                ...block,
                blockSpacing: true,
                forPdfBuilder: true,
            });
            return;
        }

        if (block.blockName === 'atos/testimony') {
            modifiedBlocks.push({
                ...block,
                blockName: 'atos/testimony-pdf',
            });
            return;
        }

        if (block.blockName === 'core/list') {
            modifiedBlocks.push({
                ...block,
                fontSize: block.fontSize ? block.fontSize+'PDF' : 'normalPDF',
                colorText: block.colorText ?? { name: 'black', color: '#000' },
            });
            return;
        }

        if (block.blockName === 'core/navigation-link') {
            modifiedBlocks.push(linkLike(block, context));
            return;
        }

        if (block.blockName === 'atos/bg-image-pdf') {
            modifiedBlocks.push({
                ...block,
            });
            return;
        }

        if (block.blockName === 'atos/spacer-pdf') {
            modifiedBlocks.push({
                ...block,
            });
            return;
        }
    });

    return modifiedBlocks;
};

const linkLike = (block: any, context: ContextType) => {
    const label = block.label.charAt(0).toUpperCase() + block.label.slice(1);
    const text = `<a href="${block.url}">${label}</a>`;

    return {
        ...block,
        blockName: 'atos/paragraph',
        children: exposeInlineLinks(context, text),
        fontSize: 'largerPDF',
        color: { color: 'red' },
        pdfBuilderText: true,
    };
};

const modifydHeading = (block: HeadingWordpressBlock) => {
    let pdfFontSize = block.manualSize ? block.size : VariantTagMap[block.tag];
    pdfFontSize+='PDF';

    return {
        ...block,
        size: pdfFontSize,
        manualSize: true,
        forPdfBuilder: true,
    };
};

const modifyParagraph = (context: ContextType, block: TextWordpressBlock) => {
    const pdfFontSize = block.fontSize + 'PDF';
    const color = Array.isArray(block.color) ? 'black' : block.color?.name;
    const text = exposeInlineLinks(context, block.children);

    return {
        ...block,
        children: text,
        fontSize: pdfFontSize,
        pdfBuilderText: true,
        color: { color },
        overrideDefaultColor: true,
        displayRedVerticalLine: true,
    };
};

const exposeInlineLinks = (context: ContextType, text: string) => {
    const linkRegex = /<a [^>]*href="([^"]+)"[^>]*>([^<]+)<\/a>/g; // matches e.g. <a rel="noreferrer noopener" href="https://google.com" data-type="URL" data-id="https://google.com" target="_blank">Google</a>
    const domain = context.siteInfo.currentDomain;

    const newText = text.replace(linkRegex, (match, url, linkText) => {
        url = url.replace(/\/$/, ''); // remove trailing slash
        url = url.startsWith('http') ? url : `${domain}${url}`; // add domain if missing
        url = url.replace(/(^\w+:|^)\/\//, ''); // remove protocol

        return `<u>${linkText}</u> <span style="color: rgba(0,0,0,0.6)">(<em>${url}</em>)</span>`; // format to e.g. "Google (google.com)", with styling
    });

    return newText;
};