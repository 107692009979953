import { styled } from 'solid-styled-components';
import { StyledCommonA4Body, StyledCommonFittingTemplate } from '../shared-components.style';
import { pdfStore } from '../../../pdf-store';

const pageRightPadding = 125;

const StyledPageBody = styled.div`
    padding-right: ${pageRightPadding}px;
`;

export const PageA4Body = (props: any) => {
    return (
        <StyledCommonA4Body debugging={pdfStore.debugging}>
            <StyledPageBody>
                {props.children}
            </StyledPageBody>
        </StyledCommonA4Body>
    );
};





const StyledPageFittingTemplate = styled.div`
    padding-right: ${pageRightPadding}px;
`;

export const PageFittingTemplate = (props: any) => {
    return (
        <StyledCommonFittingTemplate debugging={pdfStore.debugging}>
            <StyledPageFittingTemplate>
                {props.children}
            </StyledPageFittingTemplate>
        </StyledCommonFittingTemplate>
    );
};
