import theme from '../../../style/theme';
import { styled } from 'solid-styled-components';
import { inputStyle, mobilePadding } from '../common.style';

export const StyledInput = styled.input`
    ${inputStyle}

    width: 1.57rem;
    min-width: 1.57rem;
    height: 1.57rem;
    border-radius: 0.36rem;

    &:checked {
        border: 1px solid ${theme.palette.red};
        & ~ span {
            display: block !important;
        }
    }
`;

export const StyledCheckmark = styled.span`
    position: absolute;
    top: 50%;
    left: 0;
    height: 1.57rem;
    width: 1.57rem;
    min-width: 1.57rem;
    display: none;
    &::after {
        position: absolute;
        display: block;
        content: '';
        left: 0.5rem;
        bottom: 1.2rem;
        width: 0.54rem;
        height: 0.91rem;
        border: solid ${theme.palette.red};
        border-width: 0 0.21rem 0.21rem 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
