import { styled } from 'solid-styled-components';
import { theme } from '../../style/theme';

export const StyledMenu = styled.div`
    container-type: inline-size;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: ${theme.palette.white};
    overflow-y: auto;
    overflow-x: hidden;
`;
