import theme from '../../style/theme';
import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    viewBox: '0 0 22.047 25.718',
    fill: theme.palette.red,
};

export const PlayIcon = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path 
                id="play-solid"
                d="M20.727,42.506a2.765,2.765,0,0,1,1.321,2.354,2.617,2.617,0,0,1-1.321,2.3L4.193,57.267a2.649,2.649,0,0,1-2.785.1A2.757,2.757,0,0,1,0,54.965V34.755A2.756,2.756,0,0,1,4.193,32.4Z"
                transform="translate(0 -31.999)"
            />
        </SvgIcon>
    );
};

